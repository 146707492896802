import React, { Component } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { Link, Redirect } from 'react-router-dom';

// import Custom Components
import { connect } from 'react-redux';
import Select from 'react-select'
import { URL } from '../../../../variables';
import { maskPrice } from '../../../../Auxiliar/Masks';
import { logout } from '../../../../actions';

class EditarBannerTopo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading_save: false,
            msgErro:'',
            redirect:false,
            foto:'',
            foto_path:'',
            title: '',
            subtitle: '',
            link: '',
            price: '',
            order:''

        };
    }

    salvar() {
        this.setState({ loading_save: true, msgErro: '' });
        let form = new FormData();

        form.append('title', this.state.title);
        form.append('subtitle', this.state.subtitle);
        form.append('ordem', this.state.order);

        form.append('link', this.state.link);
        form.append('price', this.state.price.replace(/ /g, '').replace(',', '.').replace('R$', ''));
        form.append('type', 'web');

        form.append('foto', this.state.foto_path);
        form.append('foto_file', this.state.foto);

        form.append('banner_id', this.props.match.params.id);

        fetch(`${URL}api/admin/banner_topo/editar_banner`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_save: false, msgErro: erro });
                    window.scrollTo({top:0,behavior: 'smooth'})

                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }

    componentDidMount() {
        console.log(this.props.token);
        this.get_banner();
    }

    get_banner() {
        this.setState({ loading: true });

        fetch(`${URL}api/admin/get_banner/${this.props.match.params.id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                }
                else {
                   
                    this.setState({
                        loading: false,
                        title: resp.banner.title,
                        subtitle: resp.banner.subtitle,
                        price: resp.banner.price!=null ? maskPrice(resp.banner.price):'',
                        link:resp.banner.link==null?'':resp.banner.link,
                        order:resp.banner.order,
                        foto_path: URL+ resp.banner.image,
                    });

                }




            } catch (err) {
                console.log(err);
                this.setState({
                    loading: false
                });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({
                    loading: false
                });
            });
    }

    render() {

        return (
            <div>
                {this.state.redirect == true && <Redirect to={'/conta/banners'} />}

                <div style={{
                    color: '#721c24',
                    backgroundColor: '#f8d7da',
                    borderColor: '#f5c6cb'
                }} hidden={this.state.msgErro == ''} className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                    <button style={{ color: '#721c24', top: '55%', fontSize: '2rem' }} onClick={() => this.setState({ msgErro: '' })} type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>

                    <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                </div>
                <br/>

                <label>Foto(s) *</label>

                <input className="form-control" accept="image/*" placeholder="Modelo" type="file" onChange={(e) => {
                    let foto = e.target.files[0];
                    this.setState({ foto });
                    let foto_path = '';
                    let reader = new FileReader()
                    reader.readAsDataURL(e.target.files[0])
                    reader.onload = () => {
                        foto_path= reader.result;
                        this.setState({ foto_path });
                    };
                    reader.onerror = function (error) {
                        console.log('Error: ', error);
                    }
                    e.target.value=null;

                }} style={{marginBottom:0}} />
                <small >Para melhor visualização, recomenda-se que a altura da imagem seja 4 vezes menor que a largura. Ex: 400x100, 800x200, 1200x300, etc.</small>

                <div className="row" style={{marginTop:'1.3rem'}}>
                    {this.state.foto_path!='' &&
                        <div  style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }} className="col-6 col-md-3">
                            <div>
                                <img src={this.state.foto_path} style={{ height: 100, width: 100,objectFit:'contain' }} />
                                <a onClick={() => {
                                    
                                    this.setState({ foto:'', foto_path:'' });
                                }} className="btn btn-link btn-link-dark" style={{ color: 'indianred', cursor: 'pointer' }}><span>Remover</span><i className="fas fa-times"></i></a>
                            </div>
                        </div>

                    }
                </div>
                <hr />
                
                {/* <label>Título</label>
                <input value={this.state.title} onChange={(e) => this.setState({ title: e.target.value })} type="text" placeholder="Título do banner" className="form-control"  />
                
                <label>Subtítulo</label>
                <input value={this.state.subtitle} onChange={(e) => this.setState({ subtitle: e.target.value })} type="text" placeholder="Subtítulo do banner" className="form-control"  />

                <label>Preço</label>
                <input value={this.state.price} onChange={(e) => this.setState({ price: maskPrice(e.target.value) })} type="text" placeholder="Preço" className="form-control"  /> */}

                <label>Link</label>
                <input value={this.state.link} onChange={(e) => this.setState({ link: e.target.value })} type="text" placeholder="Link da página que o banner levará" className="form-control" style={{marginBottom:0}} />
                <small >Ex: https://www.exemplo.com</small><br/>

                <label>Ordem *</label>
                <input value={this.state.order} onChange={(e) => this.setState({ order: e.target.value })} type="number" placeholder="Ordem do banner na home" className="form-control"  />

                {this.state.loading_save == false && <button onClick={() => {
                    this.salvar();
                }} style={{ marginTop: '1.3rem' }} type="button" className="btn btn-outline-primary btn-rounded">
                    <span>Salvar</span>
                    <i className="far fa-save"></i>
                </button>}
                {this.state.loading_save == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div></div>}

            </div>
        )
    }
}

export const mapStateToProps = (state) => ({
    token: state.user.token

})

export default connect(mapStateToProps, { logout })(EditarBannerTopo);