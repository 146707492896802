import {
    TOGGLE_WISHLIST, REMOVE_FROM_WISHLIST,REPLACE_WISHLIST
} from "../constants/action-types";
import { findIndex } from "../utils/utils";
import { toast } from 'react-toastify';

export default function wishlistReducer(state = {
    list: []
}, action) {
    switch (action.type) {
        case TOGGLE_WISHLIST:
            const productId = action.product.id;

            if (findIndex(state.list, product => product.id === productId) !== -1) {
                const list = state.list.reduce((cartAcc, product) => {
                    if (product.id !== productId) {
                        cartAcc.push(product);
                    } else
                        toast.error("Produto removido da lista de desejo");
                    return cartAcc
                }, [])

                return { ...state, list }
            } else
                toast.success("Produto adicionado na lista de desejos");

            return { ...state, list: [...state.list, action.product] }

        case REMOVE_FROM_WISHLIST:
            return {
                list: state.list.filter(item => item.id !== action.productId)
            }
        case REPLACE_WISHLIST:
            return {
                list: action.payload
            }
        default:
    }
    return state;
}
