import React from 'react';
import { Link } from 'react-router-dom';
import Countdown from 'react-countdown';
import { rendererOne } from '../../components/common/countdown/renderer';
import { maskPriceDecimal } from '../../Auxiliar/Masks';
import { URL } from '../../variables';
import moment from 'moment';

export default (props) => {
    const { title, subtitle, produto, newPrice, oldPrice, bigImage, linkTitle,image,link,validade,opacity,title_color,subtitle_color,link_color } = props.data;
    console.log(moment(validade).unix())
    console.log(validade);
    console.log(Date.now())
    return (
        <div className="deal" style={{ position: "relative" }}>
            <img src={URL+image} style={{ objectFit: "cover", width: '100%', height: '100%', position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, opacity: opacity }} />

            <div className="deal-top" style={{zIndex:1}}>
                <h2 style={{color:title_color}}>{ title }</h2>
                <h4 style={{color:subtitle_color}}>{ subtitle }</h4>
            </div>

            <div className="deal-content" style={{zIndex:1}}>
                <h3 className="product-title"><a href={ `${process.env.PUBLIC_URL}/product/default/${produto.id}` } style={{color:subtitle_color}}>{ produto.name }</a></h3>

                <div className="product-price">
                    {produto.sale_price!=null && <span className="new-price" style={{color:title_color}}>Por { maskPriceDecimal(produto.sale_price) }</span>}
                    {produto.sale_price==null && <span className="new-price" style={{color:title_color}}>{ maskPriceDecimal(produto.price) }</span>}

                    { produto.sale_price!=null ? <span className="old-price" style={{color:subtitle_color}}>De { maskPriceDecimal(produto.price) }</span> : "" }
                </div>

                <a href={link!=null ?link: `${process.env.PUBLIC_URL}/product/default/${produto.id}` } className="btn btn-link" style={{color:link_color}}>
                    <span>Mostrar mais</span><i className="icon-long-arrow-right"></i>
                </a>
            </div>

            <div className="deal-bottom" style={{zIndex:1}}>
                <div className="deal-countdown offer-countdown"><Countdown date={ parseInt(moment(validade).unix()+'000') } renderer={ rendererOne } /></div>
            </div>
        </div>
    );
}