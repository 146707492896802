import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';

// import Custom Components
import HeaderSeven from '../../components/common/header/header-construcao';
import OuterOverlay from '../../components/common/overlay/outer-overlay';
import FooterFive from '../../components/common/footer/footer-construcao';
import OwlCarousel from '../../components/features/owl-carousel';
import QuickView from '../../components/features/product/quickview';
import ServiceBox from '../../components/features/service-list/service-two';
import NewsletterModal from '../../components/features/newsletter-modal';
import MobileMenuTwo from '../../components/common/header/common/mobile-menus/menu-2';

import DealProduct from './deal-product';
import IntroSlide from './intro-slide';
import Banner from './banner';
import BannerGroup from './banner-group';
import NewCollection from './new-collection';
import TrendingCollection from './trendy-collection';
import ProductCollection from './product-collection';
import { introSlider, brandSlider } from './settings'

// import Utils
import { initSettings, isIEBrowser } from '../../utils/utils';
import _data from '../../mock_data/data.json';
import store from '../../store'
import { changeDemo, outerLoading, innerLoading } from '../../actions';
import { productSlider } from './settings';

import style from './style.scss';

import { URL } from '../../variables';
import ChatCliente from '../../components/Chat/ChatCliente';

class IndexFour extends Component {

    constructor(props) {
        super(props);
        this.state = {
            produtos_novos: [],
            produtos_categorias: [],
            produtos_recomendados: [],
            produtos_populares: { avaliados: [], oferta: [], vendidos: [] },
            produtos_tendencia: [],
            banners_topo: [],
            banners_promocao: [],
            banner_faixa_meio: [],
            banners_promocao_limitada: [],
            categorias: [],
            nossas_marcas: [],
            banner_height: window.innerWidth / 4
        }
    }

    get_dados_home() {
        store.dispatch(innerLoading());

        fetch(`${URL}api/get_dados_home?cat_id=all`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                // 'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                }
                else {
                    this.setState({
                        produtos_novos: resp.produtos_novos,
                        produtos_categorias: resp.produtos_categorias,
                        produtos_recomendados: resp.produtos_recomendados,
                        produtos_populares: resp.produtos_populares,
                        categorias: resp.categorias,
                        banners_topo: resp.banners_topo,
                        banners_promocao: resp.banners_promocao,
                        banner_faixa_meio: resp.banner_faixa_meio,
                        banners_promocao_limitada: resp.banners_promocao_limitada,
                        nossas_marcas: resp.nossas_marcas
                    });

                }




            } catch (err) {
                console.log(err);

            }

        })
            .catch((err) => {
                console.log(err);
            });
    }
    componentDidMount() {
        initSettings();
        this.resize_event = (function () {
            this.setState({ banner_height: window.innerWidth / 4 })
        }).bind(this);
        window.addEventListener('resize', this.resize_event)
        store.dispatch(changeDemo("4"));
    }

    UNSAFE_componentWillMount() {
        style.use();
        store.dispatch(outerLoading());
        this.get_dados_home();

    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize_event);
        style.unuse();
    }

    render() {
        console.log('Banner height: ' + this.state.banner_height)
        return (
            <React.Fragment>
                <Helmet>
                    <title>Hefarma</title>
                </Helmet>

                <h1 className="d-none">Hefarma</h1>

                <OuterOverlay />

                <div className="page-wrapper">
                    <HeaderSeven />

                    <div className="main">
                        {this.state.banners_topo.length > 0 && <div className="intro-slider-container mb-5" style={{ height: 'auto' }}>
                            <OwlCarousel adClass="intro-slider owl-theme owl-nav-inside owl-light" carouselOptions={introSlider} >
                                {
                                    this.state.banners_topo.map((item, index) =>
                                        <IntroSlide banner_height={this.state.banner_height} data={item} key={index} />
                                    )
                                }
                            </OwlCarousel>

                            <span className="slider-loader"></span>
                        </div>}

                        <div className="container mb-5">
                            <h2 className=" text-center mb-4">Site em Construção</h2>
                            <p style={{textAlign:"center",fontSize:'2.8rem'}}>Em breve uma nova experiência de compra para você.</p>
                        </div>

                    </div>
                    <FooterFive logo="assets/images/logo.png" />
                </div>
                <MobileMenuTwo adClass="mobile-menu-light" />
                {/* <NewsletterModal demo="4"/> */}
            </React.Fragment>
        )
    }
}

export default IndexFour;