import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import Lightbox from 'react-image-lightbox';

import store from '../../../../store';
import { innerLoading, outerLoading } from '../../../../actions';

// import Custom Components
import OwlCarousel from '../../../features/owl-carousel';
import ProductEight from '../../../features/product/product-eight';
import Accordion from '../../../features/accordion/accordion';
import Card from '../../../features/accordion/card';

// import Utils
import { productGallery, isIEBrowser } from '../../../../utils/utils';
import { mainSlider8 } from '../../settings';
import { URL } from '../../../../variables';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { withStyles } from '@material-ui/core/styles';


class SingleProductComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            phIndex: 0,
            isOpen: false,
        };
        this.openLightBox = this.openLightBox.bind(this);
        this.closeLightBox = this.closeLightBox.bind(this);
    }

    componentDidMount() {
        productGallery();
    }

    componentDidUpdate(prevProps) {
        // if ( this.props.product.id !== prevProps.product.id ) {
        //     store.dispatch(innerLoading());
        // }
        productGallery();
    }

    componentWillUnmount() {
        store.dispatch(outerLoading());
    }

    openLightBox(e, ind = null) {
        let index = 0;
        if (ind != null) {
            index = ind;
        }
        else {
            let index = parseInt(document.querySelector(".product-main-image").getAttribute("index"));
            // if ( ! index ) index = 0;
        }


        this.setState({ isOpen: true, phIndex: index });
        e.preventDefault();
    }

    closeLightBox() {
        this.setState({ isOpen: false });
    }

    prevLightBox() {
        const bigImages = this.props.product.lgPictures ? this.props.product.lgPictures : this.props.product.pictures;

        this.setState({
            phIndex: (this.state.phIndex + bigImages.length - 1) % bigImages.length,
        })
    }

    nextLightBox() {
        const bigImages = this.props.product.lgPictures ? this.props.product.lgPictures : this.props.product.pictures;

        this.setState({
            phIndex: (this.state.phIndex + 1) % bigImages.length,
        })
    }

    lightbox(produto) {
        // const { product } = this.props;
        const { phIndex, isOpen } = this.state;
        const bigImages = produto.fotos;

        return (
            isOpen && (
                <Lightbox
                    mainSrc={URL + bigImages[phIndex].foto}
                    nextSrc={URL + bigImages[(phIndex + 1) % bigImages.length].foto}
                    prevSrc={URL + bigImages[(phIndex + bigImages.length - 1) % bigImages.length].foto}
                    onCloseRequest={this.closeLightBox}
                    onMovePrevRequest={() =>
                        this.setState({
                            phIndex: (phIndex + bigImages.length - 1) % bigImages.length,
                        })
                    }
                    onMoveNextRequest={() =>
                        this.setState({
                            phIndex: (phIndex + 1) % bigImages.length,
                        })
                    }
                />
            )
        )
    }

    productDetailTab(product, assunto = '', descricao = '', avaliacao = 5, loading_save = false, changeAvaliacao = () => { }, changeAssunto = () => { }, changeDescricao = () => { }, enviar_comentario = () => { }, onDelete = (id) => { }, msgErro = '') {
        console.log(this.props.user);

        return (
            <Tabs selectedTabClassName="show" selectedTabPanelClassName="active show">
                <div className="product-details-tab">
                    <TabList className="nav nav-pills justify-content-center">
                        <Tab className="nav-item">
                            <span className="nav-link"> Descrição</span>
                        </Tab>

                        <Tab className="nav-item">
                            <span className="nav-link"> Informações Adicionais</span>
                        </Tab>

                        <Tab className="nav-item">
                            <span className="nav-link">Envios e Devoluções</span>
                        </Tab>

                        <Tab className="nav-item">
                            <span className="nav-link" >Avaliações ({product.avaliacoes.length})</span>
                        </Tab>
                    </TabList>

                    <div className="tab-content">
                        <TabPanel className="tab-pane">
                            <div className="product-desc-content" style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: product.descricao }}>
                                {/* <h3>Product Information</h3>
                                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna viverra non, semper suscipit, posuere a, pede. Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci. Aenean dignissim pellentesque felis. Phasellus ultrices nulla quis nibh. Quisque a lectus. Donec consectetuer ligula vulputate sem tristique cursus. </p>
                                <ul>
                                    <li>Nunc nec porttitor turpis. In eu risus enim. In vitae mollis elit. </li>
                                    <li>Vivamus finibus vel mauris ut vehicula.</li>
                                    <li>Nullam a magna porttitor, dictum risus nec, faucibus sapien.</li>
                                </ul>
                                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna viverra non, semper suscipit, posuere a, pede. Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci. Aenean dignissim pellentesque felis. Phasellus ultrices nulla quis nibh. Quisque a lectus. Donec consectetuer ligula vulputate sem tristique cursus. </p> */}
                            </div>
                        </TabPanel>

                        <TabPanel className="tab-pane">
                            <div className="product-desc-content" style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: product.informacoes }}>
                                {/* <h3>Information</h3>
                                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna viverra non, semper suscipit, posuere a, pede. Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci. </p>

                                <h3>Fabric & care</h3>
                                <ul>
                                    <li>Faux suede fabric</li>
                                    <li>Gold tone metal hoop handles.</li>
                                    <li>RI branding</li>
                                    <li>Snake print trim interior </li>
                                    <li>Adjustable cross body strap</li>
                                    <li> Height: 31cm; Width: 32cm; Depth: 12cm; Handle Drop: 61cm</li>
                                </ul>

                                <h3>Size</h3>
                                <p>one size</p> */}
                            </div>
                        </TabPanel>

                        <TabPanel className="tab-pane">
                            <div className="product-desc-content">
                                <h3>Política de troca e devolução</h3>
                                <p style={{whiteSpace:'pre-line'}}>Como realizar a troca ou devolução de uma compra?</p>
                                <p style={{whiteSpace:'pre-line',marginTop:10}}>Se você realizou uma compra de qualquer produto através do nosso site ou app, e agora deseja trocar esses produtos ou devolvê-los, de forma parcial ou total, não se preocupe, nós vamos te ajudar. Mas antes, algumas regrinhas básicas:</p>
                                <ul style={{marginTop:10}}>
                                    <li>O prazo para solicitação de troca ou devolução de compras realizadas de forma não presencial, de acordo com o artigo 49, do Código de Defesa do Consumidor (Lei 8.078 de 1990), é de até 07 (sete) dias, contados da data de recebimento do(s) produto(s).</li>
                                </ul>
                                <p style={{whiteSpace:'pre-line',marginTop:10}}><b>Caso esteja solicitando</b> a troca ou a devolução de sua compra, pois o produto adquirido está danificado ou apresenta qualquer tipo de avaria, é muito importante que a gente entenda o seu problema, para que ele não volte a ocorrer. Dessa forma, além de todos os documentos e informações acima (nota fiscal, número do pedido, nome completo e número do CPF do titular da compra e um telefone para contato), você também deverá enviar (i) uma foto do produto; e (ii) um breve relato do que aconteceu.</p>
                                <p style={{whiteSpace:'pre-line',marginTop:10}}><b>Ah, muito importante, isso tudo também vale para os casos em que você tenha recebido um produto diferente daquele que comprou.</b></p>
                                <p style={{whiteSpace:'pre-line',marginTop:10}}>E lembre-se, nós não aceitaremos solicitações de troca ou devolução de produtos que apresentem quaisquer das seguintes condições:</p>
                                <ul style={{marginTop:10}}>
                                    <li>Indícios de uso, ou que tenham sido efetivamente consumidos, total ou parcialmente, ou cujas embalagens tenham sido abertas, violadas ou de qualquer forma danificadas após o recebimento;</li>
                                    <li>Cuja compra não possa ser comprovada através do cupom ou nota fiscal; e</li>
                                    <li>Que sejam perecíveis.</li>
                                </ul>

                                <p style={{whiteSpace:'pre-line',marginTop:10}}>Agora que você já está craque em troca e devolução, envie suas informações através do nosso e-mail: <b>faleconosco@hefarma.com.br</b>. Nós analisaremos a sua solicitação e, após processamento, caso ela seja aprovada, realizaremos o reembolso de acordo com a forma de pagamento usada no momento da compra.</p>
                                <p style={{whiteSpace:'pre-line',marginTop:10}}>O prazo para receber o reembolso depende, portanto, da forma de pagamento:</p>
                               
                                <ul style={{marginTop:10}}>
                                    <li>Caso você tenha pago com cartão de crédito, dependendo da data de fechamento e vencimento do seu cartão, o reembolso pode levar de uma a duas faturas.</li>
                                    <li>Para pagamentos com PIX, o reembolso será feito em até 07 dias, após recebermos e processarmos o cancelamento ou devolução.</li>
                                </ul>
                            </div>
                        </TabPanel>

                        <TabPanel className="tab-pane">
                            <div className="reviews">
                                <h3>Avaliações ({product.avaliacoes.length})</h3>
                                {product.avaliacoes.map((item, id) => (
                                    <div key={id} className="review">
                                        <div className="row no-gutters" style={isIEBrowser() ? { flexDirection: 'row' } : {}}>


                                            <div className="col-12 col-md-2">
                                                <h4><Link to="#">{item.user.name}</Link></h4>

                                                <div className="ratings-container">
                                                    <div className="ratings">
                                                        <div className="ratings-val" style={{ width: parseInt((100 / 5) * item.avaliacao) + "%" }}></div>
                                                    </div>
                                                </div>
                                                <span className="review-date">{item.data}</span>

                                            </div>
                                            <div className="col-12 col-md-10" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <div >

                                                    <h4>{item.assunto}</h4>

                                                    <div className="review-content">
                                                        <p style={{ whiteSpace: 'pre-line' }}>{item.descricao}</p>
                                                    </div>
                                                </div>
                                                {this.props.token != undefined && (this.props.user.role == 'admin' || this.props.user.role == 'superadmin' || this.props.user.id == item.user.id) && <div >
                                                    <button style={{ width: 40, height: 40, minWidth: 40, borderRadius: 10 }} onClick={() => {
                                                        onDelete(item.id);
                                                    }}
                                                        className="destroy_it btn btn-danger"><i
                                                            className="fas fa-trash"></i></button>
                                                </div>}
                                                {/* <div className="review-action">
                                                    <Link to="#"><i className="icon-thumbs-up"></i>Helpful (2)</Link>
                                                    <Link to="#"><i className="icon-thumbs-down"></i>Unhelpful (0)</Link>
                                                </div> */}
                                            </div>
                                        </div>
                                        {item.respostas.length > 0 && <p style={{ color: "black", marginTop: 10, marginBottom: 10, fontWeight: '500' }}>Respostas: </p>}
                                        {item.respostas.map((item2, id2) => (

                                            <div key={id2} className="row no-gutters" style={isIEBrowser() ? { flexDirection: 'row', borderTop: '1px solid #f2f2f2', marginLeft: 30, paddingTop: 20, paddingBottom: 10 } : { borderTop: '1px solid #f2f2f2', marginLeft: 30, paddingTop: 20, paddingBottom: 10 }}>
                                                <div className="col-1 d-md-flex d-none">

                                                </div>

                                                <div className="col-12 col-md-2">
                                                    <h4><Link to="#">{item2.user.name}</Link></h4>

                                                    <span className="review-date">{item2.data}</span>

                                                </div>
                                                <div className="col-12 col-md-9" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <div >

                                                        <h4>{item2.assunto}</h4>

                                                        <div className="review-content">
                                                            <p style={{ whiteSpace: 'pre-line' }}>{item2.descricao}</p>
                                                        </div>
                                                    </div>
                                                    {this.props.token != undefined && (this.props.user.role == 'admin' || this.props.user.role == 'superadmin' || this.props.user.id == item2.user.id) && <div >
                                                        <button style={{ width: 40, height: 40, minWidth: 40, borderRadius: 10 }} onClick={() => {
                                                            onDelete(item2.id);
                                                        }}
                                                            className="destroy_it btn btn-danger"><i
                                                                className="fas fa-trash"></i></button>
                                                    </div>}
                                                    {/* <div className="review-action">
                                                    <Link to="#"><i className="icon-thumbs-up"></i>Helpful (2)</Link>
                                                    <Link to="#"><i className="icon-thumbs-down"></i>Unhelpful (0)</Link>
                                                </div> */}
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                ))}

                                {product.avaliacoes.length == 0 &&
                                    <p style={{ textAlign: 'center' }}>Nenhuma avaliação registrada</p>

                                }
                                <br />
                                {this.props.token != null && this.props.token != undefined && <div>

                                    <div className="row">
                                        <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start">
                                            <div>
                                                <h5 style={{ fontSize: '1.5rem',marginBottom:5 }}>Deixe um comentário</h5>
                                                <p style={{ marginBottom: 5, color: 'red' }}>{msgErro}</p>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-end">
                                            <div>

                                                <p style={{ textAlign: 'center' }}>Avaliação</p>
                                                <Rating name="half-rating" value={avaliacao} onChange={(e) => {
                                                    changeAvaliacao(e.target.value);
                                                }} precision={1} />
                                            </div>

                                        </div>
                                    </div>

                                    <input value={assunto} onChange={(e) => changeAssunto(e.target.value)} type="text" placeholder="Assunto" className="form-control" required />

                                    <textarea value={descricao} onChange={(e) => changeDescricao(e.target.value)} type="text" placeholder="Descrição" className="form-control" required />
                                    {loading_save == false && <button onClick={() => {
                                        enviar_comentario();
                                    }} style={{ marginTop: '1.3rem' }} type="button" className="btn btn-outline-primary btn-rounded">
                                        <span>Enviar Comentário</span>
                                        <i className="far fa-save"></i>
                                    </button>}
                                    {loading_save == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div></div>}
                                </div>}

                                {/* <div className="review" >
                                    <div className="row no-gutters" style={isIEBrowser() ? { flexDirection: 'row' } : {}}>
                                        <div className="col-auto">
                                            <h4><Link to="#">John Doe</Link></h4>

                                            <div className="ratings-container">
                                                <div className="ratings">
                                                    <div className="ratings-val" style={{ width: "100%" }}></div>
                                                </div>
                                            </div>

                                            <span className="review-date">5 days ago</span>
                                        </div>

                                        <div className="col">
                                            <h4>Very good</h4>

                                            <div className="review-content">
                                                <p>Sed, molestias, tempore? Ex dolor esse iure hic veniam laborum blanditiis laudantium iste amet. Cum non voluptate eos enim, ab cumque nam, modi, quas iure illum repellendus, blanditiis perspiciatis beatae!</p>
                                            </div>

                                            <div className="review-action">
                                                <Link to="#"><i className="icon-thumbs-up"></i>Helpful (0)</Link>
                                                <Link to="#"><i className="icon-thumbs-down"></i>Unhelpful (0)</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </TabPanel>
                    </div>
                </div>
            </Tabs>
        );
    }

    productDetailAccordian() {
        const { product } = this.props;

        return (
            <Accordion adClass="accordion-plus product-details-accordion">
                <Card title="Description" adClass="card-box card-sm">
                    <div className="product-desc-content">
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna viverra non, semper suscipit, posuere a, pede. Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci.</p>
                        <ul>
                            <li>Nunc nec porttitor turpis. In eu risus enim. In vitae mollis elit. </li>
                            <li>Vivamus finibus vel mauris ut vehicula.</li>
                            <li>Nullam a magna porttitor, dictum risus nec, faucibus sapien.</li>
                        </ul>
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna viverra non, semper suscipit, posuere a, pede.</p>
                    </div>
                </Card>
                <Card title="Additional information" adClass="card-box card-sm">
                    <div className="product-desc-content" >
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna viverra non, semper suscipit, posuere a, pede. Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci.</p>

                        <h3>Fabric & care</h3>
                        <ul>
                            <li>100% Polyester</li>
                            <li>Do not iron</li>
                            <li>Do not wash</li>
                            <li>Do not bleach</li>
                            <li>Do not tumble dry</li>
                            <li>Professional dry clean only</li>
                        </ul>

                        <h3>Size</h3>
                        <p>S, M, L, XL</p>
                    </div>
                </Card>
                <Card title="Shipping & Returns" expanded={true} adClass="card-box card-sm">
                    <div className="product-desc-content">
                        <p>We deliver to over 100 countries around the world. For full details of the delivery options we offer, please view our <Link to="#">Delivery information</Link><br />
                        We hope you’ll love every purchase, but if you ever need to return an item you can do so within a month of receipt. For full details of how to make a return, please view our <Link to="#">Returns information</Link></p>
                    </div>
                </Card>
                <Card title={`Reviews (${product.reviews})`} adClass="card-box card-sm">
                    <div className="reviews">
                        <div className="review">
                            <div className="row no-gutters" style={isIEBrowser() ? { flexDirection: 'row' } : {}} >
                                <div className="col-auto">
                                    <h4><Link to="#">Samanta J.</Link></h4>
                                    <div className="ratings-container">
                                        <div className="ratings">
                                            <div className="ratings-val" style={{ width: "80%" }}></div>
                                        </div>
                                    </div>
                                    <span className="review-date">6 days ago</span>
                                </div>
                                <div className="col">
                                    <h4>Good, perfect size</h4>

                                    <div className="review-content">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ducimus cum dolores assumenda asperiores facilis porro reprehenderit animi culpa atque blanditiis commodi perspiciatis doloremque, possimus, explicabo, autem fugit beatae quae voluptas!</p>
                                    </div>

                                    <div className="review-action">
                                        <Link to="#"><i className="icon-thumbs-up"></i>Helpful (2)</Link>
                                        <Link to="#"><i className="icon-thumbs-down"></i>Unhelpful (0)</Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="review">
                            <div className="row no-gutters" style={isIEBrowser() ? { flexDirection: 'row' } : {}}>
                                <div className="col-auto">
                                    <h4><Link to="#">John Doe</Link></h4>
                                    <div className="ratings-container">
                                        <div className="ratings">
                                            <div className="ratings-val" style={{ width: "100%" }}></div>
                                        </div>
                                    </div>
                                    <span className="review-date">5 days ago</span>
                                </div>
                                <div className="col">
                                    <h4>Very good</h4>

                                    <div className="review-content">
                                        <p>Sed, molestias, tempore? Ex dolor esse iure hic veniam laborum blanditiis laudantium iste amet. Cum non voluptate eos enim, ab cumque nam, modi, quas iure illum repellendus, blanditiis perspiciatis beatae!</p>
                                    </div>

                                    <div className="review-action">
                                        <Link to="#"><i className="icon-thumbs-up"></i>Helpful (0)</Link>
                                        <Link to="#"><i className="icon-thumbs-down"></i>Unhelpful (0)</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </Accordion>
        )

    }

    relatedProducts(produtos = [], type = "model", textAdClass = "text-center") {
        const { addToCart, toggleWishlist, addToCompare, showQuickViewModal } = this.props;
        return (
            <OwlCarousel adClass="owl-simple carousel-equal-height carousel-with-shadow" carouselOptions={mainSlider8}>
                {produtos.map((item, id) => (
                    <ProductEight key={id} colorType={type} adClass={textAdClass} product={item} onAddToCartClick={addToCart} onToggleWishlistClick={toggleWishlist} onAddToCompareClick={addToCompare} showQuickViewModal={showQuickViewModal} />
                ))}
            </OwlCarousel>
        );
    }
}

export default SingleProductComponent;