import React from 'react';
import { Link } from 'react-router-dom';

import { URL } from '../../variables';

export default (props) => {
    const { foto, name,id } = props.data;

    return (
        <div style={{minWidth:150}}>
            <Link to={ `${process.env.PUBLIC_URL}/shop/sidebar/list?cat_id=${id}` } className="cat-block">
                <figure>
                    <span>
                        <img style={{width:150,height:150,objectFit:'contain'}} src={ URL  + foto } alt="Category"/>
                    </span>
                </figure>

                <h3 className="cat-block-title">{ name }</h3>
            </Link>
        </div>
    )
}