import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import Custom Components
import PageHeader from '../../common/page-header';
import Breadcrumb from '../../common/breadcrumb';

import { removeFromWishlist, addToCartFromWishlist,addToCart } from '../../../actions';
import { URL } from '../../../variables';
import { maskPriceDecimal } from '../../../Auxiliar/Masks';

class Wishlist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading_wish: {},
        }
    }


    componentDidMount() {

        let items = document.querySelectorAll(".action-col .dropdown");

        for (let i = 0; i < items.length; i++) {
            let item = items[i];
            item.addEventListener("click", function (e) {
                item.classList.add("show");
                item.querySelector(".dropdown-menu").classList.add("show");
                item.querySelector(".dropdown-menu").setAttribute("style", "position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 40px, 0px);");
                e.stopPropagation();
            });
        }
        document.addEventListener("click", this.hiddenHandle);


    }

    componentWillUnmount() {
        document.removeEventListener("click", this.hiddenHandle);
    }

    hiddenHandle(e) {
        let items = document.querySelectorAll(".action-col .dropdown");
        for (let i = 0; i < items.length; i++) {
            let item = items[i];
            item.classList.remove("show");
            item.querySelector(".dropdown-menu").classList.remove("show");
            item.querySelector(".dropdown-menu").setAttribute("style", "");
        }
    }

    salvar(product, quantidade) {
        if(this.props.token==undefined){
            this.props.addToCartFromWishlist(product,1);
            return;
        }
        let loading = { ...this.state.loading_wish };
        loading[product.id] = true;
        this.setState({ loading_wish: loading, msgErro: '' });
        let form = new FormData();
        fetch(`${URL}api/carrinho/salvar_item_carrinho/${product.id}/${quantidade}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                let loading = { ...this.state.loading_wish };
                loading[product.id] = false;
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_wish: loading, msgErro: erro });
                }
                else {
                    this.props.addToCart(product,quantidade);
                    this.excluir_wishlist(product);
                    // this.setState({ loading_wish: loading });

                }




            } catch (err) {
                console.log(err);
                let loading = { ...this.state.loading_wish };
                loading[product.id] = false;
                this.setState({ loading_wish: loading, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                let loading = { ...this.state.loading_wish };
                loading[product.id] = false;
                this.setState({ loading_wish: loading, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }

    excluir_wishlist(product) {
        if(this.props.token==undefined){
            this.props.removeFromWishlist(product.id)
            return;
        }
        let loading = { ...this.state.loading_wish };
        loading[product.id] = true;
        this.setState({ loading_wish: loading, msgErro: '' });
        let form = new FormData();

        fetch(`${URL}api/lista_desejos/excluir_item_wish/${product.id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                let loading = { ...this.state.loading_wish };
                loading[product.id] = false;
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_wish: loading, msgErro: erro });
                }
                else {
                    this.props.removeFromWishlist(product.id)
                    this.setState({ loading_wish: loading });

                }




            } catch (err) {
                console.log(err);
                let loading = { ...this.state.loading_wish };
                loading[product.id] = false;
                this.setState({ loading_wish: loading, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                let loading = { ...this.state.loading_wish };
                loading[product.id] = false;
                this.setState({ loading_wish: loading, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }

    render() {
        const { wishlist } = this.props;

        return (
            <div className="main">

                <PageHeader title="Lista de desejos" subTitle="Shop" />
                <Breadcrumb title="Lista de desejos" parent1={["Shop", "shop/sidebar/list"]} />

                <div className="page-content">
                    <div className="container">
                        <table className="table table-wishlist table-mobile">
                            <thead>
                                <tr>
                                    <th>Foto</th>
                                    <th>Produto</th>
                                    <th>Preço</th>
                                    <th>Status do Estoque</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>

                            <tbody>
                                {wishlist.length > 0 ?

                                    wishlist.map((item, index) =>

                                        <tr key={index}>
                                            <td className="product-col">
                                                <div className="product">

                                                    <Link style={{ width: 100, height: 100 }} to={`${process.env.PUBLIC_URL}/product/default/${item.id}`}>
                                                        <img style={{ width: 100, height: 100, objectFit: 'contain' }} src={URL + item.fotos[0].foto} alt="Product" />
                                                    </Link>
                                                </div>

                                            </td>

                                            <td className="product-col">
                                                <div className="product" >


                                                    <h3 className="product-title">
                                                        <Link to={`${process.env.PUBLIC_URL}/product/default/${item.id}`}>
                                                            {item.name}
                                                        </Link>
                                                    </h3>
                                                </div>
                                            </td>

                                            <td className="price-col">
                                                {maskPriceDecimal(0 < item.sale_price ?

                                                    item.sale_price:

                                                    item.price

                                                )}
                                            </td>

                                            <td className="stock-col">
                                                <span className={0 < item.estoque ? 'in-stock' : 'out-of-stock'}>
                                                    {0 < item.estoque ? 'Em Estoque' : 'Sem Estoque'}
                                                </span>
                                            </td>

                                            <td className="action-col">
                                                {
                                                    0 === item.estoque ?

                                                        <button className="btn btn-block btn-outline-primary-2 disabled">Sem Estoque</button> :

                                                        item.variants ?

                                                            <div className="dropdown">
                                                                <button className="btn btn-block btn-outline-primary-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <i className="icon-list-alt"></i>Select Options
                                                    </button>

                                                                <div className="dropdown-menu">
                                                                    <Link to={`${process.env.PUBLIC_URL}/product/default/${item.id}`} className="dropdown-item">First option</Link>
                                                                    <Link to={`${process.env.PUBLIC_URL}/product/default/${item.id}`} className="dropdown-item">Another option</Link>
                                                                    <Link to={`${process.env.PUBLIC_URL}/product/default/${item.id}`} className="dropdown-item">The best option</Link>
                                                                </div>
                                                            </div> :

                                                            <button className="btn btn-block btn-outline-primary-2"
                                                                onClick={(e) => this.salvar(item, 1)}>
                                                                <i className="icon-cart-plus"></i>Adicionar ao carrinho
                                                </button>

                                                }

                                            </td>
                                            <td className="remove-col">
                                                {this.state.loading_wish[item.id] != true && <button className="btn-remove"
                                                    onClick={(e) => this.excluir_wishlist(item)}
                                                >
                                                    <i className="icon-close"></i>
                                                </button>}
                                                {this.state.loading_wish[item.id] == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div></div>}
                                            </td>
                                        </tr>

                                    ) :
                                    <tr>
                                        <th colSpan="4">
                                            <p className="pl-2 pt-1 pb-1"> Não há produtos na lista de desejos </p>
                                        </th>
                                    </tr>
                                }

                            </tbody>
                        </table>
                        {/* <div className="wishlist-share">
                            <div className="social-icons social-icons-sm mb-2">
                                <label className="social-label">Share on:</label>
                                <a href="https://www.facebook.com/" className="social-icon" target="_blank" rel="noopener noreferrer" title="Facebook"><i className="icon-facebook-f"></i></a>
                                <a href="https://twitter.com/" className="social-icon" target="_blank" rel="noopener noreferrer" title="Twitter"><i className="icon-twitter"></i></a>
                                <a href="https://instagram.com/" className="social-icon" target="_blank" rel="noopener noreferrer" title="Instagram"><i className="icon-instagram"></i></a>
                                <a href="https://youtube.com/" className="social-icon" target="_blank" rel="noopener noreferrer" title="Youtube"><i className="icon-youtube"></i></a>
                                <a href="https://pinterest.com/" className="social-icon" target="_blank" rel="noopener noreferrer" title="Pinterest"><i className="icon-pinterest"></i></a>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        )
    }
}

export const mapStateToProps = (state) => ({
    wishlist: state.wishlist.list,
    token: state.user.token,

});

export default connect(mapStateToProps, { removeFromWishlist, addToCartFromWishlist,addToCart })(Wishlist);