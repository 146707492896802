import React, { Component } from 'react';
import { connect } from 'react-redux';
import InputRange from 'react-input-range';
import { SlideToggle } from 'react-slide-toggle';
import { Link } from 'react-router-dom';
import 'react-input-range/lib/css/index.css';

import _data from '../../../mock_data/data';
import { getCountByCategory } from '../../../services';
import { toggleCategoryFilter, resetFilter, toggleSizeFilter, toggleBrandFilter, toggleColorFilter, filterPrice } from '../../../actions';
import { findIndex } from '../../../utils/utils';
import { maskPriceDecimal } from '../../../Auxiliar/Masks';

class ShopSidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: null,
            toggle: false,

        };

        this.resizeHandle = this.resizeHandle.bind(this);
    }

    componentDidMount() {
        this.props.change_price_range({ max: parseFloat(this.props.price_range.max), min: parseFloat(this.props.price_range.min) });
        console.log()
        this.setState({ value: { max: parseFloat(this.props.price_range.max), min: parseFloat(this.props.price_range.min) } });
        window.addEventListener("resize", this.resizeHandle);
        this.resizeHandle();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeHandle);
    }

    resizeHandle() {
        if (document.querySelector("body").offsetWidth < 992)
            this.setState({ toggle: true });
        else
            this.setState({ toggle: false });
    }

    changeIcon(e) {
        e.target.classList.toggle('collapsed');
    }

    hideSideBar() {
        if (document.querySelector('body').classList.contains('sidebar-filter-active'))
            document.querySelector('body').classList.remove('sidebar-filter-active');
    }

    toggleSideBar() {
        if (document.querySelector('body').classList.contains('sidebar-filter-active'))
            document.querySelector('body').classList.remove('sidebar-filter-active');
        else
            document.querySelector('body').classList.add('sidebar-filter-active');
    }

    getWidth() {
        return document.querySelector("body").offsetWidth;
    }

    clearAll(e) {
        this.props.clear_all();
        // let items = document.querySelector('.sidebar-shop').querySelectorAll('input');
        // for( let i = 0 ; i < items.length ; i ++ ){
        //     items[i].checked = false;
        // }

        // items = document.querySelector('.sidebar-shop').querySelectorAll('.selected');

        // for( let i = 0 ; i < items.length ; i ++ ){
        //     items[i].classList.remove('selected');
        // }

        // this.setState({value:{ max: 1000, min: 0}});
        // this.props.resetFilter();
        e.preventDefault();
    }

    selectColor(e) {
        e.currentTarget.classList.toggle('selected');
        e.preventDefault();
    }

    render() {
        const sizes = ["XS", "S", "M", "L", "XL", "XXL"];

        const colors = ["#b87145", "#f0c04a", "#333333", "#cc3333", "#3399cc", "#669933", "#f2719c", "#ebebeb"];
        let categoryCountArr = [];
        // _data.categories.wears.map((item, index) => {
        //     return categoryCountArr.push(getCountByCategory(products.slice(0,47), item.name))
        // });
        // console.log(Object.keys(this.props.categorias_checked));
        return (
            <React.Fragment>
                <aside className={`${this.state.toggle ? 'sidebar-filter' : 'sidebar'} sidebar-shop`}>
                    <div className={this.state.toggle ? 'sidebar-filter-wrapper' : ''}>
                        <div className="widget widget-clean">
                            <label>Filtros:</label>
                            <a className="sidebar-filter-clear" style={{ cursor: 'pointer' }} onClick={(e) => this.clearAll(e)}>Limpar Tudo</a>
                        </div>
                        {this.props.name != '' && <div className="widget widget-clean">
                            <label >Buscado por: <label style={{ color: 'grey' }}>{this.props.name}</label></label>
                            <a className="sidebar-filter-clear" style={{ cursor: 'pointer' }} onClick={(e) => this.props.change_name('')}><i style={{ color: 'grey' }} className="fas fa-times"></i></a>

                        </div>}
                        <SlideToggle collapsed={false}>
                            {({ onToggle, setCollapsibleElement }) => (
                                <div className="widget widget-collapsible">
                                    <h3 className="widget-title"><a href="#destaques" onClick={(e) => { onToggle(e); this.changeIcon(e); e.preventDefault() }} role="button" aria-expanded="true" aria-controls="widget-1">Destaques</a></h3>
                                    <div ref={setCollapsibleElement}>
                                        <div className="widget-body">
                                            <div className="filter-items filter-items-count">
                                                {
                                                    this.props.destaques.map((item, index) =>

                                                        ((item.auth == true && this.props.token != undefined) || item.auth != true) && <div className="filter-item" key={item.id}>
                                                            <div className="custom-control custom-checkbox">

                                                                <input type="checkbox"
                                                                    className="custom-control-input"
                                                                    id={`des-${index + 1}`}
                                                                    onChange={(e) => {
                                                                        let destaques_checked = { ...this.props.destaques_checked };

                                                                        if (e.target.checked) {
                                                                            destaques_checked[item.id] = true;
                                                                        }
                                                                        else {
                                                                            delete (destaques_checked[item.id]);
                                                                        }
                                                                        this.props.change_destaques_checked(destaques_checked);
                                                                    }}
                                                                    checked={Object.keys(this.props.destaques_checked).includes(item.id.toString())}
                                                                />

                                                                <label className="custom-control-label" htmlFor={`des-${index + 1}`}>{item.name}</label>

                                                            </div>
                                                            {/* <span className="item-count">{item.produtos_count}</span> */}
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </SlideToggle>

                        <SlideToggle collapsed={false}>
                            {({ onToggle, setCollapsibleElement }) => (
                                <div className="widget widget-collapsible">
                                    <h3 className="widget-title"><a href="#category" onClick={(e) => { onToggle(e); this.changeIcon(e); e.preventDefault() }} role="button" aria-expanded="true" aria-controls="widget-1">Categorias</a></h3>
                                    <div ref={setCollapsibleElement}>
                                        <div className="widget-body">
                                            <div className="filter-items filter-items-count">
                                                {
                                                    this.props.categorias.map((item, index) =>
                                                        <div>
                                                            <div className="filter-item" key={item.id}>
                                                                <div className="custom-control custom-checkbox">

                                                                    <input type="checkbox"
                                                                        className="custom-control-input"
                                                                        id={`cat-${index + 1}`}
                                                                        onChange={(e) => {
                                                                            let categorias_checked = { ...this.props.categorias_checked };

                                                                            if (e.target.checked) {
                                                                                categorias_checked[item.id] = true;
                                                                            }
                                                                            else {
                                                                                delete (categorias_checked[item.id]);
                                                                                item.categorias.map((cat2, id2) => {
                                                                                    delete (categorias_checked[cat2.id]);
                                                                                    cat2.categorias.map((cat3, id2) => {
                                                                                        delete (categorias_checked[cat3.id]);
                                                                                    })
                                                                                })
                                                                            }
                                                                            this.props.change_categorias_checked(categorias_checked);
                                                                        }}
                                                                        checked={Object.keys(this.props.categorias_checked).includes(item.id.toString())}
                                                                    />

                                                                    <label className="custom-control-label" htmlFor={`cat-${index + 1}`}>{item.name}</label>

                                                                </div>
                                                                <span className="item-count">{item.produtos_count}</span>
                                                            </div>
                                                            {Object.keys(this.props.categorias_checked).includes(item.id.toString()) == true &&
                                                                item.categorias.map((item2, index2) =>
                                                                    <div key={index2} style={{ paddingLeft: 15 }}>
                                                                        <div className="filter-item" key={item2.id}>
                                                                            <div className="custom-control custom-checkbox">

                                                                                <input type="checkbox"
                                                                                    className="custom-control-input"
                                                                                    id={`cat2-${index2 + 1}`}
                                                                                    onChange={(e) => {
                                                                                        let categorias_checked = { ...this.props.categorias_checked };

                                                                                        if (e.target.checked) {
                                                                                            categorias_checked[item2.id] = true;
                                                                                        }
                                                                                        else {
                                                                                            delete (categorias_checked[item2.id]);
                                                                                            item2.categorias.map((cat2, id2) => {
                                                                                                delete (categorias_checked[cat2.id]);
                                                                                            })
                                                                                        }
                                                                                        this.props.change_categorias_checked(categorias_checked);
                                                                                    }}
                                                                                    checked={Object.keys(this.props.categorias_checked).includes(item2.id.toString())}
                                                                                />

                                                                                <label className="custom-control-label" htmlFor={`cat2-${index2 + 1}`}>{item2.name}</label>

                                                                            </div>
                                                                            <span className="item-count">{item2.produtos_count}</span>

                                                                        </div>

                                                                        {Object.keys(this.props.categorias_checked).includes(item2.id.toString()) == true &&
                                                                            item2.categorias.map((item3, index3) =>
                                                                                <div key={index3} style={{ paddingLeft: 15 }}>
                                                                                    <div className="filter-item" key={item3.id}>
                                                                                        <div className="custom-control custom-checkbox">

                                                                                            <input type="checkbox"
                                                                                                className="custom-control-input"
                                                                                                id={`cat3-${index3 + 1}`}
                                                                                                onChange={(e) => {
                                                                                                    let categorias_checked = { ...this.props.categorias_checked };

                                                                                                    if (e.target.checked) {
                                                                                                        categorias_checked[item3.id] = true;
                                                                                                    }
                                                                                                    else {
                                                                                                        delete (categorias_checked[item3.id]);
                                                                                                    }
                                                                                                    this.props.change_categorias_checked(categorias_checked);
                                                                                                }}
                                                                                                checked={Object.keys(this.props.categorias_checked).includes(item3.id.toString())}
                                                                                            />

                                                                                            <label className="custom-control-label" htmlFor={`cat3-${index3 + 1}`}>{item3.name}</label>

                                                                                        </div>
                                                                                        <span className="item-count">{item3.produtos_count}</span>

                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                    </div>
                                                                )}
                                                        </div>

                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </SlideToggle>

                        {/* <SlideToggle collapsed = {false }>
                            {({onToggle, setCollapsibleElement}) => (
                                <div className="widget widget-collapsible">
                                    <h3 className = "widget-title"><a href="#Size" onClick = {(e) => {onToggle(e); this.changeIcon(e); e.preventDefault() }} role="button" aria-expanded="true" aria-controls="widget-1">Size</a></h3>
                                    <div ref={ setCollapsibleElement }>
                                        <div className="widget-body">
                                            <div className="filter-items">
                                                { sizes.map((item, index) => (

                                                    <div className="filter-item" key={ index }>
                                                        <div className="custom-control custom-checkbox">
                                                            <input type="checkbox"
                                                                className="custom-control-input" 
                                                                id={ `size-${index+1}`} 
                                                                onClick={ (e) => this.props.toggleSizeFilter(item) } 
                                                                defaultChecked={ -1 < findIndex( filters['size'], filter => filter === item ) ? true : false }
                                                            />
                                                            <label className="custom-control-label" htmlFor={ `size-${index+1}` }>{ item}</label>
                                                        </div>
                                                    </div>
                                                )) }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) }
                        </SlideToggle> */}

                        {/* <SlideToggle collapsed = {false }>
                            {({onToggle, setCollapsibleElement}) => (
                                <div className="widget widget-collapsible">
                                    <h3 className = "widget-title"><a href="#colour" onClick = {(e) => {onToggle(e); this.changeIcon(e); e.preventDefault() }} role="button" aria-expanded="true" aria-controls="widget-1">Colour</a></h3>
                                    <div ref={ setCollapsibleElement }>
                                        <div className="widget-body">
                                            <div className="filter-colors">
                                                {
                                                    colors.map((item, index) => (
                                                        <Link to="#" className={ -1 < findIndex( filters['color'], filter => filter === item ) ? "selected" : "" } style={ {background:item}} onClick={ (e) => { this.props.toggleColorFilter(item); e.preventDefault(); }} key={ index }>
                                                            <span className="sr-only">Color Name</span>
                                                        </Link>
                                                    ) )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) }
                        </SlideToggle> */}

                        {this.props.marcas.length > 0 && <SlideToggle collapsed={false}>
                            {({ onToggle, setCollapsibleElement }) => (
                                <div className="widget widget-collapsible">
                                    <h3 className="widget-title"><a href="#brand" onClick={(e) => { onToggle(e); this.changeIcon(e); e.preventDefault() }} role="button" aria-expanded="true" aria-controls="widget-1">Marcas</a></h3>
                                    <div ref={setCollapsibleElement}>
                                        <div className="widget-body">
                                            <div className="filter-items">
                                                {this.props.marcas.map((item, index) => (

                                                    <div className="filter-item" key={index}>
                                                        <div className="custom-control custom-checkbox">

                                                            <input type="checkbox"
                                                                className="custom-control-input"
                                                                id={`brand-${index + 1}`}
                                                                onChange={(e) => {
                                                                    let marcas_checked = { ...this.props.marcas_checked };

                                                                    if (e.target.checked) {
                                                                        marcas_checked[item.id] = true;
                                                                    }
                                                                    else {
                                                                        delete (marcas_checked[item.id]);
                                                                    }
                                                                    this.props.change_marcas_checked(marcas_checked);
                                                                }}
                                                                checked={Object.keys(this.props.marcas_checked).includes(item.id.toString())}

                                                            />
                                                            <label className="custom-control-label" htmlFor={`brand-${index + 1}`}>{item.name}</label>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </SlideToggle>}

                        <SlideToggle collapsed={false}>
                            {({ onToggle, setCollapsibleElement }) => (
                                <div className="widget widget-collapsible" style={{ overflow: 'visible' }}>
                                    <h3 className="widget-title">
                                        <a href="#price" onClick={(e) => { onToggle(e); this.changeIcon(e); e.preventDefault() }} role="button" aria-expanded="true" aria-controls="widget-1">Preço</a>
                                    </h3>

                                    {this.state.value != null && <div ref={setCollapsibleElement}>
                                        <div className="widget-body" style={{ marginLeft: 10, marginRight: 10 }}>
                                            <div className="filter-price">
                                                <div className="filter-price-text">
                                                    Faixa de Preço:&nbsp;
                                                    <span className="filter-price-range">{maskPriceDecimal(this.state.value.min)} - {maskPriceDecimal(this.state.value.max)}</span>
                                                </div>

                                                <div className="price-slider">
                                                    <InputRange
                                                        formatLabel={value => `R$${value.toString().replace('.', ',')}`}
                                                        maxValue={parseFloat(this.props.price_range.max)}
                                                        minValue={parseFloat(this.props.price_range.min)}
                                                        step={1}
                                                        value={this.state.value}
                                                        onChange={value => { this.setState({ value }) }}
                                                        onChangeComplete={(value) => {
                                                            console.log(value);
                                                            this.props.change_price_range(value);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            )}
                        </SlideToggle>
                    </div>
                </aside>
                {this.state.toggle ?
                    <button className="sidebar-fixed-toggler" onClick={this.toggleSideBar}><i className="icon-cog"></i></button> :
                    ''
                }
                <div className="sidebar-filter-overlay" onClick={this.hideSideBar}></div>
            </React.Fragment>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        filters: state.filters,
        token: state.user.token,
    }
}

export default connect(mapStateToProps, { toggleCategoryFilter, toggleSizeFilter, toggleBrandFilter, toggleColorFilter, filterPrice, resetFilter })(ShopSidebar);