import React from 'react';
import { Link } from 'react-router-dom';
import { URL } from '../../variables';

export default ( props ) => {
    const { data } = props;
    return (
        <div className="container">
            <div className="row ">
                { data.map((item, index) => 
                    <div className="col-md-6 col-lg-4 d-flex justify-content-center align-items-center" key={ index }>
                        <div className="banner banner-group banner-overlay banner-overlay-light" style={{maxWidth: 376}}>
                            <a target="_blank" href={(item.link==null || item.link.length==0)? `${process.env.PUBLIC_URL}/shop/sidebar/list`:item.link }  style={{backgroundColor:'white'}}>
                                <img style={{height:160,maxWidth: 376,objectFit:'cover',opacity:item.opacity}} src={ URL + item.image } alt="Banner" />
                            </a>

                            <div className="banner-content" >
                               

                                {item.title!=null &&  <h3 className="banner-title">
                                    <a target="_blank" href={ (item.link==null || item.link.length==0)? `${process.env.PUBLIC_URL}/shop/sidebar/list`:item.link }><strong style={{color: item.title_color}}>{ item.title }</strong></a>
                                </h3>}
                                {item.subtitle!=null && <h4 className="banner-subtitle">
                                    <a target="_blank" href={ (item.link==null || item.link.length==0)? `${process.env.PUBLIC_URL}/shop/sidebar/list`:item.link } style={{color: item.subtitle_color,fontWeight:400}}>{ item.subtitle }</a>
                                </h4>}
                                <a target="_blank" style={{display: 'inline-block',
                                fontWeight: 400,
                                lineHeight: 1.3,
                                padding: '.3rem 1rem',
                                borderRadius: '20px',
                                letterSpacing: '-.01em',
                                marginLeft: '-1rem',
                                textAlign: 'center',
                                minWidth: '110px',color: item.link_color,fontWeight:500}} href={(item.link==null || item.link.length==0)? `${process.env.PUBLIC_URL}/shop/sidebar/list`:item.link} >Mostrar mais<i className="icon-long-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                ) }
            </div>
        </div>
    );
}