import React, { Component } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { Link } from 'react-router-dom';

// import Custom Components
import { connect } from 'react-redux';
import { URL } from '../../../../variables';
import Pagination from '../../../features/pagination';
import SweetAlert from 'react-bootstrap-sweetalert';
import { logout,changeMobile } from '../../../../actions';

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

class ChatGeral extends Component {

    constructor(props) {
        super(props);
        this.state = {
            conversas: [],
            conversas_ids: [],

            loading: false,
            show_warning: false,
            show_erro: false,
            msgErro: '',
            user_to_delete: '',
            conversa_select: '',
            mensagem: ''
        };
        this.messages_view = React.createRef();

    }

    delete_categoria(id) {

        fetch(`${URL}api/admin/categorias/delete_categoria/${id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ msgErro: erro, show_warning: false, show_erro: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({ show_warning: false });
                    this.get_categorias(this.state.page);
                    // this.setState({ users: resp.users, loading_screen: false, page: resp.pagination.current_page, total_pages: resp.pagination.last_page });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    get_conversas(page = 1) {
        this.setState({ loading: true });

        fetch(`${URL}api/admin/get_conversas`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                }
                else {
                    this.setState({
                        conversas: resp.conversas,
                        loading: false,
                        conversas_ids: resp.conversas_ids
                    }, () => {
                        try{
                            this.init_shared_worker();
                            this.props.changeMobile(false);
                        }
                        catch(e){
                            console.log(e);

                            this.shared_worker={port:{postMessage:()=>{}}}
                            this.props.changeMobile(true);
                        }
                    });

                }




            } catch (err) {
                console.log(err);

            }

        })
            .catch((err) => {
                console.log(err);
            });
    }

    componentWillUnmount(){
        if(this.shared_worker!=null){
            this.shared_worker.port.onmessage=null;
            this.shared_worker=null;
        }
    }

    componentDidMount() {

        this.get_conversas();
    }

    init_shared_worker() {
        this.shared_worker = new SharedWorker('socket_atendente.js');
        this.shared_worker.port.start();

        this.shared_worker.port.onmessage = (e) => {
            console.log(e.data);
            let conversas_ids = this.state.conversas_ids;
            let conversas = this.state.conversas;
            console.log(conversas_ids);
            if (e.data.conversa_id != null && !this.state.conversas_ids.includes(e.data.conversa_id)) {

                conversas_ids.push(e.data.conversa_id);
                conversas.unshift({
                    id: e.data.conversa_id,
                    user_id: e.data.user_id,
                    user: (e.data.user_id != null && e.data.user_id != '') ? { id: e.data.user_id, name: e.data.user_name, foto: e.data.user_foto } : null,
                    user_name: e.data.user_name,
                    user_uuid: e.data.user_uuid,
                    mensagens: [],
                    last_message: '',
                    nao_vistas: 0
                });
                this.setState({ conversas, conversas_ids }, () => {
                    if (this.state.conversa_select == e.data.conversa_id) {
                        try{
                            this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                        }
                        catch(e){
        
                        }
                    }
                });

            }
            if (e.data.status == 'msg_save') {
                for (let i = 0; i < conversas.length; i++) {
                    if (conversas[i].id == e.data.conversa_id) {
                        for (let j = 0; j < conversas[i].mensagens.length; j++) {
                            if (conversas[i].mensagens[j].uuid == e.data.id_msg) {
                                conversas[i].mensagens[j].visto = false;
                                break;
                            }
                        }
                        break;
                    }
                }
                this.setState({ conversas, conversas_ids }, () => {
                    if (this.state.conversa_select == e.data.conversa_id) {
                        try{
                            this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                        }
                        catch(e){
        
                        }
                    }
                });

            }
            else if (e.data.status == 'message_other_tab') {
                for (let i = 0; i < conversas.length; i++) {
                    if (conversas[i].id == e.data.conversa_id) {
                        conversas[i].mensagens.push({
                            atendente: this.props.user,
                            atendente_id: this.props.user.id,
                            message: e.data.msg,
                            uuid: e.data.id_msg,
                            conversa_id: e.data.conversa_id
                        })
                        conversas[i].last_message = this.props.user.name.split(' ')[0] + ': ' + e.data.msg.substring(0, 20) + (e.data.msg.length > 20 ? '...' : '')
                    }
                }
                this.setState({ conversas, conversas_ids }, () => {
                    if (this.state.conversa_select == e.data.conversa_id) {
                        try{
                            this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                        }
                        catch(e){
        
                        }
                    }
                });

            }
            else if (e.data.status == 'msg_receive') {
                for (let i = 0; i < conversas.length; i++) {
                    if (conversas[i].id == e.data.conversa_id) {
                        conversas[i].mensagens.push({
                            atendente: null,
                            atendente_id: null,
                            message: e.data.msg,
                            uuid: e.data.id_msg,
                            conversa_id: e.data.conversa_id,
                            user: e.data.user_id == null ? null : { id: e.data.user_id, name: e.data.user_name },
                            user_name: e.data.user_name,
                            user_id: e.data.user_id,
                            user_uuid: e.data.user_uuid,
                        });
                        console.log('Não vista');
                        console.log(this.state.conversa_select);

                        if (this.state.conversa_select != e.data.conversa_id) {
                            conversas[i].nao_vistas += 1;
                            console.log('Não vista');

                            console.log(conversas[i].nao_vistas);

                        }
                        else {
                            this.shared_worker.port.postMessage({ status: 'visto', conversa_id: e.data.conversa_id, user_id: e.data.user_id == null ? null : e.data.user_id, user_uuid: e.data.user_uuid,id_msg:e.data.id_msg })
                        }
                        conversas[i].last_message = e.data.user_name.split(' ')[0] + ': ' + e.data.msg.substring(0, 20) + (e.data.msg.length > 20 ? '...' : '');
                        let conversa_element = { ...conversas[i] };
                        conversas = conversas.filter((item, id) => item.id != conversa_element.id);
                        conversas.unshift(conversa_element);
                        break;
                    }
                }
                this.setState({ conversas, conversas_ids }, () => {
                    if (this.state.conversa_select == e.data.conversa_id) {
                        try{
                            this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                        }
                        catch(e){
        
                        }
                    }
                });

            }
            else if (e.data.status == 'msg_receive_atendente') {
                if (this.props.user.id != e.data.atendente_id) {
                    for (let i = 0; i < conversas.length; i++) {
                        if (conversas[i].id == e.data.conversa_id) {
                            conversas[i].mensagens.push({
                                atendente: { id: e.data.atendente_id, name: e.data.atendente_name },
                                atendente_id: e.data.atendente_id,
                                message: e.data.msg,
                                uuid: e.data.id_msg,
                                conversa_id: e.data.conversa_id
                            })
                            conversas[i].last_message = e.data.atendente_name.split(' ')[0] + ': ' + e.data.msg.substring(0, 20) + (e.data.msg.length > 20 ? '...' : '')
                        }
                    }
                }
                this.setState({ conversas, conversas_ids }, () => {
                    if (this.state.conversa_select == e.data.conversa_id) {
                        try{
                            this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                        }
                        catch(e){
        
                        }
                    }
                });

            }
            else if (e.data.status == 'msg_vista') {
                let achou = false;
                for (let i = 0; i < conversas.length; i++) {
                    if (conversas[i].id == e.data.conversa_id) {
                        for (let j = 0; j < conversas[i].mensagens.length; j++) {
                            conversas[i].mensagens[j].visto = true;

                            if (conversas[i].mensagens[j].uuid == e.data.id_msg) {
                                break;
                            }
                        }
                        break;

                    }
                }
                this.setState({ conversas, conversas_ids }, () => {
                    if (this.state.conversa_select == e.data.conversa_id) {
                        try{
                            this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                        }
                        catch(e){
        
                        }
                    }
                });

            }
        }

    }



    render() {
        let conversas = this.state.conversas;
        return (
            this.props.mobile==false && <div style={{ margin: 15 }}>
                <h6>Chat de Atendimento</h6>
                {conversas.length > 0 && <div className="row" style={{ borderRadius: 10, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.03)' }}>
                    <div className="col-4 overflow-auto"
                        style={{ border: '1px solid white', padding: 0, height: '680px', background: 'white', borderRight: '1px solid lightgrey', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }}>

                        <div id="conversas_head">

                            {conversas.map((item, id) => (

                                <div onClick={() => {
                                    let conversas = [...this.state.conversas];
                                    conversas[id].nao_vistas = 0;
                                    this.setState({ conversa_select: item.id, conversas },()=>{
                                        try{
                                            this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                                        }
                                        catch(e){
                        
                                        }
                                    });

                                    this.shared_worker.port.postMessage({ status: 'visto', conversa_id: item.id, user_id: item.user_id == null ? null : item.user_id, user_uuid: item.user_uuid });

                                }} key={id} className="chat"
                                    style={{ background: this.state.conversa_select == item.id ? '#f2f2f2' : 'white', borderBottom: '1px solid grey', cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex', margin: '5px' }} >
                                        <div className="image mr-3"><img
                                            src={(item.user == null || item.user.foto == null) ? URL + 'images/default_profile.jpg' : URL + 'storage/' + item.user.foto}
                                            alt="User image" className="rounded-circle" style={{ minWidth: '70px', height: '70px', width: '70px', objectFit: 'cover', border: '1px solid lightgrey' }} /></div>
                                        <div>
                                            <p style={{ fontWeight: 'bold', fontSize: '1.4rem', color: 'black', marginBottom: '5px' }}>{item.user == null ? item.user_name : item.user.name}</p>
                                            <span style={{ fontWeight: 'normal', fontSize: '1.2rem' }}>
                                                {item.last_message}
                                            </span>

                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', margin: '5px', justifyContent: 'center', alignItems: 'center' }} >
                                        <div hidden={item.nao_vistas == 0}
                                            style={{ minWidth: '30px', backgroundColor: 'green', borderRadius: '100px', height: '30px', width: '30px', color: 'white', textAlign: 'center', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                            {item.nao_vistas}</div>
                                    </div>
                                </div>
                            ))}

                        </div>

                    </div>
                    <div className="col-8" style={{ background: 'white', padding: 0, borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }}>

                        <div style={{ height: '630px', width: '100%' }} className="overflow-auto" id="body_msg" ref={this.messages_view}>

                            {conversas.map((item, id) => (


                                <div key={id} hidden={this.state.conversa_select != item.id}>


                                    {item.mensagens.map((msg, id2) => (
                                        <div key={id2}>

                                            {msg.atendente_id == this.props.user.id && <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
                                                <div
                                                    style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                                    <div
                                                        style={{ padding: '5px', backgroundColor: 'aquamarine', marginRight: '0.5rem', marginLeft: '6rem', borderRadius: '5px', textAlign: 'justify', display: 'flex', alignItems: 'flex-end' }}>
                                                        <p style={{ marginBottom: 0, color: 'black', marginRight: '1rem' }}>
                                                            {msg.message}</p>
                                                        <i className={msg.visto == undefined ? 'far fa-clock' : (msg.visto == false ? "fas fa-check" : "fas fa-check-double")}
                                                            style={{ fontSize: '0.5rem', marginLeft: '1rem', padding: 0, margin: 0 }}></i>
                                                    </div>
                                                </div>
                                            </div>}
                                            {msg.atendente_id == null && <div
                                                style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                                <div style={{ padding: '5px', backgroundColor: 'azure', marginRight: '6rem', marginLeft: '0.5rem', borderRadius: '5px', textAlign: 'justify' }}
                                                >
                                                    <p style={{ marginBottom: 0, color: 'blue', fontWeight: 'bold', fontSize: '1.4rem' }}>
                                                        {msg.user == null ? msg.user_name : msg.user.name}
                                                    </p>
                                                    <p style={{ marginBottom: 0, color: 'black' }}>{msg.message}</p>
                                                </div>
                                            </div>}
                                            {msg.atendente_id != null && msg.atendente_id != this.props.user.id && <div
                                                style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                                                <div style={{ padding: '5px', backgroundColor: 'azure', marginRight: '6rem', marginLeft: '0.5rem', borderRadius: '5px', textAlign: 'justify' }}
                                                >
                                                    <p style={{ marginBottom: 0, color: 'blue', fontWeight: 'bold', fontSize: '1.4rem' }}>
                                                        {msg.atendente.name}</p>
                                                    <p style={{ marginBottom: 0, color: 'black' }}>{msg.message}</p>
                                                </div>
                                            </div>}
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>

                        <div hidden={this.state.conversa_select == ''}
                            style={{ borderBottomRightRadius: '10px', height: '50px', backgroundColor: 'rgba(255,255,255,0.5)', borderTop: '1px solid rgba(255,255,255,0.5)', padding: '5px', display: 'flex' }}>
                            <textarea value={this.state.mensagem} onChange={(e) => {
                                this.setState({ mensagem: e.target.value })
                            }} onKeyPress={(e) => {
                                if (e.key == 'Enter') {
                                    e.preventDefault();
                                    let uid_msg = uuidv4();
                                    let conversa_id = this.state.conversa_select;
                                    let conversas = this.state.conversas;
                                    let user_id = null;
                                    let user_uuid = '';
                                    let msg = this.state.mensagem;

                                    if (msg != null && msg != '' && msg.replace(/ /g, '') != '') {
                                        for (let i = 0; i < conversas.length; i++) {
                                            if (conversa_id == conversas[i].id) {
                                                console.log('entrou');
                                                console.log(conversas[i]);

                                                user_uuid = conversas[i].user_uuid;
                                                user_id = conversas[i].user_id;
                                                conversas[i].mensagens.push({
                                                    atendente: this.props.user,
                                                    atendente_id: this.props.user.id,
                                                    message: msg,
                                                    uuid: uid_msg,
                                                    conversa_id: conversa_id
                                                })
                                                conversas[i].last_message = this.props.user.name.split(' ')[0] + ': ' + msg.substring(0, 20) + (msg.length > 20 ? '...' : '');
                                                break;
                                            }
                                        }
                                        this.shared_worker.port.postMessage({ user_uuid, id_msg: uid_msg, user_id: user_id, msg: this.state.mensagem, status: 'send_msg', atendente_name: this.props.user.name, atendente_id: this.props.user.id, conversa_id })
                                        // socket.emit("send_msg_fiscal", {msg:msg,sala_id:sala_id,aluno_id:aluno_id,nome_chat:$('#nome_chat').text().split(' ')[0]+' '+$('#nome_chat').text().split(' ')[1],id_msg:uid_msg,faculdade_id:faculdade_id});
                                        this.setState({ mensagem: '', conversas },()=>{
                                            try{
                                                this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                                            }
                                            catch(e){
                            
                                            }
                                        });
                                    }
                                }
                            }} name="msg" placeholder="Mensagem..." className="form-control" style={{ resize: 'none', minHeight: 0, margin: 0 }} ></textarea>
                            <div className="send_msg"
                                onClick={() => {
                                    let uid_msg = uuidv4();
                                    let conversa_id = this.state.conversa_select;
                                    let conversas = this.state.conversas;
                                    let user_id = null;
                                    let user_uuid = '';
                                    let msg = this.state.mensagem;

                                    if (msg != null && msg != '' && msg.replace(/ /g, '') != '') {
                                        for (let i = 0; i < conversas.length; i++) {
                                            if (conversa_id == conversas[i].id) {
                                                console.log('entrou');
                                                console.log(conversas[i]);

                                                user_uuid = conversas[i].user_uuid;
                                                user_id = conversas[i].user_id;
                                                conversas[i].mensagens.push({
                                                    atendente: this.props.user,
                                                    atendente_id: this.props.user.id,
                                                    message: msg,
                                                    uuid: uid_msg,
                                                    conversa_id: conversa_id
                                                })
                                                conversas[i].last_message = this.props.user.name.split(' ')[0] + ': ' + msg.substring(0, 20) + (msg.length > 20 ? '...' : '');
                                                break;
                                            }
                                        }
                                        this.shared_worker.port.postMessage({ user_uuid, id_msg: uid_msg, user_id: user_id, msg: this.state.mensagem, status: 'send_msg', atendente_name: this.props.user.name, atendente_id: this.props.user.id, conversa_id })
                                        // socket.emit("send_msg_fiscal", {msg:msg,sala_id:sala_id,aluno_id:aluno_id,nome_chat:$('#nome_chat').text().split(' ')[0]+' '+$('#nome_chat').text().split(' ')[1],id_msg:uid_msg,faculdade_id:faculdade_id});
                                        this.setState({ mensagem: '', conversas },()=>{
                                            try{
                                                this.messages_view.current.scrollTop = this.messages_view.current.scrollHeight;
                                            }
                                            catch(e){
                            
                                            }
                                        });
                                    }
                                }}
                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '5px', width: '30px', cursor: 'pointer' }}>
                                <i className="far fa-paper-plane" style={{ fontSize: '1.4rem' }}></i>

                            </div>
                        </div>
                    </div>
                </div>}
                {conversas.length == 0 && <div className="row d-flex justify-content-center align-items-center">
                    <p style={{ textAlign: "center" }}>Nenhuma conversa iniciada</p>
                </div>}
            </div>
        )
    }
}

export const mapStateToProps = (state) => ({
    token: state.user.token,
    user: state.user.user,
    mobile:state.home.mobile
})

export default connect(mapStateToProps, { logout,changeMobile })(ChatGeral);