import React, { Component } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { Link, Redirect } from 'react-router-dom';

// import Custom Components
import { connect } from 'react-redux';
import Select from 'react-select'
import { URL } from '../../../variables';
import { maskCpf, maskPrice } from '../../../Auxiliar/Masks';
import { logout, login } from '../../../actions';
import SweetAlert from 'react-bootstrap-sweetalert';

class EditUser extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            password: '',
            password_confirmation: '',
            loading_save: false,
            msgErro: '',
            redirect: false,
            roles: [],
            role_id: '',
            show_success: false,
            foto: '',
            cpf: '',
            foto_path: '',
            genero: ''
        };
    }

    salvar() {
        this.setState({ loading_save: true, msgErro: '' });
        let form = new FormData();
        form.append('name', this.state.name);
        form.append('cpf', this.state.cpf);
        form.append('genero', this.state.genero);

        form.append('password', this.state.password);
        form.append('password_confirmation', this.state.password_confirmation);
        // form.append('imagem', this.state.foto);
        if (this.state.foto != '') {
            let photo = this.state.foto;
            form.append('imagem', photo);
        }
        else {
            form.append('imagem_file', false);

        }

        // return;

        fetch(`${URL}api/auth/edit_user`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {
                    this.props.login({ user: resp.user, token: this.props.token });
                    this.setState({ loading_save: false, show_success: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }

    componentDidMount() {
        console.log(this.props.token);
        this.get_user();
    }

    get_user() {
        this.setState({ loading: true });

        fetch(`${URL}api/auth/get_user`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                }
                else {
                    this.props.login({ user: resp.user, token: this.props.token });


                    this.setState({
                        loading: false,
                        name: resp.user.name,
                        email: resp.user.email,
                        cpf: resp.user.cpf == null ? '' : resp.user.cpf,
                        genero: resp.user.genero == null ? '' : resp.user.genero,


                        foto_path: resp.user.imagem != null ? URL + resp.user.imagem : '',

                    });

                }




            } catch (err) {
                console.log(err);
                this.setState({
                    loading: false
                });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({
                    loading: false
                });
            });
    }

    render() {

        return (
            <div>
                {this.state.loading == false && <div>

                    {this.state.redirect == true && <Redirect to={'/conta/usuarios'} />}

                    <div style={{
                        color: '#721c24',
                        backgroundColor: '#f8d7da',
                        borderColor: '#f5c6cb'
                    }} hidden={this.state.msgErro == ''} className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                        <button style={{ color: '#721c24', top: '55%', fontSize: '2rem' }} onClick={() => this.setState({ msgErro: '' })} type="button" className="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>

                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                    </div>
                    <br />
                    <label>Foto</label>

                    <input className="form-control" accept="image/*" placeholder="Modelo" type="file" onChange={(e) => {
                        // let foto = e.target.files[0];
                        // this.setState({ foto });
                        let foto_path = '';
                        let reader = new FileReader()
                        reader.readAsDataURL(e.target.files[0])
                        reader.onload = () => {
                            console.log(reader);
                            foto_path = reader.result;
                            this.setState({ foto_path, foto: reader.result });
                        };
                        reader.onerror = function (error) {
                            console.log('Error: ', error);
                        }
                        e.target.value = null;
                    }} />
                    <div className="row">
                        {this.state.foto_path != '' &&
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }} className="col-6 col-md-3">
                                <div>
                                    <img src={this.state.foto_path} style={{ height: 100, width: 100, objectFit: "cover", borderRadius: 100, border: '1px solid lightgrey' }} />
                                    <a onClick={() => {

                                        this.setState({ foto: '', foto_path: '' });
                                    }} className="btn btn-link btn-link-dark" style={{ color: 'indianred', cursor: 'pointer' }}><span>Remover</span><i className="fas fa-times"></i></a>
                                </div>
                            </div>

                        }
                    </div>
                    <hr />
                    <label>Nome *</label>
                    <input value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} type="text" placeholder="Nome" className="form-control" required />
                    {/* <small className="form-text">This will be how your name will be displayed in the account section and in reviews</small> */}

                    <label>E-mail</label>
                    <input value={this.state.email} disabled type="email" placeholder="Endereço de E-mail" className="form-control text-muted" required />

                    <label>CPF *</label>
                    <input value={this.state.cpf} onChange={(e) => this.setState({ cpf: maskCpf(e.target.value) })} type="text" placeholder="CPF" className="form-control" required />

                    <label>Gênero *</label>
                    <select name="genero" id="genero" className="form-control" value={this.state.genero} onChange={(e) => {
                        this.setState({ genero: e.target.value });
                    }}>
                        <option value="">Selecione um Gênero</option>
                        <option value={'M'}>Masculino</option>
                        <option value={'F'}>Feminino</option>
                    </select>

                    <label htmlFor="singin-password-2">Senha </label>
                    <input value={this.state.password} onChange={(e) => {
                        this.setState({ password: e.target.value });
                    }} type="password" className="form-control" id="singin-password-2" name="singin-password" placeholder="Senha" required />

                    <label htmlFor="register-password-2">Confirmar Senha </label>
                    <input value={this.state.password_confirmation} onChange={(e) => {
                        this.setState({ password_confirmation: e.target.value });
                    }} type="password" className="form-control" id="register-password-2" name="register-password" placeholder="Confirmar Senha" required />


                    {this.state.loading_save == false && <button onClick={() => {
                        this.salvar();
                    }} style={{ marginTop: '1.3rem' }} type="button" className="btn btn-outline-primary btn-rounded">
                        <span>Salvar</span>
                        <i className="far fa-save"></i>
                    </button>}
                    {this.state.loading_save == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div></div>}
                    <SweetAlert
                        success
                        title={"Sucesso"}
                        onConfirm={() => {
                            this.setState({ show_success: false });
                        }}
                        show={this.state.show_success}
                        confirmBtnStyle={{ color: 'white' }}

                        confirmBtnText='OK'
                        confirmBtnBsStyle="primary"

                    >
                        Usuário editado com sucesso

                    </SweetAlert>
                </div>}
                {this.state.loading == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div></div>}
            </div>
        )
    }
}

export const mapStateToProps = (state) => ({
    token: state.user.token

})

export default connect(mapStateToProps, { logout, login })(EditUser);