import React, { Component } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { Link, Redirect, Route, Switch, NavLink } from 'react-router-dom';

// import Custom Components
import PageHeader from '../../common/page-header';
import Breadcrumb from '../../common/breadcrumb';
import Account from './account';
import Produtos from './Produtos/Produtos';
import { changeState, logout, replaceCart, replaceWishList } from '../../../actions';
import { connect } from 'react-redux';
import AdicionarProduto from './Produtos/AdicionarProduto';
import EditarProduto from './Produtos/EditarProduto';
import Marcas from './Marcas/Marcas';
import AdicionarMarca from './Marcas/AdicionarMarca';
import EditarMarca from './Marcas/EditarMarca';
import Categorias from './Categorias/Categorias';
import AdicionarCategoria from './Categorias/AdicionarCategoria';
import EditarCategoria from './Categorias/EditarCategoria';
import Banners from './Banners/Banners';
import AdicionarBannerTopo from './Banners/AdicionarBannerTopo';
import EditarBannerTopp from './Banners/EditarBannerTopp';
import AdicionarBannerPromocao from './Banners/AdicionarBannerPromocao';
import EditarBannerPromocao from './Banners/EditarBannerPromocao';
import AdicionarBannerFaixa from './Banners/AdicionarBannerFaixa';
import EditarBannerFaixa from './Banners/EditarBannerFaixa';
import AdicionarBannerLimitada from './Banners/AdicionarBannerLimitada';
import EditarBannerLimitada from './Banners/EditarBannerLimitada';
import NossasMarcas from './NossasMarcas/NossasMarcas';
import AdicionarNossaMarca from './NossasMarcas/AdicionarNossaMarca';
import EditarNossaMarca from './NossasMarcas/EditarNossaMarca';
import Usuarios from './Usuarios/Usuarios';
import AdicionarUsuario from './Usuarios/AdicionarUsuario';
import EditarUsuario from './Usuarios/EditarUsuario';
import EditUser from './EditUser';
import Enderecos from './Enderecos/Enderecos';
import AdicionarEndereco from './Enderecos/AdicionarEndereco';
import EditarEndereco from './Enderecos/EditarEndereco';
import ChatGeral from './ChatGeral/ChatGeral';
import Avaliacoes from './Comentarios/Avaliacoes';
import AdicionarBannerTopoMobile from './Banners/AdicionarBannerTopoMobile';
import EditarBannerTopoMobile from './Banners/EditarBannerTopoMobile';
import Embalagens from './Embalagens/Embalagens';
import AdicionarEmbalagem from './Embalagens/AdicionarEmbalagem';
import EditarEmbalagem from './Embalagens/EditarEmbalagem';
import PedidosAdmin from './PedidosAdmin/PedidosAdmin';
import Pedidos from './Pedidos/Pedidos';
import DefaultProfile from '../../../imgs/default_profile.jpg';
import { URL } from '../../../variables';
import Cartoes from './Cartoes/Cartoes';
import AdicionarCartao from './Cartoes/AdicionarCartao';
import Emails from './Emails/Emails';

const OtherRoute = ({ component: Component, path_name, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            <Redirect to={{ pathname: path_name, state: { from: props.location } }} />
        }
    />
);



class DashBoard extends Component {

    analisarUrl(url) {
        if (url == '/conta/dashboard' || url == '/conta/dashboard/') {
            return 'Minha Conta'
        }
        else if (url == '/conta/enderecos' || url == '/conta/enderecos/') {
            return 'Endereços'
        }
        else if (url == '/conta/editar_usuario' || url == '/conta/editar_usuario/') {
            return 'Editar Usuário'
        }
        else if (url == '/conta/historico_compras' || url == '/conta/historico_compras/') {
            return 'Histórico de Compra'
        }
        else if (url == '/conta/usuarios' || url == '/conta/usuarios/') {
            return 'Usuários'
        }
        else if (url == '/conta/nossas_marcas' || url == '/conta/nossas_marcas/') {
            return 'Nossas Marcas'
        }
        else if (url == '/conta/banners' || url == '/conta/banners/') {
            return 'Banners'
        }
        else if (url == '/conta/marcas' || url == '/conta/marcas/') {
            return 'Marcas dos Produtos'
        }
        else if (url == '/conta/embalagens' || url == '/conta/embalagens/') {
            return 'Embalagens'
        }
        else if (url == '/conta/categorias' || url == '/conta/categorias/') {
            return 'Categorias dos Produtos'
        }
        else if (url == '/conta/produtos' || url == '/conta/produtos/') {
            return 'Produtos'
        }
        else if (url == '/conta/chat_atendimento' || url == '/conta/chat_atendimento/') {
            return 'Chat de Atendimento'
        }
        else if (url == '/conta/avaliacoes' || url == '/conta/avaliacoes/') {
            return 'Avaliações'
        }
        else if (url == '/conta/pedidos' || url == '/conta/pedidos/') {
            return 'Pedidos dos Usuários'
        }
    }

    render() {
        console.log(this.props.location.pathname)
        return (
            <div className="main">
                <PageHeader background="url(assets/images/busi.jpg)" title="Minha Conta" subTitle="Painel" />
                <Breadcrumb title={this.analisarUrl(this.props.location.pathname)} />

                <div className="page-content">
                    <div className="dashboard">
                        <div className="container-fluid">
                            <ul className="nav nav-dashboard flex-column mb-3 mb-md-0" role="tablist">
                                {/* <Tabs selectedTabClassName="active show"> */}
                                <div className="row">

                                    {this.props.user != undefined && (this.props.user.role == 'admin' || this.props.user.role == 'superadmin') && <aside className="col-md-4 col-lg-3">
                                        <div className="nav-item" style={{ display: 'flex', alignItems: "center" }}>

                                            <img style={{ height: 75, width: 75, borderRadius: 100, border: '1px solid lightgrey', marginRight: 5, objectFit: 'cover' }} src={this.props.user.imagem == undefined ? DefaultProfile : URL + this.props.user.imagem} />
                                            <div>
                                                <p style={{ fontSize: '1.6rem', color: 'black' }}>Olá,</p>

                                                <span style={{ fontSize: '1.8rem', fontWeight: "bold", color: 'black' }}>{this.props.user.name}</span>
                                            </div>
                                        </div>
                                        <hr />

                                        <div className="nav-item">
                                            <NavLink to={`${this.props.match.url}/dashboard`} className="nav-link"
                                                activeStyle={{ fontWeight: 500, color: '#005b8c' }}

                                            >
                                                <i className="fas fa-chart-pie" style={{ marginRight: 15, fontSize: '1.5rem', color: '#19386e' }}></i>
                                                Dashboard
                                                </NavLink>
                                        </div>
                                        <div className="nav-item">

                                            <NavLink to={`${this.props.match.url}/usuarios`} className="nav-link"
                                                activeStyle={{ fontWeight: 500, color: '#005b8c' }}

                                            >
                                                <i className="fas fa-users" style={{ marginRight: 15, fontSize: '1.5rem', color: '#19386e' }}></i>

                                                Usuários</NavLink>
                                        </div>
                                        <div className="nav-item">

<NavLink to={`${this.props.match.url}/emails`} className="nav-link"
    activeStyle={{ fontWeight: 500, color: '#005b8c' }}

>
    <i className="fas fa-envelope" style={{ marginRight: 15, fontSize: '1.5rem', color: '#19386e' }}></i>

    E-mails</NavLink>
</div>
                                        <div className="nav-item">

                                            <NavLink to={`${this.props.match.url}/nossas_marcas`} className="nav-link"
                                                activeStyle={{ fontWeight: 500, color: '#005b8c' }}

                                            >

                                                <i className="fas fa-list" style={{ marginRight: 15, fontSize: '1.5rem', color: '#19386e' }}></i>
                                                Nossas Marcas</NavLink>
                                        </div>
                                        <div className="nav-item">

                                            <NavLink to={`${this.props.match.url}/banners`} className="nav-link"
                                                activeStyle={{ fontWeight: 500, color: '#005b8c' }}

                                            >
                                                <i className="fas fa-images" style={{ marginRight: 15, fontSize: '1.5rem', color: '#19386e' }}></i>

                                                Banners</NavLink>
                                        </div>
                                        <div className="nav-item">

                                            <NavLink to={`${this.props.match.url}/marcas`} className="nav-link"
                                                activeStyle={{ fontWeight: 500, color: '#005b8c' }}

                                            >
                                                <i className="far fa-list-alt" style={{ marginRight: 15, fontSize: '1.5rem', color: '#19386e' }}></i>

                                                Marcas dos Produtos</NavLink>
                                        </div>
                                        <div className="nav-item">

                                            <NavLink to={`${this.props.match.url}/embalagens`} className="nav-link"
                                                activeStyle={{ fontWeight: 500, color: '#005b8c' }}

                                            >
                                                <i className="fas fa-box" style={{ marginRight: 15, fontSize: '1.5rem', color: '#19386e' }}></i>

                                                Embalagens</NavLink>
                                        </div>
                                        <div className="nav-item">

                                            <NavLink to={`${this.props.match.url}/categorias`} className="nav-link"
                                                activeStyle={{ fontWeight: 500, color: '#005b8c' }}

                                            >
                                                <i className="fas fa-bars" style={{ marginRight: 15, fontSize: '1.5rem', color: '#19386e' }}></i>

                                                Categorias dos Produtos</NavLink>
                                        </div>
                                        <div className="nav-item">

                                            <NavLink to={`${this.props.match.url}/produtos`} className="nav-link"
                                                activeStyle={{ fontWeight: 500, color: '#005b8c' }}

                                            >
                                                <i className="fas fa-wine-bottle" style={{ marginRight: 15, fontSize: '1.5rem', color: '#19386e' }}></i>

                                                Produtos</NavLink>
                                        </div>
                                        <div className="nav-item">

                                            <NavLink to={`${this.props.match.url}/editar_usuario`} className="nav-link"
                                                activeStyle={{ fontWeight: 500, color: '#005b8c' }}

                                            >
                                                <i className="fas fa-user-edit" style={{ marginRight: 15, fontSize: '1.5rem', color: '#19386e' }}></i>

                                                Editar Perfil</NavLink>
                                        </div>
                                        <div className="nav-item">

                                            <NavLink to={`${this.props.match.url}/enderecos`} className="nav-link"
                                                activeStyle={{ fontWeight: 500, color: '#005b8c' }}

                                            >
                                                <i className="fas fa-map-marked-alt" style={{ marginRight: 15, fontSize: '1.5rem', color: '#19386e' }}></i>

                                                Endereços</NavLink>
                                        </div>
                                        <div className="nav-item">

                                            <NavLink to={`${this.props.match.url}/chat_atendimento`} className="nav-link"
                                                activeStyle={{ fontWeight: 500, color: '#005b8c' }}

                                            >
                                                <i className="fas fa-comments" style={{ marginRight: 15, fontSize: '1.5rem', color: '#19386e' }}></i>

                                                Chat de Atendimento</NavLink>
                                        </div>
                                        <div className="nav-item">

                                            <NavLink to={`${this.props.match.url}/avaliacoes`} className="nav-link"
                                                activeStyle={{ fontWeight: 500, color: '#005b8c' }}

                                            >
                                                <i className="fas fa-star" style={{ marginRight: 15, fontSize: '1.5rem', color: '#19386e' }}></i>

                                                Avaliações</NavLink>
                                        </div>
                                        <div className="nav-item">

                                            <NavLink to={`${this.props.match.url}/pedidos`} className="nav-link"
                                                activeStyle={{ fontWeight: 500, color: '#005b8c' }}

                                            >
                                                <i className="fas fa-truck" style={{ marginRight: 15, fontSize: '1.5rem', color: '#19386e' }}></i>

                                                Pedidos dos Usuários</NavLink>
                                        </div>
                                        <div className="nav-item">

                                            <NavLink to={`${this.props.match.url}/cartoes_credito`} className="nav-link"
                                                activeStyle={{ fontWeight: 500, color: '#005b8c' }}

                                            >
                                                <i className="fas fa-shopping-bag" style={{ marginRight: 15, fontSize: '1.5rem', color: '#19386e' }}></i>

    Cartões de Crédito</NavLink>
                                        </div>
                                        <div className="nav-item">

                                            <NavLink to={`${this.props.match.url}/historico_compras`} className="nav-link"
                                                activeStyle={{ fontWeight: 500, color: '#005b8c' }}

                                            >
                                                <i className="fas fa-shopping-bag" style={{ marginRight: 15, fontSize: '1.5rem', color: '#19386e' }}></i>

                                                Histórico de Compra</NavLink>
                                        </div>
                                        <div className="nav-item">
                                            <Link onClick={(e) => {
                                                e.preventDefault();
                                                localStorage.removeItem('token');
                                                this.props.replaceCart([]);
                                                this.props.replaceWishList([]);
                                                this.props.changeState({ endereco: {},
                                                    precos_prazos: [],
                                                    volumes: [],
                                                
                                                    endereco_cobranca: {},
                                                    forma_pagamento: { type: '', object: {},installment:1  },
                                                    shipping: { delivery_method_id: '', final_shipping_cost: 0 }})
                                                this.props.logout();
                                            }} to="#" className="nav-link"


                                            >
                                                <i className="fas fa-sign-out-alt" style={{ marginRight: 15, fontSize: '1.5rem', color: '#19386e' }}></i>

                                                Sair
                                                </Link>
                                        </div>

                                    </aside>}

                                    {this.props.user != undefined && (this.props.user.role == 'cliente') && <aside className="col-md-4 col-lg-3">
                                        <div className="nav-item">
                                            <NavLink to={`${this.props.match.url}/dashboard`} className="nav-link"
                                                activeStyle={{ fontWeight: 500, color: '#005b8c' }}
                                            >
                                                <i className="fas fa-chart-pie" style={{ marginRight: 15, fontSize: '1.5rem', color: '#19386e' }}></i>

                                                Dashboard</NavLink>
                                        </div>
                                        <div className="nav-item">
                                            <NavLink to={`${this.props.match.url}/enderecos`} className="nav-link"
                                                activeStyle={{ fontWeight: 500, color: '#005b8c' }}

                                            >
                                                <i className="fas fa-map-marked-alt" style={{ marginRight: 15, fontSize: '1.5rem', color: '#19386e' }}></i>

                                                Endereços</NavLink>
                                        </div>
                                        <div className="nav-item">
                                            <NavLink to={`${this.props.match.url}/editar_usuario`} className="nav-link"
                                                activeStyle={{ fontWeight: 500, color: '#005b8c' }}

                                            >
                                                <i className="fas fa-user-edit" style={{ marginRight: 15, fontSize: '1.5rem', color: '#19386e' }}></i>

                                                Editar Perfil</NavLink>
                                        </div>
                                        <div className="nav-item">
                                            <NavLink to={`${this.props.match.url}/historico_compras`} className="nav-link"
                                                activeStyle={{ fontWeight: 500, color: '#005b8c' }}

                                            >
                                                <i className="fas fa-shopping-bag" style={{ marginRight: 15, fontSize: '1.5rem', color: '#19386e' }}></i>

                                                Histórico de Compra</NavLink>
                                        </div>
                                        <div className="nav-item">
                                            <Link onClick={(e) => {
                                                e.preventDefault();
                                                localStorage.removeItem('token');
                                                this.props.changeState({ endereco: {},
                                                    precos_prazos: [],
                                                    volumes: [],
                                                
                                                    endereco_cobranca: {},
                                                    forma_pagamento: { type: '', object: {},installment:1  },
                                                    shipping: { delivery_method_id: '', final_shipping_cost: 0 }})
                                                this.props.logout();
                                            }} to="#" className="nav-link"

                                            >
                                                <i className="fas fa-sign-out-alt" style={{ marginRight: 15, fontSize: '1.5rem', color: '#19386e' }}></i>

                                                Sair</Link>
                                        </div>
                                    </aside>}

                                    <div className="col-md-8 col-lg-9" style={{ marginTop: "1rem" }}>
                                        {this.props.user != undefined && (this.props.user.role == 'admin' || this.props.user.role == 'superadmin') && <Switch>
                                            <Route exact path={`${this.props.match.url}/dashboard`} render={(props2) => <Account {...props2} />} />
                                            <Route exact path={`${this.props.match.url}/produtos`} render={(props2) => <Produtos {...props2} />} />
                                            <Route exact path={`${this.props.match.url}/produtos/adicionar`} render={(props2) => <AdicionarProduto {...props2} />} />
                                            <Route exact path={`${this.props.match.url}/produtos/editar/:id`} render={(props2) => <EditarProduto {...props2} />} />

                                            <Route exact path={`${this.props.match.url}/marcas`} render={(props2) => <Marcas {...props2} />} />
                                            <Route exact path={`${this.props.match.url}/marcas/adicionar`} render={(props2) => <AdicionarMarca {...props2} />} />
                                            <Route exact path={`${this.props.match.url}/marcas/editar/:id`} render={(props2) => <EditarMarca {...props2} />} />

                                            <Route exact path={`${this.props.match.url}/embalagens`} render={(props2) => <Embalagens {...props2} />} />
                                            <Route exact path={`${this.props.match.url}/embalagens/adicionar`} render={(props2) => <AdicionarEmbalagem {...props2} />} />
                                            <Route exact path={`${this.props.match.url}/embalagens/editar/:id`} render={(props2) => <EditarEmbalagem {...props2} />} />


                                            <Route exact path={`${this.props.match.url}/categorias`} render={(props2) => <Categorias {...props2} />} />
                                            <Route exact path={`${this.props.match.url}/categorias/adicionar`} render={(props2) => <AdicionarCategoria {...props2} />} />
                                            <Route exact path={`${this.props.match.url}/categorias/editar/:id`} render={(props2) => <EditarCategoria {...props2} />} />

                                            <Route exact path={`${this.props.match.url}/banners`} render={(props2) => <Banners {...props2} />} />
                                            <Route exact path={`${this.props.match.url}/banners/adicionar_topo`} render={(props2) => <AdicionarBannerTopo {...props2} />} />
                                            <Route exact path={`${this.props.match.url}/banners/editar_topo/:id`} render={(props2) => <EditarBannerTopp {...props2} />} />

                                            <Route exact path={`${this.props.match.url}/banners/adicionar_topo_mobile`} render={(props2) => <AdicionarBannerTopoMobile {...props2} />} />
                                            <Route exact path={`${this.props.match.url}/banners/editar_topo_mobile/:id`} render={(props2) => <EditarBannerTopoMobile {...props2} />} />

                                            <Route exact path={`${this.props.match.url}/banners/adicionar_promocao`} render={(props2) => <AdicionarBannerPromocao {...props2} />} />
                                            <Route exact path={`${this.props.match.url}/banners/editar_promocao/:id`} render={(props2) => <EditarBannerPromocao {...props2} />} />

                                            <Route exact path={`${this.props.match.url}/banners/adicionar_faixa_meio`} render={(props2) => <AdicionarBannerFaixa {...props2} />} />
                                            <Route exact path={`${this.props.match.url}/banners/editar_faixa_meio/:id`} render={(props2) => <EditarBannerFaixa {...props2} />} />

                                            <Route exact path={`${this.props.match.url}/banners/adicionar_limitada`} render={(props2) => <AdicionarBannerLimitada {...props2} />} />
                                            <Route exact path={`${this.props.match.url}/banners/editar_limitada/:id`} render={(props2) => <EditarBannerLimitada {...props2} />} />

                                            <Route exact path={`${this.props.match.url}/nossas_marcas`} render={(props2) => <NossasMarcas {...props2} />} />
                                            <Route exact path={`${this.props.match.url}/nossas_marcas/adicionar`} render={(props2) => <AdicionarNossaMarca {...props2} />} />
                                            <Route exact path={`${this.props.match.url}/nossas_marcas/editar/:id`} render={(props2) => <EditarNossaMarca {...props2} />} />

                                            <Route exact path={`${this.props.match.url}/emails`} render={(props2) => <Emails {...props2} />} />


                                            <Route exact path={`${this.props.match.url}/usuarios`} render={(props2) => <Usuarios {...props2} />} />
                                            <Route exact path={`${this.props.match.url}/usuarios/adicionar`} render={(props2) => <AdicionarUsuario {...props2} />} />
                                            <Route exact path={`${this.props.match.url}/usuarios/editar/:id`} render={(props2) => <EditarUsuario {...props2} />} />


                                            <Route exact path={`${this.props.match.url}/editar_usuario`} render={(props2) => <EditUser {...props2} />} />

                                            <Route exact path={`${this.props.match.url}/enderecos`} render={(props2) => <Enderecos {...props2} />} />
                                            <Route exact path={`${this.props.match.url}/enderecos/adicionar`} render={(props2) => <AdicionarEndereco {...props2} />} />
                                            <Route exact path={`${this.props.match.url}/enderecos/editar/:id`} render={(props2) => <EditarEndereco {...props2} />} />

                                            <Route exact path={`${this.props.match.url}/pedidos`} render={(props2) => <PedidosAdmin {...props2} />} />


                                            <Route exact path={`${this.props.match.url}/chat_atendimento`} render={(props2) => <ChatGeral {...props2} />} />
                                            <Route exact path={`${this.props.match.url}/avaliacoes`} render={(props2) => <Avaliacoes {...props2} />} />
                                            <Route exact path={`${this.props.match.url}/historico_compras`} render={(props2) => <Pedidos {...props2} />} />

                                            <Route exact path={`${this.props.match.url}/cartoes_credito`} render={(props2) => <Cartoes {...props2} />} />
                                            <Route exact path={`${this.props.match.url}/cartoes_credito/adicionar`} render={(props2) => <AdicionarCartao {...props2} />} />


                                            <OtherRoute path="*" path_name='/conta/dashboard' />

                                        </Switch>}
                                        {this.props.user != undefined && (this.props.user.role == 'cliente') && <Switch>
                                            <Route exact path={`${this.props.match.url}/dashboard`} render={(props2) => <Account {...props2} />} />
                                            <Route exact path={`${this.props.match.url}/enderecos`} render={(props2) => <Enderecos {...props2} />} />
                                            <Route exact path={`${this.props.match.url}/enderecos/adicionar`} render={(props2) => <AdicionarEndereco {...props2} />} />
                                            <Route exact path={`${this.props.match.url}/enderecos/editar/:id`} render={(props2) => <EditarEndereco {...props2} />} />


                                            <Route exact path={`${this.props.match.url}/editar_usuario`} render={(props2) => <EditUser {...props2} />} />
                                            <Route exact path={`${this.props.match.url}/historico_compras`} render={(props2) => <Pedidos {...props2} />} />

                                            <OtherRoute path="*" path_name='/conta/dashboard' />

                                        </Switch>}

                                        {/* <div className="tab-pane">
                                                <TabPanel>
                                                    <p>Hello <span className="font-weight-normal text-dark">User</span> (not <span className="font-weight-normal text-dark">User</span>? <Link to="#">Log out</Link>)
                                                    <br />
                                                    From your account dashboard you can view your <Link to="#tab-orders" className="tab-trigger-link link-underline">recent orders</Link>, manage your <Link to="#tab-address" className="tab-trigger-link">shipping and billing addresses</Link>, and <Link to="#tab-account" className="tab-trigger-link">edit your password and account details</Link>.</p>
                                                </TabPanel>

                                                <TabPanel>
                                                    <p>No order has been made yet.</p>
                                                    <Link to={`${process.env.PUBLIC_URL}/shop/siebar/list`} className="btn btn-outline-primary-2"><span>GO SHOP</span><i className="icon-long-arrow-right"></i></Link>
                                                </TabPanel>

                                                <TabPanel>
                                                    <p>No downloads available yet.</p>
                                                    <Link to={`${process.env.PUBLIC_URL}/shop/sidebar/list`} className="btn btn-outline-primary-2"><span>GO SHOP</span><i className="icon-long-arrow-right"></i></Link>
                                                </TabPanel>

                                                <TabPanel>
                                                    <p>The following addresses will be used on the checkout page by default.</p>

                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="card card-dashboard">
                                                                <div className="card-body">
                                                                    <h3 className="card-title">Billing Address</h3>

                                                                    <p>User Name<br />
                                                                    User Company<br />
                                                                    John str<br />
                                                                    New York, NY 10001<br />
                                                                    1-234-987-6543<br />
                                                                    yourmail@mail.com<br />
                                                                        <Link to="#">Edit <i className="icon-edit"></i></Link></p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-lg-6">
                                                            <div className="card card-dashboard">
                                                                <div className="card-body">
                                                                    <h3 className="card-title">Shipping Address</h3>

                                                                    <p>You have not set up this type of address yet.<br />
                                                                        <Link to="#">Edit <i className="icon-edit"></i></Link></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TabPanel>

                                                <TabPanel>
                                                    <form action="#">
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <label>First Name *</label>
                                                                <input type="text" className="form-control" required />
                                                            </div>

                                                            <div className="col-sm-6">
                                                                <label>Last Name *</label>
                                                                <input type="text" className="form-control" required />
                                                            </div>
                                                        </div>

                                                        <label>Display Name *</label>
                                                        <input type="text" className="form-control" required />
                                                        <small className="form-text">This will be how your name will be displayed in the account section and in reviews</small>

                                                        <label>Email address *</label>
                                                        <input type="email" className="form-control" required />

                                                        <label>Current password (leave blank to leave unchanged)</label>
                                                        <input type="password" className="form-control" />

                                                        <label>New password (leave blank to leave unchanged)</label>
                                                        <input type="password" className="form-control" />

                                                        <label>Confirm new password</label>
                                                        <input type="password" className="form-control mb-2" />

                                                        <button type="submit" className="btn btn-outline-primary-2">
                                                            <span>SAVE CHANGES</span>
                                                            <i className="icon-long-arrow-right"></i>
                                                        </button>
                                                    </form>
                                                </TabPanel>
                                            </div> */}
                                    </div>
                                </div>
                                {/* </Tabs> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export const mapStateToProps = (state) => ({
    user: state.user.user,
    token: state.user.token,
})

export default connect(mapStateToProps, { logout, replaceCart, replaceWishList,changeState })(DashBoard);