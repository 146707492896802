import React, { Component } from 'react';
import ReactDOM from 'react-dom'

import { connect } from 'react-redux';
import SocialButtonGoogle from './SocialButtonGoogle';

const handleSocialLogin = (user) => {
  console.log(user)
}

const handleSocialLoginFailure = (err) => {
  console.error(err)
}

class FacebookLogin extends Component {

  render() {
    return (
        <SocialButtonGoogle
          provider='google'
          appId='367765817048-lb91cf0i76vq8rvm296u331ohkj62cuf.apps.googleusercontent.com'
          autoLogin={false}

          onLoginSuccess={this.props.login_fb.bind(this)}
          onLoginFailure={this.props.error_fb.bind(this)}
        >
          Entrar com o Facebook
        </SocialButtonGoogle>
    );
  }

}
export const mapStateToProps = (state) => ({
  showModal: state.demo.showModal,
  modal: state.demo.modal
})

export default connect(mapStateToProps, {  })(FacebookLogin);