import React, { Component } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { Link } from 'react-router-dom';

// import Custom Components
import { connect } from 'react-redux';
import { URL } from '../../../../variables';
import Pagination from '../../../features/pagination';
import SweetAlert from 'react-bootstrap-sweetalert';
import { logout } from '../../../../actions';
import Modal from 'react-modal';
import ReactTooltip from 'react-tooltip';

const customStyles = {
    content: {
        // top: '50%',
        // transform: 'translateY(-50%)'
    },
    overlay: {
        backgroundColor: 'rgba(51,51,51,0.6)',
        zIndex: '10000',
        // overflowY: 'scroll'
    }
};
class Avaliacoes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            avaliacoes: [],
            current_page: 1,
            last_page: 1,
            total: 1,
            per_page: 1,
            page: 1,
            loading: false,
            show_warning: false,
            show_erro: false,
            msgErro: '',
            msgErroResposta: '',

            user_to_delete: '',
            modal_open: false,
            avaliacao: { respostas: [], produto: {}, user: {} },
            assunto: '',
            descricao: '',
            loading_save: false
        };
    }

    enviar_resposta() {
        this.setState({ loading_save: true, msgErroResposta: '' });
        let form = new FormData();
        form.append('assunto', this.state.assunto);
        form.append('descricao', this.state.descricao);
        form.append('avaliacao', 0);
        form.append('avaliacao_id', this.state.avaliacao.id);

        form.append('produto_id', this.state.avaliacao.produto.id);

        fetch(`${URL}api/admin/avaliacoes/salvar_resposta`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_save: false, msgErroResposta: erro });
                }
                else {

                    this.setState({ loading_save: false, assunto: '', descricao: '', avaliacao: resp.avaliacao });
                    this.get_avaliacoes(this.state.page);
                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErroResposta: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErroResposta: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }

    delete_avaliacao(id) {

        fetch(`${URL}api/admin/avaliacoes/delete_avaliacao/${id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ msgErro: erro, show_warning: false, show_erro: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    let avaliacao = resp.avaliacao;
                    if (avaliacao == null) {
                        avaliacao = this.state.avaliacao;
                    }
                    this.setState({ show_warning: false, avaliacao });
                    this.get_avaliacoes(this.state.page);
                    // this.setState({ users: resp.users, loading_screen: false, page: resp.pagination.current_page, total_pages: resp.pagination.last_page });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    get_avaliacoes(page = 1) {
        this.setState({ loading: true });

        fetch(`${URL}api/admin/avaliacoes/get_avaliacoes?page=${page}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                }
                else {
                    if (page == this.state.page) {
                        console.log('updated');
                        this.setState({
                            avaliacoes: resp.avaliacoes,
                            loading: false,
                            current_page: resp.current_page,
                            last_page: resp.last_page,
                            total: resp.total,
                            per_page: resp.per_page
                        });
                    }
                }




            } catch (err) {
                console.log(err);

            }

        })
            .catch((err) => {
                console.log(err);
            });
    }

    componentDidMount() {
        this.get_avaliacoes(this.state.page);
    }



    render() {
        let avaliacoes = this.state.avaliacoes;

        return (
            <div>

                <table className="table table-cart table-mobile">
                    <thead>
                        <tr>
                            <th>Usuário</th>
                            <th>Produto</th>
                            <th>Avaliação</th>
                            <th>Assunto</th>
                            <th>Comentário</th>
                            <th>Respostas</th>
                            <th>Ações</th>
                        </tr>
                    </thead>
                    {this.state.loading == false && <tbody>
                        {avaliacoes.length > 0 ?
                            avaliacoes.map((item, index) =>
                                <tr key={index}>
                                    <td >
                                        <div className="product">
                                            <h3 className="product-title">
                                                {item.user.name}
                                            </h3>
                                        </div>
                                    </td>
                                    <td >
                                        <div className="product" >


                                            <h3 className="product-title">
                                                <Link target="_blank" to={`${process.env.PUBLIC_URL}/product/default/${item.produto.id}`}>{item.produto.name}</Link>
                                            </h3>
                                        </div>
                                    </td>
                                    <td >
                                        <div className="product" style={{ display: "flex", alignItems: 'center' }}>


                                            <div className="ratings-container" style={{ marginBottom: 0 }}>
                                                <div className="ratings">
                                                    <div className="ratings-val" style={{ width: parseInt((100 / 5) * item.avaliacao) + "%" }}></div>
                                                </div>
                                            </div>

                                        </div>
                                    </td>
                                    <td >
                                        <div className="product">


                                            <h3 className="product-title">
                                                {item.assunto}
                                            </h3>

                                        </div>
                                    </td>
                                    <td >
                                        <div className="product">


                                            <h3 className="product-title">
                                                {item.descricao}
                                            </h3>

                                        </div>
                                    </td>
                                    <td >
                                        <div className="product">


                                            <h3 className="product-title">
                                                {item.respostas.length}
                                            </h3>

                                        </div>
                                    </td>

                                    <td style={{ width: 100 }}>
                <ReactTooltip />

                                        <button data-tip="Responder Avaliação" style={{ width: 40, height: 40, minWidth: 40, borderRadius: 10, marginRight: 10 }} onClick={() => {
                                            this.setState({ modal_open: true, avaliacao: item });
                                        }}
                                            className="destroy_it btn btn-success"><i
                                                className="fas fa-reply"></i></button>
                                        <button data-tip="Remover Avaliação" style={{ width: 40, height: 40, minWidth: 40, borderRadius: 10 }} onClick={() => {
                                            this.setState({ show_warning: true, user_to_delete: item.id });
                                        }}
                                            className="destroy_it btn btn-danger"><i
                                                className="fas fa-trash"></i></button>
                                    </td>
                                </tr>
                            ) :
                            <tr>
                                <th colSpan="4">
                                    <p className="pl-2 pt-1 pb-1"> Não há avaliações no sistema </p>
                                </th>
                            </tr>
                        }
                    </tbody>}
                    {this.state.loading == true && <tbody><tr><td colSpan="4"><div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div></div></td></tr></tbody>}
                </table>
                <Pagination count={this.state.total} unit={this.state.per_page} onChange={(page) => {
                    page += 1;
                    this.setState({ page });
                    this.get_avaliacoes(page);
                }} />
                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                        this.delete_avaliacao(this.state.user_to_delete);
                    }}
                    onCancel={() => {
                        this.setState({ show_warning: false });
                    }}
                    show={this.state.show_warning}
                    confirmBtnText='Sim, desejo apagar'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnStyle={{ color: 'white' }}

                    showCancel={true}
                >
                    ao deletar esta avaliação ele não estará mais disponível no sistema

                </SweetAlert>
                <SweetAlert
                    danger
                    title={"Erro"}
                    onConfirm={() => {
                        this.setState({ show_erro: false });
                    }}
                    show={this.state.show_erro}
                    confirmBtnText='OK'
                    confirmBtnBsStyle="primary"
                    confirmBtnStyle={{ color: 'white' }}

                >
                    {this.state.msgErro}

                </SweetAlert>
                <Modal
                    isOpen={this.state.modal_open}
                    onRequestClose={() => this.setState({ modal_open: false })}
                    contentLabel="Example Modal"
                    style={customStyles}
                >
                     <div style={{position:'fixed',top:10,right:10, cursor:'pointer'}} onClick={()=>{
                         this.setState({modal_open:false});
                     }}>
                        <i style={{color:'white',fontSize:'2.3rem'}} className="fas fa-times"></i>
                    </div>
                    <div style={{ padding: 10, borderBottom: '1px solid grey' }}>
                        <h4 style={{ textAlign: 'center' }}>Respostas</h4>
                    </div>
                    <div style={{ paddingTop: 15, paddingBottom: 15 }}>
                        <p style={{ color: 'black' }}>Usuário: {this.state.avaliacao.user.name}</p>
                        <p style={{ color: 'black' }}>Produto: {this.state.avaliacao.produto.name}</p>
                        <div className="ratings-container" style={{ marginTop: '1rem' }}>
                            <div className="ratings">
                                <div className="ratings-val" style={{ width: parseInt((100 / 5) * this.state.avaliacao.avaliacao) + "%" }}></div>
                            </div>
                        </div>
                        <p style={{ color: 'black', whiteSpace: 'pre-line' }}><b style={{fontWeight:'500'}}>Assunto:</b> {"\n"}{this.state.avaliacao.assunto}</p>
                        <p style={{ color: 'black', whiteSpace: 'pre-line' }}><b style={{fontWeight:'500'}}>Comentário:</b> {"\n"}{this.state.avaliacao.descricao}</p>

                    </div>
                    <div>
                        <p style={{ color: 'red' }}>{this.state.msgErroResposta}</p>
                        <div className="row">
                            <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start">
                                <h5 style={{ fontSize: '1.5rem' }}>Escreva uma resposta</h5>

                            </div>

                        </div>

                        <input value={this.state.assunto} onChange={(e) => this.setState({ assunto: e.target.value })} type="text" placeholder="Assunto" className="form-control" required />

                        <textarea value={this.state.descricao} onChange={(e) => this.setState({ descricao: e.target.value })} type="text" placeholder="Descrição" className="form-control" required />
                        {this.state.loading_save == false && <button onClick={() => {
                            this.enviar_resposta();
                        }} style={{ marginTop: '1.3rem' }} type="button" className="btn btn-outline-primary btn-rounded">
                            <span>Enviar Resposta</span>
                            <i className="far fa-save"></i>
                        </button>}
                        {this.state.loading_save == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div></div>}
                    </div>
                    <table className="table table-cart table-mobile mt-2">
                        <thead>
                            <tr>
                                <th>Resposta por</th>
                                <th>Assunto</th>
                                <th>Comentário</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        {this.state.loading == false && <tbody>
                            {this.state.avaliacao.respostas.length > 0 ?
                                this.state.avaliacao.respostas.map((item, index) =>
                                    <tr key={index}>
                                        <td >
                                            <div className="product">
                                                <h3 className="product-title">
                                                    {item.user.name}
                                                </h3>
                                            </div>
                                        </td>

                                        <td >
                                            <div className="product">


                                                <h3 className="product-title">
                                                    {item.assunto}
                                                </h3>

                                            </div>
                                        </td>
                                        <td >
                                            <div className="product">


                                                <h3 className="product-title">
                                                    {item.descricao}
                                                </h3>

                                            </div>
                                        </td>

                                        <td style={{ width: 100 }}>
                                        <ReactTooltip />

                                            <button data-tip="Remover Resposta" style={{ width: 40, height: 40, minWidth: 40, borderRadius: 10 }} onClick={() => {
                                                this.setState({ show_warning: true, user_to_delete: item.id });
                                            }}
                                                className="destroy_it btn btn-danger"><i
                                                    className="fas fa-trash"></i></button>
                                        </td>
                                    </tr>
                                ) :
                                <tr>
                                    <th colSpan="4">
                                        <p className="pl-2 pt-1 pb-1"> Não há resposta desta avaliação no sistema </p>
                                    </th>
                                </tr>
                            }
                        </tbody>}
                        {this.state.loading == true && <tbody><tr><td colSpan="4"><div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div></div></td></tr></tbody>}
                    </table>
                </Modal>
            </div>
        )
    }
}

export const mapStateToProps = (state) => ({
    token: state.user.token
})

export default connect(mapStateToProps, { logout })(Avaliacoes);