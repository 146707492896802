import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { maskPriceDecimal } from '../../../../Auxiliar/Masks';
import { URL } from '../../../../variables';

class BaseProduct extends Component {

    constructor(props) {
        super(props);

        this.addToCart = this.addToCart.bind(this);
        this.showQuickView = this.showQuickView.bind(this);
        this.addToCompareList = this.addToCompareList.bind(this);
        this.toggleWishList = this.toggleWishList.bind(this);
    }


    showProductImgSection() {
        const { product } = this.props;

        return (
            <Link to={`${process.env.PUBLIC_URL}/product/default/${product.id}`}>
                <img style={{ height: "100%", objectFit: 'contain',background:'white' }} src={`${URL}${product.fotos[0].foto}`} alt="Product" className="product-image" />
                { product.fotos[1] ? <img src={`${URL}${product.fotos[1].foto}`} style={{ height: "100%", objectFit: 'contain' }} alt="Product" className="product-image-hover" /> : ''}
            </Link>
        )
    }

    showFadeImage() {
        const { product } = this.props;
        return (
            <Link to={`${process.env.PUBLIC_URL}/product/default/${product.id}`} >
                <img src={`${process.env.PUBLIC_URL}/assets/images/demos-img/lazy.png`} data-oi={process.env.PUBLIC_URL + '/' + product.pictures[0]} alt="Product" className="product-image molla-lz" />
                { product.pictures[1] ? <img src={`${process.env.PUBLIC_URL}/assets/images/demos-img/lazy.png`} data-oi={process.env.PUBLIC_URL + '/' + product.pictures[1]} alt="Product" className="product-image-hover molla-lz" /> : ''}
            </Link>
        )
    }

    showProductCatSection() {
        const { product } = this.props;

        return (
            <div className="product-cat">
                {product.categorias.map((cat, index) => (
                    <span key={index} className="mr-0">
                        <Link to={"/shop/sidebar/list?cat_id=" + cat.id}>{cat.name}</Link>
                        {index < product.categorias.length - 1 ? ', ' : ''}
                    </span>
                ))}
            </div>
        )
    }

    showProductName() {
        const { product } = this.props;

        return (
            <h3 className="product-title">
                <Link to={`${process.env.PUBLIC_URL}/product/default/${product.id}`} >{product.name}</Link>
            </h3>
        );
    }

    showProductPrice(oldLabel = "De", newLabel = "Por") {
        const { product } = this.props;

        return (
            0 == product.estoque ?
                <div className="product-price">
                    {/* <span className="out-price">R${ product.price.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.',',') }</span> */}
                    <span className="out-price" style={{ textTransform: 'uppercase' }}>Indisponível</span>

                </div> :

                product.sale_price != null ?
                    <div className="product-price" style={{display:'block'}}>
                        <span className="old-price" style={{fontSize:16}}>{oldLabel} {maskPriceDecimal(product.price)}</span>
                        <br/>
                        <span className="new-price" style={{fontWeight:'bold',fontSize:20,color:'#19386e'}}>{newLabel} {maskPriceDecimal(product.sale_price)}</span>
                    </div> :

                    <div className="product-price" style={{fontWeight:'bold',fontSize:20}}>{maskPriceDecimal(product.price)}</div>
        );
    }

    showProductRating(bool = true, classNameAdded = "ratings") {
        const { product } = this.props;

        return (
            product.avaliacao_total != null && <div className="ratings-container">
                <div className={classNameAdded}>
                    <div className="ratings-val" style={{ width: parseInt((100 / 5) * product.avaliacao_total) + "%" }}></div>
                </div>
                {bool == true && <span className="ratings-text">( {product.avaliacoes.length} Avaliações )</span>}
            </div>
        )
    }

    showProductVariants(colorType = "rgb") {
        const { product } = this.props;
        let trueType = colorType;
        if (colorType === "default")
            if (product.variants[0].image || product.variants[0].modal) trueType = "image";
            else trueType = "rgb";

        return (
            product.variants ?
                ("model" === trueType && product.variants[0].model) || ("image" === trueType && product.variants[0].image) ?
                    <div className="product-nav product-nav-thumbs">
                        {product.variants.map((vari, i) =>
                            <Link to="#" key={i} className={0 === i ? 'active' : ''}>
                                <img src={process.env.PUBLIC_URL + '/' + ("model" === trueType ? vari.model : vari.image)} alt="product desc" />
                            </Link>
                        )}
                    </div> :

                    "rgb" === trueType && product.variants[0].rgb ?
                        <div className="product-nav product-nav-dots">
                            {product.variants.map((vari, i) =>
                                <Link to="#" key={i} className={0 === i ? 'active' : ''} style={{ background: vari.rgb }}>
                                </Link>
                            )}
                        </div> : ''
                : ''
        )
    }

    addToCart(e) {
        const { product, onAddToCartClick } = this.props;
        if (0 !== product.estoque)
            onAddToCartClick(product, 1);
        e.preventDefault();
    }

    addToCompareList(e) {
        const { product, onAddToCompareClick } = this.props;
        onAddToCompareClick(product);
        e.preventDefault();
    }

    toggleWishList(e,wish) {
        console.log('wishlist',wish)
        const { product, wishlist, onToggleWishlistClick } = this.props;

        if (wish) {
            window.location = '/shop/wishlist';
        } else {
            onToggleWishlistClick(product, this.props.wishlist);
        }
        e.preventDefault();
    }

    showQuickView(e) {
        console.log(this.props.product);

        const { product, showQuickViewModal } = this.props;
        console.log("active element ", document.activeElement);
        showQuickViewModal(product);
        e.preventDefault();
    }

    showAddToCartBtn(classNameAdded = "btn-product btn-cart", btnText = "Adicionar", salvar) {
        return (
            <button className={classNameAdded} onClick={(e) => { e.preventDefault(); salvar(this.props.product.id, 1) }}>
                <span>{btnText}</span>
            </button>
        )
    }

    showAddToCompareBtn(classNameAdded = "btn-product btn-compare", btnText = "compare") {
        return (
            <button className={classNameAdded} title="Compare" onClick={this.addToCompareList}>
                <span>{btnText}</span>
            </button>
        )
    }

    showToggleWishlistBtn(classNameAdded = "btn-product-icon btn-wishlist", salvar, wish) {
        return (
            <button className={`${classNameAdded} ${wish ? 'added-to-wishlist add_wishlist' : 'remove-from-wishlist remove_wishlist'}`}
                onClick={(e) => {   
                    if(wish==true){
                        this.toggleWishList(e,wish);

                    }
                    else{
                        e.preventDefault();
                        salvar(this.props.product, wish) 
                    }
                }}
                title={wish ? "Ir para lista de desejos" : "Adicionar na lista de desejos"}
            >
                <span>{wish ? "Ir para lista de desejos" : "Adicionar na lista de desejos"}</span>
            </button>
        )
    }

    showQuickViewBtn(classNameAdded = "btn-product-icon btn-quickview") {
        return (
            <button className={classNameAdded} title="Quick view" onClick={this.showQuickView}><span>Quick view</span></button>
        )
    }

    showToggleCompareBtn(classname = "btn-product-icon btn-compare") {
        return (
            <button className="btn-product-icon btn-compare" title="Compare" onClick={this.addToCompareList}><span>Compare</span></button>
        )
    }

    showQuickViewBtnWithIcon(classNameAdded = "btn-product btn-quickview", btnText = "Visualizar") {
        return (
            <button className={classNameAdded} style={{ maxWidth: '100%' }} title="Quick view" onClick={this.showQuickView}>
                <span>{btnText}</span>
            </button>
        )
    }
}

export default BaseProduct;