import React from 'react';
import { connect } from 'react-redux';

import BaseProduct from '../../features/product/common/base-product';

import { findIndex } from '../../../utils/utils';
import { URL } from '../../../variables';

class Product extends BaseProduct {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loading_wish: false
        }
    }

    salvar_wishlist(product,wish) {
        if(this.props.token==undefined){
            this.toggleWishList({ preventDefault: () => '' },wish)
            return;
        }
        this.setState({ loading_wish: true, msgErro: '' });
        let form = new FormData();

        fetch(`${URL}api/lista_desejos/salvar_item_wish/${product.id}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_wish: false, msgErro: erro });
                }
                else {
                    this.toggleWishList({ preventDefault: () => '' },wish)

                    this.setState({ loading_wish: false });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_wish: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_wish: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }

    salvar(id, quantidade) {
        if(this.props.token==undefined){
            this.addToCart({ preventDefault: () => '' });
            return;
        }
        this.setState({ loading: true, msgErro: '' });
        let form = new FormData();
        console.log(id, quantidade);
        fetch(`${URL}api/carrinho/salvar_item_carrinho/${id}/${quantidade}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading: false, msgErro: erro });
                }
                else {
                    this.addToCart({ preventDefault: () => '' });
                    this.setState({ loading: false });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    render() {
        const { product } = this.props;
        let wish=false;
        for(let i=0;i<this.props.wishlist.length;i++){
            if(this.props.wishlist[i].id==product.id){
                wish=true;
            }
        }
        return (
            product ?
                <div className="product product-11 text-center">
                    <figure className="product-media">
                        {0 != product.estoque && product.novo ? <span className="product-label label-circle label-new">Novo</span> : ''}
                        {product.sale_price != null ? <span className="product-label label-circle label-sale">Oferta</span> : ''}
                        {(0 == product.estoque) ? <span className="product-label label-circle label-out">Sem estoque</span> : ''}
                        {this.showProductImgSection()}

                        <div className="product-action-vertical">
                            {this.state.loading_wish == false && this.showToggleWishlistBtn("btn-product-icon btn-wishlist",(id, wish) => this.salvar_wishlist(id, wish),wish)}
                            {this.state.loading_wish == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div></div>}
                        </div>
                    </figure>

                    <div className="product-body">

                        {this.showProductCatSection()}
                        {this.showProductName()}
                        {this.showProductPrice()}
                        {this.showProductRating()}
                        {/* { this.showProductRating() }
                    { this.showProductVariants("rgb") } */}

                    </div>

                    <div className="product-action" style={{ backgroundColor: 'white' }}>
                        {this.state.loading == false && product.estoque != 0 && this.showAddToCartBtn("btn-product btn-cart", 'Adicionar', (id, quantidade) => this.salvar(id, quantidade))}
                        {this.state.loading == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div></div>}
                    </div>
                </div> : ''
        );
    }
}

export const mapStateToProps = (state, ownprops) => {
    
    return {
        wishlist: state.wishlist.list,
        token: state.user.token
    };
}

export default connect(mapStateToProps)(Product);