import React, { Component } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { Link } from 'react-router-dom';

// import Custom Components
import { connect } from 'react-redux';
import { URL } from '../../../../variables';
import Pagination from '../../../features/pagination';
import SweetAlert from 'react-bootstrap-sweetalert';
import { logout } from '../../../../actions';
import ReactTooltip from 'react-tooltip';

class Marcas extends Component {

    constructor(props) {
        super(props);
        this.state = {
            marcas: [],
            current_page: 1,
            last_page: 1,
            total: 1,
            per_page: 1,
            page: 1,
            loading: false,
            show_warning: false,
            show_erro: false,
            msgErro: '',
            user_to_delete: ''
        };
    }

    delete_marca(id) {

        fetch(`${URL}api/admin/delete_marca/${id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ msgErro: erro, show_warning: false, show_erro: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({ show_warning: false });
                    this.get_marcas(this.state.page);
                    // this.setState({ users: resp.users, loading_screen: false, page: resp.pagination.current_page, total_pages: resp.pagination.last_page });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    get_marcas(page = 1) {
        this.setState({ loading: true });

        fetch(`${URL}api/admin/get_marcas?page=${page}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                }
                else {
                    if (page == this.state.page) {
                        console.log('updated');
                        this.setState({
                            marcas: resp.marcas,
                            loading: false,
                            current_page: resp.current_page,
                            last_page: resp.last_page,
                            total: resp.total,
                            per_page: resp.per_page
                        });
                    }
                }




            } catch (err) {
                console.log(err);

            }

        })
            .catch((err) => {
                console.log(err);
            });
    }

    componentDidMount() {
        this.get_marcas(this.state.page);
    }



    render() {
        let marcas = this.state.marcas;

        return (
            <div>

                <div className="row mb-2">
                    <div className="col-12  d-flex justify-content-end">
                        <Link to={"/conta/marcas/adicionar"}><span

                            className="btn btn-outline-primary btn-rounded" style={{ height: '45px' }}><span>Adicionar marca</span><i className="icon-long-arrow-right"></i></span></Link>
                    </div>
                </div>
                <table className="table table-cart table-mobile">
                    <thead>
                        <tr>
                            <th>Marca</th>
                            <th>Número de produtos</th>

                            <th>Ações</th>
                        </tr>
                    </thead>
                    {this.state.loading == false && <tbody>
                        {marcas.length > 0 ?
                            marcas.map((item, index) =>
                                <tr key={index}>

                                    <td >
                                        <div className="product">


                                            <h3 className="product-title">
                                                {item.name}
                                            </h3>
                                        </div>
                                    </td>
                                    <td >
                                        <div className="product">


                                            <h3 className="product-title">
                                                {item.produtos}
                                            </h3>
                                        </div>
                                    </td>


                                    <td style={{ width: 100 }}>
                <ReactTooltip />

                                        <Link data-tip="Editar Marca" to={'/conta/marcas/editar/' + item.id}><span style={{ width: 40, height: 40, minWidth: 40, borderRadius: 10, marginRight: 10 }} className="btn btn-info"
                                            data-toggle="tooltip"
                                        ><i className="fas fa-edit"></i></span></Link>
                                        <button data-tip="Remover Marca" style={{ width: 40, height: 40, minWidth: 40, borderRadius: 10 }} onClick={() => {
                                            this.setState({ show_warning: true, user_to_delete: item.id });
                                        }}
                                            className="destroy_it btn btn-danger"><i
                                                className="fas fa-trash"></i></button>
                                    </td>
                                </tr>
                            ) :
                            <tr>
                                <th colSpan="4">
                                    <p className="pl-2 pt-1 pb-1"> Não há marcas cadastradas no sistema </p>
                                </th>
                            </tr>
                        }
                    </tbody>}
                    {this.state.loading == true && <tbody><tr><td colSpan="4"><div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div></div></td></tr></tbody>}
                </table>
                <Pagination count={this.state.total} unit={this.state.per_page} onChange={(page) => {
                    page += 1;
                    this.setState({ page });
                    this.get_marcas(page);
                }} />
                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                        this.delete_marca(this.state.user_to_delete);
                    }}
                    onCancel={() => {
                        this.setState({ show_warning: false });
                    }}
                    show={this.state.show_warning}
                    confirmBtnText='Sim, desejo apagar'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    confirmBtnStyle={{color:'white'}}

                    cancelBtnBsStyle="danger"
                    showCancel={true}
                >
                    ao deletar esta marca ele não estará mais disponível no sistema

                </SweetAlert>
                <SweetAlert
                    danger
                    title={"Erro"}
                    onConfirm={() => {
                        this.setState({ show_erro: false });
                    }}
                    show={this.state.show_erro}
                    confirmBtnText='OK'
                    confirmBtnBsStyle="primary"
                    confirmBtnStyle={{color:'white'}}

                >
                    {this.state.msgErro}

                </SweetAlert>
            </div>
        )
    }
}

export const mapStateToProps = (state) => ({
    token: state.user.token
})

export default connect(mapStateToProps, { logout })(Marcas);