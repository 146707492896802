import React, { Component } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { Link, Redirect } from 'react-router-dom';

// import Custom Components
import { connect } from 'react-redux';
import Select from 'react-select'
import { URL } from '../../../../variables';
import { maskPrice, maskCartaoCredito, maskValidadeCartao, maskNumber, maskCpfCnpj, maskPriceDecimal } from '../../../../Auxiliar/Masks';
import { logout } from '../../../../actions';
import creditCardType from 'credit-card-type';
import moment from 'moment';
import amex from '../../../../imgs/cartoes/amex.png';
import elo from '../../../../imgs/cartoes/elo.png';
import hypercard from '../../../../imgs/cartoes/hypercard.png';
import mastercard from '../../../../imgs/cartoes/mastercard.png';
import visa from '../../../../imgs/cartoes/visa.png';
import jcb from '../../../../imgs/cartoes/jcb.png';
import aura from '../../../../imgs/cartoes/aura.png';
import diners_club from '../../../../imgs/cartoes/diners_club.png';
import discover from '../../../../imgs/cartoes/discover.png';

class Card {
    constructor(){
        var self = this; this.mask = { DEFAULT_CC: '0000 0000 0000 0000', DEFAULT_CVC: '000', }
        this.type = { 
            VISA: { name: 'visa', detector: /^4/, cardLength: 16, cvcLength: 3, maskCC: self.mask.DEFAULT_CC, maskCVC: self.mask.DEFAULT_CVC, order: 99 }, 
            MASTERCARD: { name: 'mastercard', detector: /^(5[1-5]|2(2(2[1-9]|[3-9])|[3-6]|7([0-1]|20)))/, cardLength: 16, cvcLength: 3, maskCC: self.mask.DEFAULT_CC, maskCVC: self.mask.DEFAULT_CVC, order: 99 }, 
            AMEX: { name: 'amex', detector: /^3[47]/, cardLength: 15, cvcLength: 4, maskCC: '0000 000000 00000', maskCVC: '0000', order: 99 }, 
            DISCOVER: { name: 'discover', detector: /^6(?:011\d{12}|5\d{14}|4[4-9]\d{13}|22(?:1(?:2[6-9]|[3-9]\d)|[2-8]\d{2}|9(?:[01]\d|2[0-5]))\d{10})/, cardLength: 16, cvcLength: 3, maskCC: self.mask.DEFAULT_CC, maskCVC: self.mask.DEFAULT_CVC, order: 2 }, 
            HIPERCARD: { name: 'hipercard', detector: /^(?:606282|384100|384140|384160)/, cardLength: 16, cvcLength: 3, maskCC: self.mask.DEFAULT_CC, maskCVC: self.mask.DEFAULT_CVC, order: 4 }, DINERS: { name: 'diners', detector: /^(300|301|302|303|304|305|36|38)/, cardLength: 14, cvcLength: 3, maskCC: '0000 000000 0000', maskCVC: self.mask.DEFAULT_CVC, order: 5 }, 
            JCB_15: { name: 'jcb_15', detector: /^(?:2131|1800)/, cardLength: 15, cvcLength: 3, maskCC: self.mask.DEFAULT_CC, maskCVC: self.mask.DEFAULT_CVC, order: 6 }, 
            JCB_16: { name: 'jcb_16', detector: /^(35)/, cardLength: 16, cvcLength: 3, maskCC: self.mask.DEFAULT_CC, maskCVC: self.mask.DEFAULT_CVC, order: 7 }, 
            ELO: { name: 'elo', detector: /^(4011(78|79)|43(1274|8935)|45(1416|7393|763(1|2))|50(4175|6699|67([0-6][0-9]|7[0-8])|9\d{3})|627780|63(6297|6368)|650(03([^4])|04([0-9])|05(0|1)|4(0[5-9]|(1|2|3)[0-9]|8[5-9]|9[0-9])|5((3|9)[0-8]|4[1-9]|([0-2]|[5-8])\d)|7(0\d|1[0-8]|2[0-7])|9(0[1-9]|[1-6][0-9]|7[0-8]))|6516(5[2-9]|[6-7]\d)|6550(2[1-9]|5[0-8]|(0|1|3|4)\d))\d*/, cardLength: 16, cvcLength: 3, maskCC: self.mask.DEFAULT_CC, maskCVC: self.mask.DEFAULT_CVC, order: 1 }, 
            AURA: { name: 'aura', detector: /^((?!5066|5067|50900|504175|506699)50)/, cardLength: 19, cvcLength: 3, maskCC: '0000000000000000000', maskCVC: self.mask.DEFAULT_CVC, order: 3 } };
    }
}

function detect_card(text=''){
    text=text.replace(/ /g,'');
    let card = new Card();
    let types = Object.values(card.type);
    let brand='';
    let index=null;
    let masked_number='';
    let card_length=0;
    for(let i=0;i<types.length;i++){
        if(text.match(types[i].detector)){
            brand=types[i].name;
            card_length=types[i].cardLength;
            index=i;
            break;
        }
    }
    if(index!=null){
        let index_mask=0;
        
        for(let i=0;i<text.length && i<types[index].cardLength;i++){
            if(!isNaN(text[i])){
                if(types[index].maskCC[index_mask]==' '){
                    masked_number+=' ';
                    index_mask++;
                }
                if(types[index].maskCC[index_mask]=='0'){
                    masked_number+=text[i];
                }
                index_mask++;
            }
            
        }
    }
    else{
        masked_number=text;
    }
    return {brand,masked_number,index,card_length};
}

class AdicionarCartao extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            loading_save: false,
            msgErro: '',
            redirect: false,
            numero_cartao: '',
            cvv: '',
            validade: '',
            cpf_cnpj: '',
            nome: '',
            types: {brand:'',masked_number:'',card_length:''},
        };
    }

    salvar() {
        let data = {
            numero_cartao: this.state.numero_cartao.replace(/ /g,''),
            cvv: this.state.cvv,
            cpf_cnpj: this.state.cpf_cnpj,
            validade: this.state.validade,
            nome: this.state.nome,
        };
        let brand='';
        if((!moment(this.state.validade, "MM/YYYY", true).isValid() || moment(this.state.validade, "MM/YYYY", true).diff(moment()) <= 0)){
            this.setState({ msgErro: 'Cartão fora do prazo de validade', loading_save: false });
                return;
        }
        if (this.state.types.brand!='') {
            brand = this.state.types.brand.toUpperCase();
            if(data.numero_cartao.length<this.state.types.card_length){
                this.setState({ msgErro: 'Número de cartão inválido', loading_save: false });
                return;
            }
            if (brand.includes('VISA')) {
                data.brand='Visa';
            }
            else if (brand.includes('MASTER')) {
                data.brand='Master';
            }
            else if (brand.includes('AME')) {
                data.brand='Amex';
            }
            else if (brand.includes('ELO')) {
                data.brand='Elo';
            }
            else if (brand.includes('HYPER')) {
                data.brand='Hipercard';
            }
            else if (brand.includes('DINERS')) {
                data.brand='Diners';
            }
            else if (brand.includes('DISCOVER')) {
                data.brand='Discover';
            }
            else if (brand.includes('AURA')) {
                data.brand='Aura';
            }
            else if (brand.includes('JCB')) {
                data.brand='JCB';
            }
        }
        else{
            this.setState({ msgErro: 'Número de cartão inválido', loading_save: false });
            return;
        }
        this.setState({ loading_save: true });

        



        fetch(`${URL}api/store_cartao`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.props.token

            },
            body: JSON.stringify(data)
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_save: false, msgErro: erro });
                    window.scrollTo({ top: 0, behavior: 'smooth' })

                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }

    componentDidMount() {
        console.log(this.props.token);
    }



    render() {
        let logo = '';
        let brand = '';
        if (this.state.types.brand !='') {
            brand = this.state.types.brand.toUpperCase();
            console.log(brand);
            if (brand.includes('VISA')) {
                logo = visa;
            }
            else if (brand.includes('MASTER')) {
                logo = mastercard;
            }
            else if (brand.includes('HYPER')) {
                logo = hypercard;
            }
            else if (brand.includes('AME')) {
                logo = amex;
            }
            else if (brand.includes('ELO')) {
                logo = elo;
            }

            else if (brand.includes('DINERS')) {
                logo = diners_club;
            }
            else if (brand.includes('DISCOVER')) {
                logo = discover;
            }
            else if (brand.includes('AURA')) {
                logo = aura;
            }
            else if (brand.includes('JCB')) {
                logo = jcb;
            }

        }
        return (
            <div>
                {this.state.redirect == true && <Redirect to={'/conta/cartoes_credito'} />}

                <div style={{
                    color: '#721c24',
                    backgroundColor: '#f8d7da',
                    borderColor: '#f5c6cb'
                }} hidden={this.state.msgErro == ''} className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                    <button style={{ color: '#721c24', top: '55%', fontSize: '2rem' }} onClick={() => this.setState({ msgErro: '' })} type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>

                    <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                </div>
                <br />
                <label>Número do Cartão *</label>
               
                <div style={{ position: 'relative' }}>

                    <input value={this.state.numero_cartao} onChange={(e) => {
                        let text = e.target.value;
                        // let types = creditCardType(text);
                        let result=detect_card(text);
                        console.log(result)
                        // let credit_card = maskCartaoCredito(text, types);
                        this.setState({ numero_cartao: result.masked_number, types: result });
                    }} style={{
                        paddingRight: 40,
                    }} type="text" placeholder="Número do Cartão" className="form-control" required />
                    {logo != '' && <div style={{ position: 'absolute', top: 0, right: 10 }}>
                        <img src={logo} style={{ width: 30, height: 40, objectFit: "contain" }} />
                    </div>}
                    <div className="d-flex">
                        <img src={visa} style={{ width: 30, height: 40, objectFit: "contain", marginRight: 10, filter: brand.includes('VISA') ? '' : 'grayscale(1)' }} />
                        <img src={mastercard} style={{ width: 30, height: 40, objectFit: "contain", marginRight: 10, filter: brand.includes('MASTER') ? '' : 'grayscale(1)' }} />
                        <img src={amex} style={{ width: 30, height: 40, objectFit: "contain", marginRight: 10, filter: brand.includes('AME') ? '' : 'grayscale(1)' }} />
                        <img src={elo} style={{ width: 30, height: 40, objectFit: "contain", marginRight: 10, filter: brand.includes('ELO') ? '' : 'grayscale(1)' }} />
                        <img src={hypercard} style={{ width: 30, height: 40, objectFit: "contain", marginRight: 10, filter: brand.includes('HYPER') ? '' : 'grayscale(1)' }} />

                        <img src={diners_club} style={{ width: 30, height: 40, objectFit: "contain", marginRight: 10, filter: brand.includes('DINERS') ? '' : 'grayscale(1)' }} />
                                                    <img src={discover} style={{ width: 30, height: 40, objectFit: "contain", marginRight: 10, filter: brand.includes('DISCOVER') ? '' : 'grayscale(1)' }} />
                                                    <img src={jcb} style={{ width: 30, height: 40, objectFit: "contain", marginRight: 10, filter: brand.includes('JCB') ? '' : 'grayscale(1)' }} />
                                                    <img src={aura} style={{ width: 30, height: 40, objectFit: "contain", marginRight: 10, filter: brand.includes('AURA') ? '' : 'grayscale(1)' }} />
                                               
                    </div>
                </div>
                <div className="row mt-1">
                <div className="col-12 col-md-6">

<label>Validade (MM/YYYY) *</label>
<input style={{ marginBottom: '0.5rem' }} value={this.state.validade} onChange={(e) => this.setState({ validade: maskValidadeCartao(e.target.value) })} type="text" placeholder="Validade MM/YYYY" className="form-control" required />
{this.state.validade != '' && (!moment(this.state.validade, "MM/YYYY", true).isValid() || moment(this.state.validade, "MM/YYYY", true).diff(moment()) <= 0) && <small className="form-text" style={{ color: 'red' }}>Validade Inválida</small>}

</div>
                    <div className="col-12 col-md-6">

                        <label>CVV *</label>
                        <input value={this.state.cvv} onChange={(e) => this.setState({ cvv: maskNumber(e.target.value) })} type="text" placeholder="CVV" className="form-control" required />
                    </div>
                </div>


                <label>Nome do Titular *</label>
                <input value={this.state.nome} onChange={(e) => this.setState({ nome: e.target.value })} type="text" placeholder="Nome do Titular do Cartão" required className="form-control" />

                <label>CPF/CNPJ *</label>
                <input value={this.state.cpf_cnpj} onChange={(e) => this.setState({ cpf_cnpj: maskCpfCnpj(e.target.value) })} type="text" placeholder="CPF/CNPJ do Titular do Cartão" className="form-control" required />



                {this.state.loading_save == false && <button onClick={() => {
                    this.salvar();
                }} style={{ marginTop: '1.3rem' }} type="button" className="btn btn-outline-primary btn-rounded">
                    <span>Salvar</span>
                    <i className="far fa-save"></i>
                </button>}
                {this.state.loading_save == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div></div>}

            </div>
        )
    }
}

export const mapStateToProps = (state) => ({
    token: state.user.token

})

export default connect(mapStateToProps, { logout })(AdicionarCartao);