import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class FooterFive extends Component {
    render() {
        const { logo = "assets/images/demos/demo-3/logo-footer.png" } = this.props;
        return (
            <footer className="footer">
                <div className="footer-middle">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-lg-12 d-flex justify-content-center">
                                <div className="widget widget-about">
                                    <img src={process.env.PUBLIC_URL + '/' + logo} className="footer-logo" alt="Footer Logo" width="195" height="25" style={{marginLeft:'4rem'}} />

                                    <div className="widget-call">
                                        <i className="icon-phone"></i>
                                        <span>Quer falar conosco?</span>
                                        <Link style={{marginTop:'1rem'}} to="tel:#">(17) 3422-2131</Link>
                                    </div>
                                    
                                </div>
                            </div>

                            
                        </div>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="container d-flex justify-content-center">
                        <p className="footer-copyright" style={{textAlign:'center'}}>Copyright © {(new Date()).getFullYear()} Hefarma. Todos os direitos reservados.</p>

                    </div>
                </div>
            </footer>
        )
    }
}

export default FooterFive;