import React, { Component } from 'react';

// import jsons
import _data from '../../../mock_data/data';

class ServiceBoxTwo extends Component {
    render() {
        const { adClass="bg-transparent", iconAdClass = "text-dark" } = this.props;
        console.log(_data.services);
        return(
            <div className={ `icon-boxes-container ${adClass}` }>
                <div className="container">
                    <div className="row">
                        {/* { _data.services.slice(0,4).map((item, index)=>
                            <div className="col-sm-6 col-lg-3" key={ index }>
                                <div className="icon-box icon-box-side">
                                    <span className={ `icon-box-icon ${iconAdClass}` }>
                                        <i className={ item.icon }></i>
                                    </span>
                                    <div className="icon-box-content">
                                        <h3 className="icon-box-title">{ item.title}</h3>
                                        <p>{ item.subtitle}</p>
                                    </div>
                                </div>
                            </div>
                        ) } */}
                        <div className="col-sm-6 col-lg-4" >
                                <div className="icon-box icon-box-side">
                                    <span className={ `icon-box-icon ${iconAdClass}` }>
                                        <i className="icon-rocket"></i>
                                    </span>
                                    <div className="icon-box-content">
                                        <h3 className="icon-box-title">Entrega Hefarma</h3>
                                        <p>Para todo o Brasil</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4" >
                                <div className="icon-box icon-box-side">
                                    <span className={ `icon-box-icon ${iconAdClass}` }>
                                        <i className="icon-rotate-left"></i>
                                    </span>
                                    <div className="icon-box-content">
                                        <h3 className="icon-box-title">Retirar na loja</h3>
                                        <p>Para melhor conveniência</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4" >
                                <div className="icon-box icon-box-side">
                                    <span className={ `icon-box-icon ${iconAdClass}` }>
                                        <i className="icon-info-circle"></i>
                                    </span>
                                    <div className="icon-box-content">
                                        <h3 className="icon-box-title">Central de atendimento</h3>
                                        <p>Para o que precisar</p>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ServiceBoxTwo;