import React, { Component } from 'react';
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
import { connect } from 'react-redux';

import OwlCarousel from '../../components/features/owl-carousel';
import Product from './product';
import { productSlider } from './settings';

import { getProductsByCategory, getProductsByDemo, getNewProducts } from '../../services';
import { addToCart, toggleWishlist, addToCompare, showQuickViewModal } from '../../actions';
import _data from '../../mock_data/data.json';

class NewCollection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            index: 0
        };
    }

    render() {
        const { addToCart, toggleWishlist, addToCompare, showQuickViewModal } = this.props;

        let products = getNewProducts(getProductsByDemo(this.props.products, "demo4"));
        let { produtos_categorias } = this.props;
        return (
            <div className="container new-arrivals">
                <Tabs selectedTabClassName="show" className="just-action-icons-sm" onSelect={(e) => {
                    console.log(e);
                    this.setState({ index: e });
                }} selectedIndex={this.state.index}>
                    <div className="heading heading-flex mb-3">
                        <div className="heading-left">
                            <h2 className="title">Novos Produtos</h2>
                        </div>
                        <div className="heading-right">

                            <TabList className="nav nav-pills nav-border-anim justify-content-center">

                                    {

                                        Object.values(produtos_categorias).reverse().map((item, index) =>
                                            <Tab className="nav-item" key={item.id}>
                                                <span className="nav-link">{item.name}</span>
                                            </Tab>
                                        )

                                    }

                            </TabList>

                        </div>
                    </div>

                    {

                        Object.values(produtos_categorias).reverse().map((item, index) =>
                            <TabPanel key={`${item.id}-tab-product`}>
                                <OwlCarousel adClass="owl-full carousel-equal-height carousel-with-shadow" carouselOptions={productSlider} >
                                    {item.produtos.map((item, index) =>
                                        <Product product={item}
                                            key={item.id}
                                            onAddToCartClick={addToCart}
                                            onToggleWishlistClick={toggleWishlist}
                                            onAddToCompareClick={addToCompare}
                                            showQuickViewModal={showQuickViewModal} />
                                    )}

                                </OwlCarousel>
                                {item.produtos.length == 0 &&
                                    <div style={{ height: 300, width: '100%', display: 'flex', justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                                        Nenhum novo produto nesta categoria
                                        </div>
                                }
                            </TabPanel>
                        )
                    }
                </Tabs>
            </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
        products: state.data.products ? state.data.products : []
    }
}

export default connect(
    mapStateToProps, { addToCart, toggleWishlist, addToCompare, showQuickViewModal }
)(NewCollection);
