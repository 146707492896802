import React, { Component } from 'react';

class PageHeader extends Component {
    render() {
        let {title, subTitle, background } =  this.props;
        if(background==null){
            background="url(assets/images/page-header-bg.jpg)";
        }
        return (
            <div className="page-header text-center" style={ {backgroundImage: background }} >
        		<div className="container">
        			<h1 className="page-title">{title}<span>{subTitle}</span></h1>
        		</div>
        	</div>
        );
    }
}

export default PageHeader;