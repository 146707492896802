import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';

// import Custom Components
import HeaderSeven from '../../components/common/header/header-7';
import OuterOverlay from '../../components/common/overlay/outer-overlay';
import FooterFive from '../../components/common/footer/footer-five';
import OwlCarousel from '../../components/features/owl-carousel';
import QuickView from '../../components/features/product/quickview';
import ServiceBox from '../../components/features/service-list/service-two';
import NewsletterModal from '../../components/features/newsletter-modal';
import MobileMenuTwo from '../../components/common/header/common/mobile-menus/menu-2';

import DealProduct from './deal-product';
import IntroSlide from './intro-slide';
import Banner from './banner';
import BannerGroup from './banner-group';
import NewCollection from './new-collection';
import TrendingCollection from './trendy-collection';
import ProductCollection from './product-collection';
import { introSlider, brandSlider } from './settings'

// import Utils
import { initSettings, isIEBrowser } from '../../utils/utils';
import _data from '../../mock_data/data.json';
import store from '../../store'
import { changeDemo, outerLoading, innerLoading } from '../../actions';
import { productSlider } from './settings';

import style from './style.scss';

import { URL } from '../../variables';
import { changeHome,logout } from '../../actions';

import ChatCliente from '../../components/Chat/ChatCliente';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';

class IndexFour extends Component {

    constructor(props) {
        super(props);
        this.state = {
            produtos_novos: [],
            produtos_categorias: [],
            produtos_recomendados: [],
            produtos_populares: { avaliados: [], oferta: [], vendidos: [] },
            produtos_tendencia: [],
            banners_topo: [],
            banners_promocao: [],
            banner_faixa_meio: [],
            banners_promocao_limitada: [],
            categorias: [],
            nossas_marcas: [],
            banner_height:window.innerWidth/4,
            email:'',
            loading_save:false,
            msgErro:'',
            show_success:false
        }
    }

    get_dados_home() {
        store.dispatch(innerLoading());

        fetch(`${URL}api/get_dados_home?cat_id=all`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                // 'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');

                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                }
                else {
                    this.props.changeHome({
                        produtos_novos: resp.produtos_novos,
                        produtos_categorias: resp.produtos_categorias,
                        produtos_recomendados: resp.produtos_recomendados,
                        produtos_populares: resp.produtos_populares,
                        categorias: resp.categorias,
                        banners_topo: resp.banners_topo,
                        banners_promocao: resp.banners_promocao,
                        banner_faixa_meio: resp.banner_faixa_meio,
                        banners_promocao_limitada: resp.banners_promocao_limitada,
                        nossas_marcas: resp.nossas_marcas
                    });

                }




            } catch (err) {
                console.log(err);

            }

        })
            .catch((err) => {
                console.log(err);
            });
    }
    componentDidMount() {
        initSettings();
        this.resize_event=(function(){
            this.setState({banner_height:window.innerWidth/4})
        }).bind(this);
        window.addEventListener('resize',this.resize_event)
        store.dispatch(changeDemo("4"));
    }

    UNSAFE_componentWillMount() {
        style.use();
        store.dispatch(outerLoading());
        this.get_dados_home();

    }

    componentWillUnmount() {
        window.removeEventListener('resize',this.resize_event);
        style.unuse();
    }


    salvar() {
        this.setState({ loading_save: true, msgErro: '' });
        let form = new FormData();
        form.append('email', this.state.email);

        fetch(`${URL}api/store_email`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_save: false, msgErro: erro });

                }
                else {

                    this.setState({ loading_save: false,email:'',show_success:true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao salvar o e-mail' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao salvar o e-mail. Você está conectado a internet?' });
            });
    }

    render() {
        console.log('Banner height: '+this.state.banner_height)
        return (
            <React.Fragment>
                <Helmet>
                    <title>Hefarma</title>
                </Helmet>

                <h1 className="d-none">Hefarma</h1>

                <OuterOverlay />

                <div className="page-wrapper">
                    <HeaderSeven />

                    <div className="main">
                        {this.props.banners_topo.length > 0 && <div className="intro-slider-container mb-5" style={{height:'auto'}}>
                            <OwlCarousel adClass="intro-slider owl-theme owl-nav-inside owl-light" carouselOptions={introSlider} >
                                {
                                    this.props.banners_topo.map((item, index) =>
                                        <IntroSlide banner_height={this.state.banner_height} data={item} key={index} />
                                    )
                                }
                            </OwlCarousel>

                            <span className="slider-loader"></span>
                        </div>}

                        <div className="container">
                            <h2 className="title text-center mb-4">Categoria Populares</h2>

                            <div className="cat-blocks-container">
                                <OwlCarousel adClass="owl-full carousel-equal-height carousel-with-shadow d-flex justify-content-center" carouselOptions={productSlider} >
                                    {this.props.categorias.map((item, index) =>
                                        <Banner data={item} key={index} />
                                    )}
                                </OwlCarousel>

                            </div>
                        </div>
                        <div className="mb-4"></div>

                        <BannerGroup data={this.props.banners_promocao} />

                        <div className="mb-3"></div>

                        <NewCollection produtos_categorias={this.props.produtos_categorias} />

                        <div className="mb-6"></div>

                        <div className="container">
                            {this.props.banner_faixa_meio.map((item, id) => (

                                <div key={id} className="cta cta-border mb-5" style={{ backgroundImage: `url("${URL + item.image}")`, height: 150, marginLeft: 0 }}>
                                    {/* <img style={{width:258,height:107}} src={URL+item.image} alt="camera" className="cta-img" /> */}
                                    <div className="row justify-content-center">
                                        <div className="col-md-12">
                                            <div className="cta-content" style={{ display: !isIEBrowser() ? 'flex' : 'auto' }}>
                                                <div className="cta-text text-right text-white">
                                                    <p>{item.subtitle} <br /><strong>{item.title}</strong></p>
                                                </div>
                                                <Link to={(item.link == null || item.link.length == 0) ? `${process.env.PUBLIC_URL}/shop/sidebar/list` : item.link} className="btn btn-primary btn-round"><span>Mostrar mais</span><i className="icon-long-arrow-right"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>

                        {this.props.banners_promocao_limitada.length > 0 && <div className="container">
                            <div className="heading text-center mb-3">
                                <h2 className="title">Promoções Hefarma</h2>
                                <p className="title-desc">Promoções imperdíveis</p>
                            </div>

                            <div className="row">
                                {this.props.banners_promocao_limitada.map((item, index) =>
                                    <div className="col-lg-6 deal-col" key={index}>
                                        <DealProduct data={item} />
                                    </div>
                                )}
                            </div>

                            <div className="more-container text-center mt-1 mb-5">
                                <Link to={`${process.env.PUBLIC_URL}/shop/sidebar/list?des_id=oferta`} className="btn btn-outline-dark-2 btn-round btn-more">
                                    <span>Ver mais</span><i className="icon-long-arrow-right"></i>
                                </Link>
                            </div>
                        </div>}

                        <div hidden={this.props.nossas_marcas.length == 0} className="container">
                            <hr className="mb-0" />
                            <div className="heading text-center mt-3 mb-3">
                                <h2 className="title">Nossas Marcas</h2>
                            </div>
                            <OwlCarousel adClass="mt-5 mb-5 owl-simple brand-carousel" carouselOptions={brandSlider}>
                                {this.props.nossas_marcas.map((vari, i) => {
                                    return (
                                        <div className="brand" key={i} >
                                            <img style={{ width: 100, height: 55, objectFit: 'contain' }} src={URL + vari.logo} alt={vari.name} />
                                        </div>
                                    )
                                })
                                }
                            </OwlCarousel>
                        </div>

                        <TrendingCollection produtos_b={this.props.produtos_populares.avaliados}
                            produtos_v={this.props.produtos_populares.vendidos}
                            produtos_p={this.props.produtos_populares.oferta}

                        />

                        <div className="mb-5"></div>

                        <ProductCollection produtos={this.props.produtos_recomendados} />

                        <div className="mb-4"></div>

                        <div className="container">
                            <hr className="mb-0" />
                        </div>

                        <ServiceBox />

                        <QuickView />
                        <ToastContainer autoClose={3000} className="toast-container" />
                        <div className="cta bg-image bg-dark pt-4 pb-5 mb-0" style={{ backgroundImage: 'linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(assets/images/farmacia.jpeg)' }}>
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-sm-10 col-md-8 col-lg-6">
                                        <div className="cta-heading text-center">
                                            <h3 className="cta-title text-white">Deixe seu e-mail</h3>
                                            <p className="cta-desc text-white">para receber e-mails com promoções e cupons de desconto</p>
                                        </div>

                                        <form action="#">
                                            <div className="input-group input-group-round">
                                                <input value={this.state.email} onChange={(e)=>this.setState({email:e.target.value})} type="email" className="form-control form-control-white" placeholder="Escreva seu endereço de E-mail" aria-label="Email Adress" required style={isIEBrowser() ? { border: 'none' } : {}} />
                                                {this.state.loading_save==false && <div className="input-group-append">
                                                    <button onClick={()=>{
                                                        this.salvar();
                                                    }} className="btn btn-primary" type="button"><span>Inscrever</span><i className="icon-long-arrow-right"></i></button>
                                                </div>}
                                                {this.state.loading_save==true && <div className="input-group-append"
                                                style={{
                                                    display:'flex',
                                                    justifyContent:'center',
                                                    alignItems:'center',
                                                    padding:10,
                                                    paddingLeft:20,
                                                    paddingRight:20,
                                                    color:'white'
                                                }}
                                                > <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div></div>}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ChatCliente />
                    <FooterFive logo="assets/images/logo.png" />
                    <SweetAlert
                    warning
                    title={"Aviso"}
                    onConfirm={() => {
                        this.setState({ msgErro: '' });
                    }}
                    show={this.state.msgErro!=''}
                    confirmBtnText='OK'
                    confirmBtnBsStyle="primary"
                    confirmBtnStyle={{ color: 'white' }}

                >
                    {this.state.msgErro}

                </SweetAlert>

                <SweetAlert
                    success
                    title={"Sucesso"}
                    onConfirm={() => {
                        this.setState({ show_success: false });
                    }}
                    show={this.state.show_success}
                    confirmBtnText='OK'
                    confirmBtnBsStyle="primary"
                    confirmBtnStyle={{ color: 'white' }}

                >
                    E-mail salvo com sucesso

                </SweetAlert>
                </div>
                <MobileMenuTwo adClass="mobile-menu-light" />
                {/* <NewsletterModal demo="4"/> */}
            </React.Fragment>
        )
    }
}

// export default IndexFour;
export const mapStateToProps = (state) => ({
    token: state.user.token,
    produtos_novos: state.home.produtos_novos,
    produtos_categorias: state.home.produtos_categorias,
    produtos_recomendados: state.home.produtos_recomendados,
    produtos_populares: state.home.produtos_populares,
    categorias: state.home.categorias,
    banners_topo: state.home.banners_topo,
    banners_promocao: state.home.banners_promocao,
    banner_faixa_meio: state.home.banner_faixa_meio,
    banners_promocao_limitada: state.home.banners_promocao_limitada,
    nossas_marcas: state.home.nossas_marcas

})

export default connect(mapStateToProps, { changeHome })(IndexFour);