import React, { Component } from "react";
import styled from "@emotion/styled";
import { colors } from "@atlaskit/theme";
import { grid, borderRadius } from "./constants";
import { Draggable } from "react-beautiful-dnd";
import QuoteList from "./primatives/quote-list";
import Title from "./primatives/title";
import embalagem_default from '../../../../imgs/embalagem_default.jpg'

const Container = styled.div`
  margin: ${grid}px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: ${borderRadius}px;
  border-top-right-radius: ${borderRadius}px;
  background-color: ${({ isDragging }) =>
    isDragging ? colors.G50 : 'white'};
  transition: background-color 0.2s ease;
  &:hover {
    background-color: ${colors.G50};
  }
`;

export default class Column extends Component {
  render() {
    const title = this.props.title;
    const quotes = this.props.quotes;
    const index = this.props.index;
    const item = this.props.item;

    return (
      <Draggable draggableId={item.id} index={index} >
        {(provided, snapshot) => (
          <Container ref={provided.innerRef} {...provided.draggableProps}>
            <Header isDragging={snapshot.isDragging}>
              <Title
                isDragging={snapshot.isDragging}
                {...provided.dragHandleProps}
              >
                <p style={{ color: 'black', fontWeight: 'bold', marginBottom: 5, textAlign: 'center', lineHeight: 1.5 }}>{item.nome} {item.embalagem_produto == true ? 'Embalagem do próprio produto' : ''}</p>

                <img style={{ width: '100%', height: 150, objectFit: 'contain', paddingRight: 10 }} src={embalagem_default} alt="Product" />
                <div className="mt-2">
                  <p style={{ color: 'black', textAlign: 'center', lineHeight: 1.5 }}>Comprimento: {item.comprimento} cm</p>
                  <p style={{ color: 'black', textAlign: 'center', lineHeight: 1.5 }}>Largura: {item.largura} cm</p>
                  <p style={{ color: 'black', textAlign: 'center', lineHeight: 1.5 }}>Altura: {item.altura} cm</p>
                  <p style={{ color: 'black', marginBottom: 5, textAlign: 'center', lineHeight: 1.5 }}>Quantidade de itens: {item.produtos.length}</p>

                  {item.etiqueta != null && <a href={item.etiqueta} style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>Imprimir Etiqueta</a>}
                <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                <button
                disabled={quotes.length>0}
                onClick={() => {
                  this.props.removeColum();
                }} className={"btn "+(quotes.length>0?'btn-outline-secondary':'btn-outline-danger')}><span>Remover</span><i className="fas fa-trash"></i></button>
                </div>
                </div>

                {/* {item.id} */}
              </Title>
              {/* <div
              isDragging={snapshot.isDragging}
              {...provided.dragHandleProps}
              >
             
              </div> */}

            </Header>
            <QuoteList
              listId={item.id}
              isDropDisabled={item.embalagem_produto == true}
              listType="QUOTE"
              style={{
                backgroundColor: snapshot.isDragging ? colors.G50 : null
              }}
              quotes={quotes}
              internalScroll={this.props.isScrollable}
              isCombineEnabled={Boolean(this.props.isCombineEnabled)}
            />
            
          </Container>
        )}
      </Draggable>
    );
  }
}
