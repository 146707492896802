import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

// import Custom Components
import Logo from '../logo';
import MainMenu from './common/main-menus/menu-1';
import CartMenu from './common/cart-menus/menu-4';
import CategoryMenu from './common/category-menus/menu-1';
import CompareMenuTwo from './common/compare-menus/menu-2';
import LoginModal from '../../features/login-modal';

import { showModal, removeFromWishlist, login, logout, changeState, replaceCart, replaceWishList } from '../../../actions';
import { stickyHeaderHandler, safeContent } from '../../../utils/utils';
import { URL } from '../../../variables';

import DefaultProfile from '../../../imgs/default_profile.jpg';
import { maskPriceDecimal } from '../../../Auxiliar/Masks';


class HeaderSeven extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name_produto: [],
            redirect: false,
            loading: true,
            loading_wish: {}
        }
        this.openLoginModal = this.openLoginModal.bind(this);
    }

    componentDidMount() {
        if (this.props.token != undefined) {
            this.get_user();
        }
        else {
            this.setState({ loading: false });
        }
        window.addEventListener('scroll', stickyHeaderHandler);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', stickyHeaderHandler);
    }

    openLoginModal(e) {
        this.props.showModal('login');
        e.preventDefault();
    }

    get_user() {
        // this.setState({ loading: true });

        fetch(`${URL}api/auth/get_user`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    this.setState({ loading: false });

                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ loading: false });

                }
                else {
                    this.props.login({ user: resp.user, token: this.props.token });
                    this.props.replaceCart(resp.cartlist);
                    this.props.replaceWishList(resp.wishlist);

                    this.setState({ loading: false });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading: false });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading: false });

            });
    }

    excluir_wishlist(product) {
        if(this.props.token==undefined){
            this.props.removeFromWishlist(product.id)
            return;
        }
        let loading = { ...this.state.loading_wish };
        loading[product.id] = true;
        this.setState({ loading_wish: loading, msgErro: '' });
        let form = new FormData();

        fetch(`${URL}api/lista_desejos/excluir_item_wish/${product.id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                let loading = { ...this.state.loading_wish };
                loading[product.id] = false;
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_wish: loading, msgErro: erro });
                }
                else {
                    this.props.removeFromWishlist(product.id)
                    this.setState({ loading_wish: loading });

                }




            } catch (err) {
                console.log(err);
                let loading = { ...this.state.loading_wish };
                loading[product.id] = false;
                this.setState({ loading_wish: loading, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                let loading = { ...this.state.loading_wish };
                loading[product.id] = false;
                this.setState({ loading_wish: loading, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }

    render() {
        let wishlist = this.props.wishlist;

        return (
            <header className="header header-intro-clearance header-4">
                <div className="header-top" style={{ height: 40, display: "flex" }}>
                    <div className="container">
                        <div className="header-left">
                            <Link to="tel:#" style={{ marginRight: 20 }} className="d-none d-sm-flex"><i className="icon-phone"></i>(17) 3422-2131</Link>
                            <Link to="tel:#"><i className="fab fa-whatsapp"></i>(17) 99792-6125</Link>

                        </div>

                        <div className="header-right">
                            <ul className="top-menu">
                                <li>
                                    <Link to="#">Conta</Link>
                                    <ul>

                                        {/* <li>
                                            <div className="header-dropdown">
                                                <Link to="#">USD</Link>
                                                <div className="header-menu">
                                                    <ul>
                                                        <li><Link to="#">Eur</Link></li>
                                                        <li><Link to="#">Usd</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li> */}

                                        {/* <li>
                                            <div className="header-dropdown">
                                                <Link to="#">English</Link>
                                                <div className="header-menu">
                                                    <ul>
                                                        <li><Link to="#">English</Link></li>
                                                        <li><Link to="#">French</Link></li>
                                                        <li><Link to="#">Spanish</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li> */}

                                        {this.state.loading == false && this.props.token == undefined && <li><Link to="#signin-modal" data-toggle="modal" onClick={this.openLoginModal}>Entrar / Cadastre-se</Link></li>}
                                        {this.state.loading == false && this.props.token != undefined && <li style={{ display: 'flex' }}><Link to="/conta/dashboard"  >
                                            <img style={{ height: 35, width: 35, borderRadius: 100, border: '1px solid lightgrey', marginRight: 5, objectFit: 'cover' }} src={this.props.user.imagem == undefined ? DefaultProfile : URL + this.props.user.imagem} />
                                            <span style={{ fontSize: '1.4rem' }}>{this.props.user.name}</span>

                                        </Link></li>}

                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="header-middle">
                    <div className="container">
                        <div className="header-left">
                            <button className="mobile-menu-toggler">
                                <span className="sr-only">Menu Mobile</span>
                                <i className="icon-bars"></i>
                            </button>

                            <Logo logo="logo.png" />
                        </div>

                        <div className="header-center">
                            <div className="header-search header-search-extended header-search-visible d-none d-lg-block" style={{ marginRight: 0 }}>
                                <Link to="#" className="search-toggle" role="button"><i className="icon-search"></i></Link>
                                <form action={'/shop/sidebar/list?name=' + this.state.name_produto} method="get">
                                    <div className="header-search-wrapper search-wrapper-wide">
                                        <label htmlFor="q" className="sr-only">Buscar</label>
                                        <button className="btn btn-primary" type="submit"><i className="icon-search"></i></button>
                                        <input value={this.state.name_produto} onChange={(e) => this.setState({ name_produto: e.target.value })} type="search" className="form-control" name="name" id="name" placeholder="Busque por um produto..." />
                                    </div>
                                </form>
                            </div>
                        </div>

                        {this.state.loading == false && <div className="header-right">
                            {/* <CompareMenuTwo/> */}

                            {/* <div className="wishlist">
                                <Link to={`${process.env.PUBLIC_URL}/shop/wishlist`} title="Wishlist">
                                    <div className="icon">
                                        <i className="icon-heart-o"></i>
                                        <span className="wishlist-count badge">{this.props.wishlist.length}</span>
                                    </div>
                                    <p>Lista de desejos</p>
                                </Link>
                            </div> */}
                            <div className="dropdown cart-dropdown">
                                <a href={`${process.env.PUBLIC_URL}/shop/wishlist`} className="dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
                                    <div className="icon">
                                        <i className="icon-heart-o"></i>
                                        <span className="cart-count">{wishlist.length}</span>
                                    </div>
                                    <p>Lista de desejos</p>
                                </a>

                                <div className={`dropdown-menu dropdown-menu-right ${wishlist.length === 0 ? 'text-center' : ''}`} style={wishlist.length === 0 ? { width: '200px' } : {}}>
                                    <div className="dropdown-cart-products">
                                        {wishlist.map((item, index) => (
                                            <div className="product" key={index}>
                                                <div className="product-cart-details">
                                                    <h4 className="product-title">
                                                        <Link to={`${process.env.PUBLIC_URL}/product/default/${item.id}`} dangerouslySetInnerHTML={safeContent(item.name)}></Link>
                                                    </h4>

                                                    <span className="cart-product-info">
                                                        {maskPriceDecimal(item.sale_price != null ? item.sale_price : item.price)}
                                                    </span>
                                                </div>

                                                <figure className="product-image-container">
                                                    <Link to={`${process.env.PUBLIC_URL}/product/default/${item.id}`} className="product-image">
                                                        <img src={URL + item.fotos[0].foto} data-oi={URL + item.fotos[0].foto} alt="product" />
                                                    </Link>
                                                </figure>

                                                <button className="btn-remove" title="Remove Product" onClick={() => this.excluir_wishlist(item)}><i className="icon-close"></i></button>
                                                {this.state.loading_wish[item.id] == true && <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'rgba(255,255,255,0.5)' }}>
                                                    <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div></div>
                                                </div>}
                                            </div>
                                        ))}
                                    </div>

                                    {wishlist.length === 0 ?
                                        <p>Lista de desejos está vazia.</p> : ''
                                    }
                                </div>
                            </div>
                            <CartMenu />
                        </div>}
                        {this.state.loading == true && <div className="header-right">
                            <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div></div>
                        </div>}
                    </div>
                </div>

                <div className="header-bottom sticky-header">
                    <div className="container">
                        <div className="header-left">
                            <CategoryMenu type={3} />
                        </div>

                        {/* <div className="header-center">
                             <MainMenu /> 
                        </div> */}

                        {/* <div className="header-right">
                            <p>Clearance<span className="highlight">&nbsp;Up to 30% Off</span></p>
                        </div> */}
                    </div>
                </div>
                <LoginModal />
            </header>
        )
    }
}

export const mapStateToProps = (state) => ({
    wishlist: state.wishlist.list,
    user: state.user.user,
    token: state.user.token,
})

export default connect(mapStateToProps, { showModal, removeFromWishlist, login, logout, changeState, replaceCart, replaceWishList })(HeaderSeven);