import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { URL } from '../../../../../variables';

import MobileMainNav from './nav';

class MobileMenuOne extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name_produto: '',
            categorias: [],
            loading: true,
            hover_cats: {}

        };
    }

    componentDidMount() {
        this.get_categorias();
    }

    get_categorias() {
        this.setState({ loading: true });

        fetch(`${URL}api/get_dados_produtos`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                // 'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                }
                else {

                    this.setState({
                        categorias: resp.categorias,
                        loading: false
                    });

                }




            } catch (err) {
                console.log(err);
                this.setState({
                    loading: false
                });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({
                    loading: false
                });
            });
    }


    render() {
        const { adClass } = this.props;

        return (
            <div className={`mobile-menu-container ${adClass}`}>
                <div className="mobile-menu-wrapper">
                    <span className="mobile-menu-close"><i className="icon-close"></i></span>

                    <form action={'/shop/sidebar/list?name=' + this.state.name_produto} method="get" className="mobile-search">
                        <label htmlFor="mobile-search" className="sr-only">Buscar</label>
                        <input onChange={(e) => {
                            this.setState({ name_produto: e.target.value });
                        }} type="name" value={this.state.name_produto} className="form-control" name="name" id="mobile-search" placeholder="Buscar Produto ..." required />
                        <button className="btn btn-primary" type="submit"><i className="icon-search"></i></button>
                    </form>

                    <Tabs defaultIndex={0} selectedTabClassName="show">
                        <TabList className="nav nav-pills-mobile nav-border-anim" role="tablist">
                            <Tab className="nav-item">
                                <span className="nav-link">Menu</span>
                            </Tab>

                            <Tab className="nav-item">
                                <span className="nav-link">Categorias</span>
                            </Tab>
                        </TabList>

                        <div className="tab-content">
                            <TabPanel>
                                <MobileMainNav />
                            </TabPanel>

                            <TabPanel>
                                <nav className="mobile-cats-nav">
                                    {this.state.categorias.map((item, id) => (
                                        <ul key={id} className="mobile-cats-menu" style={{marginBottom:0}}>

                                            <li style={{ cursor: 'pointer' }} onClick={() => {
                                                let hover_cats = { ...this.state.hover_cats };
                                                hover_cats[item.id] = !hover_cats[item.id];
                                                this.setState({ hover_cats })
                                            }}><a style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}><div>{item.name}</div> <i style={{ textAlign: 'end' }} className={this.state.hover_cats[item.id] == true ? "fas fa-angle-up" : "fas fa-angle-down"}></i></a> </li>
                                            {this.state.hover_cats[item.id] == true && item.categorias.length > 0 &&
                                                item.categorias.map((item2, id2) => (
                                                    <li key={id2}>
                                                        {item2.categorias.length != 0 && <li onClick={() => {
                                                            let hover_cats = { ...this.state.hover_cats };
                                                            hover_cats[item2.id] = !hover_cats[item2.id];
                                                            this.setState({ hover_cats })
                                                        }} style={{ cursor: 'pointer', paddingLeft: 15, borderLeft: 'none', borderRight: 'none' }} ><a style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}><div>{item2.name}</div> <i style={{ textAlign: 'end' }} className={this.state.hover_cats[item2.id] == true ? "fas fa-angle-up" : "fas fa-angle-down"}></i></a></li>}
                                                        {item2.categorias.length == 0 && <li style={{ paddingLeft: 0, borderLeft: 'none', borderRight: 'none' }} ><Link to={"/shop/sidebar/list?cat_id=" + item.id + ',' + item2.id}>{item2.name}</Link></li>}


                                                        {this.state.hover_cats[item2.id] == true && item2.categorias.map((item3, id3) => (
                                                            <li key={id3} style={{ paddingLeft: 45, borderLeft: 'none', borderRight: 'none' }} ><Link to={"/shop/sidebar/list?cat_id=" + item.id + ',' + item2.id + ',' + item3.id}>{item3.name}</Link></li>

                                                        ))}
                                                    </li>

                                                ))
                                            }
                                        </ul>

                                    ))}
                                    {/* <li><Link className="mobile-cats-lead" to="#">Daily offers</Link></li>
                                        <li><Link className="mobile-cats-lead" to="#">Gift Ideas</Link></li>
                                        <li><Link to="#">Beds</Link></li>
                                        <li><Link to="#">Lighting</Link></li>
                                        <li><Link to="#">Sofas & Sleeper sofas</Link></li>
                                        <li><Link to="#">Storage</Link></li>
                                        <li><Link to="#">Armchairs & Chaises</Link></li>
                                        <li><Link to="#">Decoration </Link></li>
                                        <li><Link to="#">Kitchen Cabinets</Link></li>
                                        <li><Link to="#">Coffee & Tables</Link></li>
                                        <li><Link to="#">Outdoor Furniture </Link></li> */}
                                </nav>
                            </TabPanel>
                        </div>
                    </Tabs>

                    <div className="social-icons">
                        {/* <Link to="#" className="social-icon" target="_blank" title="Facebook"><i className="icon-facebook-f"></i></Link>
                        <Link to="#" className="social-icon" target="_blank" title="Twitter"><i className="icon-twitter"></i></Link>
                        <Link to="#" className="social-icon" target="_blank" title="Instagram"><i className="icon-instagram"></i></Link>
                        <Link to="#" className="social-icon" target="_blank" title="Youtube"><i className="icon-youtube"></i></Link> */}
                    </div>
                </div>
            </div>
        )
    }
}

export default MobileMenuOne;