import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
// import Custom Components
import PageHeader from '../../common/page-header';
import Breadcrumb from '../../common/breadcrumb';
import Accordion from '../../features/accordion/accordion';
import Card from '../../features/accordion/card';

import { getCartTotal } from '../../../services';
import { URL } from '../../../variables';
import { maskCep, maskCpf, maskPriceDecimal } from '../../../Auxiliar/Masks';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import { logout, changeState, login } from '../../../actions/index';

import { isIEBrowser, isSafariBrowser, isEdgeBrowser } from '../../../utils/utils';
import Stepper from 'react-stepper-horizontal';
import Modal from 'react-modal';


class SelecioneEndereco extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect_cart: false,
            cep: '',
            rua: '',
            numero: '',
            bairro: '',
            cidade: '',
            estado: '',
            latitude: '',
            longitude: '',
            complemento: '',
            loading_save: false,
            msgErro: '',
            redirect: false,
            enderecos: [],
            loading: true,
            endereco_select: {},
            endereco_hidden: true,
            modal_open: false,
            cpf: '',
            loading_save_cpf: false,
            msgErroResposta: "",
            genero:''
        }
    }
    componentDidMount() {
        // let item = document.querySelector("#checkout-discount-input");

        // item.addEventListener("focus", function (e) {
        //     e.currentTarget.parentNode.querySelector("label").setAttribute("style", "opacity: 0");
        // });

        // item.addEventListener("blur", function (e) {
        //     let $this = e.currentTarget;
        //     if ($this.value.length !== 0) {
        //         $this.parentNode.querySelector("label").setAttribute("style", "opacity: 0");
        //     } else {
        //         $this.parentNode.querySelector("label").setAttribute("style", "opacity: 1");
        //     }
        // });
        this.props.changeState({ endereco_cobranca: {}, endereco: {}, precos_prazos: [], shipping: {}, forma_pagamento: { type: '', object: {},installment:1  }, volumes: [] });

        this.setState({cpf:this.props.user.cpf==null?'':this.props.user.cpf,genero:this.props.user.genero==null?'':this.props.user.genero});

        if (this.props.cartlist.length == 0 || this.props.token == undefined) {
            this.setState({ redirect_cart: true });
        }
        else {
            this.get_enderecos();
        }
    }

    componentDidUpdate(props){
        if(this.props.cartlist.length!=props.cartlist.length){
            this.setState({redirect_cart:true});
        }
    }

    get_lat_lng() {
        let address = `${this.state.rua},+${this.state.numero},+${this.state.bairro},+${this.state.cidade},+${this.state.estado},+Brasil`
        fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyAyaSKLxkfqUOco6ZoPx08OxUKUnaNRjnE&components=country=Brazil|region=BR`, {
            method: 'GET',
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.results.length > 0) {
                    this.setState({ latitude: resp.results[0].geometry.location.lat, longitude: resp.results[0].geometry.location.lng })
                }



            } catch (err) {
                console.log(err);
            }

        })
            .catch((err) => {
                console.log(err);
            });
    }

    get_enderecos() {
        this.setState({ loading: true });

        fetch(URL + `api/carrinho/get_enderecos`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                // 'plugin-version':'v2.0.0',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                }
                else {
                    let endereco_select = {};
                    let has_endereco=false;
                    if (resp.enderecos.length > 0) {
                        for (let i = 0; i < resp.enderecos.length; i++) {
                            if (resp.enderecos[i].principal == 1) {
                                endereco_select = resp.enderecos[i];
                            }
                            if(this.props.endereco != undefined && this.props.endereco.id != undefined && resp.enderecos[i].id==this.props.endereco.id){
                                has_endereco=true;
                                endereco_select = this.props.endereco;
                                break;
                            }
                        }
                    }
                    // if (this.props.endereco != undefined && this.props.endereco.id != undefined) {
                    //     endereco_select = this.props.endereco;
                    // }

                    this.setState({ enderecos: resp.enderecos, endereco_select, loading: false },()=>{
                        console.log(this.state.endereco_select)
                    })

                    // this.setState({
                    //     loading: false,
                    //     correio_fretes: resp.correio_fretes,
                    // });

                }




            } catch (err) {
                console.log(err);
                this.setState({
                    loading: false
                });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({
                    loading: false
                });
            });
    }

    buscar_endereco(cep) {
        console.log(cep);
        fetch(`https://viacep.com.br/ws/${cep}/json/`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                this.setState({
                    rua: resp.logradouro,
                    bairro: resp.bairro,
                    cidade: resp.localidade,
                    estado: resp.uf,
                    numero: ''

                }, () => {
                    this.get_lat_lng();
                });

                // await AsyncStorage.setItem('token', resp.access_token)
                // await AsyncStorage.setItem('user', JSON.stringify(resp.user))




            } catch (err) {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Algo deu errado. Seu CEP está correto?' });
            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading: false, msgErro: `Erro ao pegar resposta do servidor. Você está conectado a internet?` });
            });


    }

    salvar() {
        this.setState({ loading_save: true, msgErro: '' });
        let form = new FormData();
        form.append('cep', this.state.cep);

        form.append('rua', this.state.rua);
        form.append('numero', this.state.numero);
        form.append('bairro', this.state.bairro);
        form.append('cidade', this.state.cidade);
        form.append('estado', this.state.estado);
        form.append('complemento', this.state.complemento);
        form.append('latitude', this.state.latitude);
        form.append('longitude', this.state.longitude);


        fetch(`${URL}api/salvar_endereco`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_save: false, msgErro: erro });
                    if (isIEBrowser() || isSafariBrowser() || isEdgeBrowser()) {
                        let pos = window.pageYOffset;
                        let timerId = setInterval(() => {
                            if (pos <= 0)
                                clearInterval(timerId);
                            window.scrollBy(0, -120);
                            pos -= 120;
                        }, 1);
                    } else {
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth'
                        })
                    }
                }
                else {

                    this.setState({
                        loading_save: false,

                        cep: '',
                        rua: '',
                        numero: '',
                        bairro: '',
                        cidade: '',
                        estado: '',
                        latitude: '',
                        longitude: '',
                        complemento: '',
                    }, () => {
                        if (isIEBrowser() || isSafariBrowser() || isEdgeBrowser()) {
                            let pos = window.pageYOffset;
                            let timerId = setInterval(() => {
                                if (pos <= 0)
                                    clearInterval(timerId);
                                window.scrollBy(0, -120);
                                pos -= 120;
                            }, 1);
                        } else {
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth'
                            })
                        }
                    });
                    this.get_enderecos();

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    salvar_cpf() {
        this.setState({ loading_save_cpf: true, msgErroResposta: '' });
        let form = new FormData();
        form.append('cpf', this.state.cpf);
        form.append('genero', this.state.genero);


        fetch(`${URL}api/auth/add_cpf`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_save_cpf: false, msgErroResposta: erro });
                }
                else {
                    this.props.login({ user: resp.user, token: this.props.token });
                    this.setState({ loading_save_cpf: false, modal_open: false });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save_cpf: false, msgErroResposta: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save_cpf: false, msgErroResposta: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }

    render() {

        const { cartlist, total } = this.props;
        const shippingPrice = { "free": 0, "standard": 10, "express": 20 };
        const shippingObj = { "free": "Free shipping", "standard": "Standard", "express": "Express" };

        return (
            <div className="main">
                {this.state.redirect_cart == true && <Redirect to={'/shop/cart'} />}
                <PageHeader background="url(assets/images/cart.jpg)" title="Endereço" subTitle="Endereço de Entrega" />
                <div className="d-block d-sm-none">

                    <Breadcrumb title="Endereço de Entrega" parent1={["Carrinho", "shop/cart"]} />
                </div>

                <div className="container d-none d-sm-block">

                    <div className={'breadcrumb-nav'}>
                        <Stepper defaultColor={'#999'} defaultTitleColor={'#555'} activeTitleColor={'#19386e'} titleFontSize={14}
                            activeColor={'#19386e'} completeColor={'#005b8c'} completeTitleColor={'#005b8c'}
                            steps={[{
                                title: 'Carrinho',
                                href: '/shop/cart',
                            },
                            {
                                title: 'Endereço',
                            },
                            {
                                title: 'Entrega',
                            },
                            {
                                title: 'Pagamento',
                            },
                            {
                                title: 'Resumo'
                            },
                            ]} activeStep={1} />
                    </div>
                </div>
                <div className="page-content">
                    <div className="checkout">
                        <div className="container">
                            {/* <div className="checkout-discount">
                                <form action="#">
                                    <input type="text" className="form-control" required id="checkout-discount-input"/>
                                    <label htmlFor="checkout-discount-input" className="text-truncate">Have a coupon? <span>Click here to enter your code</span></label>
                                </form>
                            </div> */}


                            <form action="#">
                                <div className="row">
                                    <div className="col-lg-9">
                                        <div hidden={this.state.loading == true || this.state.enderecos.length == 0}>
                                            <h2 className="checkout-title" style={{ marginTop: 0 }}>Selecione um Endereço</h2>

                                            <div className="row">

                                                {this.state.enderecos.map((item, id) => (
                                                    <div className="col-12 col-sm-6 col-lg-4 mt-1" key={id}>
                                                        <div onClick={() => {
                                                            this.setState({ endereco_select: item });
                                                        }} className="card select_address" style={{
                                                            border: this.state.endereco_select.id == item.id ? '1px solid green' : '1px solid lightgrey',
                                                            padding: '5px',
                                                            borderRadius: 5,
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            cursor: 'pointer',
                                                            height: '100%',
                                                            maxWidth: 260
                                                        }}>
                                                            <div className="card-body" style={{
                                                                padding: 0, display: 'flex',
                                                                justifyContent: 'center',
                                                            }}>

                                                                <h3 className="product-title" style={{ whiteSpace: 'pre-line', fontSize: '1.4rem', fontWeight: '400' }}>
                                                                    <span style={{ marginBottom: '0.5rem' }}>{item.rua.substring(0, 25)}{item.rua.length > 25 ? '.' : ''} {item.numero}</span>

                                                                    {item.complemento != null && item.complemento != '' && '\n'}
                                                                    {item.complemento != null && item.complemento != '' && <span style={{ marginBottom: '0.5rem' }}>{item.complemento.substring(0, 25)}{item.complemento.length > 25 ? '.' : ''}</span>}

                                                                    {'\n'}
                                                                    <span style={{ marginBottom: '0.5rem' }}>{item.bairro.substring(0, 25)}{item.bairro.length > 25 ? '.' : ''}{'\n'}</span>
                                                                    <span style={{ marginBottom: '0.5rem' }}>{item.cidade.substring(0, 25)}{item.cidade.length > 25 ? '.' : ''} - {item.estado}{'\n'}</span>
                                                                    <span style={{ marginBottom: '0.5rem' }}>{item.cep}</span>
                                                                </h3>
                                                            </div>
                                                        </div>

                                                    </div>
                                                ))}
                                                {/* <div className="col-12 col-sm-6 col-lg-4" >
                                                <div onClick={() => {
                                                    this.setState({ select_option: 'add' });
                                                }} className="card" style={{
                                                    border: this.state.select_option == 'add' ? '1px solid green' : '1px solid lightgrey',
                                                    padding: '5px',
                                                    borderRadius: 5,
                                                    height: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    cursor: 'pointer'
                                                }}>
                                                    <div className="card-body" style={{
                                                        padding: 0, display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}>

                                                        <h3 className="product-title" style={{ whiteSpace: 'pre-line', fontSize: '1.3rem', fontWeight: '400' }}>
                                                            Adicionar um novo endereço
                                                            </h3>
                                                    </div>
                                                </div>

                                            </div> */}
                                            </div>

                                        </div>
                                        {this.state.loading == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div></div>}
                                        <hr />
                                        <div >

                                            <h2 onClick={() => this.setState({ endereco_hidden: !this.state.endereco_hidden })} className="checkout-title show_hide" style={{ display: 'flex', alignItems: 'center' }}>Adicione um Novo Endereço <i style={{ fontSize: 22, marginLeft: 20, paddingTop: this.state.endereco_hidden == true ? 0 : 10, paddingBottom: this.state.endereco_hidden == true ? 10 : 0 }} className={this.state.endereco_hidden == true ? "fas fa-sort-down" : "fas fa-sort-up"}></i> </h2>
                                            <div hidden={this.state.endereco_hidden}>

                                                <div style={{
                                                    color: '#721c24',
                                                    backgroundColor: '#f8d7da',
                                                    borderColor: '#f5c6cb'
                                                }} hidden={this.state.msgErro == ''} className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                                    <button style={{ color: '#721c24', top: '55%', fontSize: '2rem' }} onClick={() => this.setState({ msgErro: '' })} type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>

                                                    <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                                </div>
                                                <br />
                                                <label>Cep *</label>
                                                <input value={this.state.cep} onChange={(e) => {
                                                    this.setState({ cep: maskCep(e.target.value) });
                                                    let text = e.target.value.replace('-', '');
                                                    if (text.length == 8) {
                                                        this.buscar_endereco(text);
                                                    }
                                                }} type="text" placeholder="Cep" className="form-control" required />

                                                <label>Rua *</label>
                                                <input value={this.state.rua} onChange={(e) => this.setState({ rua: e.target.value }, () => this.get_lat_lng())} type="text" placeholder="Rua" className="form-control" required />

                                                <label>Número *</label>
                                                <input value={this.state.numero} onChange={(e) => this.setState({ numero: e.target.value }, () => this.get_lat_lng())} type="text" placeholder="Número" className="form-control" required />

                                                <label>Complemento</label>
                                                <input value={this.state.complemento} onChange={(e) => this.setState({ complemento: e.target.value })} type="text" placeholder="Complemento" className="form-control" />

                                                <label>Bairro *</label>
                                                <input value={this.state.bairro} onChange={(e) => this.setState({ bairro: e.target.value }, () => this.get_lat_lng())} type="text" placeholder="Bairro" className="form-control" required />

                                                <label>Cidade *</label>
                                                <input value={this.state.cidade} onChange={(e) => this.setState({ cidade: e.target.value }, () => this.get_lat_lng())} type="text" placeholder="Cidade" className="form-control" required />

                                                <label>Estado (Sigla) *</label>
                                                <input value={this.state.estado} onChange={(e) => this.setState({ estado: e.target.value }, () => this.get_lat_lng())} type="text" placeholder="Estado (Sigla)" className="form-control" required />
                                                <div style={{ height: 300, overflow: 'hidden' }}>

                                                    <Map
                                                        google={window.google}
                                                        style={{ height: 300 }}
                                                        center={{ lat: this.state.latitude, lng: this.state.longitude }}
                                                        className="map"
                                                        zoom={this.state.latitude == '' ? 0 : 14}>
                                                        {this.state.latitude != '' && <Marker
                                                            title={'The marker`s title will appear as a tooltip.'}
                                                            name={'SOMA'}
                                                            position={{ lat: this.state.latitude, lng: this.state.longitude }} />}

                                                    </Map>
                                                </div>




                                                {this.state.loading_save == false && <button onClick={() => {
                                                    this.salvar();
                                                }} style={{ marginTop: '1.3rem' }} type="button" className="btn btn-outline-primary btn-rounded">
                                                    <span>Salvar</span>
                                                    <i className="far fa-save"></i>
                                                </button>}
                                                {this.state.loading_save == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div></div>}
                                            </div>

                                        </div>

                                    </div>

                                    <aside className="col-lg-3">
                                        <div className="summary">
                                            <h3 className="summary-title">Seu Pedido</h3>

                                            <table className="table table-summary">
                                                <thead>
                                                    <tr>
                                                        <th>Produto</th>
                                                        <th>Total</th>
                                                    </tr>
                                                </thead>

                                                <tbody>

                                                    {cartlist.map((item, index) =>
                                                        <tr key={index}>
                                                            <td style={{ color: 'black' }}><p style={{ color: 'black' }}>{item.qty}x {item.name}</p></td>
                                                            <td style={{ color: 'black' }}>
                                                                {maskPriceDecimal(parseFloat(item.sale_price == null ? item.price : item.sale_price) * parseInt(item.qty))}
                                                            </td>
                                                        </tr>
                                                    )}
                                                    <tr className="summary-subtotal">
                                                        <td>Subtotal:</td>
                                                        <td>{maskPriceDecimal(total)}</td>
                                                    </tr>

                                                    {/* <tr className="summary-total">
                                                        <td>Total:</td>
                                                        <td>${(total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                    </tr> */}
                                                </tbody>
                                            </table>
                                            {this.props.user != undefined && this.props.user.cpf != null && this.props.user.genero!=null && <Link onClick={(e) => {
                                                if (this.state.endereco_select.id != undefined) {
                                                    console.log(this.state.endereco_select)
                                                    this.props.changeState({ endereco_cobranca: this.state.endereco_select, endereco: this.state.endereco_select, precos_prazos: [], shipping: {}, forma_pagamento: { type: '', object: {},installment:1  }, volumes: [] });
                                                }
                                            }} style={{ marginTop: '1.2rem' }} hidden={this.props.token == undefined || this.props.cartlist.length == 0 || this.state.enderecos.length == 0 || this.state.loading == true || this.state.endereco_select.id == undefined} to={`${process.env.PUBLIC_URL}/shop/delivery`}
                                                className="btn btn-outline-primary-2 btn-order btn-block">

                                                <span className="btn-text">Continuar</span>
                                                <span className="btn-hover-text">Selecionar Forma de Entrega</span>
                                            </Link>}
                                            {this.props.user != undefined && (this.props.user.cpf == null || this.props.user.genero == null) && <button type="button" onClick={() => {
                                                // if (this.state.endereco_select.id != undefined) {
                                                //     this.props.changeState({ endereco_cobranca: this.state.endereco_select, endereco: this.state.endereco_select, precos_prazos: [], shipping: {}, forma_pagamento: { type: '', object: {},installment:1  }, volumes: [] });
                                                // }
                                                this.setState({ modal_open: true });

                                            }} style={{ marginTop: '1.2rem' }} hidden={this.props.token == undefined || this.props.cartlist.length == 0 || this.state.enderecos.length == 0 || this.state.loading == true || this.state.endereco_select.id == undefined}
                                                className="btn btn-outline-primary-2 btn-order btn-block">

                                                <span className="btn-text">Continuar</span>
                                                <span className="btn-hover-text">Preencher dados pessoais</span>
                                            </button>}
                                        </div>
                                    </aside>
                                </div>
                            </form>
                        </div>
                    </div>
                    <Modal
                        isOpen={this.state.modal_open}
                        onRequestClose={() => this.setState({ modal_open: false })}
                        contentLabel="Example Modal"
                        style={customStyles}
                    >
                        <div style={{ position: 'fixed', top: 10, right: 10, cursor: 'pointer' }} onClick={() => {
                            this.setState({ modal_open: false });
                        }}>
                            <i style={{ color: 'white', fontSize: '2.3rem' }} className="fas fa-times"></i>
                        </div>
                        <div style={{ padding: 10, borderBottom: '1px solid grey' }}>
                            <h4 style={{ textAlign: 'center' }}>Dados Pessoais</h4>
                        </div>

                        <div style={{ marginTop: 25 }}>
                            <p style={{ color: 'red' }}>{this.state.msgErroResposta}</p>
                            <div className="row">
                                <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start">
                                    <h5 style={{ fontSize: '1.5rem' }}>Preencha seu CPF</h5>

                                </div>

                            </div>

                            <input value={this.state.cpf} onChange={(e) => this.setState({ cpf: maskCpf(e.target.value) })} type="text" placeholder="CPF" className="form-control" required />

                            <div className="row">
                                <div className="col-12 col-sm-6 d-flex justify-content-center justify-content-sm-start">
                                    <h5 style={{ fontSize: '1.5rem' }}>Gênero</h5>

                                </div>

                            </div>

                            <select name="genero" id="genero" className="form-control" value={this.state.genero} onChange={(e) => {
                                this.setState({ genero: e.target.value });
                            }}>
                                <option value="">Selecione um Gênero</option>
                                <option value={'M'}>Masculino</option>
                                <option value={'F'}>Feminino</option>
                            </select>

                            {/* <input value={this.state.genero} onChange={(e) => this.setState({ genero: maskCpf(e.target.value) })} type="text" placeholder="CPF" className="form-control" required /> */}
                            {console.log(this.state.loading_save_cpf)}
                            {this.state.loading_save_cpf == false && <button onClick={() => {
                                this.salvar_cpf();
                            }} style={{ marginTop: '1.3rem' }} type="button" className="btn btn-outline-primary btn-rounded">
                                <span>Salvar</span>
                                <i className="far fa-save"></i>
                            </button>}
                            {this.state.loading_save_cpf == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div></div>}
                        </div>

                    </Modal>
                </div >
            </div >
        )
    }
}

export const mapStateToProps = (state) => ({
    cartlist: state.cartlist.cart,
    total: getCartTotal(state.cartlist.cart),
    endereco: state.cartlist.endereco,
    shipping: state.cartlist.shipping,
    token: state.user.token,
    user: state.user.user,

})

const customStyles = {
    content: {
        // top: '50%',
        // transform: 'translateY(-50%)',
        height: 400,
        top: '25%',
        right: 10,
        left: 10
    },
    overlay: {
        backgroundColor: 'rgba(51,51,51,0.6)',
        zIndex: '10000',

        // overflowY: 'scroll'
    }
};

export default connect(mapStateToProps, { logout, changeState, login })(GoogleApiWrapper({ apiKey: 'AIzaSyAyaSKLxkfqUOco6ZoPx08OxUKUnaNRjnE' })(SelecioneEndereco));