import React, { Component } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { Link } from 'react-router-dom';

// import Custom Components
import { connect } from 'react-redux';
import { URL } from '../../../../variables';
import Pagination from '../../../features/pagination';
import SweetAlert from 'react-bootstrap-sweetalert';
import { logout } from '../../../../actions';
import ReactTooltip from 'react-tooltip';

class Enderecos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            enderecos: [],
            current_page: 1,
            last_page: 1,
            total: 1,
            per_page: 1,
            page: 1,
            loading: false,
            show_warning: false,
            show_erro: false,
            msgErro: '',
            user_to_delete: '',
            loading_principal:{}
        };
    }

    delete_endereco(id) {

        fetch(`${URL}api/delete_endereco/${id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ msgErro: erro, show_warning: false, show_erro: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({ show_warning: false });
                    this.get_enderecos(this.state.page);
                    // this.setState({ users: resp.users, loading_screen: false, page: resp.pagination.current_page, total_pages: resp.pagination.last_page });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    tornar_principal(id) {

        fetch(`${URL}api/tornar_principal/${id}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    let loading_principal={...this.state.loading_principal};
                    loading_principal[id]=false;
                    this.setState({ msgErro: erro, show_warning: false, show_erro: true,loading_principal });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    let loading_principal={...this.state.loading_principal};
                    loading_principal[id]=false;
                    this.setState({ loading_principal: loading_principal });
                    this.get_enderecos(this.state.page);
                    // this.setState({ users: resp.users, loading_screen: false, page: resp.pagination.current_page, total_pages: resp.pagination.last_page });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    get_enderecos(page = 1) {
        this.setState({ loading: true });

        fetch(`${URL}api/get_enderecos?page=${page}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                }
                else {
                    if (page == this.state.page) {
                        console.log('updated');
                        this.setState({
                            enderecos: resp.enderecos,
                            loading: false,
                            current_page: resp.current_page,
                            last_page: resp.last_page,
                            total: resp.total,
                            per_page: resp.per_page
                        });
                    }
                }




            } catch (err) {
                console.log(err);

            }

        })
            .catch((err) => {
                console.log(err);
            });
    }

    componentDidMount() {
        this.get_enderecos(this.state.page);
    }



    render() {
        let enderecos = this.state.enderecos;

        return (
            <div>

                <div className="row mb-2">
                    <div className="col-12  d-flex justify-content-end">
                        <Link to={"/conta/enderecos/adicionar"}><span

                            className="btn btn-outline-primary btn-rounded" style={{ height: '45px' }}><span>Adicionar endereço</span><i className="icon-long-arrow-right"></i></span></Link>
                    </div>
                </div>
                <table className="table table-cart table-mobile">
                    <thead>
                        <tr>
                            <th>Endereço</th>
                            <th></th>

                            <th>Ações</th>
                        </tr>
                    </thead>
                    {this.state.loading == false && <tbody>
                        {enderecos.length > 0 ?
                            enderecos.map((item, index) =>
                                <tr key={index}>

                                    <td colSpan="2">
                                        <div className="product">


                                            <h3 className="product-title" style={{ whiteSpace: 'pre-line' }}>
                                                {item.rua}, {item.numero} - Cep: {item.cep}
                                                {'\n'}
                                                {item.bairro}, {item.cidade} - {item.estado}
                                                {'\n'}
                                                {item.complemento != null && item.complemento != '' && <span>Complemento: {item.complemento}</span>}
                                            </h3>
                                            {item.principal == 1 && <div>
                                                <span style={{ color: 'green' }}>Principal</span>
                                            </div>}

                                        </div>
                                    </td>


                                    {this.state.loading_principal[item.id] != true && <td colSpan="1">
                                        <ReactTooltip />
                                        {item.principal == 0 && <button data-tip="Tornar Endereço principal" style={{ width: 40, height: 40, minWidth: 40, borderRadius: 10, marginRight: 10 }} onClick={() => {
                                            let loading_principal = { ...this.state.loading_principal };
                                            loading_principal[item.id] = true;
                                            this.setState({ loading_principal: loading_principal },()=>{
                                                this.tornar_principal(item.id);
                                            });
                                        }}
                                            className="destroy_it btn btn-success"><i
                                                className="fas fa-check"></i></button>}
                                        <Link data-tip="Editar Endereço" to={'/conta/enderecos/editar/' + item.id}><span style={{ width: 40, height: 40, minWidth: 40, borderRadius: 10, marginRight: 10 }} className="btn btn-info"
                                            data-toggle="tooltip"
                                        ><i className="fas fa-edit"></i></span></Link>
                                        <button data-tip="Remover Endereço" style={{ width: 40, height: 40, minWidth: 40, borderRadius: 10 }} onClick={() => {
                                            this.setState({ show_warning: true, user_to_delete: item.id });
                                        }}
                                            className="destroy_it btn btn-danger"><i
                                                className="fas fa-trash"></i></button>
                                    </td>}
                                    {this.state.loading_principal[item.id] == true && <td colSpan="1">
                                        <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div></div>
                                    </td>}
                                </tr>
                            ) :
                            <tr>
                                <th colSpan="4">
                                    <p className="pl-2 pt-1 pb-1"> Não há enderecos cadastrados no sistema </p>
                                </th>
                            </tr>
                        }
                    </tbody>}
                    {this.state.loading == true && <tbody><tr><td colSpan="4"><div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div></div></td></tr></tbody>}
                </table>
                <Pagination count={this.state.total} unit={this.state.per_page} onChange={(page) => {
                    page += 1;
                    this.setState({ page });
                    this.get_enderecos(page);
                }} />
                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                        this.delete_endereco(this.state.user_to_delete);
                    }}
                    onCancel={() => {
                        this.setState({ show_warning: false });
                    }}
                    show={this.state.show_warning}
                    confirmBtnText='Sim, desejo apagar'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnStyle={{ color: 'white' }}

                    showCancel={true}
                >
                    ao deletar este endereço ele não estará mais disponível no sistema

                </SweetAlert>
                <SweetAlert
                    danger
                    title={"Erro"}
                    onConfirm={() => {
                        this.setState({ show_erro: false });
                    }}
                    show={this.state.show_erro}
                    confirmBtnText='OK'
                    confirmBtnBsStyle="primary"
                    confirmBtnStyle={{ color: 'white' }}

                >
                    {this.state.msgErro}

                </SweetAlert>
            </div>
        )
    }
}

export const mapStateToProps = (state) => ({
    token: state.user.token
})

export default connect(mapStateToProps, { logout })(Enderecos);