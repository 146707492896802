import React, { Component } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { Link, Redirect } from 'react-router-dom';

// import Custom Components
import { connect } from 'react-redux';
import Select from 'react-select'
import { URL } from '../../../../variables';
import { maskPrice } from '../../../../Auxiliar/Masks';
import { logout } from '../../../../actions';

class AdicionarUsuario extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            password: '',
            password_confirmation: '',
            loading_save: false,
            msgErro: '',
            redirect: false,
            roles:[],
            role_id:''
        };
    }

    salvar() {
        this.setState({ loading_save: true, msgErro: '' });
        let form = new FormData();
        form.append('name', this.state.name);
        form.append('email', this.state.email);
        form.append('password', this.state.password);
        form.append('password_confirmation', this.state.password_confirmation);
        form.append('role', this.state.role_id);

        fetch(`${URL}api/admin/salvar_usuario`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_save: false, msgErro: erro });
                    window.scrollTo({top:0,behavior: 'smooth'})

                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }

    get_roles() {
        this.setState({ loading: true });

        fetch(`${URL}api/admin/get_roles`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                }
                else {
                    let roles = [];

                    for (let i = 0; i < resp.roles.length; i++) {
                        roles.push({ value: resp.roles[i].id, label: resp.roles[i].description });
                    }
                    this.setState({
                        roles: roles,
                        loading: false
                    });

                }




            } catch (err) {
                console.log(err);
                this.setState({
                    loading: false
                });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({
                    loading: false
                });
            });
    }

    componentDidMount() {
        this.get_roles();
    }



    render() {

        return (
            <div>
                {this.state.redirect == true && <Redirect to={'/conta/usuarios'} />}

                <div style={{
                    color: '#721c24',
                    backgroundColor: '#f8d7da',
                    borderColor: '#f5c6cb'
                }} hidden={this.state.msgErro == ''} className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                    <button style={{ color: '#721c24', top: '55%', fontSize: '2rem' }} onClick={() => this.setState({ msgErro: '' })} type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>

                    <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                </div>
                <br />

                <label>Nome *</label>
                <input value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} type="text" placeholder="Nome" className="form-control" required />
                {/* <small className="form-text">This will be how your name will be displayed in the account section and in reviews</small> */}

                <label>E-mail *</label>
                <input value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} type="email" placeholder="Endereço de E-mail" className="form-control" required />

               
                <label htmlFor="singin-password-2">Senha *</label>
                <input value={this.state.password} onChange={(e) => {
                    this.setState({ password: e.target.value });
                }} type="password" className="form-control" id="singin-password-2" name="singin-password" placeholder="Senha" required />

                <label htmlFor="register-password-2">Confirmar Senha *</label>
                <input value={this.state.password_confirmation} onChange={(e) => {
                    this.setState({ password_confirmation: e.target.value });
                }} type="password" className="form-control" id="register-password-2" name="register-password" placeholder="Confirmar Senha" required />

                <label htmlFor="register-password-2">Selecione a função do usuário *</label>
                <Select onChange={(e) => {
                    console.log(e);
                    this.setState({ role_id: e.value });
                }} placeholder={'Selecione a função do usuário'} noOptionsMessage={() => 'Nenhuma função disponível'} options={this.state.roles} />


                {this.state.loading_save == false && <button onClick={() => {
                    this.salvar();
                }} style={{ marginTop: '1.3rem' }} type="button" className="btn btn-outline-primary btn-rounded">
                    <span>Salvar</span>
                    <i className="far fa-save"></i>
                </button>}
                {this.state.loading_save == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div></div>}

            </div>
        )
    }
}

export const mapStateToProps = (state) => ({
    token: state.user.token

})

export default connect(mapStateToProps, { logout })(AdicionarUsuario);