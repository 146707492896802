import React, { Component } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { Link } from 'react-router-dom';

// import Custom Components
import { connect } from 'react-redux';
import { URL } from '../../../../variables';
import Pagination from '../../../features/pagination';
import SweetAlert from 'react-bootstrap-sweetalert';
import { logout } from '../../../../actions';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

class Banners extends Component {

    constructor(props) {
        super(props);
        this.state = {
            banners_topo: [],
            banners_promocao: [],
            banner_faixa_meio: [],
            banners_promocao_limitada: [],
            current_page: 1,
            last_page: 1,
            total: 1,
            per_page: 1,
            page: 1,
            loading: false,
            show_warning: false,
            show_erro: false,
            msgErro: '',
            user_to_delete: '',
            banners_topo_mobile: [],
            loading_banner_order: {}
        };
    }

    delete_banner(id) {

        fetch(`${URL}api/admin/delete_banner/${id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ msgErro: erro, show_warning: false, show_erro: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({ show_warning: false });
                    this.get_banners(this.state.page);
                    // this.setState({ users: resp.users, loading_screen: false, page: resp.pagination.current_page, total_pages: resp.pagination.last_page });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    change_order(id, order) {
        let loading_banner_order = { ...this.state.loading_banner_order };
        loading_banner_order[id] = true;
        this.setState({ loading_banner_order: loading_banner_order });
        fetch(`${URL}api/admin/change_order/${id}/${order}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    let loading_banner_order = { ...this.state.loading_banner_order };
                    loading_banner_order[id] = false;
                    this.setState({ msgErro: erro, show_warning: false, show_erro: true, loading_banner_order });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    let loading_banner_order = { ...this.state.loading_banner_order };
                    loading_banner_order[id] = false;
                    this.setState({
                        banners_topo: resp.banners_topo,
                        banners_promocao: resp.banners_promocao,
                        banner_faixa_meio: resp.banner_faixa_meio,
                        banners_promocao_limitada: resp.banners_promocao_limitada,
                        banners_topo_mobile: resp.banners_topo_mobile,
                        loading_banner_order: loading_banner_order,
                    });
                    // this.setState({ users: resp.users, loading_screen: false, page: resp.pagination.current_page, total_pages: resp.pagination.last_page });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    get_banners(page = 1) {
        this.setState({ loading: true });

        fetch(`${URL}api/admin/get_banners`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                }
                else {
                    if (page == this.state.page) {
                        console.log('updated');
                        this.setState({
                            banners_topo: resp.banners_topo,
                            banners_promocao: resp.banners_promocao,
                            banner_faixa_meio: resp.banner_faixa_meio,
                            banners_promocao_limitada: resp.banners_promocao_limitada,
                            banners_topo_mobile: resp.banners_topo_mobile,

                            loading: false,
                            // current_page: resp.current_page,
                            // last_page: resp.last_page,
                            // total: resp.total,
                            // per_page: resp.per_page
                        });
                    }
                }




            } catch (err) {
                console.log(err);

            }

        })
            .catch((err) => {
                console.log(err);
            });
    }

    componentDidMount() {
        this.get_banners(this.state.page);
    }



    render() {
        let banners_topo = this.state.banners_topo;
        let banners_topo_mobile = this.state.banners_topo_mobile;

        let banners_promocao = this.state.banners_promocao;
        let banner_faixa_meio = this.state.banner_faixa_meio;
        let banners_promocao_limitada = this.state.banners_promocao_limitada;

        return (
            <div>
                <div className="row mb-2">
                    <div className="col-12 col-md-6">
                        <h4>Banner Topo</h4>
                    </div>

                    <div className="col-12 col-md-6  d-flex justify-content-end">
                        <Link to={"/conta/banners/adicionar_topo"}><span

                            className="btn btn-outline-primary btn-rounded" style={{ height: '45px' }}><span>Adicionar banner topo</span><i className="icon-long-arrow-right"></i></span></Link>
                    </div>
                </div>
                <table className="table table-cart table-mobile">
                    <thead>
                        <tr>
                            <th>Imagem</th>
                            <th>Link</th>
                            <th>Ordem</th>

                            <th>Ações</th>
                        </tr>
                    </thead>
                    {this.state.loading == false && <tbody>
                        {banners_topo.length > 0 ?
                            banners_topo.map((item, index) =>
                                <tr key={index}>
                                    <td colSpan="1">
                                        <div style={{ width: '100%' }} className="d-flex justify-content-center justify-content-lg-start">
                                            <img src={URL + item.image} style={{ width: 100, height: 50, objectFit: 'contain' }} />

                                        </div>
                                    </td>
                                    <td colSpan="1">
                                        <div className="product">


                                            <h3 className="product-title">
                                                {item.link}
                                            </h3>
                                        </div>
                                    </td>
                                    <td colSpan="1">
                                        {this.state.loading_banner_order[item.id] != true && <div className="product d-flex align-items-center justify-content-center justify-content-lg-start" >


                                            <h3 className="product-title">
                                                {item.order}
                                            </h3>
                                            <div><div
                                                onClick={() => {
                                                    let order = item.order + 1;
                                                    this.change_order(item.id, order);
                                                }}
                                                className="show_hide" style={{
                                                    height: 15,
                                                    marginLeft: 10,
                                                    marginRight: 10
                                                }}><i className="fas fa-sort-up" aria-hidden="true"></i></div>
                                                <div onClick={() => {
                                                    let order = item.order - 1;
                                                    this.change_order(item.id, order);
                                                }} className="show_hide" style={{
                                                    marginLeft: 10,
                                                    marginRight: 10
                                                }}><i className="fas fa-sort-down" aria-hidden="true"></i></div></div>
                                        </div>}
                                        {this.state.loading_banner_order[item.id] == true &&
                                            <div className="d-flex justify-content-center justify-content-lg-start align-items-center"><div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div></div>
                                        }
                                    </td>


                                    <td colSpan="1">
                                        <ReactTooltip />

                                        <Link data-tip="Editar Banner" data-tip="Editar Banner" to={'/conta/banners/editar_topo/' + item.id}><span style={{ width: 40, height: 40, minWidth: 40, borderRadius: 10, marginRight: 10 }} className="btn btn-info"
                                            data-toggle="tooltip"
                                        ><i className="fas fa-edit"></i></span></Link>
                                        <button data-tip="Remover Banner" style={{ width: 40, height: 40, minWidth: 40, borderRadius: 10 }} onClick={() => {
                                            this.setState({ show_warning: true, user_to_delete: item.id });
                                        }}
                                            className="destroy_it btn btn-danger"><i
                                                className="fas fa-trash"></i></button>
                                    </td>
                                </tr>
                            ) :
                            <tr>
                                <th colSpan="4">
                                    <p className="pl-2 pt-1 pb-1"> Não há banners cadastrados no sistema </p>
                                </th>
                            </tr>
                        }
                    </tbody>}
                    {this.state.loading == true && <tbody><tr><td colSpan="4"><div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div></div></td></tr></tbody>}
                </table>
                <div className="row mb-2">
                    <div className="col-12 col-md-6">
                        <h4>Banner Topo Mobile</h4>
                    </div>

                    <div className="col-12 col-md-6  d-flex justify-content-end">
                        <Link to={"/conta/banners/adicionar_topo_mobile"}><span

                            className="btn btn-outline-primary btn-rounded" style={{ height: '45px' }}><span>Adicionar banner topo para mobile</span><i className="icon-long-arrow-right"></i></span></Link>
                    </div>
                </div>
                <table className="table table-cart table-mobile">
                    <thead>
                        <tr>
                            <th>Imagem</th>
                            <th>Link</th>
                            <th>Ordem</th>

                            <th>Ações</th>
                        </tr>
                    </thead>
                    {this.state.loading == false && <tbody>
                        {banners_topo_mobile.length > 0 ?
                            banners_topo_mobile.map((item, index) =>
                                <tr key={index}>
                                    <td colSpan="1">
                                        <div style={{ width: '100%' }} className="d-flex justify-content-center justify-content-lg-start">
                                            <img src={URL + item.image} style={{ width: 100, height: 50, objectFit: 'contain' }} />

                                        </div>
                                    </td>
                                    <td colSpan="1">
                                        <div className="product">


                                            <h3 className="product-title">
                                                {item.link}
                                            </h3>
                                        </div>
                                    </td>
                                    <td colSpan="1">
                                        {this.state.loading_banner_order[item.id] != true && <div className="product d-flex align-items-center justify-content-center justify-content-lg-start" >


                                            <h3 className="product-title">
                                                {item.order}
                                            </h3>
                                            <div><div
                                                onClick={() => {
                                                    let order = item.order + 1;
                                                    this.change_order(item.id, order);
                                                }}
                                                className="show_hide" style={{
                                                    height: 15,
                                                    marginLeft: 10,
                                                    marginRight: 10
                                                }}><i className="fas fa-sort-up" aria-hidden="true"></i></div>
                                                <div onClick={() => {
                                                    let order = item.order - 1;
                                                    this.change_order(item.id, order);
                                                }} className="show_hide" style={{
                                                    marginLeft: 10,
                                                    marginRight: 10
                                                }}><i className="fas fa-sort-down" aria-hidden="true"></i></div></div>
                                        </div>}
                                        {this.state.loading_banner_order[item.id] == true &&
                                            <div className="d-flex justify-content-center justify-content-lg-start align-items-center"><div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div></div>
                                        }
                                    </td>


                                    <td colSpan="1">
                                        <ReactTooltip />

                                        <Link data-tip="Editar Banner" to={'/conta/banners/editar_topo_mobile/' + item.id}><span style={{ width: 40, height: 40, minWidth: 40, borderRadius: 10, marginRight: 10 }} className="btn btn-info"
                                            data-toggle="tooltip"
                                        ><i className="fas fa-edit"></i></span></Link>
                                        <button data-tip="Remover Banner" style={{ width: 40, height: 40, minWidth: 40, borderRadius: 10 }} onClick={() => {
                                            this.setState({ show_warning: true, user_to_delete: item.id });
                                        }}
                                            className="destroy_it btn btn-danger"><i
                                                className="fas fa-trash"></i></button>
                                    </td>
                                </tr>
                            ) :
                            <tr>
                                <th colSpan="4">
                                    <p className="pl-2 pt-1 pb-1"> Não há banners cadastrados no sistema </p>
                                </th>
                            </tr>
                        }
                    </tbody>}
                    {this.state.loading == true && <tbody><tr><td colSpan="4"><div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div></div></td></tr></tbody>}
                </table>

                <div className="row mb-2" style={{ marginTop: '6rem' }}>
                    <div className="col-12 col-md-6">
                        <h4>Banners Promoção</h4>
                    </div>

                    <div className="col-12 col-md-6  d-flex justify-content-end">
                        <Link to={"/conta/banners/adicionar_promocao"}><span

                            className="btn btn-outline-primary btn-rounded" style={{ height: '45px' }}><span>Adicionar banner promoção</span><i className="icon-long-arrow-right"></i></span></Link>
                    </div>
                </div>
                <table className="table table-cart table-mobile">
                    <thead>
                        <tr>
                            <th>Imagem</th>
                            <th>Titulo</th>
                            <th>Ordem</th>

                            <th>Ações</th>
                        </tr>
                    </thead>
                    {this.state.loading == false && <tbody>
                        {banners_promocao.length > 0 ?
                            banners_promocao.map((item, index) =>
                                <tr key={index}>
                                    <td colSpan="1">
                                        <div style={{ width: '100%' }} className="d-flex justify-content-center justify-content-lg-start">
                                            <img src={URL + item.image} style={{ width: 100, height: 50, objectFit: 'contain' }} />

                                        </div>
                                    </td>
                                    <td colSpan="1">
                                        <div className="product">


                                            <h3 className="product-title">
                                                {item.title}
                                            </h3>
                                        </div>
                                    </td>
                                    <td colSpan="1">
                                        {this.state.loading_banner_order[item.id] != true && <div className="product d-flex align-items-center justify-content-center justify-content-lg-start" >


                                            <h3 className="product-title">
                                                {item.order}
                                            </h3>
                                            <div><div
                                                onClick={() => {
                                                    let order = item.order + 1;
                                                    this.change_order(item.id, order);
                                                }}
                                                className="show_hide" style={{
                                                    height: 15,
                                                    marginLeft: 10,
                                                    marginRight: 10
                                                }}><i className="fas fa-sort-up" aria-hidden="true"></i></div>
                                                <div onClick={() => {
                                                    let order = item.order - 1;
                                                    this.change_order(item.id, order);
                                                }} className="show_hide" style={{
                                                    marginLeft: 10,
                                                    marginRight: 10
                                                }}><i className="fas fa-sort-down" aria-hidden="true"></i></div></div>
                                        </div>}
                                        {this.state.loading_banner_order[item.id] == true &&
                                            <div className="d-flex justify-content-center justify-content-lg-start align-items-center"><div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div></div>
                                        }
                                    </td>


                                    <td colSpan="1">
                                        <ReactTooltip />

                                        <Link data-tip="Editar Banner" to={'/conta/banners/editar_promocao/' + item.id}><span style={{ width: 40, height: 40, minWidth: 40, borderRadius: 10, marginRight: 10 }} className="btn btn-info"
                                            data-toggle="tooltip"
                                        ><i className="fas fa-edit"></i></span></Link>
                                        <button data-tip="Remover Banner" style={{ width: 40, height: 40, minWidth: 40, borderRadius: 10 }} onClick={() => {
                                            this.setState({ show_warning: true, user_to_delete: item.id });
                                        }}
                                            className="destroy_it btn btn-danger"><i
                                                className="fas fa-trash"></i></button>
                                    </td>
                                </tr>
                            ) :
                            <tr>
                                <th colSpan="4">
                                    <p className="pl-2 pt-1 pb-1"> Não há banners cadastrados no sistema </p>
                                </th>
                            </tr>
                        }
                    </tbody>}
                    {this.state.loading == true && <tbody><tr><td colSpan="4"><div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div></div></td></tr></tbody>}
                </table>




                <div className="row mb-2" style={{ marginTop: '6rem' }}>
                    <div className="col-12 col-md-6">
                        <h4>Banner Faixa Meio</h4>
                    </div>

                    {banner_faixa_meio.length == 0 && <div className="col-12 col-md-6  d-flex justify-content-end">
                        <Link to={"/conta/banners/adicionar_faixa_meio"}><span

                            className="btn btn-outline-primary btn-rounded" style={{ height: '45px' }}><span>Adicionar banner faixa</span><i className="icon-long-arrow-right"></i></span></Link>
                    </div>}
                </div>
                <table className="table table-cart table-mobile">
                    <thead>
                        <tr>
                            <th>Imagem</th>
                            <th>Titulo</th>
                            <th>Subtítulo</th>

                            <th>Ações</th>
                        </tr>
                    </thead>
                    {this.state.loading == false && <tbody>
                        {banner_faixa_meio.length > 0 ?
                            banner_faixa_meio.map((item, index) =>
                                <tr key={index}>
                                    <td colSpan="1">
                                        <div style={{ width: '100%' }} className="d-flex justify-content-center justify-content-lg-start">
                                            <img src={URL + item.image} style={{ width: 100, height: 50, objectFit: 'contain' }} />

                                        </div>
                                    </td>
                                    <td colSpan="1">
                                        <div className="product">


                                            <h3 className="product-title">
                                                {item.title}
                                            </h3>
                                        </div>
                                    </td>
                                    <td colSpan="1">
                                        <div className="product">


                                            <h3 className="product-title">
                                                {item.subtitle}
                                            </h3>
                                        </div>
                                    </td>


                                    <td colSpan="1">
                                        <ReactTooltip />

                                        <Link data-tip="Editar Banner" to={'/conta/banners/editar_faixa_meio/' + item.id}><span style={{ width: 40, height: 40, minWidth: 40, borderRadius: 10, marginRight: 10 }} className="btn btn-info"
                                            data-toggle="tooltip"
                                        ><i className="fas fa-edit"></i></span></Link>
                                        <button data-tip="Remover Banner" style={{ width: 40, height: 40, minWidth: 40, borderRadius: 10 }} onClick={() => {
                                            this.setState({ show_warning: true, user_to_delete: item.id });
                                        }}
                                            className="destroy_it btn btn-danger"><i
                                                className="fas fa-trash"></i></button>
                                    </td>
                                </tr>
                            ) :
                            <tr>
                                <th colSpan="4">
                                    <p className="pl-2 pt-1 pb-1"> Não há banners cadastrados no sistema </p>
                                </th>
                            </tr>
                        }
                    </tbody>}
                    {this.state.loading == true && <tbody><tr><td colSpan="4"><div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div></div></td></tr></tbody>}
                </table>



                <div className="row mb-2" style={{ marginTop: '6rem' }}>
                    <div className="col-12 col-md-6">
                        <h4>Banner Promoção Limitada</h4>
                    </div>

                    <div className="col-12 col-md-6  d-flex justify-content-end">
                        <Link to={"/conta/banners/adicionar_limitada"}><span

                            className="btn btn-outline-primary btn-rounded" style={{ height: '45px' }}><span>Adicionar banner promoção limitada</span><i className="icon-long-arrow-right"></i></span></Link>
                    </div>
                </div>
                <table className="table table-cart table-mobile">
                    <thead>
                        <tr>
                            <th>Imagem</th>
                            <th>Titulo</th>
                            <th>Ordem</th>
                            <th>Validade</th>


                            <th>Ações</th>
                        </tr>
                    </thead>
                    {this.state.loading == false && <tbody>
                        {banners_promocao_limitada.length > 0 ?
                            banners_promocao_limitada.map((item, index) =>
                                <tr key={index}>
                                    <td colSpan="1">
                                        <div style={{ width: '100%' }} className="d-flex justify-content-center justify-content-lg-start">
                                            <img src={URL + item.image} style={{ width: 100, height: 50, objectFit: 'contain' }} />

                                        </div>
                                    </td>
                                    <td colSpan="1">
                                        <div className="product">


                                            <h3 className="product-title">
                                                {item.title}
                                            </h3>
                                        </div>
                                    </td>
                                    <td colSpan="1">
                                        {this.state.loading_banner_order[item.id] != true && <div className="product d-flex align-items-center justify-content-center justify-content-lg-start" >


                                            <h3 className="product-title">
                                                {item.order}
                                            </h3>
                                            <div><div
                                                onClick={() => {
                                                    let order = item.order + 1;
                                                    this.change_order(item.id, order);
                                                }}
                                                className="show_hide" style={{
                                                    height: 15,
                                                    marginLeft: 10,
                                                    marginRight: 10
                                                }}><i className="fas fa-sort-up" aria-hidden="true"></i></div>
                                                <div onClick={() => {
                                                    let order = item.order - 1;
                                                    this.change_order(item.id, order);
                                                }} className="show_hide" style={{
                                                    marginLeft: 10,
                                                    marginRight: 10
                                                }}><i className="fas fa-sort-down" aria-hidden="true"></i></div></div>
                                        </div>}
                                        {this.state.loading_banner_order[item.id] == true &&
                                            <div className="d-flex justify-content-center justify-content-lg-start align-items-center"><div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div></div>
                                        }
                                    </td>
                                    <td colSpan="1">
                                        {moment(item.validade).format('DD/MM/YYYY HH:mm')}
                                    </td>

                                    <td colSpan="1">
                                        <ReactTooltip />

                                        <Link data-tip="Editar Banner" to={'/conta/banners/editar_limitada/' + item.id}><span style={{ width: 40, height: 40, minWidth: 40, borderRadius: 10, marginRight: 10 }} className="btn btn-info"
                                            data-toggle="tooltip"
                                        ><i className="fas fa-edit"></i></span></Link>
                                        <button data-tip="Remover Banner" style={{ width: 40, height: 40, minWidth: 40, borderRadius: 10 }} onClick={() => {
                                            this.setState({ show_warning: true, user_to_delete: item.id });
                                        }}
                                            className="destroy_it btn btn-danger"><i
                                                className="fas fa-trash"></i></button>
                                    </td>
                                </tr>
                            ) :
                            <tr>
                                <th colSpan="4">
                                    <p className="pl-2 pt-1 pb-1"> Não há banners cadastrados no sistema </p>
                                </th>
                            </tr>
                        }
                    </tbody>}
                    {this.state.loading == true && <tbody><tr><td colSpan="4"><div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div></div></td></tr></tbody>}
                </table>
                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                        this.delete_banner(this.state.user_to_delete);
                    }}
                    onCancel={() => {
                        this.setState({ show_warning: false });
                    }}
                    show={this.state.show_warning}
                    confirmBtnText='Sim, desejo apagar'
                    confirmBtnStyle={{ color: 'white' }}

                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    showCancel={true}
                >
                    ao deletar este banner ele não estará mais disponível no sistema

                </SweetAlert>
                <SweetAlert
                    danger
                    title={"Erro"}
                    onConfirm={() => {
                        this.setState({ show_erro: false });
                    }}
                    show={this.state.show_erro}
                    confirmBtnText='OK'
                    confirmBtnBsStyle="primary"
                    confirmBtnStyle={{ color: 'white' }}

                >
                    {this.state.msgErro}

                </SweetAlert>
            </div>
        )
    }
}

export const mapStateToProps = (state) => ({
    token: state.user.token
})

export default connect(mapStateToProps, { logout })(Banners);