import { CHANGE_HOME } from '../constants/action-types'
import { findIndex } from "../utils/utils";

export default function (state = {
    produtos_novos: [],
    produtos_categorias: [],
    produtos_recomendados: [],
    produtos_populares: { avaliados: [], oferta: [], vendidos: [] },
    produtos_tendencia: [],
    banners_topo: [],
    banners_promocao: [],
    banner_faixa_meio: [],
    banners_promocao_limitada: [],
    categorias: [],
    nossas_marcas: [],
    mobile: false
}, action) {
    switch (action.type) {

        case CHANGE_HOME:
            return { ...state, ...action.payload };
        case 'mobile':
            console.log('MObile')
            return { ...state, mobile:action.payload };

        default:
            return state;
    }
}