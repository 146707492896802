import React, { Component } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { Link, Redirect } from 'react-router-dom';

// import Custom Components
import { connect } from 'react-redux';
import Select from 'react-select'
import { URL } from '../../../../variables';
import { maskPrice } from '../../../../Auxiliar/Masks';
import { logout } from '../../../../actions';
import { SketchPicker, PhotoshopPicker } from 'react-color';
import ReactTooltip from 'react-tooltip';

class AdicionarBannerTopo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: '',
            subtitle: '',
            opacity:1,
            link: '',
            loading_save: false,
            msgErro: '',
            redirect: false,
            foto: '',
            foto_path: process.env.PUBLIC_URL + '/' + "assets/images/demos/demo-4/deal/bg-1.jpg",
            order: '',
            color_title: { hex: '#000' },
            color_subtitle: { hex: '#000' },
            color_link: { hex: '#19386e' },

            show_picker_title: false,
            show_picker_subtitle: false,
            show_picker_link: false,

        };
    }

    salvar() {
        this.setState({ loading_save: true, msgErro: '' });
        let form = new FormData();
        form.append('title', this.state.title);
        form.append('title_color', this.state.color_title.hex);
        form.append('subtitle_color', this.state.color_subtitle.hex);
        form.append('link_color', this.state.color_link.hex);
        form.append('opacity', this.state.opacity);

        form.append('subtitle', this.state.subtitle);
        form.append('ordem', this.state.order);

        form.append('link', this.state.link);

        form.append('foto', this.state.foto);

        fetch(`${URL}api/admin/banner_promocao/salvar_banner`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_save: false, msgErro: erro });
                    window.scrollTo({ top: 0, behavior: 'smooth' })

                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }

    componentDidMount() {
        console.log(this.props.token);
    }



    render() {

        return (
            <div>
                {this.state.redirect == true && <Redirect to={'/conta/banners'} />}

                <div style={{
                    color: '#721c24',
                    backgroundColor: '#f8d7da',
                    borderColor: '#f5c6cb'
                }} hidden={this.state.msgErro == ''} className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                    <button style={{ color: '#721c24', top: '55%', fontSize: '2rem' }} onClick={() => this.setState({ msgErro: '' })} type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>

                    <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                </div>
                <br />
                <label>Foto *</label>

                <input className="form-control" accept="image/*" placeholder="Modelo" type="file" onChange={(e) => {
                    let foto = e.target.files[0];
                    this.setState({ foto });
                    let foto_path = '';
                    let reader = new FileReader()
                    reader.readAsDataURL(e.target.files[0])
                    reader.onload = () => {
                        foto_path = reader.result;
                        this.setState({ foto_path });
                    };
                    reader.onerror = function (error) {
                        console.log('Error: ', error);
                    }
                    e.target.value = null;

                }} />
               

                <div className="row">
                    {this.state.foto_path != '' &&
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }} className="col-6 col-md-3">
                            <div>
                                <img src={this.state.foto_path} style={{ height: 100, width: 100, objectFit: 'contain' }} />
                                <a onClick={() => {

                                    this.setState({ foto: '', foto_path: '' });
                                }} className="btn btn-link btn-link-dark" style={{ color: 'indianred', cursor: 'pointer' }}><span>Remover</span><i className="fas fa-times"></i></a>
                            </div>
                        </div>

                    }
                </div>
                <br/>
                <label>Opacidade da imagem = {this.state.opacity}</label>
                <input value={this.state.opacity} onChange={(e)=>{
                    this.setState({opacity:e.target.value});
                }} id="typeinp" type="range" min="0" max="1" step="0.1" className="form-control" style={{paddingRight:0,paddingLeft:0}} />
                <hr />

                <label>Título</label>
                <ReactTooltip />

                <div className="d-flex" style={{ position: 'relative' }}>
                    <input value={this.state.title} onChange={(e) => this.setState({ title: e.target.value })} style={{ paddingRight: 40 }} type="text" placeholder="Título do banner" className="form-control" />

                    <button data-tip="Alterar cor" style={{ border: "1px solid #ebebeb", borderLeftWidth: 0, width: 40, height: 40, minWidth: 40, backgroundColor: '#f9f9f9', position: 'absolute', top: 0, bottom: 0, right: 0 }} onClick={() => {
                        this.setState({ show_picker_title: true });
                    }}
                        className="destroy_it btn btn-success" ><i
                            className="fas fa-palette" style={{ color: 'grey' }}></i></button>
                </div>

                {/* <small className="form-text">This will be how your name will be displayed in the account section and in reviews</small> */}
                <label>Subtítulo</label>
                <ReactTooltip />
                <div className="d-flex" style={{ position: 'relative' }}>
                    <input value={this.state.subtitle} onChange={(e) => this.setState({ subtitle: e.target.value })} style={{ paddingRight: 40 }} type="text" placeholder="Subtítulo do banner" className="form-control" />

                    <button data-tip="Alterar cor" style={{ border: "1px solid #ebebeb", borderLeftWidth: 0, width: 40, height: 40, minWidth: 40, backgroundColor: '#f9f9f9', position: 'absolute', top: 0, bottom: 0, right: 0 }} onClick={() => {
                        this.setState({ show_picker_subtitle: true });
                    }}
                        className="destroy_it btn btn-success" ><i
                            className="fas fa-palette" style={{ color: 'grey' }}></i></button>
                </div>



                <label>Link</label>
                <div className="d-flex" style={{ position: 'relative' }}>
                    <input value={this.state.link} onChange={(e) => this.setState({ link: e.target.value })} type="text" placeholder="Link da página que o banner levará" className="form-control" style={{ marginBottom: 0, paddingRight: 40 }} />

                    <button data-tip="Alterar cor" style={{ border: "1px solid #ebebeb", borderLeftWidth: 0, width: 40, height: 40, minWidth: 40, backgroundColor: '#f9f9f9', position: 'absolute', top: 0, bottom: 0, right: 0 }} onClick={() => {
                        this.setState({ show_picker_link: true });
                    }}
                        className="destroy_it btn btn-success" ><i
                            className="fas fa-palette" style={{ color: 'grey' }}></i></button>
                </div>
                <small >Ex: https://www.exemplo.com</small><br />

                <label>Ordem *</label>
                <input value={this.state.order} onChange={(e) => this.setState({ order: e.target.value })} type="number" placeholder="Ordem do banner na home" className="form-control" />

                {this.state.show_picker_title == true && <div style={{ position: 'fixed', top: 100, right: 0, zIndex: 10000000 }}><PhotoshopPicker header="Escolha uma cor" onAccept={(color) => {

                    this.setState({ show_picker_title: false });
                }} onCancel={() => {
                    this.setState({ show_picker_title: false, });
                }} color={this.state.color_title} onChange={(color) => {
                    console.log(color);
                    this.setState({ color_title: { ...color } }, () => {
                    });

                }} /></div>}
                {this.state.show_picker_subtitle == true && <div style={{ position: 'fixed', top: 100, right: 0, zIndex: 10000000 }}><PhotoshopPicker header="Escolha uma cor" onAccept={(color) => {

                    this.setState({ show_picker_subtitle: false });
                }} onCancel={() => {
                    this.setState({ show_picker_subtitle: false, });
                }} color={this.state.color_subtitle} onChange={(color) => {
                    console.log(color);
                    this.setState({ color_subtitle: { ...color } }, () => {
                    });

                }} /></div>}
                {this.state.show_picker_link == true && <div style={{ position: 'fixed', top: 100, right: 0, zIndex: 10000000 }}><PhotoshopPicker header="Escolha uma cor" onAccept={(color) => {

                    this.setState({ show_picker_link: false });
                }} onCancel={() => {
                    this.setState({ show_picker_link: false, });
                }} color={this.state.color_link} onChange={(color) => {
                    console.log(color);
                    this.setState({ color_link: { ...color } }, () => {
                    });

                }} /></div>}
                <hr />
                <label style={{
                    color: 'black',
                    fontWeight: 500,
                    marginBottom: '10px'
                }}>Preview</label>

                <div className="banner banner-group banner-overlay banner-overlay-light" style={{ maxWidth: 376 }}>
                    <a onClick={(e) => {
                        if (this.state.link.length == 0) {
                            e.preventDefault();
                        }
                    }} href={this.state.link} target="_blank" style={{backgroundColor:'white'}}>
                        <img src={this.state.foto_path} alt="Banner" style={{ height: 160, maxWidth: 376, objectFit: 'cover',opacity:this.state.opacity }} />
                    </a>
                    <div className="banner-content" >
                        <h3 className="banner-title">
                            <a onClick={(e) => {
                                if (this.state.link.length == 0) {
                                    e.preventDefault();
                                }
                            }} target="_blank" href={this.state.link}>
                                <strong style={{ color: this.state.color_title.hex }}>{this.state.title.length == 0 ? 'Título' : this.state.title}</strong>
                            </a>
                        </h3>
                        <h4 className="banner-subtitle">
                            <a onClick={(e) => {
                                if (this.state.link.length == 0) {
                                    e.preventDefault();
                                }
                            }} target="_blank" href={this.state.link} style={{ color: this.state.color_subtitle.hex,fontWeight:400 }}>{this.state.subtitle.length == 0 ? 'Sub Título' : this.state.subtitle}</a>
                        </h4>
                        <a
                            style={{
                                display: 'inline-block',
                                fontWeight: 400,
                                lineHeight: 1.3,
                                padding: '.3rem 1rem',
                                borderRadius: '20px',
                                letterSpacing: '-.01em',
                                marginLeft: '-1rem',
                                textAlign: 'center',
                                minWidth: '110px',
                                color: this.state.color_link.hex,fontWeight:400
                            }}

                            target="_blank" href={this.state.link} onClick={(e) => {
                                if (this.state.link.length == 0) {
                                    e.preventDefault();
                                }
                            }}  >Mostrar mais<i style={{ marginLeft: 5 }} className="icon-long-arrow-right"></i></a>
                    </div>
                </div>
                {this.state.loading_save == false && <button onClick={() => {
                    this.salvar();
                }} style={{ marginTop: '1.3rem' }} type="button" className="btn btn-outline-primary btn-rounded">
                    <span>Salvar</span>
                    <i className="far fa-save"></i>
                </button>}
                {this.state.loading_save == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div></div>}

            </div>
        )
    }
}

export const mapStateToProps = (state) => ({
    token: state.user.token

})

export default connect(mapStateToProps, { logout })(AdicionarBannerTopo);