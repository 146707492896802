import React, { Component } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { Link, Redirect } from 'react-router-dom';

// import Custom Components
import { connect } from 'react-redux';
import Select from 'react-select'
import { URL } from '../../../../variables';
import { maskNumber, maskPrice } from '../../../../Auxiliar/Masks';
import { logout } from '../../../../actions';

class Produtos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            descricao: '',
            informacoes: '',
            estoque: 0,
            price: '',
            sale_price: '',

            comprimento:'',
            largura:'',
            altura:'',
            peso:'',
            
            marca_id: '',
            marcas: [],
            categorias: [],
            categorias_ids: [],
            tamanho: '',
            fotos: [],
            fotos_path: [],
            loading_save: false,
            msgErro:'',
            redirect:false

        };
    }

    salvar() {
        this.setState({ loading_save: true, msgErro: '' });
        let form = new FormData();
        form.append('name', this.state.name);
        form.append('descricao', this.state.descricao);
        form.append('informacoes', this.state.informacoes);
        form.append('estoque', this.state.estoque);
        form.append('tamanho', this.state.tamanho);

        form.append('peso', this.state.peso);
        form.append('altura', this.state.altura);
        form.append('comprimento', this.state.comprimento);
        form.append('largura', this.state.largura);

        form.append('marca_id', this.state.marca_id);
        // form.append('categorias_ids', this.state.categorias_ids);

        form.append('price', this.state.price.replace(/ /g, '').replace(',', '.').replace('R$', ''));
        form.append('sale_price', this.state.sale_price.replace(/ /g, '').replace(',', '.').replace('R$', ''));

        for (let i = 0; i < this.state.fotos.length; i++) {

            form.append('fotos[]', this.state.fotos[i]);
        }
        console.log(this.state.categorias_ids)
        for (let i = 0; i < this.state.categorias_ids.length; i++) {

            form.append('categorias_ids[]', this.state.categorias_ids[i]);
        }

        fetch(`${URL}api/admin/salvar_produtos`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_save: false, msgErro: erro });
                    window.scrollTo({top:0,behavior: 'smooth'})

                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }

    componentDidMount() {
        console.log(this.props.token);
        this.get_categorias();
    }

    get_categorias() {
        this.setState({ loading: true });

        fetch(`${URL}api/admin/get_categorias`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                }
                else {
                    let categorias = [];
                    let marcas = [];

                    for (let i = 0; i < resp.categorias.length; i++) {
                        categorias.push({ value: resp.categorias[i].id, label: resp.categorias[i].name });
                    }

                    for (let i = 0; i < resp.marcas.length; i++) {
                        marcas.push({ value: resp.marcas[i].id, label: resp.marcas[i].name });
                    }
                    this.setState({
                        categorias: categorias,
                        marcas: marcas,
                        loading: false
                    });

                }




            } catch (err) {
                console.log(err);
                this.setState({
                    loading: false
                });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({
                    loading: false
                });
            });
    }

    render() {

        return (
            <div>
                {this.state.redirect == true && <Redirect to={'/conta/produtos'} />}

                <div style={{
                    color: '#721c24',
                    backgroundColor: '#f8d7da',
                    borderColor: '#f5c6cb'
                }} hidden={this.state.msgErro == ''} className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                    <button style={{ color: '#721c24', top: '55%', fontSize: '2rem' }} onClick={() => this.setState({ msgErro: '' })} type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>

                    <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                </div>
                <br/>
                <label>Foto(s) *</label>

                <input className="form-control" accept="image/*" multiple placeholder="Modelo" type="file" onChange={(e) => {
                    let fotos = this.state.fotos;
                    fotos.push(...e.target.files);
                    this.setState({ fotos });
                    let fotos_path = this.state.fotos_path;
                    for (let i = 0; i < e.target.files.length; i++) {
                        let reader = new FileReader()
                        reader.readAsDataURL(e.target.files[i])
                        reader.onload = () => {
                            fotos_path.push(reader.result);
                            this.setState({ fotos_path });
                        };
                        reader.onerror = function (error) {
                            console.log('Error: ', error);
                        }
                    }
                    e.target.value=null;

                }} />
                <div className="row">
                    {this.state.fotos_path.map((item, id) => (
                        <div key={id} style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }} className="col-6 col-md-3">
                            <div>
                                <img src={item} style={{ height: 100, width: 100 }} />
                                <a onClick={() => {
                                    let fotos = this.state.fotos.filter((item2, id2) => {
                                        if (id2 == id) {
                                            return false;
                                        }
                                        return true;
                                    });
                                    let fotos_path = this.state.fotos_path.filter((item2, id2) => {
                                        if (id2 == id) {
                                            return false;
                                        }
                                        return true;
                                    });
                                    this.setState({ fotos, fotos_path });
                                }} className="btn btn-link btn-link-dark" style={{ color: 'indianred', cursor: 'pointer' }}><span>Remover</span><i className="fas fa-times"></i></a>
                            </div>
                        </div>

                    ))}
                </div>
                <hr />
                <label>Nome *</label>
                <input value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} type="text" placeholder="Nome do produto" className="form-control" required />
                {/* <small className="form-text">This will be how your name will be displayed in the account section and in reviews</small> */}

                <label>Descrição *</label>
                <textarea placeholder="Descrição do produto" value={this.state.descricao} onChange={(e) => this.setState({ descricao: e.target.value })} className="form-control" required></textarea>

                <label>Informações *</label>
                <textarea placeholder={'Informações do produto'} value={this.state.informacoes} onChange={(e) => this.setState({ informacoes: e.target.value })} className="form-control" required></textarea>

                <label>Estoque *</label>
                <input type="number" min={0} value={this.state.estoque} onChange={(e) => this.setState({ estoque: e.target.value })} placeholder="Número em Estoque" className="form-control" required />

                <label>Preço *</label>
                <input value={this.state.price} onChange={(e) => {
                    console.log(e.target.value);
                    this.setState({ price: maskPrice(e.target.value)});
                }} type="text" placeholder="Preço do produto" className="form-control" required />

                <label>Preço em promoção</label>
                <input value={this.state.sale_price} onChange={(e) => this.setState({ sale_price: maskPrice(e.target.value) })} type="text" placeholder="Preço o produto em promoção" className="form-control" required />
                <small className="form-text">Preencher este campo apenas se o produto estiver em promoção</small>

                <label>Comprimento(cm) *</label>
                <input value={this.state.comprimento} onChange={(e) => this.setState({ comprimento: maskNumber(e.target.value) })} type="text" placeholder="Comprimento do produto com sua embalagem" className="form-control" required />
                
                <label>Altura(cm) *</label>
                <input value={this.state.altura} onChange={(e) => this.setState({ altura: maskNumber(e.target.value) })} type="text" placeholder="Altura do produto com sua embalagem" className="form-control" required />
                
                <label>Largura(cm) *</label>
                <input value={this.state.largura} onChange={(e) => this.setState({ largura: maskNumber(e.target.value) })} type="text" placeholder="Largura do produto com sua embalagem" className="form-control" required />
                
                <label>Peso(Kg) *</label>
                <input value={this.state.peso} onChange={(e) => this.setState({ peso: maskNumber(e.target.value) })} type="text" placeholder="Peso do produto com sua embalagem" className="form-control" required />
                

                <label>Tamanho</label>
                <input value={this.state.tamanho} onChange={(e) => this.setState({ tamanho: e.target.value })} type="text" placeholder="Tamanho do produto. Ex: 100ml, P, M, G" className="form-control" required />

                <label>Selecione a marca do produto *</label>

                {/* <select value={this.state.marca_id} className="form-control" id="cameras" onChange={(e)=>{

                }}>
                    {this.state.marcas.map((item, id) => (
                        <option key={id} value={item.id}>{item.name}</option>
                    ))}
                </select> */}

                <Select onChange={(e) => {
                    console.log(e);
                    this.setState({ marca_id: e.value });
                }} placeholder={'Selecione a marca do produto'} noOptionsMessage={() => 'Nenhuma marca disponível'} options={this.state.marcas} />


                <label style={{ marginTop: '1.3rem' }}>Selecione uma ou mais categorias do produto *</label>

                <Select onChange={(e) => {
                    console.log(e);
                    let categorias_ids = [];
                    for (let i = 0; i < e.length; i++) {
                        categorias_ids.push(e[i].value);
                    }
                    this.setState({ categorias_ids });
                }} placeholder={'Selecione uma ou mais categorias'} noOptionsMessage={() => 'Nenhuma categoria disponível'} options={this.state.categorias} isMulti />

                {this.state.loading_save == false && <button onClick={() => {
                    this.salvar();
                }} style={{ marginTop: '1.3rem' }} type="button" className="btn btn-outline-primary btn-rounded">
                    <span>Salvar</span>
                    <i className="far fa-save"></i>
                </button>}
                {this.state.loading_save == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div></div>}

            </div>
        )
    }
}

export const mapStateToProps = (state) => ({
    token: state.user.token

})

export default connect(mapStateToProps, { logout })(Produtos);