import React, { Component } from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { connect } from 'react-redux';
import Modal from 'react-modal';

import { closeModal, login,changeState, replaceCart, replaceWishList } from '../../actions';
import { URL } from '../../variables';
import { Link } from 'react-router-dom';
import FacebookLogin from '../main/pages/FacebookLogin';
import GoogleLogin from '../main/pages/GoogleLogin';

const customStyles = {
    content: {
        // top: '50%',
        // transform: 'translateY(-50%)'
    },
    overlay: {
        backgroundColor: 'rgba(77,77,77,0.6)',
        zIndex: '10000',
        overflowY: 'scroll'
    }
};

Modal.setAppElement('#root');

class LoginModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email_login: '',
            senha_login: '',
            email_registrar: '',
            name: '',
            senha: '',
            confirmar_senha: '',
            termos: false,
            msg_erro_login: '',
            msg_erro_registrar: '',
            loading_login: false,
            loading_registrar: false,
            show_success_register: false,
            show_success_login: false,
            data_fb: {}

        }
        this.closeModal = this.closeModal.bind(this);
    }


    login() {
        this.setState({ loading_login: true, msg_erro_login: '' });
        fetch(`${URL}api/auth/login`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: this.state.email_login,
                password: this.state.senha_login,
                cartlist:this.props.cartlist,
                wishlist:this.props.wishlist,
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_login: false, msg_erro_login: erro, show_success_login: false });
                }
                else {
                    this.setState({ loading_login: false, show_success_login: true });
                    localStorage.setItem('token', resp.access_token);
                    this.props.login({ token: resp.access_token, user: resp.user });
                    this.props.replaceCart(resp.cartlist);
                    this.props.replaceWishList(resp.wishlist);
                    this.closeModal();

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_login: false, msg_erro_login: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_login: false, msg_erro_login: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }

    registrar() {
        this.setState({ loading_registrar: true, msg_erro_registrar: '' });
        fetch(`${URL}api/auth/register`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: this.state.name,
                email: this.state.email_registrar,
                password: this.state.senha,
                password_confirmation: this.state.confirmar_senha,
            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_registrar: false, msg_erro_registrar: erro, show_success_register: false });
                }
                else {
                    this.setState({ loading_registrar: false, show_success_register: true,name:'',email_registrar:'',senha:'',confirmar_senha:'' });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_registrar: false, msg_erro_registrar: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_registrar: false, msg_erro_registrar: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }

    closeModal() {
        if (document.getElementById("login-modal") != null) {
            document.getElementById("login-modal").classList.remove("ReactModal__Content--after-open");

           
        }
        this.timer = setTimeout(() => {
            this.props.closeModal('login');
        }, 200);
    }

    login_fb(data) {
        data.cartlist=this.props.cartlist;
        data.wishlist=this.props.wishlist;

        console.log(data);
        this.setState({ loading_login: true, msg_erro_login: '', data_db: data });
        fetch(`${URL}api/auth/login_fb`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_login: false, msg_erro_login: erro, show_success_login: false });
                }
                else {

                    this.setState({ loading_login: false, show_success_login: true });
                    localStorage.setItem('token', resp.access_token);
                    this.props.login({ token: resp.access_token, user: resp.user });
                    this.props.replaceCart(resp.cartlist);
                    this.props.replaceWishList(resp.wishlist);
                    this.closeModal();

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_login: false, msg_erro_login: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_login: false, msg_erro_login: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer);
    }

    render() {
        const { showModal, modal } = this.props;
        return (
            <Modal
                isOpen={showModal && 'login' === modal}
                onRequestClose={this.closeModal}
                style={customStyles}
                contentLabel="Login Modal"
                className="modal-dialog modal-dialog-centered"
                id="login-modal" >
                <div className="modal-content">
                    <div className="modal-body">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeModal}>
                            <span aria-hidden="true"><i className="icon-close"></i></span>
                        </button>
                        <div className="form-box">
                            <div className="form-tab">
                                <Tabs selectedTabClassName="show" defaultIndex={0}>
                                    <TabList className="nav nav-pills nav-fill">
                                        <Tab className="nav-item">
                                            <span className="nav-link">Entrar</span>
                                        </Tab>

                                        <Tab className="nav-item">
                                            <span className="nav-link">Cadastrar</span>
                                        </Tab>
                                    </TabList>

                                    <div className="tab-content">
                                        <TabPanel style={{ paddingTop: "2rem" }}>
                                            <form onSubmit={(e) => {
                                                e.preventDefault();
                                                this.login();
                                            }}>
                                                <div>
                                                    <div style={{
                                                        color: '#721c24',
                                                        backgroundColor: '#f8d7da',
                                                        borderColor: '#f5c6cb'
                                                    }} hidden={this.state.msg_erro_login == ''} className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                                        <button style={{ color: '#721c24', top: '55%', fontSize: '2rem' }} onClick={() => this.setState({ msg_erro_login: '' })} type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>

                                                        <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msg_erro_login}</p>

                                                    </div>

                                                    <br />
                                                    <div className="form-group">
                                                        <label htmlFor="singin-email-2">Endereço de E-mail *</label>
                                                        <input value={this.state.email_login} onChange={(e) => {
                                                            this.setState({ email_login: e.target.value });
                                                        }} type="text" className="form-control" id="singin-email-2" name="singin-email" required />
                                                    </div>

                                                    <div className="form-group">
                                                        <label htmlFor="singin-password-2">Senha *</label>
                                                        <input value={this.state.senha_login} onChange={(e) => {
                                                            this.setState({ senha_login: e.target.value });
                                                        }} type="password" className="form-control" id="singin-password-2" name="singin-password" required />
                                                    </div>

                                                    <div className="form-footer" style={{ display: 'block' }}>

                                                        <div className="col-12" style={{ padding: 0, marginBottom: '1rem', marginTop: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                                            {this.state.loading_login == false && <button type="submit" style={{ width: '100%' }} className="btn btn-outline-primary-2">
                                                                <span>Entrar</span>
                                                                <i className="icon-long-arrow-right"></i>
                                                            </button>}
                                                            {this.state.loading_login == true && <div className="spinner-border text-primary" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div>}
                                                        </div>

                                                        <Link to="/recuperar_senha" className="forgot-link">Esqueceu sua senha?</Link>

                                                    </div>
                                                    {this.state.loading_login == false && <div className="form-choice">
                                                        <p className="text-center">ou entre com</p>
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                <GoogleLogin login_fb={(data) => {
                                                                    console.log(data);
                                                                    let data2 = {
                                                                        email: data._profile.email,
                                                                        name: data._profile.name,
                                                                        id_fb: data._profile.id,
                                                                        imagem: data._profile.profilePicURL,
                                                                        provider:data.provider

                                                                    }
                                                                    this.login_fb(data2);
                                                                }} error_fb={(e) => {
                                                                    console.log(e);
                                                                    if(e=='SDK not loaded'){
                                                                        return;
                                                                    }
                                                                    if (e == 'User already connected' && this.state.data_fb.id != undefined) {
                                                                        this.login_fb(this.state.data_fb);
                                                                    }
                                                                    else {
                                                                        this.setState({ msg_erro_login: 'Falha ao entrar com a conta do google' })
                                                                    }
                                                                }} />
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <FacebookLogin login_fb={(data) => {
                                                                    console.log(data);
                                                                    let data2 = {
                                                                        email: data._profile.email,
                                                                        name: data._profile.name,
                                                                        id_fb: data._profile.id,
                                                                        imagem: data._profile.profilePicURL,
                                                                        provider:data.provider

                                                                    }
                                                                    this.login_fb(data2);
                                                                }} error_fb={(e) => {
                                                                    console.log(e);
                                                                    if(e=='SDK not loaded'){
                                                                        return;
                                                                    }
                                                                    if (e == 'User already connected' && this.state.data_fb.id != undefined) {
                                                                        this.login_fb(this.state.data_fb);
                                                                    }
                                                                    else {
                                                                        this.setState({ msg_erro_login: 'Falha ao entrar com a conta do facebook' })
                                                                    }
                                                                }} />

                                                            </div>
                                                        </div>
                                                    </div>}
                                                </div>
                                            </form>
                                        </TabPanel>

                                        <TabPanel>
                                            <form onSubmit={(e) => {
                                                e.preventDefault();
                                                this.registrar();
                                            }}>

                                                <div hidden={this.state.show_success_register == false} style={{
                                                    color: '#155724',
                                                    backgroundColor: '#d4edda',
                                                    borderColor: '#c3e6cb'
                                                }} className="alert alert-success alert-dismissible fade show" role="alert">
                                                    <strong>Sucesso!</strong> Cadastro realizado com sucesso.
                                                    <button onClick={() => {
                                                        this.setState({ show_success_register: false });
                                                    }} style={{ top: '55%', color: 'rgb(21, 87, 36)', fontSize: '2rem' }} type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div style={{
                                                    color: '#721c24',
                                                    backgroundColor: '#f8d7da',
                                                    borderColor: '#f5c6cb'
                                                }} hidden={this.state.msg_erro_registrar == ''} className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                                    <button style={{ color: '#721c24', top: '55%', fontSize: '2rem' }} onClick={() => this.setState({ msg_erro_registrar: '' })} type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>

                                                    <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msg_erro_registrar}</p>

                                                </div>

                                                <br />
                                                <div className="form-group">
                                                    <label htmlFor="register-email-2">Nome *</label>
                                                    <input value={this.state.name} onChange={(e) => {
                                                        this.setState({ name: e.target.value });
                                                    }} type="text" className="form-control" id="register-email-2" name="register-email" required />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="register-email-2">Seu endereço de E-mail *</label>
                                                    <input value={this.state.email_registrar} onChange={(e) => {
                                                        this.setState({ email_registrar: e.target.value });
                                                    }} type="email" className="form-control" id="register-email-2" name="register-email" required />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="register-password-2">Senha *</label>
                                                    <input value={this.state.senha} onChange={(e) => {
                                                        this.setState({ senha: e.target.value });
                                                    }} type="password" className="form-control" id="register-password-2" name="register-password" required />
                                                </div>

                                                <div className="form-group">
                                                    <label htmlFor="register-password-2">Confirmar Senha *</label>
                                                    <input value={this.state.confirmar_senha} onChange={(e) => {
                                                        this.setState({ confirmar_senha: e.target.value });
                                                    }} type="password" className="form-control" id="register-password-2" name="register-password" required />
                                                </div>

                                                <div className="form-footer" style={{ display: 'block' }}>

                                                    <div className="custom-control custom-checkbox">
                                                        <input value={this.state.termos} onChange={(e) => {
                                                            this.setState({ termos: e.target.checked });
                                                        }} type="checkbox" className="custom-control-input" id="register-policy-2" required />
                                                        <label className="custom-control-label" htmlFor="register-policy-2">Eu concordo com ás <a href="pages/login/#a">polítias de privacidade e termos de uso</a> *</label>
                                                    </div>
                                                    <div className="col-12" style={{ padding: 0, marginTop: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        {this.state.loading_registrar == false && <button style={{ width: '100%' }} type="submit" className="btn btn-outline-primary-2">
                                                            <span>Cadastrar</span>
                                                            <i className="icon-long-arrow-right"></i>
                                                        </button>}
                                                        {this.state.loading_registrar == true && <div className="spinner-border text-primary" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>}
                                                    </div>



                                                </div>
                                                {/* <div className="form-choice">
                                                    <p className="text-center">ou entre com</p>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <a href="pages/login/#a" className="btn btn-login btn-g">
                                                                <i className="icon-google"></i>
                                                            Entrar com Google
                                                        </a>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <a href="pages/login/#a" className="btn btn-login  btn-f">
                                                                <i className="icon-facebook-f"></i>
                                                            Entrar com Facebook
                                                        </a>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </form>

                                        </TabPanel>
                                    </div>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

export const mapStateToProps = (state) => ({
    showModal: state.demo.showModal,
    modal: state.demo.modal,
    wishlist: state.wishlist.list,
    cartlist: state.cartlist.cart,

})

export default connect(mapStateToProps, { closeModal, login,changeState, replaceCart, replaceWishList })(LoginModal);