import { ADD_TO_CART, REMOVE_FROM_CART, CHANGE_QTY, CHANGE_SHIPPING, CHANGE_STATE, REPLACE_CART } from "../constants/action-types";
import { findIndex } from "../utils/utils";

export default function cartReducer(state = {
    cart: [],
    endereco: {},
    precos_prazos: [],
    volumes: [],

    endereco_cobranca: {},
    forma_pagamento: { type: '', object: {},installment:1  },
    shipping: { delivery_method_id: '', final_shipping_cost: 0 }

}, action) {
    switch (action.type) {
        case ADD_TO_CART:
            const productId = action.product.id;

            if (findIndex(state.cart, product => product.id === productId) !== -1) {
                const cart = state.cart.reduce((cartAcc, product) => {
                    if(parseInt(product.qty) + parseInt(action.qty) > product.estoque){
                        return cartAcc;
                    }
                    if (product.id === productId) {
                        cartAcc.push({ ...product, qty: parseInt(product.qty) + parseInt(action.qty), sum: (product.sale_price ? product.sale_price : product.price) * (parseInt(product.qty) + parseInt(action.qty)) }) // Increment qty
                    } else {
                        cartAcc.push(product)
                    }
                    return cartAcc
                }, [])

                return { ...state, cart, shipping: { delivery_method_id: '', final_shipping_cost: 0 }, endereco: {}, forma_pagamento: { type: '', object: {},installment:1  }, volumes: [] }
            }
            return {
                ...state,
                cart: [
                    ...state.cart,
                    {
                        ...action.product,
                        qty: action.qty,
                        sum: (action.product.sale_price ? action.product.sale_price : action.product.price) * action.qty
                    }
                ]
                , shipping: { delivery_method_id: '', final_shipping_cost: 0 }, endereco: {}, forma_pagamento: { type: '', object: {},installment:1  }, volumes: []
            }

        case REMOVE_FROM_CART:
            return {
                ...state,
                cart: state.cart.filter(item => item.id !== action.productId)
                , shipping: { delivery_method_id: '', final_shipping_cost: 0 }, endereco: {}, forma_pagamento: { type: '', object: {},installment:1  }, volumes: []
            };

        case CHANGE_QTY:
            const cart = state.cart.reduce((cartAcc, product) => {
                if (product.id === action.productId) {
                    cartAcc.push({ ...product, qty: action.qty, sum: (product.sale_price ? product.sale_price : product.price) * action.qty }) // Increment qty
                } else {
                    cartAcc.push(product)
                }
                return cartAcc
            }, [])

            return { ...state, cart, shipping: { delivery_method_id: '', final_shipping_cost: 0 }, endereco: {}, forma_pagamento: { type: '', object: {},installment:1  }, volumes: [] };

        case CHANGE_SHIPPING:
            return { ...state, shipping: action.shipping };
        case CHANGE_STATE:
            return { ...state, ...action.payload };
        case REPLACE_CART:
            let list = action.payload;
            for(let i=0;i<list.length;i++){
                list[i].sum=(list[i].sale_price ? list[i].sale_price : list[i].price) * list[i].qty;
            }
            return {
                ...state,
                cart: action.payload
            }
        default:
            return state;
    }
}
