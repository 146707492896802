import React, { Component } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { Link, Redirect } from 'react-router-dom';

// import Custom Components
import { connect } from 'react-redux';
import Select from 'react-select'
import { URL } from '../../../../variables';
import { maskNumber, maskPrice } from '../../../../Auxiliar/Masks';
import { logout } from '../../../../actions';

class AdicionarEmbalagem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nome: '',
            loading_save: false,
            msgErro:'',
            redirect:false,
            comprimento:'',
            largura:'',
            altura:'',
            peso:'',
            estoque:'',

        };
    }

    salvar() {
        this.setState({ loading_save: true, msgErro: '' });
        let form = new FormData();
        form.append('nome', this.state.nome);
        form.append('comprimento', this.state.comprimento);
        form.append('largura', this.state.largura);
        form.append('altura', this.state.altura);
        form.append('peso', this.state.peso);
        form.append('estoque', this.state.estoque);



        fetch(`${URL}api/admin/salvar_embalagem`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_save: false, msgErro: erro });
                    window.scrollTo({top:0,behavior: 'smooth'})

                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }

    componentDidMount() {
        console.log(this.props.token);
    }

   

    render() {

        return (
            <div>
                {this.state.redirect == true && <Redirect to={'/conta/embalagens'} />}

                <div style={{
                    color: '#721c24',
                    backgroundColor: '#f8d7da',
                    borderColor: '#f5c6cb'
                }} hidden={this.state.msgErro == ''} className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                    <button style={{ color: '#721c24', top: '55%', fontSize: '2rem' }} onClick={() => this.setState({ msgErro: '' })} type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>

                    <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                </div>
                <br/>
               
                <label>Nome *</label>
                <input value={this.state.nome} onChange={(e) => this.setState({ nome: e.target.value })} type="text" placeholder="Nome da embalagem (Ex: Caixa Grande)" className="form-control" required />
                {/* <small className="form-text">This will be how your name will be displayed in the account section and in reviews</small> */}
                <label>Comprimento(cm) *</label>
                <input value={this.state.comprimento} onChange={(e) => this.setState({ comprimento: maskNumber(e.target.value) })} type="text" placeholder="Comprimento dda embalagem" className="form-control" required />
                
                <label>Altura(cm) *</label>
                <input value={this.state.altura} onChange={(e) => this.setState({ altura: maskNumber(e.target.value) })} type="text" placeholder="Altura da embalagem" className="form-control" required />
                
                <label>Largura(cm) *</label>
                <input value={this.state.largura} onChange={(e) => this.setState({ largura:maskNumber(e.target.value) })} type="text" placeholder="Largura da embalagem" className="form-control" required />
                
                
                <label>Peso(Kg) *</label>
                <input value={this.state.peso} onChange={(e) => this.setState({ peso: maskNumber(e.target.value) })} type="text" placeholder="Peso da embalagem" className="form-control" required />
                
                <label>Estoque *</label>
                <input value={this.state.estoque} onChange={(e) => this.setState({ estoque: maskNumber(e.target.value) })} type="text" placeholder="Estoque" className="form-control" required />
                

                
                {this.state.loading_save == false && <button onClick={() => {
                    this.salvar();
                }} style={{ marginTop: '1.3rem' }} type="button" className="btn btn-outline-primary btn-rounded">
                    <span>Salvar</span>
                    <i className="far fa-save"></i>
                </button>}
                {this.state.loading_save == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div></div>}

            </div>
        )
    }
}

export const mapStateToProps = (state) => ({
    token: state.user.token

})

export default connect(mapStateToProps, { logout })(AdicionarEmbalagem);