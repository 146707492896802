import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
// import Custom Components
import PageHeader from '../../common/page-header';
import Breadcrumb from '../../common/breadcrumb';
import Accordion from '../../features/accordion/accordion';
import Card from '../../features/accordion/card';

import { getCartTotal } from '../../../services';
import { URL } from '../../../variables';
import { maskCep, maskCartaoCredito, maskValidadeCartao, maskNumber, maskCpfCnpj, maskPriceDecimal } from '../../../Auxiliar/Masks';
import { logout, changeState } from '../../../actions/index';
import creditCardType from 'credit-card-type';

import { isIEBrowser, isSafariBrowser, isEdgeBrowser } from '../../../utils/utils';
import amex from '../../../imgs/cartoes/amex.png';
import elo from '../../../imgs/cartoes/elo.png';
import hypercard from '../../../imgs/cartoes/hypercard.png';
import mastercard from '../../../imgs/cartoes/mastercard.png';
import visa from '../../../imgs/cartoes/visa.png';

import jcb from '../../../imgs/cartoes/jcb.png';
import aura from '../../../imgs/cartoes/aura.png';
import diners_club from '../../../imgs/cartoes/diners_club.png';
import discover from '../../../imgs/cartoes/discover.png';

import moment from 'moment';
import Modal from 'react-modal';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import Stepper from 'react-stepper-horizontal';

const customStyles = {
    content: {
        // top: '50%',
        // transform: 'translateY(-50%)'
    },
    overlay: {
        backgroundColor: 'rgba(51,51,51,0.6)',
        zIndex: '10000',
        // overflowY: 'scroll'
    }
};
class ResumoPedido extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect_cart: false,
            loading_save: false,
            msgErro: '',
            redirect: false,
            loading: true,
            cartoes: [],
            cartao: {},
            cep: '',
            rua: '',
            numero: '',
            bairro: '',
            cidade: '',
            estado: '',
            latitude: '',
            longitude: '',
            complemento: '',
            enderecos: [],
            modal_open: false,
            msgErroPedido: '',
            redirect_finish: false

        }
    }
    componentDidMount() {
        // let item = document.querySelector("#checkout-discount-input");

        // item.addEventListener("focus", function (e) {
        //     e.currentTarget.parentNode.querySelector("label").setAttribute("style", "opacity: 0");
        // });

        // item.addEventListener("blur", function (e) {
        //     let $this = e.currentTarget;
        //     if ($this.value.length !== 0) {
        //         $this.parentNode.querySelector("label").setAttribute("style", "opacity: 0");
        //     } else {
        //         $this.parentNode.querySelector("label").setAttribute("style", "opacity: 1");
        //     }
        // });

        if (this.props.cartlist.length == 0 || this.props.token == undefined || this.props.forma_pagamento.type == '' || this.props.endereco.id == undefined || this.props.shipping.delivery_method_id == undefined || this.props.precos_prazos.length == 0) {
            this.setState({ redirect_cart: true });
        }
        else {
            if (this.props.endereco_cobranca == undefined || this.props.endereco_cobranca.id == undefined) {
                this.props.changeState({ endereco_cobranca: this.props.endereco });
            }
            this.get_enderecos();

        }
    }

    get_lat_lng() {
        let address = `${this.state.rua},+${this.state.numero},+${this.state.bairro},+${this.state.cidade},+${this.state.estado},+Brasil`
        fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyAyaSKLxkfqUOco6ZoPx08OxUKUnaNRjnE&components=country=Brazil|region=BR`, {
            method: 'GET',
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.results.length > 0) {
                    this.setState({ latitude: resp.results[0].geometry.location.lat, longitude: resp.results[0].geometry.location.lng })
                }



            } catch (err) {
                console.log(err);
            }

        })
            .catch((err) => {
                console.log(err);
            });
    }

    get_enderecos() {
        this.setState({ loading: true });

        fetch(URL + `api/carrinho/get_enderecos`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                // 'plugin-version':'v2.0.0',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                }
                else {

                    this.setState({ enderecos: resp.enderecos, loading: false })

                    // this.setState({
                    //     loading: false,
                    //     correio_fretes: resp.correio_fretes,
                    // });

                }




            } catch (err) {
                console.log(err);
                this.setState({
                    loading: false
                });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({
                    loading: false
                });
            });
    }

    buscar_endereco(cep) {
        console.log(cep);
        fetch(`https://viacep.com.br/ws/${cep}/json/`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                this.setState({
                    rua: resp.logradouro,
                    bairro: resp.bairro,
                    cidade: resp.localidade,
                    estado: resp.uf,
                    numero: ''

                }, () => {
                    this.get_lat_lng();
                });

                // await AsyncStorage.setItem('token', resp.access_token)
                // await AsyncStorage.setItem('user', JSON.stringify(resp.user))




            } catch (err) {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Algo deu errado. Seu CEP está correto?' });
            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading: false, msgErro: `Erro ao pegar resposta do servidor. Você está conectado a internet?` });
            });


    }

    componentDidUpdate(props){
        if(this.props.cartlist.length!=props.cartlist.length){
            this.setState({redirect_cart:true});
        }
    }

    salvar() {
        this.setState({ loading_save: true, msgErro: '' });
        let form = new FormData();
        form.append('cep', this.state.cep);

        form.append('rua', this.state.rua);
        form.append('numero', this.state.numero);
        form.append('bairro', this.state.bairro);
        form.append('cidade', this.state.cidade);
        form.append('estado', this.state.estado);
        form.append('complemento', this.state.complemento);
        form.append('latitude', this.state.latitude);
        form.append('longitude', this.state.longitude);


        fetch(`${URL}api/salvar_endereco`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_save: false, msgErro: erro });
                    if (isIEBrowser() || isSafariBrowser() || isEdgeBrowser()) {
                        let pos = document.getElementById('modal_address').pageYOffset;
                        let timerId = setInterval(() => {
                            if (pos <= 0)
                                clearInterval(timerId);
                            document.getElementById('modal_address').scrollBy(0, -120);
                            pos -= 120;
                        }, 1);
                    } else {
                        document.getElementById('modal_address').scrollTo({
                            top: 0,
                            behavior: 'smooth'
                        })
                    }
                }
                else {

                    this.setState({
                        loading_save: false,

                        cep: '',
                        rua: '',
                        numero: '',
                        bairro: '',
                        cidade: '',
                        estado: '',
                        latitude: '',
                        longitude: '',
                        complemento: '',
                    }, () => {
                        if (isIEBrowser() || isSafariBrowser() || isEdgeBrowser()) {
                            let pos = document.getElementById('modal_address').pageYOffset;
                            let timerId = setInterval(() => {
                                if (pos <= 0)
                                    clearInterval(timerId);
                                document.getElementById('modal_address').scrollBy(0, -120);
                                pos -= 120;
                            }, 1);
                        } else {
                            document.getElementById('modal_address').scrollTo({
                                top: 0,
                                behavior: 'smooth'
                            })
                        }
                    });
                    this.get_enderecos();

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }

    gerar_pedido() {
        let data = {
            cart: this.props.cartlist,
            endereco: this.props.endereco,
            precos_prazos: this.props.precos_prazos,
            volumes: this.props.volumes,

            endereco_cobranca: this.props.endereco_cobranca,
            forma_pagamento: this.props.forma_pagamento,
            shipping: this.props.shipping
        }

        console.log(data);

        this.setState({ loading_save: true, msgErroPedido: '' });

        fetch(`${URL}api/carrinho/gerar_pedido`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-type': 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
            },
            body: JSON.stringify(data)
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_save: false, msgErroPedido: erro });
                    // if (isIEBrowser() || isSafariBrowser() || isEdgeBrowser()) {
                    //     let pos = document.getElementById('modal_address').pageYOffset;
                    //     let timerId = setInterval(() => {
                    //         if (pos <= 0)
                    //             clearInterval(timerId);
                    //         document.getElementById('modal_address').scrollBy(0, -120);
                    //         pos -= 120;
                    //     }, 1);
                    // } else {
                    //     document.getElementById('modal_address').scrollTo({
                    //         top: 0,
                    //         behavior: 'smooth'
                    //     })
                    // }
                }
                else {
                    this.props.changeState({ cart: [], shipping: { delivery_method_id: '', final_shipping_cost: 0 }, endereco: {}, forma_pagamento: { type: '', object: {},installment:1  }, volumes: [] });
                    this.setState({ loading_save: false, redirect_finish: true });
                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErroPedido: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErroPedido: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }


    render() {

        const { cartlist, total } = this.props;
        let endereco = this.props.endereco;
        let endereco_cobranca = this.props.endereco_cobranca;
        console.log('Endereço de Cobrança');

        console.log(endereco_cobranca);
        console.log(this.props.forma_pagamento);

        let shipping = this.props.shipping;

        let hour = moment().format('HH:mm');



        return (
            <div className="main">
                {this.state.redirect_cart == true && <Redirect to={'/shop/cart'} />}
                {this.state.redirect_finish == true && <Redirect to={'/conta/historico_compras?type=new'} />}

                <PageHeader background="url(assets/images/cart.jpg)" title="Resumo do Pedido" subTitle="Confirme seu Pedido" />
                <div className="d-block d-sm-none">

                    <Breadcrumb title="Resumo" parent1={["Carrinho", "shop/cart"]} parent2={["Endereço de Entrega", "shop/address"]} parent3={["Opção de Entrega", "shop/delivery"]} parent4={["Pagamento", "shop/payment"]} />
                </div>

                <div className="container d-none d-sm-block">

                    <div className={'breadcrumb-nav'}>
                        <Stepper defaultColor={'#999'} defaultTitleColor={'#555'} activeTitleColor={'#19386e'} titleFontSize={14}
                            activeColor={'#19386e'} completeColor={'#005b8c'} completeTitleColor={'#005b8c'}
                            steps={[{
                                title: 'Carrinho',
                                href: '/shop/cart',
                            },
                            {
                                title: 'Endereço',
                                href: '/shop/address'
                            },
                            {
                                title: 'Entrega',
                                href: '/shop/delivery'
                            },
                            {
                                title: 'Pagamento',
                                href: '/shop/payment'
                            },
                            {
                                title: 'Resumo'
                            },
                            ]} activeStep={4} />
                    </div>
                </div>
                <div className="page-content">
                    <div className="checkout">
                        <div className="container">
                            {/* <div className="checkout-discount">
                                <form action="#">
                                    <input type="text" className="form-control" required id="checkout-discount-input"/>
                                    <label htmlFor="checkout-discount-input" className="text-truncate">Have a coupon? <span>Click here to enter your code</span></label>
                                </form>
                            </div> */}


                            <form action="#">
                                <div className="row">
                                    <div className="col-lg-9">
                                        <h2 className="checkout-title" style={{ marginTop: 0 }}>Informações do Pedido:</h2>
                                        <div style={{
                                            color: '#721c24',
                                            backgroundColor: '#f8d7da',
                                            borderColor: '#f5c6cb'
                                        }} hidden={this.state.msgErroPedido == ''} className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                            <button style={{ color: '#721c24', top: '55%', fontSize: '2rem' }} onClick={() => this.setState({ msgErro: '' })} type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>

                                            <p style={{ marginBottom: 0, whiteSpace: 'pre-line' }}>{this.state.msgErroPedido}</p>

                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-12 col-md-6  mt-1" >
                                                <h2 className="checkout-title" style={{ marginTop: 0, fontSize: '1.4rem' }}>Enviar Para: <Link style={{ fontSize: '1.1rem', marginLeft: 20 }} to={`${process.env.PUBLIC_URL}/shop/address`}>Alterar</Link></h2>
                                                <h3 className="product-title" style={{ whiteSpace: 'pre-line', fontSize: '1.4rem', fontWeight: '400' }}>
                                                    <div style={{ marginBottom: '0.5rem' }}>{endereco.rua}, {endereco.numero}</div>

                                                    {endereco.complemento != null && endereco.complemento != '' && <div style={{ marginBottom: '0.5rem' }}>{endereco.complemento}</div>}

                                                    <div style={{ marginBottom: '0.5rem' }}>{endereco.bairro}, {endereco.cidade} - {endereco.estado}</div>
                                                    <div style={{ marginBottom: '0.5rem' }}>{endereco.cep}</div>
                                                </h3>
                                            </div>
                                            {endereco_cobranca != undefined && endereco_cobranca.id != undefined && <div className="col-12 col-md-6  mt-1" >
                                                <h2 className="checkout-title" style={{ marginTop: 0, fontSize: '1.4rem' }}>Endereço de Cobrança: <a onClick={(e) => { e.preventDefault(); this.setState({ modal_open: true }) }} href="#" style={{ fontSize: '1.1rem', marginLeft: 20 }} >Alterar</a></h2>
                                                <h3 className="product-title" style={{ whiteSpace: 'pre-line', fontSize: '1.4rem', fontWeight: '400' }}>
                                                    <div style={{ marginBottom: '0.5rem' }}>{endereco_cobranca.rua}, {endereco_cobranca.numero}</div>

                                                    {endereco_cobranca.complemento != null && endereco_cobranca.complemento != '' && <div style={{ marginBottom: '0.5rem' }}>{endereco_cobranca.complemento}</div>}

                                                    <div style={{ marginBottom: '0.5rem' }}>{endereco_cobranca.bairro}, {endereco_cobranca.cidade} - {endereco_cobranca.estado}</div>
                                                    <div style={{ marginBottom: '0.5rem' }}>{endereco_cobranca.cep}</div>
                                                </h3>
                                            </div>}
                                        </div>
                                        <div className="row mt-2">

                                            <div className="col-12 col-md-6  mt-1" hidden={this.props.precos_prazos.length == 0}>
                                                <h2 className="checkout-title" style={{ marginTop: 0, fontSize: '1.4rem' }}>Opção de entrega:</h2>

                                                {this.props.precos_prazos.map((item, id) => (<div key={id} hidden={this.props.precos_prazos.length == 0} className="d-flex">
                                                    <div>
                                                        <div className="custom-control custom-radio" style={{ marginTop: 0, marginRight: 20 }}>
                                                            <input type="radio"
                                                                id={"shipping" + id}
                                                                name="shipping"
                                                                className="custom-control-input"
                                                                onChange={(e) => {
                                                                    console.log(item);
                                                                    this.props.changeState({ shipping: item })

                                                                }}
                                                                defaultChecked={this.props.shipping.delivery_method_id == item.delivery_method_id}
                                                            />
                                                            <label className="custom-control-label" htmlFor={"shipping" + id}>{item.description}</label>
                                                        </div>
                                                    </div>
                                                    {item.retirada==true && <div><span style={{color:'green'}}>Grátis</span> - {hour<'08:00'?'Disponível para retirada hoje a partir das 08:00':((hour>='08:00' && hour<'21:00')? 'Disponível para retirada hoje':'Disponível para retirada amanhã a partir das 08:00')}</div>}
{item.retirada!=true && <div>{maskPriceDecimal(item.final_shipping_cost)} - {item.delivery_estimate_business_days} {item.delivery_estimate_business_days==1?'dia útil':'dias úteis'}</div>}
                                                </div>
                                                ))}
                                            </div>
                                            {this.props.forma_pagamento.type != '' && <div className="col-12 col-md-6  mt-1" >
                                                <h2 className="checkout-title" style={{ marginTop: 0, fontSize: '1.4rem' }}>Forma de Pagamento: <Link style={{ fontSize: '1.1rem', marginLeft: 20 }} to={`${process.env.PUBLIC_URL}/shop/payment`}>Alterar</Link></h2>
                                                <h3 className="product-title" style={{ whiteSpace: 'pre-line', fontSize: '1.4rem', fontWeight: '400' }}>
                                                    <div className="d-flex">

                                                        <div className="d-flex align-items-center">

                                                            <img src={visa} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: this.props.forma_pagamento.object.brand.toUpperCase().includes('VISA') ? 'block' : 'none' }} />
                                                            <img src={mastercard} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: this.props.forma_pagamento.object.brand.toUpperCase().includes('MASTER') ? 'block' : 'none' }} />
                                                            <img src={amex} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: this.props.forma_pagamento.object.brand.toUpperCase().includes('AME') ? 'block' : 'none' }} />
                                                            <img src={elo} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: this.props.forma_pagamento.object.brand.toUpperCase().includes('ELO') ? 'block' : 'none' }} />
                                                            <img src={hypercard} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: this.props.forma_pagamento.object.brand.toUpperCase().includes('HYPER') ? 'block' : 'none' }} />

                                                            <img src={diners_club} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: this.props.forma_pagamento.object.brand.toUpperCase().includes('DINERS') ? 'block' : 'none' }} />
                                                    <img src={discover} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: this.props.forma_pagamento.object.brand.toUpperCase().includes('DISCOVER') ? 'block' : 'none' }} />
                                                    <img src={jcb} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: this.props.forma_pagamento.object.brand.toUpperCase().includes('JCB') ? 'block' : 'none' }} />
                                                    <img src={aura} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: this.props.forma_pagamento.object.brand.toUpperCase().includes('AURA') ? 'block' : 'none' }} />
                                                        </div>
                                                        <div className="align-items-center" style={{ marginLeft: 0 }}>

                                                            <p style={{ color: 'black', marginBottom: '1rem' }}>{this.props.forma_pagamento.object.brand.toUpperCase()} <b style={{ marginLeft: 15 }}>(Crédito)</b></p>

                                                            <p style={{ margin: 0, color: 'black' }}> •••• {this.props.forma_pagamento.object.last_digits}</p>

                                                        </div>
                                                    </div>
                                                    <p style={{ margin: 0, color: 'black',marginTop:10 }}><span className="checkout-title" style={{ marginTop: 0, fontSize: '1.4rem',color:'#333' }}>Parcelas:</span> {this.props.forma_pagamento.installment+'x de '+ maskPriceDecimal(total/this.props.forma_pagamento.installment)}</p>


                                                </h3>
                                            </div>}
                                        </div>
                                        <hr />

                                        <h2 className="checkout-title" style={{ marginTop: 0 }}>Produtos:</h2>

                                        <div className="row">
                                            {/* <div className="col-12 col-lg-6" hidden={this.state.loading == true || this.state.precos_prazos.length == 0}>
                                                <h2 className="checkout-title" style={{ marginTop: 0 }}>Enviar Para:</h2>
                                                <h3 className="product-title" style={{ whiteSpace: 'pre-line', fontSize: '1.4rem', fontWeight: '400' }}>
                                                    <div style={{ marginBottom: '0.5rem' }}>{endereco.rua}, {endereco.numero}</div>

                                                    {endereco.complemento != null && endereco.complemento != '' && <div style={{ marginBottom: '0.5rem' }}>{endereco.complemento}</div>}

                                                    <div style={{ marginBottom: '0.5rem' }}>{endereco.bairro}, {endereco.cidade} - {endereco.estado}</div>
                                                    <div style={{ marginBottom: '0.5rem' }}>{endereco.cep}</div>
                                                </h3>
                                            </div> */}

                                            <div className="col-12 col-lg-12" >
                                                <table className="table table-cart table-mobile">
                                                    <thead>
                                                        <tr>
                                                            <th>Foto</th>
                                                            <th>Produto</th>
                                                            <th>Preço</th>
                                                            <th>Quantidade</th>
                                                            <th>Total</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {cartlist.length > 0 ?
                                                            cartlist.map((item, index) =>
                                                                <tr key={index}>
                                                                    <td className="product-col">
                                                                        <div className="product">

                                                                            <Link style={{ width: 100, height: 100 }} to={`${process.env.PUBLIC_URL}/product/default/${item.id}`}>
                                                                                <img style={{ width: 100, height: 100, objectFit: 'contain' }} src={URL + item.fotos[0].foto} alt="Product" />
                                                                            </Link>
                                                                        </div>

                                                                    </td>
                                                                    <td className="product-col">
                                                                        <div className="product">
                                                                            {/* <figure className="product-media">
                                                                    <Link to={ `${process.env.PUBLIC_URL}/product/default/${ item.id}` }>
                                                                        <img src={ URL + item.fotos[0].foto } alt="Product"/>
                                                                    </Link>
                                                                </figure> */}

                                                                            <h3 className="product-title">
                                                                                <Link to={`${process.env.PUBLIC_URL}/product/default/${item.id}`}>{item.name}</Link>
                                                                            </h3>
                                                                        </div>
                                                                    </td>

                                                                    <td className="price-col">
                                                                        {maskPriceDecimal(0 < item.sale_price ?

                                                                            item.sale_price :

                                                                            item.price

                                                                        )}
                                                                    </td>

                                                                    <td className="quantity-col">
                                                                        {item.qty}
                                                                    </td>

                                                                    <td className="total-col">
                                                                    {maskPriceDecimal(parseFloat(item.sale_price==null?item.price:item.sale_price)*parseInt(item.qty))}
                                                                    </td>


                                                                </tr>
                                                            ) :
                                                            <tr>
                                                                <th colSpan="4">
                                                                    <p className="pl-2 pt-1 pb-1"> Não há produtos no carrinho </p>
                                                                </th>
                                                            </tr>
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        {/* {this.state.loading == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div></div>} */}

                                    </div>

                                    <aside className="col-lg-3">
                                        <div className="summary">

                                            <h3 className="summary-title" style={{ marginBottom: 0 }}>Seu Pedido</h3>

                                            <table className="table table-summary">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th></th>
                                                    </tr>
                                                </thead>

                                                <tbody>


                                                    <tr className="summary-subtotal">
                                                        <td>Subtotal:</td>
                                                        <td>{maskPriceDecimal(total)}</td>
                                                    </tr>
                                                    {this.props.shipping.delivery_method_id != undefined && <tr className="summary-subtotal">
                                                        <td>{shipping.description}:</td>
                                                        <td>{shipping.final_shipping_cost==0?<span style={{color:'green'}}>Grátis</span>: maskPriceDecimal(shipping.final_shipping_cost)}</td>
                                                    </tr>}

                                                    <tr className="summary-total">
                                                        <td>Total:</td>
                                                        <td>{maskPriceDecimal(total + (this.props.shipping.delivery_method_id == undefined ? 0 : this.props.shipping.final_shipping_cost))}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            {this.state.loading_save == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div></div>}
                                            {this.state.loading_save == false && <button type="button" onClick={() => {
                                                let data = {
                                                    cart: this.props.cartlist,
                                                    endereco: this.props.endereco,
                                                    precos_prazos: this.props.precos_prazos,
                                                    volumes: this.props.volumes,
                                        
                                                    endereco_cobranca: this.props.endereco_cobranca,
                                                    forma_pagamento: this.props.forma_pagamento,
                                                    shipping: this.props.shipping
                                                }
                                                console.log(data)
                                                // return;
                                                this.gerar_pedido();
                                                // if (this.state.endereco_select.id != undefined) {
                                                //     this.props.changeState({ endereco: this.state.endereco_select });
                                                // }
                                                // if (this.state.shipping.delivery_method_id != undefined) {
                                                //     this.props.changeState({ shipping: this.state.shipping });
                                                // }
                                            }} hidden={this.props.token == undefined || this.props.forma_pagamento.type == '' || this.props.cartlist.length == 0 || this.props.shipping.delivery_method_id == undefined || this.state.loading == true || this.props.endereco.id == undefined || this.props.endereco_cobranca.id == undefined}
                                                className="btn btn-outline-primary-2 btn-order btn-block">

                                                <span className="btn-text">Finalizar Pedido</span>
                                                <span className="btn-hover-text">Finalizar Pedido</span>
                                            </button>}
                                        </div>
                                    </aside>
                                </div>
                            </form>
                        </div>
                    </div>
                </div >
                <Modal
                    isOpen={this.state.modal_open}
                    onRequestClose={() => this.setState({ modal_open: false })}
                    contentLabel="Example Modal"
                    style={customStyles}
                    id="modal_address"
                >
                    <div style={{ position: 'fixed', top: 10, right: 10, cursor: 'pointer' }} onClick={() => {
                        this.setState({ modal_open: false });
                    }}>
                        <i style={{ color: 'white', fontSize: '2.3rem' }} className="fas fa-times"></i>
                    </div>
                    <div hidden={this.state.enderecos.length == 0}>
                        <h2 className="checkout-title" style={{ marginTop: 0 }}>Selecione um Endereço de Cobrança</h2>

                        <div className="row">

                            {this.state.enderecos.map((item, id) => (
                                <div className="col-12 col-sm-6 col-lg-4 mt-1" key={id}>
                                    <div onClick={() => {
                                        this.props.changeState({ endereco_cobranca: item });
                                    }} className="card select_address" style={{
                                        border: this.props.endereco_cobranca.id == item.id ? '1px solid green' : '1px solid lightgrey',
                                        padding: '5px',
                                        borderRadius: 5,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        height: '100%',
                                        maxWidth: 260
                                    }}>
                                        <div className="card-body" style={{
                                            padding: 0, display: 'flex',
                                            justifyContent: 'center',
                                        }}>

                                            <h3 className="product-title" style={{ whiteSpace: 'pre-line', fontSize: '1.4rem', fontWeight: '400' }}>
                                                <span style={{ marginBottom: '0.5rem' }}>{item.rua.substring(0, 25)}{item.rua.length > 25 ? '.' : ''} {item.numero}</span>

                                                {item.complemento != null && item.complemento != '' && '\n'}
                                                {item.complemento != null && item.complemento != '' && <span style={{ marginBottom: '0.5rem' }}>{item.complemento.substring(0, 25)}{item.complemento.length > 25 ? '.' : ''}</span>}

                                                {'\n'}
                                                <span style={{ marginBottom: '0.5rem' }}>{item.bairro.substring(0, 25)}{item.bairro.length > 25 ? '.' : ''}{'\n'}</span>
                                                <span style={{ marginBottom: '0.5rem' }}>{item.cidade.substring(0, 25)}{item.cidade.length > 25 ? '.' : ''} - {item.estado}{'\n'}</span>
                                                <span style={{ marginBottom: '0.5rem' }}>{item.cep}</span>
                                            </h3>
                                        </div>
                                    </div>

                                </div>
                            ))}

                        </div>

                    </div>
                    {this.state.loading == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div></div>}
                    <hr />
                    <div >

                        <h2 className="checkout-title">Adicione Endereço de Cobrança</h2>
                        <div style={{
                            color: '#721c24',
                            backgroundColor: '#f8d7da',
                            borderColor: '#f5c6cb'
                        }} hidden={this.state.msgErro == ''} className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                            <button style={{ color: '#721c24', top: '55%', fontSize: '2rem' }} onClick={() => this.setState({ msgErro: '' })} type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>

                            <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                        </div>
                        <br />
                        <label>Cep *</label>
                        <input value={this.state.cep} onChange={(e) => {
                            this.setState({ cep: maskCep(e.target.value) });
                            let text = e.target.value.replace('-', '');
                            if (text.length == 8) {
                                this.buscar_endereco(text);
                            }
                        }} type="text" placeholder="Cep" className="form-control" required />

                        <label>Rua *</label>
                        <input value={this.state.rua} onChange={(e) => this.setState({ rua: e.target.value }, () => this.get_lat_lng())} type="text" placeholder="Rua" className="form-control" required />

                        <label>Número *</label>
                        <input value={this.state.numero} onChange={(e) => this.setState({ numero: e.target.value }, () => this.get_lat_lng())} type="text" placeholder="Número" className="form-control" required />

                        <label>Complemento</label>
                        <input value={this.state.complemento} onChange={(e) => this.setState({ complemento: e.target.value })} type="text" placeholder="Complemento" className="form-control" />

                        <label>Bairro *</label>
                        <input value={this.state.bairro} onChange={(e) => this.setState({ bairro: e.target.value }, () => this.get_lat_lng())} type="text" placeholder="Bairro" className="form-control" required />

                        <label>Cidade *</label>
                        <input value={this.state.cidade} onChange={(e) => this.setState({ cidade: e.target.value }, () => this.get_lat_lng())} type="text" placeholder="Cidade" className="form-control" required />

                        <label>Estado (Sigla) *</label>
                        <input value={this.state.estado} onChange={(e) => this.setState({ estado: e.target.value }, () => this.get_lat_lng())} type="text" placeholder="Estado (Sigla)" className="form-control" required />
                        <div style={{ height: 300, overflow: 'hidden' }}>

                            <Map
                                google={window.google}
                                style={{ height: 300 }}
                                center={{ lat: this.state.latitude, lng: this.state.longitude }}
                                className="map"
                                zoom={this.state.latitude == '' ? 0 : 14}>
                                {this.state.latitude != '' && <Marker
                                    title={'The marker`s title will appear as a tooltip.'}
                                    name={'SOMA'}
                                    position={{ lat: this.state.latitude, lng: this.state.longitude }} />}

                            </Map>
                        </div>




                        {this.state.loading_save == false && <button onClick={() => {
                            this.salvar();
                        }} style={{ marginTop: '1.3rem' }} type="button" className="btn btn-outline-primary btn-rounded">
                            <span>Salvar</span>
                            <i className="far fa-save"></i>
                        </button>}
                        {this.state.loading_save == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div></div>}
                    </div>

                </Modal>
            </div >
        )
    }
}

export const mapStateToProps = (state) => ({
    cartlist: state.cartlist.cart,
    total: getCartTotal(state.cartlist.cart),
    endereco: state.cartlist.endereco,
    endereco_cobranca: state.cartlist.endereco_cobranca,

    shipping: state.cartlist.shipping,
    forma_pagamento: state.cartlist.forma_pagamento,
    precos_prazos: state.cartlist.precos_prazos,
    volumes: state.cartlist.volumes,

    token: state.user.token,

})
export default connect(mapStateToProps, { logout, changeState })(GoogleApiWrapper({ apiKey: 'AIzaSyAyaSKLxkfqUOco6ZoPx08OxUKUnaNRjnE' })(ResumoPedido));

// export default connect(mapStateToProps, { logout, changeState })(ResumoPedido);