import React, { Component } from 'react';
import ReactDOM from 'react-dom'

import SocialButton from './SocialButton'
import { connect } from 'react-redux';

const handleSocialLogin = (user) => {
  console.log(user)
}

const handleSocialLoginFailure = (err) => {
  console.error(err)
}

class FacebookLogin extends Component {

  render() {
    return (
        <SocialButton
          provider='facebook'
          appId='405822060714047'
          autoLogin={false}

          onLoginSuccess={this.props.login_fb.bind(this)}
          onLoginFailure={this.props.error_fb.bind(this)}
        >
          Entrar com o Facebook
        </SocialButton>
    );
  }

}
export const mapStateToProps = (state) => ({
  showModal: state.demo.showModal,
  modal: state.demo.modal
})

export default connect(mapStateToProps, {  })(FacebookLogin);