// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export default reorder;

export const reorderQuoteMap = ({ quoteMap, source, destination }) => {
    // const current = [...quoteMap[source.droppableId]];
    // const next = [...quoteMap[destination.droppableId]];
    const current_embalagem = quoteMap.filter((item, id) => item.id == source.droppableId)[0];
    const next_embalagem = quoteMap.filter((item, id) => item.id == destination.droppableId)[0];

    // const current = current_embalagem.produtos;
    // const next = next_embalagem.produtos;



    const target = current_embalagem.produtos[source.index];

    // moving to same list
    if (source.droppableId === destination.droppableId) {
        const reordered = reorder(current_embalagem.produtos, source.index, destination.index);
        for (let i = 0; i < quoteMap.length; i++) {
            if (quoteMap[i].id == source.droppableId) {
                quoteMap[i].produtos = [...reordered];
                break;
            }
        }
        //   const result = {
        //     ...quoteMap,
        //     [source.droppableId]: reordered
        //   };
        return {
            quoteMap: quoteMap
        };
    }

    // moving to different list

    // remove from original
    current_embalagem.produtos.splice(source.index, 1);
    // insert into next
    next_embalagem.produtos.splice(destination.index, 0, target);

    for (let i = 0; i < quoteMap.length; i++) {
        if (quoteMap[i].id == source.droppableId) {
            quoteMap[i] = {...current_embalagem};
        }
        else if (quoteMap[i].id == destination.droppableId) {
            quoteMap[i] = {...next_embalagem};
        }
        quoteMap[i].produtos=[...quoteMap[i].produtos];
    }

    // const result = {
    //     ...quoteMap,
    //     [source.droppableId]: current,
    //     [destination.droppableId]: next
    // };

    return {
        quoteMap: quoteMap
    };
};
