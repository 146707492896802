import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { URL } from '../../../../../variables';

class CategoryMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            categorias: [],
            loading: true,
            hover_cats: {}
        }
    }

    componentDidMount() {
        this.get_categorias();
    }

    get_categorias() {
        this.setState({ loading: true });

        fetch(`${URL}api/get_dados_produtos`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                // 'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                }
                else {

                    this.setState({
                        categorias: resp.categorias,
                        loading: false
                    });

                }




            } catch (err) {
                console.log(err);
                this.setState({
                    loading: false
                });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({
                    loading: false
                });
            });
    }


    render() {
        const { type = 1 } = this.props;
        return (
            <div className="dropdown category-dropdown">
                <Link to={`${process.env.PUBLIC_URL}/shop/sidebar/list`} className="dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static" title="Busque por categoria">
                    Busque por categoria
                    {type === 3 ?
                        <i className="icon-angle-down"></i>
                        : ''
                    }
                </Link>

                {<div hidden={this.state.loading == true} className="dropdown-menu">
                    <nav className="side-nav" style={{ maxHeight: '70vh', overflow: 'scroll' }}>
                        {/* <li className="item-lead"><Link to="#">Daily offers</Link></li>
                            <li className="item-lead"><Link to="#">Gift Ideas</Link></li> */}
                        {this.state.categorias.map((item, id) => (
                            <ul key={id} className="menu-vertical sf-arrows"  >


                                <li style={{ cursor: 'pointer' }} onClick={() => {
                                    let hover_cats = { ...this.state.hover_cats };
                                    hover_cats[item.id] = !hover_cats[item.id];
                                    this.setState({ hover_cats })
                                }}><a style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}><div>{item.name}</div> <i style={{ textAlign: 'end' }} className={this.state.hover_cats[item.id] == true ? "fas fa-angle-up" : "fas fa-angle-down"}></i></a> </li>
                                {this.state.hover_cats[item.id] == true && item.categorias.length > 0 &&
                                    item.categorias.map((item2, id2) => (
                                        <li key={id2}>
                                            {item2.categorias.length != 0 && <li onClick={() => {
                                                let hover_cats = { ...this.state.hover_cats };
                                                hover_cats[item2.id] = !hover_cats[item2.id];
                                                this.setState({ hover_cats })
                                            }} style={{ cursor: 'pointer', paddingLeft: 0, borderLeft: 'none', borderRight: 'none' }} ><a style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}><div>{item2.name}</div> <i style={{ textAlign: 'end' }} className={this.state.hover_cats[item2.id] == true ? "fas fa-angle-up" : "fas fa-angle-down"}></i></a></li>}
                                            {item2.categorias.length == 0 && <li style={{ paddingLeft: 0, borderLeft: 'none', borderRight: 'none' }} ><Link to={"/shop/sidebar/list?cat_id=" + item.id + ',' + item2.id}>{item2.name}</Link></li>}


                                            {this.state.hover_cats[item2.id] == true && item2.categorias.map((item3, id3) => (
                                                <li key={id3} style={{ paddingLeft: 30, borderLeft: 'none', borderRight: 'none' }} ><Link to={"/shop/sidebar/list?cat_id=" + item.id + ',' + item2.id + ',' + item3.id}>{item3.name}</Link></li>

                                            ))}
                                        </li>

                                    ))
                                }
                            </ul>


                        ))}
                    </nav>
                </div>}

                {/* {this.state.loading==true && <div className="loading-overlay" style={{position:'relative'}}>
                        <div className="bounce-loader">
                            <div className="bounce1"></div>
                            <div className="bounce2"></div>
                            <div className="bounce3"></div>
                        </div>
                    </div>} */}
            </div>
        );
    }
}

export default CategoryMenu; 