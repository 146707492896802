import React, { Component } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { Link } from 'react-router-dom';

// import Custom Components
import { connect } from 'react-redux';
import { URL } from '../../../../variables';
import Pagination from '../../../features/pagination';
import SweetAlert from 'react-bootstrap-sweetalert';
import { logout } from '../../../../actions';
import ReactTooltip from 'react-tooltip';

class Categorias extends Component {

    constructor(props) {
        super(props);
        this.state = {
            categorias: [],
            current_page: 1,
            last_page: 1,
            total: 1,
            per_page: 1,
            page: 1,
            loading: false,
            show_warning: false,
            show_erro: false,
            msgErro: '',
            user_to_delete: ''
        };
    }

    delete_categoria(id) {

        fetch(`${URL}api/admin/categorias/delete_categoria/${id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ msgErro: erro, show_warning: false, show_erro: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({ show_warning: false });
                    this.get_categorias(this.state.page);
                    // this.setState({ users: resp.users, loading_screen: false, page: resp.pagination.current_page, total_pages: resp.pagination.last_page });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    get_categorias(page = 1) {
        this.setState({ loading: true });

        fetch(`${URL}api/admin/categorias/get_categorias?page=${page}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                }
                else {
                    if (page == this.state.page) {
                        console.log('updated');
                        this.setState({
                            categorias: resp.categorias,
                            loading: false,
                            current_page: resp.current_page,
                            last_page: resp.last_page,
                            total: resp.total,
                            per_page: resp.per_page
                        });
                    }
                }




            } catch (err) {
                console.log(err);

            }

        })
            .catch((err) => {
                console.log(err);
            });
    }

    componentDidMount() {
        this.get_categorias(this.state.page);
    }



    render() {
        let categorias = this.state.categorias;

        return (
            <div>

                {/* <div className="row mb-2">
                    <div className="col-12  d-flex justify-content-end">
                        <Link to={"/conta/categorias/adicionar"}><span

                            className="btn btn-outline-primary btn-rounded" style={{ height: '45px' }}><span>Adicionar categoria</span><i className="icon-long-arrow-right"></i></span></Link>
                    </div>
                </div> */}
                <div className="row mb-2" style={{ marginLeft: 0, marginRight: 0 }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: 20,marginTop:10 }}>
                        <div style={{ width: 30, height: 30, borderRadius: 5, background: 'green' }}>
                        </div>
                        <p style={{ color: 'black', fontWeight: '600', marginLeft: 5 }}>Categoria Nível 1</p>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: 20,marginTop:10 }}>
                        <div style={{ width: 30, height: 30, borderRadius: 5, background: 'darkblue' }}>
                        </div>
                        <p style={{ color: 'black', fontWeight: '600', marginLeft: 5 }}>Categoria Nível 2</p>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: 0 ,marginTop:10}}>
                        <div style={{ width: 30, height: 30, borderRadius: 5, background: 'chocolate' }}>
                        </div>
                        <p style={{ color: 'black', fontWeight: '600', marginLeft: 5 }}>Categoria Nível 3</p>
                    </div>

                </div>

                <table className="table table-cart table-mobile">
                    <thead>
                        <tr>
                            <th>Foto</th>
                            <th>Nome</th>
                            <th>Tipo</th>

                            <th>Número de produtos</th>

                            <th>Ações</th>
                        </tr>
                    </thead>
                    {this.state.loading == false && <tbody>
                        {categorias.length > 0 ?
                            categorias.map((teste, index) => {
                                let item = teste;
                                let array = [];
                                array.push(<tr key={index}>

                                    <td >
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                            <img src={URL + item.foto} style={{ width: 100, height: 50, objectFit: 'contain' }} />

                                        </div>
                                    </td>
                                    <td >
                                        <div className="product">


                                            <h3 className="product-title" >
                                                {item.name}
                                            </h3>
                                        </div>
                                    </td>
                                    <td >
                                        <div className="product">


                                            <h3 className="product-title" style={{ color: 'green', fontWeight: 'bold' }}>
                                                Categoria
                                            </h3>
                                        </div>
                                    </td>
                                    <td >
                                        <div className="product">


                                            <h3 className="product-title">
                                                {item.produtos}
                                            </h3>
                                        </div>
                                    </td>


                                    <td style={{ width: 100 }}>
                                        <ReactTooltip />

                                        <Link data-tip="Editar Categoria" to={'/conta/categorias/editar/' + item.id}><span style={{ width: 40, height: 40, minWidth: 40, borderRadius: 10, marginRight: 10 }} className="btn btn-info"
                                            data-toggle="tooltip"
                                        ><i className="fas fa-edit"></i></span></Link>
                                        {/* <button data-tip="Remover Categoria" style={{ width: 40, height: 40, minWidth: 40, borderRadius: 10 }} onClick={() => {
                                            this.setState({ show_warning: true, user_to_delete: item.id });
                                        }}
                                            className="destroy_it btn btn-danger"><i
                                                className="fas fa-trash"></i></button> */}
                                    </td>
                                </tr>)

                                item.categorias.map((item2, id2) => {
                                    let item = item2
                                    array.push(<tr key={parseInt(Math.random() * 100000)}>
                                        <td >
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                                <img src={URL + item.foto} style={{ width: 100, height: 50, objectFit: 'contain' }} />

                                            </div>
                                        </td>
                                        <td >
                                            <div className="product">


                                                <h3 className="product-title" >
                                                    {item.name}
                                                </h3>
                                            </div>
                                        </td>
                                        <td >
                                            <div className="product">


                                                <h3 className="product-title" style={{ color: 'darkblue', fontWeight: 'bold' }}>
                                                    SubCategoria {teste.name}
                                                </h3>
                                            </div>
                                        </td>
                                        <td >
                                            <div className="product">


                                                <h3 className="product-title">
                                                    {item.produtos}
                                                </h3>
                                            </div>
                                        </td>


                                        <td style={{ width: 100 }}>
                                            <ReactTooltip />

                                            <Link data-tip="Editar Categoria" to={'/conta/categorias/editar/' + item.id}><span style={{ width: 40, height: 40, minWidth: 40, borderRadius: 10, marginRight: 10 }} className="btn btn-info"
                                                data-toggle="tooltip"
                                            ><i className="fas fa-edit"></i></span></Link>
                                            {/* <button data-tip="Remover Categoria" style={{ width: 40, height: 40, minWidth: 40, borderRadius: 10 }} onClick={() => {
                                                this.setState({ show_warning: true, user_to_delete: item.id });
                                            }}
                                                className="destroy_it btn btn-danger"><i
                                                    className="fas fa-trash"></i></button> */}
                                        </td>
                                    </tr>)
                                    item.categorias.map((item3, id3) => {
                                        let item = item3;
                                        array.push(<tr key={parseInt(Math.random() * 100000)}>
                                            <td >
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                                    <img src={URL + item.foto} style={{ width: 100, height: 50, objectFit: 'contain' }} />

                                                </div>
                                            </td>
                                            <td >
                                                <div className="product">


                                                    <h3 className="product-title">
                                                        {item.name}
                                                    </h3>
                                                </div>
                                            </td>
                                            <td >
                                                <div className="product">


                                                    <h3 className="product-title" style={{ color: 'chocolate', fontWeight: 'bold' }}>
                                                        SubCategoria {item2.name}
                                                    </h3>
                                                </div>
                                            </td>
                                            <td >
                                                <div className="product">


                                                    <h3 className="product-title">
                                                        {item.produtos}
                                                    </h3>
                                                </div>
                                            </td>


                                            <td style={{ width: 100 }}>
                                                <ReactTooltip />

                                                <Link data-tip="Editar Categoria" to={'/conta/categorias/editar/' + item.id}><span style={{ width: 40, height: 40, minWidth: 40, borderRadius: 10, marginRight: 10 }} className="btn btn-info"
                                                    data-toggle="tooltip"
                                                ><i className="fas fa-edit"></i></span></Link>
                                                {/* <button data-tip="Remover Categoria" style={{ width: 40, height: 40, minWidth: 40, borderRadius: 10 }} onClick={() => {
                                                this.setState({ show_warning: true, user_to_delete: item.id });
                                            }}
                                                className="destroy_it btn btn-danger"><i
                                                    className="fas fa-trash"></i></button> */}
                                            </td>
                                        </tr>)
                                    })
                                })



                                return array
                            }) :
                            <tr>
                                <th colSpan="4">
                                    <p className="pl-2 pt-1 pb-1"> Não há categorias cadastradas no sistema </p>
                                </th>
                            </tr>
                        }
                    </tbody>}
                    {this.state.loading == true && <tbody><tr><td colSpan="4"><div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div></div></td></tr></tbody>}
                </table>
                <Pagination count={this.state.total} unit={this.state.per_page} onChange={(page) => {
                    page += 1;
                    this.setState({ page });
                    this.get_categorias(page);
                }} />
                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                        this.delete_categoria(this.state.user_to_delete);
                    }}
                    onCancel={() => {
                        this.setState({ show_warning: false });
                    }}
                    show={this.state.show_warning}
                    confirmBtnText='Sim, desejo apagar'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnStyle={{ color: 'white' }}

                    showCancel={true}
                >
                    ao deletar esta categoria ele não estará mais disponível no sistema

                </SweetAlert>
                <SweetAlert
                    danger
                    title={"Erro"}
                    onConfirm={() => {
                        this.setState({ show_erro: false });
                    }}
                    show={this.state.show_erro}
                    confirmBtnText='OK'
                    confirmBtnBsStyle="primary"
                    confirmBtnStyle={{ color: 'white' }}

                >
                    {this.state.msgErro}

                </SweetAlert>
            </div>
        )
    }
}

export const mapStateToProps = (state) => ({
    token: state.user.token
})

export default connect(mapStateToProps, { logout })(Categorias);