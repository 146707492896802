import React, { Component } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { Link } from 'react-router-dom';

// import Custom Components
import { connect } from 'react-redux';
import { URL } from '../../../../variables';
import Pagination from '../../../features/pagination';
import SweetAlert from 'react-bootstrap-sweetalert';
import { logout } from '../../../../actions';
import ReactTooltip from 'react-tooltip';

import moment from 'moment';
import amex from '../../../../imgs/cartoes/amex.png';
import elo from '../../../../imgs/cartoes/elo.png';
import hypercard from '../../../../imgs/cartoes/hypercard.png';
import mastercard from '../../../../imgs/cartoes/mastercard.png';
import visa from '../../../../imgs/cartoes/visa.png';
import jcb from '../../../../imgs/cartoes/jcb.png';
import aura from '../../../../imgs/cartoes/aura.png';
import diners_club from '../../../../imgs/cartoes/diners_club.png';
import discover from '../../../../imgs/cartoes/discover.png';

class Cartoes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cartoes: [],
            current_page: 1,
            last_page: 1,
            total: 1,
            per_page: 1,
            page: 1,
            loading: false,
            show_warning: false,
            show_erro: false,
            msgErro: '',
            user_to_delete: ''
        };
    }

    delete_cartao(id) {

        fetch(`${URL}api/remove_cartao/${id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ msgErro: erro, show_warning: false, show_erro: true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({ show_warning: false });
                    this.get_cartoes();
                    // this.setState({ users: resp.users, loading_screen: false, page: resp.pagination.current_page, total_pages: resp.pagination.last_page });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    get_cartoes() {
        this.setState({ loading: true, msgErro: '' });
        fetch(`${URL}api/get_cartoes`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },

        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    this.setState({
                        loading: false,
                    });
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({
                        loading: false,
                        msgErro: erro,
                    });
                }
                else {

                    this.setState({
                        loading: false,
                        cartoes: resp.cartoes,
                    });


                }




            } catch (err) {
                console.log(err);
                this.setState({
                    loading: false
                });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({
                    loading: false
                });
            });
    }

    componentDidMount() {
        this.get_cartoes();
    }



    render() {
        let cartoes = this.state.cartoes;

        return (
            <div>

                <div className="row mb-2">
                    <div className="col-12  d-flex justify-content-end">
                        <Link to={"/conta/cartoes_credito/adicionar"}><span

                            className="btn btn-outline-primary btn-rounded" style={{ height: '45px' }}><span>Adicionar cartão de crédito</span><i className="icon-long-arrow-right"></i></span></Link>
                    </div>
                </div>
                <table className="table table-cart table-mobile">
                    <thead>
                        <tr>

                            <th>Cartão de Crédito</th>
                            <th>Nome do Cartão</th>

                            <th>Vencimento</th>

                            <th>Ações</th>
                        </tr>
                    </thead>
                    {this.state.loading == false && <tbody>
                        {cartoes.length > 0 ?
                            cartoes.map((item, index) =>
                                <tr key={index}>
                                  
                                    <td style={{ padding: 10 }}>

                                        <div className="align-items-center d-flex ">
                                            <div className="d-flex justify-content-center align-items-center">
                                                <div className="custom-control custom-radio" style={{ marginTop: 0, marginRight: 20, marginBottom: 0, }}>

                                                    <div className="d-flex align-items-center">

                                                        <img src={visa} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: item.brand.toUpperCase().includes('VISA') ? 'block' : 'none' }} />
                                                        <img src={mastercard} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: item.brand.toUpperCase().includes('MASTER') ? 'block' : 'none' }} />
                                                        <img src={amex} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: item.brand.toUpperCase().includes('AME') ? 'block' : 'none' }} />
                                                        <img src={elo} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: item.brand.toUpperCase().includes('ELO') ? 'block' : 'none' }} />
                                                        <img src={hypercard} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: item.brand.toUpperCase().includes('HYPER') ? 'block' : 'none' }} />


                                                        <img src={diners_club} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: item.brand.toUpperCase().includes('DINERS') ? 'block' : 'none' }} />
                                                                                        <img src={discover} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: item.brand.toUpperCase().includes('DISCOVER') ? 'block' : 'none' }} />
                                                                                        <img src={jcb} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: item.brand.toUpperCase().includes('JCB') ? 'block' : 'none' }} />
                                                                                        <img src={aura} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: item.brand.toUpperCase().includes('AURA') ? 'block' : 'none' }} />

    
                                                    </div>

                                                </div>

                                            </div>
                                            <div className="align-items-center" style={{ marginLeft: 0 }}>
                                                <p style={{ margin: 0, color: 'black' }}><b style={{ marginRight: 15, fontWeight: 500 }}>(Crédito)</b> {item.brand.toUpperCase()}</p>

                                                <p style={{ margin: 0, color: 'black' }}> •••• {item.last_digits}</p>

                                            </div>

                                        </div>
                                    </td>
                                    <td style={{ padding: 10 }}>

                                        <div className="d-flex align-items-center ">
                                            <div>
                                                <p style={{ margin: 0, color: 'black' }}>{item.holder_name}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td style={{ padding: 10 }}>

                                        <div className="d-flex  align-items-center">
                                            <div>
                                                <p style={{ margin: 0, color: 'black' }}>{moment(item.expiration_date, 'MM/YYYY', true).format('MM/YYYY')}</p>
                                            </div>
                                        </div>
                                    </td>


                                    <td style={{ width: 100 }}>
                                        <ReactTooltip />


                                        <button data-tip="Remover Cartão" style={{ width: 40, height: 40, minWidth: 40, borderRadius: 10 }} onClick={() => {
                                            this.setState({ show_warning: true, user_to_delete: item.id });
                                        }}
                                            className="destroy_it btn btn-danger"><i
                                                className="fas fa-trash"></i></button>
                                    </td>
                                </tr>
                            ) :
                            <tr>
                                <th colSpan="4">
                                    <p className="pl-2 pt-1 pb-1"> Você não possui cartões de crédito cadastrados no sistema </p>
                                </th>
                            </tr>
                        }
                    </tbody>}
                    {this.state.loading == true && <tbody><tr><td colSpan="4"><div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div></div></td></tr></tbody>}
                </table>

                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                        this.delete_cartao(this.state.user_to_delete);
                    }}
                    onCancel={() => {
                        this.setState({ show_warning: false });
                    }}
                    show={this.state.show_warning}
                    confirmBtnText='Sim, desejo apagar'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnStyle={{ color: 'white' }}

                    showCancel={true}
                >
                    Ao deletar este cartão ele não estará mais disponível no sistema para uso

                </SweetAlert>
                <SweetAlert
                    danger
                    title={"Erro"}
                    onConfirm={() => {
                        this.setState({ show_erro: false });
                    }}
                    show={this.state.show_erro}
                    confirmBtnText='OK'
                    confirmBtnBsStyle="primary"
                    confirmBtnStyle={{ color: 'white' }}

                >
                    {this.state.msgErro}

                </SweetAlert>
            </div>
        )
    }
}

export const mapStateToProps = (state) => ({
    token: state.user.token
})

export default connect(mapStateToProps, { logout })(Cartoes);