import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
// import Custom Components
import PageHeader from '../../common/page-header';
import Breadcrumb from '../../common/breadcrumb';
import Accordion from '../../features/accordion/accordion';

import { getCartTotal } from '../../../services';
import { URL } from '../../../variables';
import { maskCep, maskCartaoCredito, maskValidadeCartao, maskNumber, maskCpfCnpj, maskPriceDecimal } from '../../../Auxiliar/Masks';
import { logout, changeState } from '../../../actions/index';
import creditCardType from 'credit-card-type';
import Select from 'react-select'

import { isIEBrowser, isSafariBrowser, isEdgeBrowser } from '../../../utils/utils';

import amex from '../../../imgs/cartoes/amex.png';
import elo from '../../../imgs/cartoes/elo.png';
import hypercard from '../../../imgs/cartoes/hypercard.png';
import mastercard from '../../../imgs/cartoes/mastercard.png';
import visa from '../../../imgs/cartoes/visa.png';
import jcb from '../../../imgs/cartoes/jcb.png';
import aura from '../../../imgs/cartoes/aura.png';
import diners_club from '../../../imgs/cartoes/diners_club.png';
import discover from '../../../imgs/cartoes/discover.png';


import moment from 'moment';
import Stepper from 'react-stepper-horizontal';

class Card {
    constructor(){
        var self = this; this.mask = { DEFAULT_CC: '0000 0000 0000 0000', DEFAULT_CVC: '000', }
        this.type = { 
            VISA: { name: 'visa', detector: /^4/, cardLength: 16, cvcLength: 3, maskCC: self.mask.DEFAULT_CC, maskCVC: self.mask.DEFAULT_CVC, order: 99 }, 
            MASTERCARD: { name: 'mastercard', detector: /^(5[1-5]|2(2(2[1-9]|[3-9])|[3-6]|7([0-1]|20)))/, cardLength: 16, cvcLength: 3, maskCC: self.mask.DEFAULT_CC, maskCVC: self.mask.DEFAULT_CVC, order: 99 }, 
            AMEX: { name: 'amex', detector: /^3[47]/, cardLength: 15, cvcLength: 4, maskCC: '0000 000000 00000', maskCVC: '0000', order: 99 }, 
            DISCOVER: { name: 'discover', detector: /^6(?:011\d{12}|5\d{14}|4[4-9]\d{13}|22(?:1(?:2[6-9]|[3-9]\d)|[2-8]\d{2}|9(?:[01]\d|2[0-5]))\d{10})/, cardLength: 16, cvcLength: 3, maskCC: self.mask.DEFAULT_CC, maskCVC: self.mask.DEFAULT_CVC, order: 2 }, 
            HIPERCARD: { name: 'hipercard', detector: /^(?:606282|384100|384140|384160)/, cardLength: 16, cvcLength: 3, maskCC: self.mask.DEFAULT_CC, maskCVC: self.mask.DEFAULT_CVC, order: 4 }, DINERS: { name: 'diners', detector: /^(300|301|302|303|304|305|36|38)/, cardLength: 14, cvcLength: 3, maskCC: '0000 000000 0000', maskCVC: self.mask.DEFAULT_CVC, order: 5 }, 
            JCB_15: { name: 'jcb_15', detector: /^(?:2131|1800)/, cardLength: 15, cvcLength: 3, maskCC: self.mask.DEFAULT_CC, maskCVC: self.mask.DEFAULT_CVC, order: 6 }, 
            JCB_16: { name: 'jcb_16', detector: /^(35)/, cardLength: 16, cvcLength: 3, maskCC: self.mask.DEFAULT_CC, maskCVC: self.mask.DEFAULT_CVC, order: 7 }, 
            ELO: { name: 'elo', detector: /^(4011(78|79)|43(1274|8935)|45(1416|7393|763(1|2))|50(4175|6699|67([0-6][0-9]|7[0-8])|9\d{3})|627780|63(6297|6368)|650(03([^4])|04([0-9])|05(0|1)|4(0[5-9]|(1|2|3)[0-9]|8[5-9]|9[0-9])|5((3|9)[0-8]|4[1-9]|([0-2]|[5-8])\d)|7(0\d|1[0-8]|2[0-7])|9(0[1-9]|[1-6][0-9]|7[0-8]))|6516(5[2-9]|[6-7]\d)|6550(2[1-9]|5[0-8]|(0|1|3|4)\d))\d*/, cardLength: 16, cvcLength: 3, maskCC: self.mask.DEFAULT_CC, maskCVC: self.mask.DEFAULT_CVC, order: 1 }, 
            AURA: { name: 'aura', detector: /^((?!5066|5067|50900|504175|506699)50)/, cardLength: 19, cvcLength: 3, maskCC: '0000000000000000000', maskCVC: self.mask.DEFAULT_CVC, order: 3 } };
    }
}

function detect_card(text=''){
    text=text.replace(/ /g,'');
    let card = new Card();
    let types = Object.values(card.type);
    let brand='';
    let index=null;
    let masked_number='';
    let card_length=0;
    for(let i=0;i<types.length;i++){
        if(text.match(types[i].detector)){
            brand=types[i].name;
            card_length=types[i].cardLength;
            index=i;
            break;
        }
    }
    if(index!=null){
        let index_mask=0;
        
        for(let i=0;i<text.length && i<types[index].cardLength;i++){
            if(!isNaN(text[i])){
                if(types[index].maskCC[index_mask]==' '){
                    masked_number+=' ';
                    index_mask++;
                }
                if(types[index].maskCC[index_mask]=='0'){
                    masked_number+=text[i];
                }
                index_mask++;
            }
            
        }
    }
    else{
        masked_number=text;
    }
    return {brand,masked_number,index,card_length};
}

var regras_parcelas=[
    {
        min:0.00,
        max:39.99,
        parcela:1
    },
    {
        min:40.00,
        max:59.99,
        parcela:2
    },
    {
        min:60.00,
        max:79.99,
        parcela:3
    },
    {
        min:80.00,
        max:99.99,
        parcela:4
    },
    {
        min:100.00,
        max:299.99,
        parcela:5
    },
    {
        min:300.00,
        parcela:6
    }
]

class SelecionePagamento extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect_cart: false,
            loading_save: false,
            msgErro: '',
            redirect: false,
            loading: true,
            cartoes: [],
            cartao: {},

            numero_cartao: '',
            cvv: '',
            validade: '',
            cpf_cnpj: '',
            nome: '',
            types: {brand:'',masked_number:'',card_length:''},
            pagamento_hidden:true,
            installments:[],
            installment:{value:1, label:''}

        }
    }
    componentDidMount() {
        // let item = document.querySelector("#checkout-discount-input");

        // item.addEventListener("focus", function (e) {
        //     e.currentTarget.parentNode.querySelector("label").setAttribute("style", "opacity: 0");
        // });

        // item.addEventListener("blur", function (e) {
        //     let $this = e.currentTarget;
        //     if ($this.value.length !== 0) {
        //         $this.parentNode.querySelector("label").setAttribute("style", "opacity: 0");
        //     } else {
        //         $this.parentNode.querySelector("label").setAttribute("style", "opacity: 1");
        //     }
        // });
        this.props.changeState({ forma_pagamento: { type: '', object: {},installment:1  } });


        if (this.props.cartlist.length == 0 || this.props.token == undefined || this.props.endereco.id == undefined || this.props.shipping.delivery_method_id == undefined) {
            this.setState({ redirect_cart: true });
        }
        else {
            this.get_cartoes();

        }
    }

    get_cartoes() {
        this.setState({ loading: true, msgErro: '' });
        fetch(`${URL}api/get_cartoes`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },

        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    this.setState({
                        loading: false,
                    });
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({
                        loading: false,
                        msgErro: erro,
                    });
                }
                else {
                    let cartao = {};
                    let installments=[];

                    for(let i=0;i<resp.cartoes.length;i++){
                        if(this.props.forma_pagamento!=undefined && this.props.forma_pagamento.object!=undefined && this.props.forma_pagamento.object.id!=undefined
                            &&
                            resp.cartoes[i].id==this.props.forma_pagamento.object.id
                            ){
                            cartao=this.props.forma_pagamento.object;
                            break;
                        }
                    }

                    if (resp.cartoes.length != 0 && cartao.id==undefined) {
                        cartao = resp.cartoes[0];
                    }
                    installments.push({ value: 1, label: '1x de '+maskPriceDecimal(this.props.total)  })
                    let installment= { value: 1, label: '1x de '+maskPriceDecimal(this.props.total)  }
                    let parcelas = regras_parcelas.filter((item,id)=>{
                        if(item.min<=this.props.total && item.max>=this.props.total && item.max!=undefined){
                            console.log(item);

                            return true;
                        }
                        else if(item.min<=this.props.total && item.max==undefined){
                            console.log(item);
                            return true;
                        }
                        else{
                            return false;
                        }
                    })[0].parcela;
                    console.log(parcelas)
                    console.log(this.props.total)
                    
                    for(let j=2;j<=parcelas;j++){
                        installments.push({value:j,label:j+'x de '+maskPriceDecimal(this.props.total/j) });
                    }
                    // if(this.props.forma_pagamento!=undefined && this.props.forma_pagamento.object!=undefined && this.props.forma_pagamento.object.id!=undefined){
                    //     cartao=this.props.forma_pagamento.object;
                    // }
                    this.setState({
                        loading: false,
                        cartoes: resp.cartoes,
                        cartao,
                        installments,
                        installment
                    });


                }




            } catch (err) {
                console.log(err);
                this.setState({
                    loading: false
                });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({
                    loading: false
                });
            });
    }

    salvar() {
        let data = {
            numero_cartao: this.state.numero_cartao.replace(/ /g,''),
            cvv: this.state.cvv,
            cpf_cnpj: this.state.cpf_cnpj,
            validade: this.state.validade,
            nome: this.state.nome,
        };
        let brand='';
        if((!moment(this.state.validade, "MM/YYYY", true).isValid() || moment(this.state.validade, "MM/YYYY", true).diff(moment()) <= 0)){
            this.setState({ msgErro: 'Cartão fora do prazo de validade', loading_save: false });
                return;
        }
        if (this.state.types.brand!='') {
            brand = this.state.types.brand.toUpperCase();
            if(data.numero_cartao.length<this.state.types.card_length){
                this.setState({ msgErro: 'Número de cartão inválido', loading_save: false });
                return;
            }
            if (brand.includes('VISA')) {
                data.brand='Visa';
            }
            else if (brand.includes('MASTER')) {
                data.brand='Master';
            }
            else if (brand.includes('AME')) {
                data.brand='Amex';
            }
            else if (brand.includes('ELO')) {
                data.brand='Elo';
            }
            else if (brand.includes('HYPER')) {
                data.brand='Hipercard';
            }
            else if (brand.includes('DINERS')) {
                data.brand='Diners';
            }
            else if (brand.includes('DISCOVER')) {
                data.brand='Discover';
            }
            else if (brand.includes('AURA')) {
                data.brand='Aura';
            }
            else if (brand.includes('JCB')) {
                data.brand='JCB';
            }
        }
        else{
            this.setState({ msgErro: 'Número de cartão inválido', loading_save: false });
            return;
        }
        this.setState({ loading_save: true });

        



        fetch(`${URL}api/store_cartao`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.props.token

            },
            body: JSON.stringify(data)
        }).then(async (responseLog) => {
            try {
                console.log(await responseLog);
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ msgErro: erro, loading_save: false });
                    if (isIEBrowser() || isSafariBrowser() || isEdgeBrowser()) {
                        let pos = window.pageYOffset;
                        let timerId = setInterval(() => {
                            if (pos <= 0)
                                clearInterval(timerId);
                            window.scrollBy(0, -120);
                            pos -= 120;
                        }, 1);
                    } else {
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth'
                        })
                    }
                }
                else {


                    this.setState({
                        loading_save: false, msgErro: '', numero_cartao: '',
                        cvv: '',
                        validade: '',
                        cpf_cnpj: '',
                        nome: '',
                        types: {brand:'',masked_number:'',card_length:''}
                    });
                    this.get_cartoes();
                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });
            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: `Erro ao pegar resposta do servidor. Você está conectado a internet?` });
            });


    }

    componentDidUpdate(props){
        if(this.props.cartlist.length!=props.cartlist.length){
            this.setState({redirect_cart:true});
        }
    }


    render() {

        const { cartlist, total } = this.props;
        let endereco = this.props.endereco;
        let shipping = this.props.shipping;

        console.log(endereco);

        let logo = '';
        let brand = '';
        if (this.state.types.brand !='') {
            brand = this.state.types.brand.toUpperCase();
            console.log(brand);
            if (brand.includes('VISA')) {
                logo = visa;
            }
            else if (brand.includes('MASTER')) {
                logo = mastercard;
            }
            else if (brand.includes('HYPER')) {
                logo = hypercard;
            }
            else if (brand.includes('AME')) {
                logo = amex;
            }
            else if (brand.includes('ELO')) {
                logo = elo;
            }

            else if (brand.includes('DINERS')) {
                logo = diners_club;
            }
            else if (brand.includes('DISCOVER')) {
                logo = discover;
            }
            else if (brand.includes('AURA')) {
                logo = aura;
            }
            else if (brand.includes('JCB')) {
                logo = jcb;
            }

        }
        return (
            <div className="main">
                {this.state.redirect_cart == true && <Redirect to={'/shop/cart'} />}
                <PageHeader background="url(assets/images/cart.jpg)" title="Pagamento" subTitle="Forma de Pagamento" />
                <div className="d-block d-sm-none">

                    <Breadcrumb title="Pagamento" parent1={["Carrinho", "shop/cart"]} parent2={["Endereço de Entrega", "shop/address"]} parent3={["Opção de Entrega", "shop/delivery"]} />
                </div>
                <div className="container d-none d-sm-block">

                    <div className={'breadcrumb-nav'}>
                        <Stepper defaultColor={'#999'} defaultTitleColor={'#555'} activeTitleColor={'#19386e'} titleFontSize={14}
                            activeColor={'#19386e'} completeColor={'#005b8c'} completeTitleColor={'#005b8c'}
                            steps={[{
                                title: 'Carrinho',
                                href: '/shop/cart',
                            },
                            {
                                title: 'Endereço',
                                href: '/shop/address'
                            },
                            {
                                title: 'Entrega',
                                href: '/shop/delivery'
                            },
                            {
                                title: 'Pagamento',
                            },
                            {
                                title: 'Resumo'
                            },
                            ]} activeStep={3} />
                    </div>
                </div>
                <div className="page-content">
                    <div className="checkout">
                        <div className="container">
                            {/* <div className="checkout-discount">
                                <form action="#">
                                    <input type="text" className="form-control" required id="checkout-discount-input"/>
                                    <label htmlFor="checkout-discount-input" className="text-truncate">Have a coupon? <span>Click here to enter your code</span></label>
                                </form>
                            </div> */}


                            <form action="#">
                                <div className="row">
                                    <div className="col-lg-9">
                                        <h2 className="checkout-title" style={{ marginTop: 0 }}>Escolha uma opção de pagamento:</h2>
                                        {this.state.cartoes.length == 0 && this.state.loading == false && <div>
                                            <p>Nenhum cartão cadastrado</p>
                                        </div>}
                                        <div className="row">
                                            {/* <div className="col-12 col-lg-6" hidden={this.state.loading == true || this.state.precos_prazos.length == 0}>
                                                <h2 className="checkout-title" style={{ marginTop: 0 }}>Enviar Para:</h2>
                                                <h3 className="product-title" style={{ whiteSpace: 'pre-line', fontSize: '1.4rem', fontWeight: '400' }}>
                                                    <div style={{ marginBottom: '0.5rem' }}>{endereco.rua}, {endereco.numero}</div>

                                                    {endereco.complemento != null && endereco.complemento != '' && <div style={{ marginBottom: '0.5rem' }}>{endereco.complemento}</div>}

                                                    <div style={{ marginBottom: '0.5rem' }}>{endereco.bairro}, {endereco.cidade} - {endereco.estado}</div>
                                                    <div style={{ marginBottom: '0.5rem' }}>{endereco.cep}</div>
                                                </h3>
                                            </div> */}

                                            <div className="col-12 col-lg-12" hidden={this.state.loading == true || this.state.cartoes.length == 0}>
                                                <div className="table-rep-plugin">

                                                    <div className="table-responsive">

                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th></th>

                                                                    <th>Cartão de Crédito</th>
                                                                    <th>Nome do Cartão</th>

                                                                    <th>Vencimento</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {this.state.cartoes.map((item, id) => (<tr key={id} hidden={this.state.cartoes.length == 0} >
                                                                    <td>
                                                                        <div className="d-flex justify-content-center align-items-center">
                                                                            <div className="custom-control custom-radio" style={{ marginTop: 0, marginRight: 20, marginBottom: 0, }}>
                                                                                <input type="radio"
                                                                                    id={"shipping" + id}
                                                                                    name="shipping"
                                                                                    className="custom-control-input"
                                                                                    style={{
                                                                                        width: '26px',
                                                                                        height: '26px'
                                                                                    }}
                                                                                    onChange={(e) => {
                                                                                        console.log(item);
                                                                                        this.setState({ cartao: item });
                                                                                    }}
                                                                                    defaultChecked={this.state.cartao.id == item.id}
                                                                                />
                                                                                <label className="custom-control-label" htmlFor={"shipping" + id}>
                                                                                    <div className="d-flex align-items-center">

                                                                                        <img src={visa} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: item.brand.toUpperCase().includes('VISA') ? 'block' : 'none' }} />
                                                                                        <img src={mastercard} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: item.brand.toUpperCase().includes('MASTER') ? 'block' : 'none' }} />
                                                                                        <img src={amex} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: item.brand.toUpperCase().includes('AME') ? 'block' : 'none' }} />
                                                                                        <img src={elo} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: item.brand.toUpperCase().includes('ELO') ? 'block' : 'none' }} />
                                                                                        <img src={hypercard} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: item.brand.toUpperCase().includes('HYPER') ? 'block' : 'none' }} />

                                                                                        <img src={diners_club} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: item.brand.toUpperCase().includes('DINERS') ? 'block' : 'none' }} />
                                                                                        <img src={discover} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: item.brand.toUpperCase().includes('DISCOVER') ? 'block' : 'none' }} />
                                                                                        <img src={jcb} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: item.brand.toUpperCase().includes('JCB') ? 'block' : 'none' }} />
                                                                                        <img src={aura} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: item.brand.toUpperCase().includes('AURA') ? 'block' : 'none' }} />

                                                                                    </div>
                                                                                </label>

                                                                            </div>

                                                                        </div>
                                                                    </td>
                                                                    <td style={{ padding: 10 }}>

                                                                        <div className="align-items-center d-flex ">

                                                                            <div className="align-items-center" style={{ marginLeft: 0 }}>
                                                                                <p style={{ margin: 0, color: 'black' }}><b style={{ marginRight: 15, fontWeight: 500 }}>(Crédito)</b> {item.brand.toUpperCase()}</p>

                                                                                <p style={{ margin: 0, color: 'black' }}> •••• {item.last_digits}</p>

                                                                            </div>

                                                                        </div>
                                                                    </td>
                                                                    <td style={{ padding: 10 }}>

                                                                        <div className="d-flex align-items-center ">
                                                                            <div>
                                                                                <p style={{ margin: 0, color: 'black' }}>{item.holder_name}</p>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td style={{ padding: 10 }}>

                                                                        <div className="d-flex  align-items-center">
                                                                            <div>
                                                                                <p style={{ margin: 0, color: 'black' }}>{moment(item.expiration_date, 'MM/YYYY', true).format('MM/YYYY')}</p>
                                                                            </div>
                                                                        </div>
                                                                    </td>

                                                                </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                {this.state.installment.value!=undefined &&  <h2 className="checkout-title" style={{ marginTop: 0 }}>Número de parcelas</h2>}

                                                {this.state.installment.value!=undefined && <Select value={this.state.installment} onChange={(e) => {
                    console.log(e);
                    this.setState({ installment: e });
                }}  options={this.state.installments} />}


                                            </div>
                                        </div>

                                        {this.state.loading == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div></div>}
                                        <hr />
                                        <div >

                                            <h2 onClick={()=>this.setState({pagamento_hidden:!this.state.pagamento_hidden})} className="checkout-title show_hide" style={{display:'flex',alignItems:'center'}}>Adicione um Cartão <i style={{fontSize:22,marginLeft:20,paddingTop:this.state.pagamento_hidden==true?0:10,paddingBottom:this.state.pagamento_hidden==true?10:0}} className={this.state.pagamento_hidden==true? "fas fa-sort-down":"fas fa-sort-up"}></i> </h2>
                                            <div hidden={this.state.pagamento_hidden}>
                                            <label>Número do Cartão *</label>
                                            <div style={{
                                                color: '#721c24',
                                                backgroundColor: '#f8d7da',
                                                borderColor: '#f5c6cb'
                                            }} hidden={this.state.msgErro == ''} className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                                <button style={{ color: '#721c24', top: '55%', fontSize: '2rem' }} onClick={() => this.setState({ msgErro: '' })} type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>

                                                <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                                            </div>
                                            <br />
                                            <div style={{ position: 'relative' }}>

                                                <input value={this.state.numero_cartao} onChange={(e) => {
                                                    let text = e.target.value;
                                                    // let types = creditCardType(text);
                                                    let result=detect_card(text);
                                                    console.log(result)
                                                    // let credit_card = maskCartaoCredito(text, types);
                                                    this.setState({ numero_cartao: result.masked_number, types: result });
                                                }} style={{
                                                    paddingRight: 40,
                                                }} type="text" placeholder="Número do Cartão" className="form-control" required />
                                                {logo != '' && <div style={{ position: 'absolute', top: 0, right: 10 }}>
                                                    <img src={logo} style={{ width: 30, height: 40, objectFit: "contain" }} />
                                                </div>}
                                                <div className="d-flex">
                                                    <img src={visa} style={{ width: 30, height: 40, objectFit: "contain", marginRight: 10, filter: brand.includes('VISA') ? '' : 'grayscale(1)' }} />
                                                    <img src={mastercard} style={{ width: 30, height: 40, objectFit: "contain", marginRight: 10, filter: brand.includes('MASTER') ? '' : 'grayscale(1)' }} />
                                                    <img src={amex} style={{ width: 30, height: 40, objectFit: "contain", marginRight: 10, filter: brand.includes('AME') ? '' : 'grayscale(1)' }} />
                                                    <img src={elo} style={{ width: 30, height: 40, objectFit: "contain", marginRight: 10, filter: brand.includes('ELO') ? '' : 'grayscale(1)' }} />
                                                    <img src={hypercard} style={{ width: 30, height: 40, objectFit: "contain", marginRight: 10, filter: brand.includes('HYPER') ? '' : 'grayscale(1)' }} />

                                                    <img src={diners_club} style={{ width: 30, height: 40, objectFit: "contain", marginRight: 10, filter: brand.includes('DINERS') ? '' : 'grayscale(1)' }} />
                                                    <img src={discover} style={{ width: 30, height: 40, objectFit: "contain", marginRight: 10, filter: brand.includes('DISCOVER') ? '' : 'grayscale(1)' }} />
                                                    <img src={jcb} style={{ width: 30, height: 40, objectFit: "contain", marginRight: 10, filter: brand.includes('JCB') ? '' : 'grayscale(1)' }} />
                                                    <img src={aura} style={{ width: 30, height: 40, objectFit: "contain", marginRight: 10, filter: brand.includes('AURA') ? '' : 'grayscale(1)' }} />
                                                </div>
                                            </div>
                                            <div className="row mt-1">
                                                <div className="col-12 col-md-6">

                                                    <label>Validade (MM/YYYY) *</label>
                                                    <input style={{ marginBottom: '0.5rem' }} value={this.state.validade} onChange={(e) => this.setState({ validade: maskValidadeCartao(e.target.value) })} type="text" placeholder="Validade MM/YYYY" className="form-control" required />
                                                    {this.state.validade != '' && (!moment(this.state.validade, "MM/YYYY", true).isValid() || moment(this.state.validade, "MM/YYYY", true).diff(moment()) <= 0) && <small className="form-text" style={{ color: 'red' }}>Validade Inválida</small>}

                                                </div>
                                                <div className="col-12 col-md-6">

                                                    <label>CVV *</label>
                                                    <input value={this.state.cvv} onChange={(e) => this.setState({ cvv: maskNumber(e.target.value) })} type="text" placeholder="CVV" className="form-control" required />
                                                </div>
                                            </div>


                                            <label>Nome do Titular *</label>
                                            <input value={this.state.nome} onChange={(e) => this.setState({ nome: e.target.value })} type="text" placeholder="Nome do Titular do Cartão" required className="form-control" />

                                            <label>CPF/CNPJ *</label>
                                            <input value={this.state.cpf_cnpj} onChange={(e) => this.setState({ cpf_cnpj: maskCpfCnpj(e.target.value) })} type="text" placeholder="CPF/CNPJ do Titular do Cartão" className="form-control" required />




                                            {this.state.loading_save == false && <button onClick={() => {
                                                this.salvar();
                                            }} style={{ marginTop: '1.3rem' }} type="button" className="btn btn-outline-primary btn-rounded">
                                                <span>Salvar</span>
                                                <i className="far fa-save"></i>
                                            </button>}
                                            {this.state.loading_save == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div></div>}
                                            </div>
                                        </div>
                                    </div>

                                    <aside className="col-lg-3">
                                        <div className="summary">
                                            <h3 className="summary-title">Enviar Para</h3>
                                            <h3 className="product-title" style={{ whiteSpace: 'pre-line', fontSize: '1.4rem', fontWeight: '400' }}>
                                                <div style={{ marginBottom: '0.5rem' }}>{endereco.rua}, {endereco.numero}</div>

                                                {endereco.complemento != null && endereco.complemento != '' && <div style={{ marginBottom: '0.5rem' }}>{endereco.complemento}</div>}

                                                <div style={{ marginBottom: '0.5rem' }}>{endereco.bairro}</div>
                                                <div style={{ marginBottom: '0.5rem' }}>{endereco.cidade} - {endereco.estado}</div>

                                                <div style={{ marginBottom: '0.5rem' }}>{endereco.cep}</div>
                                            </h3>
                                            <h3 className="summary-title mt-3">Seu Pedido</h3>

                                            <table className="table table-summary">
                                                <thead>
                                                    <tr>
                                                        <th>Produto</th>
                                                        <th>Total</th>
                                                    </tr>
                                                </thead>

                                                <tbody>

                                                    {cartlist.map((item, index) =>
                                                        <tr key={index}>
                                                            <td style={{ color: 'black' }}><Link to="#">{item.qty}x {item.name}</Link></td>
                                                            <td style={{ color: 'black' }}>
                                                            {maskPriceDecimal(parseFloat(item.sale_price==null?item.price:item.sale_price)*parseInt(item.qty))}
                                                                </td>
                                                        </tr>
                                                    )}
                                                    <tr className="summary-subtotal">
                                                        <td>Subtotal:</td>
                                                        <td>{maskPriceDecimal(total)}</td>
                                                    </tr>
                                                    {this.props.shipping.delivery_method_id != undefined && <tr className="summary-subtotal">
                                                        <td>{shipping.description}:</td>
                                                        <td>{shipping.final_shipping_cost==0?<span style={{color:'green'}}>Grátis</span> :maskPriceDecimal(shipping.final_shipping_cost)}</td>
                                                    </tr>}

                                                    <tr className="summary-total">
                                                        <td>Total:</td>
                                                        <td>{maskPriceDecimal(total + (this.props.shipping.delivery_method_id == undefined ? 0 : this.props.shipping.final_shipping_cost))}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <Link onClick={() => {
                                                // if (this.state.endereco_select.id != undefined) {
                                                //     this.props.changeState({ endereco: this.state.endereco_select });
                                                // }
                                                if (this.state.cartao.id != undefined) {
                                                    this.props.changeState({ forma_pagamento: { type: "credit_card", object: this.state.cartao,installment:this.state.installment.value } });
                                                }
                                            }} hidden={this.props.token == undefined || this.props.cartlist.length == 0 || this.props.shipping.delivery_method_id == undefined || this.state.cartoes.length == 0 || this.state.cartao.id == undefined || this.state.loading == true || this.props.endereco.id == undefined} to={`${process.env.PUBLIC_URL}/shop/resume`}
                                                className="btn btn-outline-primary-2 btn-order btn-block">

                                                <span className="btn-text">Continuar</span>
                                                <span className="btn-hover-text">Revisar Pedido</span>
                                            </Link>
                                        </div>
                                    </aside>
                                </div>
                            </form>
                        </div>
                    </div>
                </div >
            </div >
        )
    }
}

export const mapStateToProps = (state) => ({
    cartlist: state.cartlist.cart,
    total: getCartTotal(state.cartlist.cart),
    endereco: state.cartlist.endereco,
    shipping: state.cartlist.shipping,
    token: state.user.token,
    forma_pagamento:state.cartlist.forma_pagamento

})

export default connect(mapStateToProps, { logout, changeState })(SelecionePagamento);