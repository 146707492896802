import React from 'react'
import SocialLogin from 'react-social-login'

class SocialButton extends React.Component {

    render() {
        return (
            <button style={{ width: '100%' }} className="btn btn-login btn-g" type="button" onClick={this.props.triggerLogin} {...this.props}>
                <i className="icon-google"></i>
                                                            Entrar com Google
            </button>
        );
    }
}

export default SocialLogin(SocialButton);