import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Magnifier } from "react-image-magnifiers";

import SingleProductComponent from './common/base';
import ProductDetailOne from './common/detail-one';

import InnerOverlay from '../../common/overlay/inner-overlay';
import Breadcrumb from '../../common/breadcrumb';
import QuickView from '../../features/product/quickview';
import ErrorPage from '../../main/pages/404';

import { addToCart, toggleWishlist, addToCompare, showQuickViewModal } from '../../../actions';
import { URL } from '../../../variables';
import SweetAlert from 'react-bootstrap-sweetalert';

class SingleProduct extends SingleProductComponent {

    constructor(props) {
        super(props);
        this.state = {
            produto: { fotos: [{ foto: '' }], price: 0, descricao: '', tamanhos: [], categorias: [] },
            produtos: [],
            loading: true,
            index_image: 0,
            assunto: '',
            descricao: '',
            loading_save: false,
            avaliacao:5,
            avaliacao_to_delete:{},
            show_warning:false,
            msgErro:''
        };
    }

    componentWillMount() {
        this.get_produto();
    }

    enviar_comentario() {
        this.setState({ loading_save: true, msgErro: '' });
        let form = new FormData();
        form.append('assunto', this.state.assunto);
        form.append('descricao', this.state.descricao);
        form.append('avaliacao', this.state.avaliacao);

        form.append('produto_id', this.state.produto.id);

        fetch(`${URL}api/admin/salvar_comentario`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_save: false, msgErro: erro });
                }
                else {

                    this.setState({ loading_save: false,produto:resp.produto,assunto:'',descricao:'',avaliacao:5 });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }

    get_produto() {
        fetch(`${URL}api/get_produto/${this.props.match.params.id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                // 'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                }
                else {
                    this.setState({
                        produto: resp.produto,
                        produtos: resp.produtos,
                        loading: false,
                        assunto: '',
                        descricao: '',
                        loading_save: false
                    });

                }




            } catch (err) {
                console.log(err);

            }

        })
            .catch((err) => {
                console.log(err);
            });
    }
    componentWillUnmount() {
        super.componentWillUnmount();
    }

    componentDidUpdate(props) {
        if (this.props.match.params.id != props.match.params.id) {
            this.get_produto();
        }
    }

    render() {
        console.log(this.state.produto);
        const { addToCart, toggleWishlist, addToCompare } = this.props;

        if (!this.state.produto) {
            return (
                <ErrorPage />
            )
        }
        let produto = this.state.produto;
        const bigImages = produto.fotos;
        const smallImages = produto.fotos;

        return (
            this.state.loading == false && <div className="main">
                <InnerOverlay />

                <Breadcrumb
                    title={produto.name}
                    type="product"
                    slug="default"
                    adClass="breadcrumb-nav border-0 mb-0"
                    productId={produto.id}
                    parent1={["Products", "product"]}
                />

                <div className="page-content">
                    <div className="container">
                        <div className="product-details-top">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="product-gallery product-gallery-vertical">
                                        <div className="row">
                                            <figure className="product-main-image" index="0">
                                                {produto.novo ? <span className="product-label label-new">Novo</span> : ''}

                                                {produto.top ? <span className="product-label label-top">Top</span> : ''}

                                                {produto.sale_price != null ? <span className="product-label label-sale">Oferta</span> : ''}

                                                {(0 == produto.estoque) ? <span className="product-label label-out">Sem Estoque</span> : ''}

                                                <Magnifier
                                                    imageSrc={URL + produto.fotos[this.state.index_image].foto}
                                                    imageAlt="Example"
                                                    largeImageSrc={URL + bigImages[this.state.index_image].foto} // Optional
                                                    dragToMove={false}
                                                    mouseActivation="hover"
                                                    cursorStyleActive="crosshair"
                                                    id="product-zoom"
                                                />

                                                <Link to="#" id="btn-product-gallery" className="btn-product-gallery" onClick={(e) => this.openLightBox(e, this.state.index_image)}>
                                                    <i className="icon-arrows"></i>
                                                </Link>
                                            </figure>

                                            <div id="product-zoom-gallery" className="product-image-gallery">
                                                {
                                                    produto.fotos.map((item, index) =>
                                                        <div className={`product-gallery-item ${this.state.index_image === index ? 'active' : ''}`} onClick={() => {
                                                            this.setState({ index_image: index });
                                                        }} data-image={URL + smallImages[index].foto} data-zoom-image={URL + bigImages[index].foto} key={produto.id + '-' + index}>
                                                            <img src={URL + smallImages[index].foto} alt="product back" />
                                                        </div>
                                                    )
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <ProductDetailOne product={produto}
                                        onAddToCartClick={() => addToCart(produto, document.querySelector("#qty").value)}
                                        onToggleWishlistClick={() => toggleWishlist(produto)}
                                        onAddToCompareClick={() => addToCompare(produto)} />
                                </div>
                            </div>
                        </div>

                        {this.productDetailTab(produto,
                            this.state.assunto,this.state.descricao,
                            this.state.avaliacao,
                            this.state.loading_save,
                            (value)=>{this.setState({avaliacao:parseInt(value)})},

                            (value)=>{this.setState({assunto:value})},
                            (value)=>{this.setState({descricao:value})},
                            this.enviar_comentario.bind(this),
                            this.onDeleteComentario.bind(this),
                            this.state.msgErro
                        )}

                        {this.state.produtos.length > 0 && <div>
                            <h2 className="title text-center mb-4">Você também deve gostar de:</h2>

                            {this.relatedProducts(this.state.produtos)}
                        </div>}


                    </div>
                </div>

                {/* <div className="sticky-bar">
                    <div className="container">
                        <div className="row">
                            <div className="col-6">
                                <figure className="product-media">
                                    <Link to={`${process.env.PUBLIC_URL}/product/default/`+produto.id}>
                                        <img src={URL + produto.fotos[0].foto} alt="Product" />
                                    </Link>
                                </figure>
                                <h4 className="product-title"><Link to={`${process.env.PUBLIC_URL}/product/default/`+produto.id}>{produto.name}</Link></h4>
                            </div>

                            <div className="col-6 justify-content-end">
                                <div className="product-price">
                                    R$ {produto.price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </div>
                                <div className="product-details-quantity">
                                    <input type="number" id="sticky-cart-qty" className="form-control" defaultValue={produto.estoque==0?0:1} min="1" max={produto.estoque} step="1" data-decimals="0" required />
                                </div>

                                <div className="product-details-action">
                                    <button className="btn-product btn-cart" disabled={produto.estoque==0} onClick={() => addToCart(produto, document.querySelector("#sticky-cart-qty").value)}><span>Adicionar ao carrinho</span></button>
                                    <button className="btn-product btn-wishlist pr-0 pl-0" title="Wishlist" onClick={() => toggleWishlist(produto)}><span>Adicionar na lista de desejos</span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                {this.lightbox(produto)}

                <QuickView />
                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                        this.delete_avaliacao(this.state.avaliacao_to_delete);
                    }}
                    onCancel={() => {
                        this.setState({ show_warning: false });
                    }}
                    show={this.state.show_warning}
                    confirmBtnText='Sim, desejo apagar'
                    confirmBtnStyle={{color:'white'}}

                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    showCancel={true}
                >
                    ao deletar este produto ele não estará mais disponível no sistema

                </SweetAlert>
            </div>
        )
    }

    delete_avaliacao(id) {

        fetch(`${URL}api/admin/delete_comentario/${id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ msgErro: erro,show_warning:false,show_erro:true });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({show_warning:false,produto:resp.produto});
                    // this.setState({ users: resp.users, loading_screen: false, page: resp.pagination.current_page, total_pages: resp.pagination.last_page });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ redirect: true });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ redirect: true });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    onDeleteComentario(id){
        this.setState({show_warning:true,avaliacao_to_delete:id});
    }
}

const mapStateToProps = (state, props) => {
    // const product_id = props.match.params.id;
    // return {
    //     products: state.data.products ? state.data.products : [],
    //     product: state.data.products.filter(item => item.id.toString() === product_id)[0]
    // }
    return {
        token:state.user.token,
        user:state.user.user,
    };
}

export default connect(
    mapStateToProps, { addToCart, toggleWishlist, addToCompare, showQuickViewModal }
)(SingleProduct);