import * as types from '../constants/action-types'
import { findIndex } from "../utils/utils";

export default function (state = {
    user: {},
    token: undefined,
}, action) {
    switch (action.type) {
        
        case types.LOGOUT:
            return {
                ...state,
                token: undefined,
                user: {},
            };
        case types.USER:
            return {
                ...state,
                user: action.payload,
            };
        case types.LOGIN:
            return {
                ...state,
                token: action.payload.token,
                user: action.payload.user,

            };

        default:
            return state;
    }
}