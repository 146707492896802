import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import Custom Components
import PageHeader from '../../common/page-header';
import Breadcrumb from '../../common/breadcrumb';

import { getCartTotal } from '../../../services';
import { quantityInputs, isIEBrowser } from '../../../utils/utils';
import { changetQty, removeFromCart, changeShipping, changeState, showModal } from '../../../actions';
import { URL } from '../../../variables';
import { maskCep, maskNumberInteger, maskPriceDecimal } from '../../../Auxiliar/Masks';
import Stepper from 'react-stepper-horizontal';

class Cart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // shipping: { delivery_method_id: '', final_shipping_cost: 0 },
            update: true,
            cep: '',
            loading_frete: false,
            msgErro: '',
            loading: {}

        }
    }

    componentDidMount() {
        // quantityInputs();
    }

    salvar(id, quantidade, igual = 0) {
        if (this.props.token == undefined) {
            for (let i = 0; i < this.props.cartlist.length; i++) {
                if (this.props.cartlist[i].id == id) {
                    let cartlist = [...this.props.cartlist];
                    if (igual == 1) {
                        if (quantidade <= 0) {
                            this.props.removeFromCart(id);
                        }
                        else {
                            cartlist[i].qty = quantidade;
                            this.props.changeState({ cart: cartlist });

                        }


                    }
                    else {
                        if (parseInt(cartlist[i].qty) + quantidade <= 0) {
                            this.props.removeFromCart(id);
                        }
                        else {
                            cartlist[i].qty = parseInt(cartlist[i].qty) + quantidade;
                            this.props.changeState({ cart: cartlist });

                        }
                    }
                    break;
                }
            }
            return;
        }
        let loading = { ...this.state.loading };
        loading[id] = true;
        this.setState({ loading: loading, msgErro: '' });
        let form = new FormData();
        console.log(id, quantidade);
        fetch(`${URL}api/carrinho/salvar_item_carrinho/${id}/${quantidade}/${igual}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                let loading = { ...this.state.loading };
                loading[id] = false;
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading: loading, msgErro: erro });
                }
                else {
                    for (let i = 0; i < this.props.cartlist.length; i++) {
                        if (this.props.cartlist[i].id == id) {
                            let cartlist = [...this.props.cartlist];
                            if (igual == 1) {
                                if (quantidade <= 0) {
                                    this.props.removeFromCart(id);
                                }
                                else {
                                    cartlist[i].qty = quantidade;
                                    this.props.changeState({ cart: cartlist });

                                }


                            }
                            else {
                                if (parseInt(cartlist[i].qty) + quantidade <= 0) {
                                    this.props.removeFromCart(id);
                                }
                                else {
                                    cartlist[i].qty = parseInt(cartlist[i].qty) + quantidade;
                                    this.props.changeState({ cart: cartlist });

                                }
                            }
                            break;
                        }
                    }


                    this.setState({ loading: loading });

                }




            } catch (err) {
                console.log(err);
                let loading = { ...this.state.loading };
                loading[id] = false;
                this.setState({ loading: loading, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                let loading = { ...this.state.loading };
                loading[id] = false;
                this.setState({ loading: loading, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }





    remove(id) {
        if (this.props.token == undefined) {
            this.props.removeFromCart(id);
            return;
        }
        let loading = { ...this.state.loading };
        loading[id] = true;
        this.setState({ loading: loading, msgErro: '' });
        let form = new FormData();
        fetch(`${URL}api/carrinho/excluir_item_carrinho/${id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: ''
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                let loading = { ...this.state.loading };
                loading[id] = false;
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading: loading, msgErro: erro });
                }
                else {
                    this.props.removeFromCart(id);
                    this.setState({ loading: loading });

                }




            } catch (err) {
                console.log(err);
                let loading = { ...this.state.loading };
                loading[id] = false;
                this.setState({ loading: loading, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                let loading = { ...this.state.loading };
                loading[id] = false;
                this.setState({ loading: loading, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }







    render() {
        const { cartlist, total, removeFromCart } = this.props;
        return (
            <div className="main">

                <PageHeader background="url(assets/images/cart.jpg)" title="Carrinho" subTitle="Lista" />
                <div className="d-block d-sm-none">

                    <Breadcrumb title="Carrinho" parent1={["Lista", "shop/sidebar/list"]} />
                </div>

                <div className="container d-none d-sm-block">

                    <div className={'breadcrumb-nav'}>
                        <Stepper defaultColor={'#999'} defaultTitleColor={'#555'} activeTitleColor={'#19386e'} titleFontSize={14}
                            activeColor={'#19386e'} completeColor={'#005b8c'} completeTitleColor={'#005b8c'}
                            steps={[{
                                title: 'Carrinho',
                                href: '/shop/cart',
                            },
                            {
                                title: 'Endereço',
                                href: '/shop/address'
                            },
                            {
                                title: 'Entrega',
                                href: '/shop/delivery'
                            },
                            {
                                title: 'Pagamento',
                                href: '/shop/payment'
                            },
                            {
                                title: 'Resumo'
                            },
                            ]} activeStep={0} />
                    </div>
                </div>

                <div className="page-content">
                    <div className="cart">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-9">
                                    <table className="table table-cart table-mobile">
                                        <thead>
                                            <tr>
                                                <th>Foto</th>
                                                <th>Produto</th>
                                                <th>Preço</th>
                                                <th>Quantidade</th>
                                                <th>Total</th>
                                                <th></th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {cartlist.length > 0 ?
                                                cartlist.map((item, index) =>
                                                    <tr key={index} style={{ position: 'relative' }}>
                                                        <td className="product-col">
                                                            <div className="product">

                                                                <Link style={{ width: 100, height: 100 }} to={`${process.env.PUBLIC_URL}/product/default/${item.id}`}>
                                                                    <img style={{ width: 100, height: 100, objectFit: 'contain' }} src={URL + item.fotos[0].foto} alt="Product" />
                                                                </Link>
                                                            </div>

                                                        </td>
                                                        <td className="product-col">
                                                            <div className="product">


                                                                <h3 className="product-title">
                                                                    <Link to={`${process.env.PUBLIC_URL}/product/default/${item.id}`}>{item.name}</Link>
                                                                </h3>
                                                            </div>
                                                        </td>

                                                        <td className="price-col">
                                                            {maskPriceDecimal(0 < item.sale_price ?

                                                                item.sale_price :

                                                                item.price

                                                            )}
                                                        </td>

                                                        <td className="quantity-col">


                                                            <div hidden={this.state.loading == true} className="cart-product-quantity" >
                                                                {/* <select className="form-control" value={item.qty} onChange={(e) => {
                                                                    this.salvar(item.id, parseInt(e.target.value), 1);
                                                                }}>
                                                                    <option value="0">0</option>

                                                                    {(() => {
                                                                        let elements = [];
                                                                        for (let i = 1; i <= item.estoque && i <= 10; i++) {
                                                                            elements.push(<option key={i} value={i}>{i}</option>)
                                                                        }
                                                                        return elements;
                                                                    })()}


                                                                </select> */}

                                                                <div style={{ display: 'flex' }}>

                                                                    <div className="btn-qty-custom-left" onClick={(e) => {
                                                                        if (parseInt(item.qty) - 1 > 0) {
                                                                    this.salvar(item.id, parseInt(item.qty)-1, 1);

                                                                        }
                                                                    }}>
                                                                        -
                                                                    </div>
                                                                    <div className="input-qty-custom">
                                                                        {item.qty}
                                                                    </div>
                                                                    <div className="btn-qty-custom-right" onClick={(e) => {
                                                                        if (parseInt(item.qty) + 1 <= 10 && parseInt(item.qty)<parseInt(item.estoque)) {
                                                                            
                                                                                this.salvar(item.id, parseInt(item.qty)+1, 1);

                                                                            

                                                                        }
                                                                    }}>
                                                                        +
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </td>

                                                        <td className="total-col">
                                                            {maskPriceDecimal(parseFloat(item.sale_price == null ? item.price : item.sale_price) * parseInt(item.qty))}
                                                        </td>

                                                        <td className="remove-col">
                                                            {this.state.loading[item.id] != true && <button className="btn-remove" onClick={(e) => {
                                                                e.preventDefault();
                                                                this.remove(item.id);

                                                            }}><i className="icon-close"></i></button>}
                                                            {this.state.loading[item.id] == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div></div>}
                                                        </td>

                                                    </tr>
                                                ) :
                                                <tr>
                                                    <th colSpan="4">
                                                        <p className="pl-2 pt-1 pb-1"> Não há produtos no carrinho </p>
                                                    </th>
                                                </tr>
                                            }

                                        </tbody>
                                    </table>

                                    {/* <div className="cart-bottom">
                                        <div className="cart-discount" style={{ minHeight: isIEBrowser() ? '40px' : 'auto' }}>
                                            <form action="#">
                                                <div className="input-group">
                                                    <input type="text" className="form-control" required="" placeholder="coupon code" />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-outline-primary-2" type="submit"><i className="icon-long-arrow-right"></i></button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                        <button className="btn btn-outline-dark-2" onClick={() => this.setState({ update: !this.state.update })}><span>Atualizar carrinho</span><i className="icon-refresh"></i></button>
                                    </div> */}
                                </div>
                                <aside className="col-lg-3">
                                    <div className="summary summary-cart">
                                        <h3 className="summary-title">Valor Total</h3>

                                        <table className="table table-summary">
                                            <tbody>
                                                <tr className="summary-subtotal">
                                                    <td>Subtotal:</td>
                                                    <td>{maskPriceDecimal(total)}</td>
                                                </tr>
                                                {/* <tr hidden={this.props.cartlist.length==0 || this.props.token==undefined}>
                                                    <td colSpan="2" style={{ paddingTop: 15 }}>
                                                        <div style={{ position: 'relative' }}>

                                                            <input disabled={this.state.loading_frete} style={this.state.loading_frete == true ? { marginBottom: '1rem' } : { backgroundColor: 'white', marginBottom: '1rem' }} value={this.props.cep} onChange={(e) => {
                                                                let cep = maskCep(e.target.value);
                                                                this.props.changeState({ cep });
                                                                let text = e.target.value.replace('-', '');
                                                                if (text.length == 8) {
                                                                    this.get_preco_prazo(text);
                                                                }
                                                            }} type="text" placeholder="Digite seu CEP" className="form-control" required />
                                                            {this.state.loading_frete == true && <div style={{ position: 'absolute', top: 10, right: 10 }}>

                                                                <div style={{ height: 20, width: 20, marginLeft: 10 }} className="spinner-border text-primary" role="status"  >
                                                                    <span className="sr-only">Loading...</span>
                                                                </div>
                                                            </div>}
                                                            <div style={{
                                                                textAlign: 'left',
                                                                fontSize: '11px',
                                                                marginBottom: '1rem'
                                                            }}>
                                                                <span style={{ color: 'red' }}>{this.state.msgErro}</span>
                                                            </div>
                                                        </div>

                                                    </td>
                                                </tr> */}
                                                {/* <tr hidden={this.props.precos_prazos.length == 0} className="summary-shipping">
                                                    <td>Opções de Entrega:</td>
                                                    <td>&nbsp;</td>
                                                </tr> */}

                                                {/* {this.props.precos_prazos.map((item, id) => (<tr key={id} hidden={this.props.precos_prazos.length == 0} className="summary-shipping-row">
                                                    <td>
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio"
                                                                id={"shipping"+id}
                                                                name="shipping"
                                                                className="custom-control-input"
                                                                onChange={(e) => {
                                                                    console.log(item);
                                                                    this.onChangeShipping(item)
                                                                }}
                                                                defaultChecked={this.props.shipping.delivery_method_id == item.delivery_method_id}
                                                            />
                                                            <label className="custom-control-label" htmlFor={"shipping"+id}>{item.description}</label>
                                                        </div>
                                                    </td>
                                                    <td>R$ {item.final_shipping_cost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace('.', ',')}</td>
                                                </tr>
                                                ))} */}

                                                {/* <tr className="summary-shipping-row">
                                                    <td>
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio"
                                                                id="standard-shipping"
                                                                name="shipping"
                                                                className="custom-control-input"
                                                                onChange={(e) => this.onChangeShipping("standard")}
                                                                defaultChecked={"standard" === this.props.shipping ? true : false}
                                                            />
                                                            <label className="custom-control-label" htmlFor="standard-shipping">Standard:</label>
                                                        </div>
                                                    </td>
                                                    <td>$10.00</td>
                                                </tr>

                                                <tr className="summary-shipping-row">
                                                    <td>
                                                        <div className="custom-control custom-radio">
                                                            <input type="radio"
                                                                id="express-shipping"
                                                                name="shipping"
                                                                className="custom-control-input"
                                                                onChange={(e) => this.onChangeShipping("express")}
                                                                defaultChecked={"express" === this.props.shipping ? true : false}
                                                            />
                                                            <label className="custom-control-label" htmlFor="express-shipping">Express:</label>
                                                        </div>
                                                    </td>
                                                    <td>$20.00</td>
                                                </tr> */}

                                                {/* <tr className="summary-shipping-estimate">
                                                    <td>Estimate for Your Country<br /> <a href={`${process.env.PUBLIC_URL}/shop/dashboard`}>Change address</a></td>
                                                    <td>&nbsp;</td>
                                                </tr> */}


                                            </tbody>
                                        </table>
                                        {/* <div hidden={this.props.precos_prazos.length == 0 || this.props.shipping.delivery_method_id == ''} style={{
                                            display: 'flex', justifyContent: 'space-between', paddingTop: '1rem',
                                            borderTop: '.1rem solid #ebebeb',
                                            marginTop: '1rem'
                                        }} >
                                            <p style={{ color: '#19386e', fontSize: '1.6rem', fontWeight: '400', flex: 1 }}>Total:</p>
                                            <p style={{ color: '#19386e', fontSize: '1.6rem', fontWeight: '400', flex: 1,textAlign:'right' }}>
                                                R$ {(total + this.props.shipping.final_shipping_cost).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).replace('.', ',')}
                                            </p>
                                        </div> */}
                                        <Link style={{ marginTop: '1.2rem' }} hidden={this.props.token == undefined || this.props.cartlist.length == 0} to={`${process.env.PUBLIC_URL}/shop/address`}
                                            className="btn btn-outline-primary-2 btn-order btn-block"

                                        >
                                            <span className="btn-text">Continuar</span>
                                            <span className="btn-hover-text">Selecionar Endereço de Entrega</span>
                                        </Link>
                                        {this.props.token == undefined && this.props.cartlist.length > 0 && <button style={{ marginTop: '1.2rem' }}
                                            onClick={() => {
                                                this.props.showModal('login');
                                            }}
                                            className="btn btn-outline-primary-2 btn-order btn-block"

                                        >
                                            Faça Login para Continuar
                                        </button>}
                                    </div>

                                    <Link to={`${process.env.PUBLIC_URL}/shop/sidebar/list`} className="btn btn-outline-dark-2 btn-block mb-3"><span>Continuar Comprando</span><i className="icon-shopping-bag"></i></Link>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export const mapStateToProps = (state) => {
    console.log(state.cartlist);
    return ({
        cartlist: state.cartlist.cart,
        total: getCartTotal(state.cartlist.cart),
        shipping: state.cartlist.shipping,
        cep: state.cartlist.cep,
        token: state.user.token,

    })
}

export default connect(mapStateToProps, { changetQty, removeFromCart, changeShipping, changeState, showModal })(Cart);