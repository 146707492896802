import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import amex from '../../../imgs/cartoes/amex.png';
import elo from '../../../imgs/cartoes/elo.png';
import hypercard from '../../../imgs/cartoes/hypercard.png';
import mastercard from '../../../imgs/cartoes/mastercard.png';
import visa from '../../../imgs/cartoes/visa.png';
import { showModal, removeFromWishlist, login, logout, changeState, replaceCart, replaceWishList } from '../../../actions';
import { connect } from 'react-redux';

class FooterFive extends Component {
    render() {
        const { logo = "assets/images/demos/demo-3/logo-footer.png" } = this.props;
        return (
            <footer className="footer">
                <div className="footer-middle">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6 col-lg-4">
                                <div className="widget widget-about">
                                    <img src={process.env.PUBLIC_URL + '/' + logo} className="footer-logo" alt="Footer Logo" width="195" height="25" />

                                    <div className="widget-call">
                                        <i className="icon-phone"></i>
                                        <span>Quer falar conosco?</span>
                                        <Link style={{ marginTop: '1rem' }} to="tel:#">(17) 3422-2131</Link>
                                    </div>
                                   
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg-4">
                                <div className="widget" style={{marginBottom:10}}>
                                    <h4 className="widget-title">Institucional</h4>

                                    <ul className="widget-list">
                                        <li><Link to={`${process.env.PUBLIC_URL}/sobre_nos`}>Sobre nós</Link></li>
                                        {/* <li><Link to="#">Termos de uso</Link></li> */}
                                        <li><Link to={`/politica_privacidade`}>Políticas de privacidade</Link></li>
                                    </ul>
                                </div>
                                <figure className="footer-payments" style={{ marginBottom: 25}}>
                                        {/* <img src={`${process.env.PUBLIC_URL}/assets/images/payments.png`} alt="Payment methods" width="272" height="20" /> */}
                                        <div className="row" style={{margin:0}}>
                                            <img src={visa} style={{ width: 40, height: 40, objectFit: "contain", marginRight: 15 }} />
                                            <img src={mastercard} style={{ width: 40, height: 40, objectFit: "contain", marginRight: 15 }} />
                                            <img src={amex} style={{ width: 40, height: 40, objectFit: "contain", marginRight: 15, }} />
                                            <img src={elo} style={{ width: 40, height: 40, objectFit: "contain", marginRight: 15 }} />
                                            <img src={hypercard} style={{ width: 40, height: 40, objectFit: "contain", marginRight: 0 }} />

                                        </div>
                                    </figure>
                            </div>

                            <div className="col-sm-6 col-lg-4" style={{marginBottom:10}}>
                                <div className="widget">
                                    <h4 className="widget-title">Minha conta</h4>

                                    <ul className="widget-list">
                                        {this.props.user.id==undefined && <li><Link to="#" onClick={(e)=>{
                                            e.preventDefault();
                                            this.props.showModal('login');
                                        }}>Entrar/Criar novo cadastro</Link></li>}
                                        {this.props.user.id!=undefined && <li><Link to="/conta/editar_usuario">Alterar dados pessoais</Link></li>}
                                        {this.props.user.id!=undefined && <li><Link to="/conta/enderecos">Editar endereços</Link></li>}
                                    </ul>
                                </div>
                            </div>

                            {/* <div className="col-sm-6 col-lg-3">
                                <div className="widget">
                                    <h4 className="widget-title">Atendimento</h4>

                                    <ul className="widget-list">
                                        <li><Link to="#">Central de atendimento</Link></li>
                                        <li><Link to={`#`}>Formas de pagamento disponíveis</Link></li>
                                        <li><Link to={`#`}>Retirar na loja</Link></li>
                                    </ul>
                                </div>
                            </div> */}
                          

                            <div className="col-sm-12">
                                <div className="widget">
                                    {/* <h4 className="widget-title">Institucional</h4> */}
                                    <p style={{color:'black',textAlign:'justify',fontSize:12}}>HG HERRERA EIRELI | HEFARMA |CNPJ 04.703.383/0001-15 |I.E 718109038117 | Rua Amazonas, 3245 Votuporanga (SP) | CEP 15500-004 | Horário de Atendimento ao cliente: de segunda à segunda 07h00 às 00:00h | 17-3422-2131 | Farmacêutica responsável: Claudia Larissa da Silva Ramos | CRF: 78634 | CEVS: 355710501-477-000156-1-0  | As informações contidas neste site como promoções e ofertas, não devem ser usadas para automedicação e não substituem, em hipótese alguma, a medicação prescrita pelo profissional da área médica. Somente o médico está em condições de diagnosticar qualquer problema de saúde e prescrever o tratamento adequado. As fotos contidas em nosso site são meramente ilustrativas. Os preços e as promoções, são válidos apenas para compras via Internet. Preços e disponibilidade sujeitos a alterações no decorrer do dia Copyright © 2022 Hefarma. Todos os direitos reservados.</p>
{/* 
                                    <ul className="widget-list">
                                        <li><Link to={`${process.env.PUBLIC_URL}/about`}>Sobre nós</Link></li>
                                        <li><Link to="#">Trabalhe conosco</Link></li>
                                        <li><Link to={`${process.env.PUBLIC_URL}/pages/about`}>Redes sociais</Link></li>
                                    </ul> */}
                                </div>
                            </div>

                            
                        </div>
                    </div>
                </div>

                <div className="footer-bottom">
                    <div className="container">
                        <p className="footer-copyright">Copyright © {(new Date()).getFullYear()} Hefarma. Todos os direitos reservados.</p>

                    </div>
                </div>
            </footer>
        )
    }
}
export const mapStateToProps = (state) => ({
    wishlist: state.wishlist.list,
    user: state.user.user,
    token: state.user.token,
})

export default connect(mapStateToProps, { showModal, removeFromWishlist, login, logout, changeState, replaceCart, replaceWishList })(FooterFive);