import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCartCount } from '../../../../../services';
import { removeFromCart } from '../../../../../actions';
import { safeContent } from '../../../../../utils/utils';
import { URL } from '../../../../../variables';
import { maskPriceDecimal } from '../../../../../Auxiliar/Masks';

class CartMenuFour extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: {}
        }
    }

    remove(id) {
        if(this.props.token==undefined){
            this.props.removeFromCart(id);
            return;
        }
        let loading = { ...this.state.loading };
        loading[id] = true;
        this.setState({ loading: loading, msgErro: '' });
        let form = new FormData();
        fetch(`${URL}api/carrinho/excluir_item_carrinho/${id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: ''
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                let loading = { ...this.state.loading };
                loading[id] = false;
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading: loading, msgErro: erro });
                }
                else {
                    this.props.removeFromCart(id);
                    this.setState({ loading: loading });

                }




            } catch (err) {
                console.log(err);
                let loading = { ...this.state.loading };
                loading[id] = false;
                this.setState({ loading: loading, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                let loading = { ...this.state.loading };
                loading[id] = false;
                this.setState({ loading: loading, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }

    render() {
        const { cartlist, removeFromCart } = this.props;
        console.log('header', this.props.cartlist);
        return (
            // default
            <div className="dropdown cart-dropdown">
                <a href={`${process.env.PUBLIC_URL}/shop/cart`} className="dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
                    <div className="icon">
                        <i className="icon-shopping-cart"></i>
                        <span className="cart-count">{getCartCount(cartlist)}</span>
                    </div>
                    <p>Carrinho</p>
                </a>

                <div className={`dropdown-menu dropdown-menu-right ${cartlist.length === 0 ? 'text-center' : ''}`} style={cartlist.length === 0 ? { width: '200px' } : {}}>
                    <div className="dropdown-cart-products">
                        {cartlist.map((item, index) => (
                            <div className="product" key={index} style={{ position: 'relative' }}>
                                <div className="product-cart-details">
                                    <h4 className="product-title">
                                        <Link to={`${process.env.PUBLIC_URL}/product/default/${item.id}`} dangerouslySetInnerHTML={safeContent(item.name)}></Link>
                                    </h4>

                                    <span className="cart-product-info">
                                        <span className="cart-product-qty">{item.qty}</span>
                                        x {maskPriceDecimal(item.sale_price != null ? item.sale_price : item.price)}
                                    </span>
                                </div>

                                <figure className="product-image-container">
                                    <Link to={`${process.env.PUBLIC_URL}/product/default/${item.id}`} className="product-image">
                                        <img src={URL + item.fotos[0].foto} data-oi={URL + item.fotos[0].foto} alt="product" />
                                    </Link>
                                </figure>
                                <button className="btn-remove" title="Remove Product" onClick={(e) => { e.preventDefault(); this.remove(item.id); }}><i className="icon-close"></i></button>
                                {this.state.loading[item.id] == true && <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'rgba(255,255,255,0.5)' }}>
                                    <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div></div>
                                </div>}
                            </div>
                        ))}
                    </div>

                    {cartlist.length === 0 ?
                        <p>Carrinho está vazio.</p> : ''
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        cartlist: state.cartlist.cart ? state.cartlist.cart : [],
        token: state.user.token,

    }
}

export default connect(mapStateToProps, { removeFromCart })(CartMenuFour);