import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Helmet } from 'react-helmet';

// import Custom Components
import HeaderSeven from '../../components/common/header/header-7';
import OuterOverlay from '../../components/common/overlay/outer-overlay';
import FooterFive from '../../components/common/footer/footer-five';
import OwlCarousel from '../../components/features/owl-carousel';
import QuickView from '../../components/features/product/quickview';
import ServiceBox from '../../components/features/service-list/service-two';
import NewsletterModal from '../../components/features/newsletter-modal';
import MobileMenuTwo from '../../components/common/header/common/mobile-menus/menu-2';

import DealProduct from './deal-product';
import IntroSlide from './intro-slide';
import Banner from './banner';
import BannerGroup from './banner-group';
import NewCollection from './new-collection';
import TrendingCollection from './trendy-collection';
import ProductCollection from './product-collection';
import { introSlider, brandSlider } from './settings'

// import Utils
import { initSettings, isIEBrowser } from '../../utils/utils';
import _data from '../../mock_data/data.json';
import store from '../../store'
import { changeDemo, outerLoading, innerLoading } from '../../actions';
import { productSlider } from './settings';

import style from './style.scss';

import { URL } from '../../variables';
import { changeHome,logout } from '../../actions';

import ChatCliente from '../../components/Chat/ChatCliente';
import { connect } from 'react-redux';

class SobreNos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            produtos_novos: [],
            produtos_categorias: [],
            produtos_recomendados: [],
            produtos_populares: { avaliados: [], oferta: [], vendidos: [] },
            produtos_tendencia: [],
            banners_topo: [],
            banners_promocao: [],
            banner_faixa_meio: [],
            banners_promocao_limitada: [],
            categorias: [],
            nossas_marcas: [],
            banner_height:window.innerWidth/4
        }
    }

    get_dados_home() {
        store.dispatch(innerLoading());

        fetch(`${URL}api/get_dados_home?cat_id=all`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                // 'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');

                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                }
                else {
                    this.props.changeHome({
                        produtos_novos: resp.produtos_novos,
                        produtos_categorias: resp.produtos_categorias,
                        produtos_recomendados: resp.produtos_recomendados,
                        produtos_populares: resp.produtos_populares,
                        categorias: resp.categorias,
                        banners_topo: resp.banners_topo,
                        banners_promocao: resp.banners_promocao,
                        banner_faixa_meio: resp.banner_faixa_meio,
                        banners_promocao_limitada: resp.banners_promocao_limitada,
                        nossas_marcas: resp.nossas_marcas
                    });

                }




            } catch (err) {
                console.log(err);

            }

        })
            .catch((err) => {
                console.log(err);
            });
    }
    componentDidMount() {
        initSettings();
        this.resize_event=(function(){
            this.setState({banner_height:window.innerWidth/4})
        }).bind(this);
        window.addEventListener('resize',this.resize_event)
        store.dispatch(changeDemo("4"));
    }

    UNSAFE_componentWillMount() {
        style.use();
        store.dispatch(outerLoading());
        this.get_dados_home();

    }

    componentWillUnmount() {
        window.removeEventListener('resize',this.resize_event);
        style.unuse();
    }

    render() {
        console.log('Banner height: '+this.state.banner_height)
        return (
            <React.Fragment>
                <Helmet>
                    <title>Hefarma</title>
                </Helmet>

                <h1 className="d-none">Hefarma</h1>

                <OuterOverlay />

                <div className="page-wrapper">
                    <HeaderSeven />

                    <div className="main">
                        {this.props.banners_topo.length > 0 && <div className="intro-slider-container mb-5" style={{height:'auto'}}>
                            <OwlCarousel adClass="intro-slider owl-theme owl-nav-inside owl-light" carouselOptions={introSlider} >
                                {
                                    this.props.banners_topo.map((item, index) =>
                                        <IntroSlide banner_height={this.state.banner_height} data={item} key={index} />
                                    )
                                }
                            </OwlCarousel>

                            <span className="slider-loader"></span>
                        </div>}

                        <div className="container">
                            <h2 className="title text-center mb-4">Sobre Nós</h2>
                            <p style={{fontWeight:'bold'}}>MISSÃO</p>
                            <p style={{textAlign:'justify'}}>Comercializar produtos com preços acessíveis e oferecer sempre qualidade em nosso atendimento de forma a estabelecer laços de confiança com nossos clientes, evolução da marca e oportunidade de crescimento aos colaboradores.</p>
                            <br/>
                            <p style={{fontWeight:'bold'}}>VISÃO</p>
                            <p style={{textAlign:'justify'}}>Ser referência regional na venda de produtos farmacêuticos e perfumaria com preços altamente competitivos e atendimento de excelência.</p>
                            <br/>
                            <p style={{fontWeight:'bold'}}>VALORES</p>
                            <p style={{textAlign:'justify'}}><span style={{textDecoration:'underline'}}>Aprimoramento continuo com simplicidade:</span> Melhorar nossos processos de atendimento ao cliente constantemente de forma simples e cordial.</p>
                            <p style={{textAlign:'justify'}}><span style={{textDecoration:'underline'}}>Foco:</span> Não nos afastarmos de nossa missão focada na evolução da empresa, ótimo atendimento ao cliente e geração de oportunidades aos colaboradores</p>
                            <p style={{textAlign:'justify'}}><span style={{textDecoration:'underline'}}>Ética:</span> Realizar o melhor de nosso trabalho sem negligenciar em momento algum os princípios humanos e sociais.</p>
                            <p style={{textAlign:'justify'}}><span style={{textDecoration:'underline'}}>Transparência:</span> Ser sempre claro a respeito de nossos princípios, ambições e motivação.</p>
                            <p style={{textAlign:'justify'}}><span style={{textDecoration:'underline'}}>Dedicação total a empresa, colaboradores e clientes:</span> Trabalhar incansavelmente em prol de nossa missão.</p>
                            <p style={{textAlign:'justify'}}><span style={{textDecoration:'underline'}}>Respeito a vida:</span> Respeitar cada ser humano como igual e contribuir para a saúde de todos.</p>

                        </div>
                        <br/>
                        <br/>




                     
                    </div>
                    <ChatCliente />
                    <FooterFive logo="assets/images/logo.png" />
                </div>
                <MobileMenuTwo adClass="mobile-menu-light" />
                {/* <NewsletterModal demo="4"/> */}
            </React.Fragment>
        )
    }
}

// export default IndexFour;
export const mapStateToProps = (state) => ({
    token: state.user.token,
    produtos_novos: state.home.produtos_novos,
    produtos_categorias: state.home.produtos_categorias,
    produtos_recomendados: state.home.produtos_recomendados,
    produtos_populares: state.home.produtos_populares,
    categorias: state.home.categorias,
    banners_topo: state.home.banners_topo,
    banners_promocao: state.home.banners_promocao,
    banner_faixa_meio: state.home.banner_faixa_meio,
    banners_promocao_limitada: state.home.banners_promocao_limitada,
    nossas_marcas: state.home.nossas_marcas

})

export default connect(mapStateToProps, { changeHome })(SobreNos);