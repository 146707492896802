import React, { Component } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { Link } from 'react-router-dom';

// import Custom Components
import { connect } from 'react-redux';
import { URL } from '../../../../variables';
import Pagination from '../../../features/pagination';
import SweetAlert from 'react-bootstrap-sweetalert';
import { logout } from '../../../../actions';
import Modal from 'react-modal';

import embalagem_default from '../../../../imgs/embalagem_default.jpg'
import amex from '../../../../imgs/cartoes/amex.png';
import elo from '../../../../imgs/cartoes/elo.png';
import hypercard from '../../../../imgs/cartoes/hypercard.png';
import mastercard from '../../../../imgs/cartoes/mastercard.png';
import visa from '../../../../imgs/cartoes/visa.png';

import jcb from '../../../../imgs/cartoes/jcb.png';
import aura from '../../../../imgs/cartoes/aura.png';
import diners_club from '../../../../imgs/cartoes/diners_club.png';
import discover from '../../../../imgs/cartoes/discover.png';

import Stepper from 'react-stepper-horizontal';
import { maskPriceDecimal } from '../../../../Auxiliar/Masks';
import ReactTooltip from 'react-tooltip';

const customStyles = {
    content: {
        right: 0,
        left: 0
        // top: '50%',
        // transform: 'translateY(-50%)'
    },
    overlay: {
        backgroundColor: 'rgba(51,51,51,0.6)',
        zIndex: '10000',
        // overflowY: 'scroll'
    }
};
class Pedidos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pedidos: [],
            current_page: 1,
            last_page: 1,
            total: 1,
            per_page: 1,
            page: 1,
            loading: false,
            show_warning: false,
            show_erro: false,
            msgErro: '',
            msgErroResposta: '',
            msgErroPedido: '',
            user_to_delete: '',
            modal_open: false,
            loading_save: false,

            pedido: { id: '' },
            type: '',
            loading_pedido: false,
            show_warning_cancelar: false
        };
    }




    get_pedidos(page = 1) {
        this.setState({ loading: true });

        fetch(`${URL}api/get_pedidos?page=${page}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                }
                else {
                    if (page == this.state.page) {
                        console.log('updated');
                        this.setState({
                            pedidos: resp.pedidos,
                            loading: false,
                            current_page: resp.current_page,
                            last_page: resp.last_page,
                            total: resp.total,
                            per_page: resp.per_page
                        });
                    }
                    if (page == 1 && this.state.type == 'new' && resp.pedidos.length > 0) {
                        this.setState({ type: '', pedido: resp.pedidos[0], modal_open: true });
                    }
                }




            } catch (err) {
                console.log(err);

            }

        })
            .catch((err) => {
                console.log(err);
            });
    }

    componentDidMount() {
        if (this.props.location.search.replace('?type=', '') != '') {
            this.setState({ type: this.props.location.search.replace('?type=', '') }, () => {
                this.get_pedidos(this.state.page);
                this.props.history.push({
                    pathname: '/conta/historico_compras'
                });
            });
        }
        else {

            this.get_pedidos(this.state.page);

        }
    }


    cancelar_pedido() {
        this.setState({ loading_pedido: true, msgErroPedido: '',show_warning_cancelar:false });
        let form = new FormData();
        form.append('pedido_id', this.state.pedido.id);

        fetch(`${URL}api/cancelar_pedido/${this.state.pedido.id}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    this.setState({ loading_pedido: false, msgErroPedido: erro });
                }
                else {
                    this.setState({ loading_pedido: false, pedido: resp.pedido,page:1 });
                    this.get_pedidos(1);
                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_pedido: false, msgErroPedido: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_pedido: false, msgErroPedido: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }

    checkStep(status){
        if(status==='Recebemos seu Pedido'){
            return 0;
        }
        else if(status==='O seu pagamento foi aprovado!'){
            return 1;
        }
        else if(status==='Sua Nota Fiscal foi emitida'){
            return 2;
        }
        else if(status==='Despachado'){
            return 3;
        }
        else if(status==='Pronto para envio'){
            return 4;
        }
        else if(status==='Em trânsito'){
            return 5;
        }
        else if(status==='Saiu para Entrega'){
            return 6;
        }
        else if(status==='Entregue'){
            return 7;
        }
    }


    checkStepJadlog(status){
        if(status==='Recebemos seu Pedido'){
            return 0;
        }
        else if(status==='O seu pagamento foi aprovado!'){
            return 1;
        }
        else if(status==='Sua Nota Fiscal foi emitida'){
            return 2;
        }
        else if(status==='COLETA' || status=='EMISSAO' || status=='TRANSFERENCIA'){
            return 3;
        }
        else if(status==='ENTRADA'){
            return 4;
        }
        else if(status==='EM ROTA'){
            return 5;
        }
        else if(status==='ENTREGUE'){
            return 6;
        }
    }


    checkStepLocal(status) {
        if (status === 'Recebemos seu Pedido') {
            return 0;
        }
        else if (status === 'O seu pagamento foi aprovado!') {
            return 1;
        }
        // else if(status==='Sua Nota Fiscal foi emitida'){
        //     return 2;
        // }
        else if (status === 'ENTREGUE') {
            return 2;
        }
    }

    checkStepRetirada(status) {
        if (status === 'Recebemos seu Pedido') {
            return 0;
        }
        else if (status === 'O seu pagamento foi aprovado!') {
            return 1;
        }
        else if(status==='Pronto para retirada'){
            return 2;
        }
        else if (status === 'Retirado') {
            return 3;
        }
    }




    render() {
        let pedidos = this.state.pedidos;

        return (
            <div>

                <table className="table table-cart table-mobile">

                    <thead>
                        <tr>
                            <th>Pedido</th>
                            <th>Nº Produtos</th>
                            <th>Status</th>

                            <th>Valor Total</th>
                            <th>Status</th>

                            <th>Ações</th>
                        </tr>
                    </thead>
                    {this.state.loading == false && <tbody>
                        {pedidos.length > 0 ?
                            pedidos.map((item, index) =>
                                <tr key={index}>
                                    <td className="product-col">
                                        <div className="product">


                                            <h3 className="product-title">
                                                #{item.id}
                                            </h3>
                                        </div>
                                    </td>
                                    <td className="product-col">
                                        <div className="product">


                                            <h3 className="product-title">
                                                {item.num_produtos}
                                            </h3>
                                        </div>
                                    </td>
                                    <td className="product-col">
                                        <div className="product">


                                            <h3 className="product-title">
                                                {item.status}
                                            </h3>
                                        </div>
                                    </td>

                                    <td className="product-col">
                                        <div className="product">


                                            <h3 className="product-title">
                                                {
                                                    maskPriceDecimal(item.total)

                                                }
                                            </h3>
                                        </div>
                                    </td>

                                    <td className="product-col">
                                        <div className="product">


                                            <h3 className="product-title">
                                                {
                                                    item.status
                                                }
                                            </h3>
                                        </div>
                                    </td>

                                    <td style={{ width: 100 }}>
                                        <ReactTooltip />

                                        <button data-tip="Visualizar Pedido" style={{ width: 40, height: 40, minWidth: 40, borderRadius: 10, marginRight: 10 }} onClick={() => {
                                            this.setState({ modal_open: true, pedido: item });
                                        }}
                                            className="destroy_it btn btn-success"><i
                                                className="fas fa-eye"></i></button>

                                    </td>
                                </tr>
                            ) :
                            <tr>
                                <th colSpan="4">
                                    <p className="pl-2 pt-1 pb-1"> Não há pedidos no sistema </p>
                                </th>
                            </tr>
                        }
                    </tbody>}
                    {this.state.loading == true && <tbody><tr><td colSpan="4"><div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div></div></td></tr></tbody>}
                </table>
                <Pagination count={this.state.total} unit={this.state.per_page} onChange={(page) => {
                    page += 1;
                    this.setState({ page });
                    this.get_pedidos(page);
                }} />

                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                        // this.setState({ show_warning_cancelar: false });
                        this.cancelar_pedido();
                    }}
                    onCancel={() => {
                        this.setState({ show_warning_cancelar: false });
                    }}
                    show={this.state.show_warning_cancelar}
                    confirmBtnText='Sim, desejo cancelar'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnStyle={{ color: 'white' }}
                    showCancel={true}
                >
                    ao cancelar este pedido ele não será mais entregue no endereço solicitado
                </SweetAlert>

                <SweetAlert
                    warning
                    title={"Atenção"}
                    onConfirm={() => {
                    }}
                    onCancel={() => {
                        this.setState({ show_warning: false });
                    }}
                    show={this.state.show_warning}
                    confirmBtnText='Sim, desejo apagar'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnStyle={{ color: 'white' }}

                    showCancel={true}
                >
                    ao deletar este pedido ele não estará mais disponível no sistema

                </SweetAlert>
                <SweetAlert
                    danger
                    title={"Erro"}
                    onConfirm={() => {
                        this.setState({ show_erro: false });
                    }}
                    show={this.state.show_erro}
                    confirmBtnText='OK'
                    confirmBtnBsStyle="primary"
                    confirmBtnStyle={{ color: 'white' }}

                >
                    {this.state.msgErro}

                </SweetAlert>
                <Modal
                    isOpen={this.state.modal_open}
                    onRequestClose={() => this.setState({ modal_open: false })}
                    contentLabel="Example Modal"
                    style={customStyles}
                >
                    <div style={{ position: 'fixed', top: 10, right: 10, cursor: 'pointer' }} onClick={() => {
                        this.setState({ modal_open: false });
                    }}>
                        <i style={{ color: 'white', fontSize: '2.3rem' }} className="fas fa-times"></i>
                    </div>
                    {this.state.pedido.id != '' && <div>

                        <div style={{ padding: 10, borderBottom: '1px solid grey' }}>
                            <h4 style={{ textAlign: 'center' }}>Pedido #{this.state.pedido.id}</h4>
                        </div>
                        <div style={{ paddingTop: 15, paddingBottom: 15 }}>
                            <div className="row">
                                <div className="col-12" style={{ overflowX: 'auto' }}>
                                    {/* {this.state.pedido.status != 'Pedido cancelado' && <div style={{ minWidth: 1100 }}>
                                        <Stepper defaultColor={'#999'} defaultTitleColor={'#555'} activeTitleColor={'#19386e'} titleFontSize={14} activeColor={'#19386e'} completeColor={'#005b8c'} completeTitleColor={'#005b8c'} steps={[{ title: 'Recebemos seu Pedido' }, { title: 'O seu pagamento foi aprovado!' }, { title: 'Sua Nota Fiscal foi emitida' }, { title: 'Despachado' }, { title: 'Pronto para envio' }, { title: 'Em trânsito' }, { title: 'Saiu para Entrega' }, { title: 'Entregue' }]}
                                            activeStep={this.checkStep(this.state.pedido.status)}
                                            />
                                    </div>} */}
                                    {this.state.pedido.entrega!=undefined && this.state.pedido.entrega.entrega_local == false && this.state.pedido.status != 'Pedido cancelado' && <div style={{ minWidth: 1100 }}>
                                        <Stepper defaultColor={'#999'} defaultTitleColor={'#555'} activeTitleColor={'#19386e'} titleFontSize={14} activeColor={'#19386e'} completeColor={'#005b8c'} completeTitleColor={'#005b8c'} steps={[{ title: 'Recebemos seu Pedido' }, { title: 'O seu pagamento foi aprovado!' }, { title: 'Sua Nota Fiscal foi emitida' }, { title: 'Despachado' }, { title: 'Pronto para envio' }, { title: 'Em trânsito' },  { title: 'Entregue' }]}
                                            activeStep={this.checkStepJadlog(this.state.pedido.status)}
                                        />
                                    </div>}
                                    {this.state.pedido.entrega!=undefined && this.state.pedido.entrega.entrega_local == true && this.state.pedido.status != 'Pedido cancelado' && <div style={{ minWidth: 1100 }}>
                                        <Stepper defaultColor={'#999'} defaultTitleColor={'#555'} activeTitleColor={'#19386e'} titleFontSize={14} activeColor={'#19386e'} completeColor={'#005b8c'} completeTitleColor={'#005b8c'} steps={[{ title: 'Recebemos seu Pedido' }, { title: 'O seu pagamento foi aprovado!' }, { title: 'Entregue' }]}
                                            activeStep={this.checkStepLocal(this.state.pedido.status)}
                                        />
                                    </div>}
                                    {this.state.pedido.retirada==true  && this.state.pedido.status != 'Pedido cancelado' && <div style={{ minWidth: 1100 }}>
                                        <Stepper defaultColor={'#999'} defaultTitleColor={'#555'} activeTitleColor={'#19386e'} titleFontSize={14} activeColor={'#19386e'} completeColor={'#005b8c'} completeTitleColor={'#005b8c'} steps={[{ title: 'Recebemos seu Pedido' }, { title: 'O seu pagamento foi aprovado!' },{ title: 'Pronto para retirada' }, { title: 'Retirado' }]}
                                            activeStep={this.checkStepRetirada(this.state.pedido.status)}
                                        />
                                    </div>}
                                    {this.state.pedido.status == 'Pedido cancelado' && <div>
                                        <h4 style={{ color: 'red', textAlign: 'center', marginTop: '1.4rem' }}>Pedido Cancelado</h4>
                                    </div>}
                                    <hr />
                                </div>
                                {/* <div className="col-12 col-sm-6 col-md-4 mt-2" >
                                    <p style={{ color: 'black' }}><span style={{ fontWeight: 'bold' }}>Usuário:<br /></span> {this.state.pedido.user.name}</p>
                                </div> */}

                                {this.state.pedido.transacao != null && <div className="col-12 col-sm-6 col-md-4 col-lg-3  mt-2" >
                                    <p style={{ marginTop: 0, fontWeight: 'bold', color: 'black', marginBottom: 10 }}>Forma de Pagamento:

                                    </p>
                                    <h3 className="product-title" style={{ whiteSpace: 'pre-line', fontSize: '1.4rem', fontWeight: '400' }}>
                                        <div className="d-flex">

                                            <div className="d-flex align-items-center">

                                                <img src={visa} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: this.state.pedido.transacao.credit_card.brand.toUpperCase().includes('VISA') ? 'block' : 'none' }} />
                                                <img src={mastercard} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: this.state.pedido.transacao.credit_card.brand.toUpperCase().includes('MASTER') ? 'block' : 'none' }} />
                                                <img src={amex} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: this.state.pedido.transacao.credit_card.brand.toUpperCase().includes('AME') ? 'block' : 'none' }} />
                                                <img src={elo} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: this.state.pedido.transacao.credit_card.brand.toUpperCase().includes('ELO') ? 'block' : 'none' }} />
                                                <img src={hypercard} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: this.state.pedido.transacao.credit_card.brand.toUpperCase().includes('HYPER') ? 'block' : 'none' }} />

                                                <img src={diners_club} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: this.state.pedido.transacao.credit_card.brand.includes('DINERS') ? 'block' : 'none' }} />
                                                    <img src={discover} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: this.state.pedido.transacao.credit_card.brand.includes('DISCOVER') ? 'block' : 'none' }} />
                                                    <img src={jcb} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: this.state.pedido.transacao.credit_card.brand.includes('JCB') ? 'block' : 'none' }} />
                                                    <img src={aura} style={{ width: 50, height: 50, objectFit: "contain", marginRight: 10, display: this.state.pedido.transacao.credit_card.brand.includes('AURA') ? 'block' : 'none' }} />
                                            </div>
                                            <div className="align-items-center" style={{ marginLeft: 0 }}>

                                                <p style={{ color: 'black', marginBottom: '1rem' }}><b style={{ marginRight: 10, fontWeight: 500 }}>(Crédito)</b> {this.state.pedido.transacao.credit_card.brand.toUpperCase()} </p>

                                                <p style={{ margin: 0, color: 'black' }}> •••• {this.state.pedido.transacao.credit_card.last_digits}</p>

                                            </div>
                                        </div>

                                    </h3>
                                    <p style={{ color: 'black', fontWeight: '500' }}>Status:
                                        {/* {this.state.pedido.transacao.status == 'paid' && <span style={{ color: 'green', fontWeight: 'normal' }}> Pago</span>} */}
                                        {/* {this.state.pedido.transacao.status == 'authorized' && <span style={{ color: 'green', fontWeight: 'normal' }}> Autorizado</span>}
                                        {this.state.pedido.transacao.status == 'processing' && <span style={{ color: 'darkgoldenrod', fontWeight: 'normal' }}> Processando</span>}
                                        {this.state.pedido.transacao.status == 'refunded' && <span style={{ color: 'red', fontWeight: 'normal' }}> Extornado</span>}
                                        {this.state.pedido.transacao.status == 'pending_refund' && <span style={{ color: 'red', fontWeight: 'normal' }}> Extorno pendente</span>}
                                        {this.state.pedido.transacao.status == 'refused' && <span style={{ color: 'red', fontWeight: 'normal' }}> Recursado</span>}
                                        {this.state.pedido.transacao.status == 'waiting_payment' && <span style={{ color: 'darkgoldenrod', fontWeight: 'normal' }}> Aguardando pagamento</span>} */}

                                        {(this.state.pedido.transacao.status == '2' ||this.state.pedido.transacao.status == '4' || this.state.pedido.transacao.status == '6') && <span style={{ color: 'green', fontWeight: 'normal' }}> Autorizado</span>}
                                        {(this.state.pedido.transacao.status == '05' || this.state.pedido.transacao.status == '99') && <span style={{ color: 'darkgoldenrod', fontWeight: 'normal' }}> Não autorizada</span>}
                                        {this.state.pedido.transacao.status == '57' && <span style={{ color: 'red', fontWeight: 'normal' }}> Cartão expirado</span>}
                                        {this.state.pedido.transacao.status == '78' && <span style={{ color: 'red', fontWeight: 'normal' }}> Cartão bloqueado</span>}
                                        {this.state.pedido.transacao.status == '77' && <span style={{ color: 'red', fontWeight: 'normal' }}> Cartão cancelado</span>}
                                        {this.state.pedido.transacao.status == '70' && <span style={{ color: 'darkgoldenrod', fontWeight: 'normal' }}> Problemas com o cartão de crédito</span>}
                                    </p>
                                </div>}

                                {this.state.pedido.entrega != null && <div className="col-12 col-sm-6 col-md-4 col-lg-3  mt-2" >
                                    <p style={{ marginTop: 0, fontWeight: 'bold', color: 'black', marginBottom: 10 }}>Forma de Entrega: <br /><span style={{ fontWeight: 'normal' }}>{this.state.pedido.entrega.description} - {maskPriceDecimal(parseFloat(this.state.pedido.entrega.final_shipping_cost))}</span></p>
                                </div>}
                                {this.state.pedido.retirada == false && <div className="col-12 col-sm-6 col-md-4 col-lg-3  mt-2" >
                                    <p style={{ marginTop: 0, fontWeight: 'bold', color: 'black', marginBottom: 10 }}>Endereço de Entrega:</p>

                                    <p style={{ marginTop: 0, fontWeight: 'normal', color: 'black', marginBottom: 10 }}>
                                        {this.state.pedido.endereco.rua}, {this.state.pedido.endereco.numero}
                                        <br />
                                        {this.state.pedido.endereco.bairro}, {this.state.pedido.endereco.cidade} - {this.state.pedido.endereco.estado}
                                        <br />
                                        Cep: {this.state.pedido.endereco.cep}
                                        <br />
                                        {this.state.pedido.endereco.complemento != null && this.state.pedido.endereco.complemento != '' && <span>Complemento: {this.state.pedido.endereco.complemento}</span>}
                                    </p>
                                </div>}
                                {<div className="col-12 col-sm-6 col-md-4 col-lg-3  mt-2" >
                                    <p style={{ marginTop: 0, fontWeight: 'bold', color: 'black', marginBottom: 10 }}>Endereço de Cobrança:</p>

                                    <p style={{ marginTop: 0, fontWeight: 'normal', color: 'black', marginBottom: 10 }}>
                                        {this.state.pedido.endereco_cobranca.rua}, {this.state.pedido.endereco_cobranca.numero}
                                        <br />
                                        {this.state.pedido.endereco_cobranca.bairro}, {this.state.pedido.endereco_cobranca.cidade} - {this.state.pedido.endereco_cobranca.estado}
                                        <br />
                                        Cep: {this.state.pedido.endereco_cobranca.cep}
                                        <br />
                                        {this.state.pedido.endereco_cobranca.complemento != null && this.state.pedido.endereco_cobranca.complemento != '' && <span>Complemento: {this.state.pedido.endereco_cobranca.complemento}</span>}
                                    </p>
                                </div>}
                            </div>


                        </div>

                        <h2 className="checkout-title" >Resumo dos produtos:</h2>
                        <table className="table table-cart table-mobile">

                            <thead>
                                <tr>
                                    <th>Foto</th>

                                    <th>Produto</th>
                                    <th>Quantidade</th>
                                    <th>Valor Individual</th>

                                    <th>Valor Total</th>
                                    {/* <th>Ações</th> */}
                                </tr>
                            </thead>
                            {this.state.loading == false && <tbody>
                                {this.state.pedido.produtos.map((item, index) =>
                                    <tr key={index}>
                                        <td className="product-col">
                                            <div className="product">
                                                <img style={{ width: 100, height: 100, objectFit: 'contain', paddingRight: 10 }} src={URL + item.imagem} alt="Product" />

                                            </div>
                                        </td>

                                        <td className="product-col">
                                            <div className="product">


                                                <h3 className="product-title">


                                                    {item.name}
                                                </h3>
                                            </div>

                                        </td>

                                        <td className="product-col">
                                            <div className="product">


                                                <h3 className="product-title">
                                                    {item.quantidade}
                                                </h3>
                                            </div>
                                        </td>
                                        <td className="product-col">
                                            <div className="product">


                                                <h3 className="product-title">
                                                    {maskPriceDecimal(0 < item.sale_price ?

                                                        item.sale_price :

                                                        item.price

                                                    )}
                                                </h3>
                                            </div>
                                        </td>
                                        <td className="product-col">
                                            <div className="product">


                                                <h3 className="product-title">
                                                    {maskPriceDecimal(0 < item.sale_price ?

                                                        (item.sale_price * item.quantidade) :

                                                        (item.price * item.quantidade)

                                                    )}
                                                </h3>
                                            </div>
                                        </td>

                                    </tr>)}

                            </tbody>}



                            {this.state.loading == true && <tbody><tr><td colSpan="4"><div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div></div></td></tr></tbody>}
                        </table>
                        <div className="summary-subtotal" style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '1.6rem', marginBottom: 10 }}>

                            <span style={{ color: 'black', fontWeight: 'bold', marginRight: 10 }}>Subtotal:</span>
                            <span style={{ color: 'black' }}>{maskPriceDecimal(parseFloat(this.state.pedido.total) - (this.state.pedido.entrega != null ? parseFloat(this.state.pedido.entrega.final_shipping_cost) : 0))}</span>
                        </div>
                        {this.state.pedido.entrega != null && <div className="summary-subtotal" style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '1.6rem', marginBottom: 10 }}>
                            <span style={{ color: 'black', fontWeight: 'bold', marginRight: 10 }}>{this.state.pedido.entrega.description}:</span>
                            <span style={{ color: 'black' }}>{maskPriceDecimal(parseFloat(this.state.pedido.entrega.final_shipping_cost))}</span>
                        </div>}

                        <div className="summary-total" style={{ display: 'flex', justifyContent: 'flex-end', fontSize: '1.6rem', marginBottom: 10 }}>
                            <span style={{ color: 'black', fontWeight: 'bold', marginRight: 10 }}>Total:</span>
                            <span style={{ color: 'black' }}>{maskPriceDecimal(parseFloat(this.state.pedido.total))}</span>
                        </div>
                        <p className="d-md-flex d-none" style={{ marginBottom: 10, textAlign: 'justify',color:'red' }}>{this.state.msgErroPedido}</p>
                        <p className="d-md-none d-flex" style={{ marginBottom: 10, textAlign: 'center',color:'red' }}>{this.state.msgErroPedido}</p>

                        {(this.state.pedido.status=='O seu pagamento foi aprovado!' || this.state.pedido.status=='Recebemos seu Pedido')  && <div className="summary-total d-md-flex d-none" style={{ justifyContent: 'flex-start', fontSize: '1.6rem', marginBottom: 10 }}>

                            {this.state.loading_pedido == false && <button type="button" style={{ width: 200 }} onClick={() => {
                                this.setState({ show_warning_cancelar: true });
                            }}
                                className="btn btn-outline-danger btn-order btn-block">

                                <span className="btn-text">Cancelar Pedido</span>
                                <span className="btn-hover-text">Cancelar Pedido</span>
                            </button>}
                            {this.state.loading_pedido == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'flex-start', width: '100%' }}><div className="spinner-border spinner-sm" role="status">
                                <span className="sr-only">Loading...</span>
                            </div></div>}
                        </div>}

                        {(this.state.pedido.status=='O seu pagamento foi aprovado!' || this.state.pedido.status=='Recebemos seu Pedido')  &&  <div className="summary-total d-md-none d-flex" style={{ justifyContent: 'center', fontSize: '1.6rem', marginBottom: 10 }}>
                            {this.state.loading_pedido == false && <button type="button" onClick={() => {
                                this.setState({ show_warning_cancelar: true });
                            }}
                                className="btn btn-outline-danger btn-order btn-block">

                                <span className="btn-text">Cancelar Pedido</span>
                                <span className="btn-hover-text">Cancelar Pedido</span>
                            </button>}
                            {this.state.loading_pedido == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'flex-start', width: '100%' }}><div className="spinner-border spinner-sm" role="status">
                                <span className="sr-only">Loading...</span>
                            </div></div>}
                        </div>}

                    </div>}

                </Modal>
            </div>
        )
    }
}

export const mapStateToProps = (state) => ({
    token: state.user.token
})

export default connect(mapStateToProps, { logout })(Pedidos);