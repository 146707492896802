import React from 'react';
import { Link } from 'react-router-dom';

import { safeContent } from '../../utils/utils';
import { URL } from '../../variables';

export default ( props ) => {
    const { image, title, subtitle, subtitleAdClass, price, btnText = "Mostrar mais",link, } = props.data;
    let props_href={};
    if(link!=null){
        props_href={href:link};

    }
    return (
        <a {...props_href} target={'_blank'} ><div className="intro-slide" style={ {backgroundImage: "url(" +URL+ image + ")", height:props.banner_height} }>
            <div className="container intro-content">
                <div className="row justify-content-end">
                    <div className="col-auto col-sm-7 col-md-6 col-lg-5">
                        {/* {subtitle!=null && <h3 style={{color:'#19386e'}} className={ `intro-subtitle ${subtitleAdClass}` }>{ subtitle }</h3>}
                        {title !=null && <h1 className="intro-title">
                            {title }
                        </h1>}

                        {price!=null && <div className="intro-price" style={{color:'#19386e'}}>
                            R$ {price.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace('.',',') }
                        </div>}

                        {link!=null && link!='null' && <a href={ `${link}`} className="btn btn-primary btn-round">
                            <span>{ btnText }</span>
                            <i className="icon-long-arrow-right"></i>
                        </a>} */}
                    </div>
                </div>
            </div>
        </div></a>
    )
}