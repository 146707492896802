import React from 'react'
import SocialLogin from 'react-social-login'

class SocialButton extends React.Component {

    render() {
        return (
            <button style={{width:'100%'}} className="btn btn-login btn-f" type="button" onClick={this.props.triggerLogin} {...this.props}>
                <i className="icon-facebook-f"></i>
                                                            Entrar com Facebook
            </button>
        );
    }
}

export default SocialLogin(SocialButton);