import React, { Component } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { Link, Redirect } from 'react-router-dom';

// import Custom Components
import { connect } from 'react-redux';
import Select from 'react-select'
import { URL } from '../../../../variables';
import { maskPrice, maskPriceDecimal } from '../../../../Auxiliar/Masks';
import { logout } from '../../../../actions';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
    DatePicker,
    TimePicker,
    DateTimePicker
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/pt-br';
import moment from 'moment';
import { SketchPicker, PhotoshopPicker } from 'react-color';
import ReactTooltip from 'react-tooltip';
import { rendererOne } from '../../../common/countdown/renderer';
import Countdown from 'react-countdown';

class EditarBannerPromocao extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading_save: false,
            msgErro: '',
            redirect: false,
            foto: '',
            foto_path: '',
            title: '',
            subtitle: '',
            link: '',
            order: '',
            show_autocomplete_produto: false,
            produtos: [],
            loading_search_produto: false,
            data: moment().add('months', 2).add('hours', 2),
            produto_select: null,
            produto_name: '',
            color_title: { hex: '#ef837b' },
            color_subtitle: { hex: '#000' },
            color_link: { hex: '#19386e' },

            show_picker_title: false,
            show_picker_subtitle: false,
            show_picker_link: false,
            opacity: 1,

        };
    }

    select_produto(item) {
        this.setState({ produto_name: '', show_autocomplete_produto: false, produto_select: item })
    }

    get_produto_search(search) {

        if (this.state.loading_search_produto == false) {
            this.setState({ loading_search_produto: true });
        }
        fetch(`${URL}api/admin/produtos_search?search=${search}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);
                    // this.setState({ redirect: true });
                    this.setState({ loading_search_produto: false });

                    //   setTimeout(()=>{
                    //     this.get_processo();
                    //   });
                }
                else {
                    // this.props.mudarUser(resp.user);
                    this.setState({ loading_search_produto: false, produtos: resp.produtos });
                    // if (resp.processo != null) {


                }




            } catch (err) {
                this.setState({ loading_search_produto: false });
                console.log(err);


            }

        })
            .catch((err) => {
                this.setState({ loading_search_produto: false });

                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    salvar() {
        this.setState({ loading_save: true, msgErro: '' });
        let form = new FormData();

        form.append('title', this.state.title);
        form.append('subtitle', this.state.subtitle);
        form.append('ordem', this.state.order);

        form.append('link', this.state.link);
        form.append('validade', this.state.data.toString());

        form.append('foto', this.state.foto_path);
        form.append('foto_file', this.state.foto);
        form.append('title_color', this.state.color_title.hex);
        form.append('subtitle_color', this.state.color_subtitle.hex);
        form.append('link_color', this.state.color_link.hex);
        form.append('opacity', this.state.opacity);
        if (this.state.produto_select != null) {
            form.append('produto', this.state.produto_select.id);
        }
        form.append('banner_id', this.props.match.params.id);

        fetch(`${URL}api/admin/banner_limitada/editar_banner`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_save: false, msgErro: erro });
                    window.scrollTo({ top: 0, behavior: 'smooth' })

                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }

    componentDidMount() {
        console.log(this.props.token);
        this.get_banner();
    }

    get_banner() {
        this.setState({ loading: true });

        fetch(`${URL}api/admin/get_banner/${this.props.match.params.id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                }
                else {

                    this.setState({
                        loading: false,
                        title: resp.banner.title,
                        subtitle: resp.banner.subtitle,
                        link: resp.banner.link == null ? '' : resp.banner.link,
                        order: resp.banner.order,
                        produto_select: resp.banner.produto,
                        color_title: { hex: resp.banner.title_color },
                        color_subtitle: { hex: resp.banner.subtitle_color },
                        color_link: { hex: resp.banner.link_color },
                        opacity: resp.banner.opacity,
                        data: moment(resp.banner.validade),
                        foto_path: URL + resp.banner.image,
                    });

                }




            } catch (err) {
                console.log(err);
                this.setState({
                    loading: false
                });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({
                    loading: false
                });
            });
    }

    render() {

        return (
            <div>
                {this.state.redirect == true && <Redirect to={'/conta/banners'} />}

                <div style={{
                    color: '#721c24',
                    backgroundColor: '#f8d7da',
                    borderColor: '#f5c6cb'
                }} hidden={this.state.msgErro == ''} className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                    <button style={{ color: '#721c24', top: '55%', fontSize: '2rem' }} onClick={() => this.setState({ msgErro: '' })} type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>

                    <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                </div>
                <br />

                <label>Foto(s) *</label>

                <input className="form-control" accept="image/*" placeholder="Modelo" type="file" onChange={(e) => {
                    let foto = e.target.files[0];
                    this.setState({ foto });
                    let foto_path = '';
                    let reader = new FileReader()
                    reader.readAsDataURL(e.target.files[0])
                    reader.onload = () => {
                        foto_path = reader.result;
                        this.setState({ foto_path });
                    };
                    reader.onerror = function (error) {
                        console.log('Error: ', error);
                    }
                    e.target.value = null;

                }} />
                <div className="row">
                    {this.state.foto_path != '' &&
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: "center" }} className="col-6 col-md-3">
                            <div>
                                <img src={this.state.foto_path} style={{ height: 100, width: 100, objectFit: 'contain' }} />
                                <a onClick={() => {

                                    this.setState({ foto: '', foto_path: '' });
                                }} className="btn btn-link btn-link-dark" style={{ color: 'indianred', cursor: 'pointer' }}><span>Remover</span><i className="fas fa-times"></i></a>
                            </div>
                        </div>

                    }
                </div>
                <br />
                <label>Opacidade da imagem = {this.state.opacity}</label>
                <input value={this.state.opacity} onChange={(e) => {
                    this.setState({ opacity: e.target.value });
                }} id="typeinp" type="range" min="0" max="1" step="0.1" className="form-control" style={{ paddingRight: 0, paddingLeft: 0 }} />
                <hr />

                <label>Título</label>
                <ReactTooltip />

                <div className="d-flex" style={{ position: 'relative' }}>
                    <input value={this.state.title} onChange={(e) => this.setState({ title: e.target.value })} style={{ paddingRight: 40 }} type="text" placeholder="Título do banner" className="form-control" />

                    <button data-tip="Alterar cor" style={{ border: "1px solid #ebebeb", borderLeftWidth: 0, width: 40, height: 40, minWidth: 40, backgroundColor: '#f9f9f9', position: 'absolute', top: 0, bottom: 0, right: 0 }} onClick={() => {
                        this.setState({ show_picker_title: true });
                    }}
                        className="destroy_it btn btn-success" ><i
                            className="fas fa-palette" style={{ color: 'grey' }}></i></button>
                </div>
                <label>Subtítulo</label>
                <ReactTooltip />
                <div className="d-flex" style={{ position: 'relative' }}>
                    <input value={this.state.subtitle} onChange={(e) => this.setState({ subtitle: e.target.value })} style={{ paddingRight: 40 }} type="text" placeholder="Subtítulo do banner" className="form-control" />

                    <button data-tip="Alterar cor" style={{ border: "1px solid #ebebeb", borderLeftWidth: 0, width: 40, height: 40, minWidth: 40, backgroundColor: '#f9f9f9', position: 'absolute', top: 0, bottom: 0, right: 0 }} onClick={() => {
                        this.setState({ show_picker_subtitle: true });
                    }}
                        className="destroy_it btn btn-success" ><i
                            className="fas fa-palette" style={{ color: 'grey' }}></i></button>
                </div>

                <MuiPickersUtilsProvider utils={MomentUtils}>

                    <label className="col-form-label">Data de finalização da Promoção *</label>

                    <DateTimePicker
                        autoOk
                        margin="normal"
                        style={{ width: '100%' }}
                        invalidDateMessage="Data inválida"
                        ampm={false}
                        minDate={moment()}
                        id="time-picker"
                        cancelLabel="Cancelar"
                        clearLabel="Limpar"
                        format="DD/MM/YYYY HH:mm"
                        value={this.state.data}
                        onChange={(date) => { this.setState({ data: date }) }}

                    />



                </MuiPickersUtilsProvider>
                <div className="form-group row">
                    <label className="col-sm-12 col-form-label">Produto Vinculado *</label>
                    <div className="col-sm-12">
                        <input autoComplete="nope" onFocus={() => this.setState({ show_autocomplete_produto: true })} className="form-control" value={this.state.produto_name} placeholder="Nome do produto" type="text" onChange={(e) => { this.get_produto_search(e.target.value); this.setState({ produto_name: e.target.value }) }} />
                        {this.state.show_autocomplete_produto == true && this.state.produto_name != '' && <div style={{ width: '90%', position: 'absolute', zIndex: 20, backgroundColor: 'white', border: '1px solid lightgrey', borderRadius: '5px', height: 'auto', overflowY: 'scroll' }}>
                            {this.state.loading_search_produto == false && this.state.produtos.map((item, id) => (
                                <div key={id} onClick={() => this.select_produto(item)} className="opcao_autocomplete">
                                    {item.name}
                                </div>
                            ))}
                            {this.state.loading_search_produto == false && this.state.produtos.length == 0 &&
                                <div className="opcao_autocomplete">
                                    Nenhum produto encontrado com este termo
                                    </div>
                            }
                            {this.state.loading_search_produto == true &&
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <div className="spinner-border text-primary" role="status"  >
                                        <span className="sr-only">Loading...</span>
                                    </div></div>}
                        </div>}
                    </div>


                </div>
                {this.state.produto_select != null && <div className="form-group row">
                    <label className="col-sm-12 col-form-label">Produto Selecionado:</label>


                    <div className="col-sm-6 col-12 col-md-4 col-lg-3" style={{
                        padding: 10, display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <span>{this.state.produto_select.name}</span>
                        <button onClick={() => {

                            this.setState({ produto_select: null });
                        }}
                            className="btn btn-danger btn-outline btn-circle m-r-5" style={{ background: 'white' }}><i
                                className="fas fa-trash" style={{ color: 'red' }}></i></button>
                    </div>


                </div>}

                <label>Link</label>
                <div className="d-flex" style={{ position: 'relative' }}>
                    <input value={this.state.link} onChange={(e) => this.setState({ link: e.target.value })} type="text" placeholder="Link da página que o banner levará" className="form-control" style={{ marginBottom: 0, paddingRight: 40 }} />

                    <button data-tip="Alterar cor" style={{ border: "1px solid #ebebeb", borderLeftWidth: 0, width: 40, height: 40, minWidth: 40, backgroundColor: '#f9f9f9', position: 'absolute', top: 0, bottom: 0, right: 0 }} onClick={() => {
                        this.setState({ show_picker_link: true });
                    }}
                        className="destroy_it btn btn-success" ><i
                            className="fas fa-palette" style={{ color: 'grey' }}></i></button>
                </div>
                <small >Ex: https://www.exemplo.com</small><br />

                <label>Ordem *</label>
                <input value={this.state.order} onChange={(e) => this.setState({ order: e.target.value })} type="number" placeholder="Ordem do banner na home" className="form-control" />

                {this.state.show_picker_title == true && <div style={{ position: 'fixed', top: 100, right: 0, zIndex: 10000000 }}><PhotoshopPicker header="Escolha uma cor" onAccept={(color) => {

                    this.setState({ show_picker_title: false });
                }} onCancel={() => {
                    this.setState({ show_picker_title: false, });
                }} color={this.state.color_title} onChange={(color) => {
                    console.log(color);
                    this.setState({ color_title: { ...color } }, () => {
                    });

                }} /></div>}
                {this.state.show_picker_subtitle == true && <div style={{ position: 'fixed', top: 100, right: 0, zIndex: 10000000 }}><PhotoshopPicker header="Escolha uma cor" onAccept={(color) => {

                    this.setState({ show_picker_subtitle: false });
                }} onCancel={() => {
                    this.setState({ show_picker_subtitle: false, });
                }} color={this.state.color_subtitle} onChange={(color) => {
                    console.log(color);
                    this.setState({ color_subtitle: { ...color } }, () => {
                    });

                }} /></div>}
                {this.state.show_picker_link == true && <div style={{ position: 'fixed', top: 100, right: 0, zIndex: 10000000 }}><PhotoshopPicker header="Escolha uma cor" onAccept={(color) => {

                    this.setState({ show_picker_link: false });
                }} onCancel={() => {
                    this.setState({ show_picker_link: false, });
                }} color={this.state.color_link} onChange={(color) => {
                    console.log(color);
                    this.setState({ color_link: { ...color } }, () => {
                    });

                }} /></div>}
                <hr />
                <label style={{
                    color: 'black',
                    fontWeight: 500,
                    marginBottom: '10px'
                }}>Preview</label>
                <div className="deal" style={{ position: "relative" }} >
                    <img src={this.state.foto_path} style={{ objectFit: "cover", width: '100%', height: '100%', position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, opacity: this.state.opacity }} />
                    <div className="deal-top" style={{ zIndex: 1 }}>
                        <h2 style={{ color: this.state.color_title.hex }}>{this.state.title}</h2>
                        <h4 style={{ color: this.state.color_subtitle.hex }}>{this.state.subtitle}</h4>
                    </div>

                    <div className="deal-content" style={{ zIndex: 1 }}>
                        {this.state.produto_select != null && <h3 className="product-title"><a href={this.state.link != '' ? this.state.link : `${process.env.PUBLIC_URL}/product/default/${this.state.produto_select.id}`} style={{ color: this.state.color_subtitle.hex }}>{this.state.produto_select.name}</a></h3>}

                        {this.state.produto_select != null && <div className="product-price">
                            {this.state.produto_select.sale_price != null && <span className="new-price" style={{ color: this.state.color_title.hex }}>Por {maskPriceDecimal(this.state.produto_select.sale_price)}</span>}
                            {this.state.produto_select.sale_price == null && <span className="new-price" style={{ color: this.state.color_title.hex }}>{maskPriceDecimal(this.state.produto_select.price)}</span>}

                            {this.state.produto_select.sale_price != null ? <span className="old-price" style={{ color: this.state.color_subtitle.hex }}>De {maskPriceDecimal(this.state.produto_select.price)}</span> : ""}
                        </div>}

                        {this.state.produto_select != null && <a href={this.state.link != '' ? this.state.link : `${process.env.PUBLIC_URL}/product/default/${this.state.produto_select.id}`} className="btn btn-link" style={{ color: this.state.color_link.hex }}>
                            <span>Mostrar mais</span><i className="icon-long-arrow-right"></i>
                        </a>}
                    </div>

                    <div className="deal-bottom">
                        <div className="deal-countdown offer-countdown"><Countdown date={parseInt(moment(this.state.data.toString()).unix() + '000')} renderer={rendererOne} /></div>
                    </div>
                </div>

                {this.state.loading_save == false && <button onClick={() => {
                    this.salvar();
                }} style={{ marginTop: '1.3rem' }} type="button" className="btn btn-outline-primary btn-rounded">
                    <span>Salvar</span>
                    <i className="far fa-save"></i>
                </button>}
                {this.state.loading_save == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div></div>}

            </div>
        )
    }
}

export const mapStateToProps = (state) => ({
    token: state.user.token

})

export default connect(mapStateToProps, { logout })(EditarBannerPromocao);