import React, { Component } from 'react';
import store from '../../../store';

// import Custom Components
import PageHeader from '../../common/page-header';
import Breadcrumb from '../../common/breadcrumb';
import ShopSidebar from '../../features/sidebar/shop-sidebar';
import ProductList from '../../features/product/common/product-list';
import ErrorPage from '../pages/404';

import { innerLoading, outerLoading } from '../../../actions';
import { URL } from '../../../variables';
import { connect } from 'react-redux';
import Quickview from '../../features/product/quickview';


class ShopList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            produtos: [],
            loading: true,
            current_page: 1,
            last_page: 1,
            total: 1,
            per_page: 1,
            page: 1,
            categorias: [],
            marcas: [],
            price_range: { max: 1, min: 1 },
            destaques: [],
            categorias_checked: {},
            destaques_checked: {},
            marcas_checked: {},
            value_price: { max: 1, min: 1 },
            laoding_dados: true

        };
        this.time_produtos = Date.now();
        this.time_dados = Date.now();
    }

    componentDidUpdate() {
        // store.dispatch(innerLoading());
    }

    componentWillUnmount() {
        // store.dispatch(outerLoading());
    }

    componentDidMount() {
        this.time_produtos = Date.now();
        this.time_dados = Date.now();
        // this.props.innerLoading();
        this.setState({ laoding_dados: true });
        this.get_dados_produtos(this.time_dados, [], true);
    }

    change_pagination(page) {
        this.time_produtos = Date.now();
        this.setState({ page: page });
        console.log(page);
        this.get_produtos(this.time_produtos, page, Object.keys(this.state.categorias_checked), Object.keys(this.state.marcas_checked), Object.keys(this.state.destaques_checked), this.state.name, this.state.value_price.min, this.state.value_price.max);
    }

    change_marcas_checked(marcas) {
        this.time_produtos = Date.now();

        this.setState({ marcas_checked: marcas, page: 1 });
        this.get_produtos(this.time_produtos, 1, Object.keys(this.state.categorias_checked), Object.keys(marcas), Object.keys(this.state.destaques_checked), this.state.name, this.state.value_price.min, this.state.value_price.max)

    }

    change_categorias_checked(categorias_checked) {
        this.time_produtos = Date.now();
        this.time_dados = Date.now();


        this.setState({ categorias_checked: categorias_checked, marcas_checked: {}, page: 1 });
        // this.props.innerLoading();
        this.get_dados_produtos(this.time_dados, Object.keys(categorias_checked));
        this.get_produtos(this.time_produtos, 1, Object.keys(categorias_checked), Object.keys({}), Object.keys(this.state.destaques_checked), this.state.name, this.state.value_price.min, this.state.value_price.max)
    }

    change_destaques_checked(destaques_checked) {
        this.time_produtos = Date.now();
        this.time_dados = Date.now();


        this.setState({ destaques_checked: destaques_checked, page: 1 });
        // this.props.innerLoading();
        this.get_produtos(this.time_produtos, 1, Object.keys(this.state.categorias_checked), Object.keys(this.state.marcas_checked), Object.keys(destaques_checked), this.state.name, this.state.value_price.min, this.state.value_price.max)
    }

    change_price_range(value_price) {
        this.time_produtos = Date.now();
        this.setState({ value_price: value_price, page: 1 });
        this.get_produtos(this.time_produtos, 1, Object.keys(this.state.categorias_checked), Object.keys(this.state.marcas_checked), Object.keys(this.state.destaques_checked), this.state.name, value_price.min, value_price.max)

    }

    change_name(name) {
        this.time_produtos = Date.now();
        this.setState({ name: name, page: 1 });
        this.get_produtos(this.time_produtos, 1, Object.keys(this.state.categorias_checked), Object.keys(this.state.marcas_checked), Object.keys(this.state.destaques_checked), '', this.state.value_price.min, this.state.value_price.max)

    }

    clear_all() {
        this.time_produtos = Date.now();

        this.setState({ page: 1, categorias_checked: {}, destaques_checked: {}, marcas_checked: {}, value_price: this.state.price_range, marcas: [], name: '' });
        this.get_produtos(this.time_produtos, 1, [], [], [], '', this.state.price_range.min, this.state.price_range.max)

    }

    componentDidUpdate(props) {

        if (this.props.location.search != props.location.search) {
            let categorias_checked = {};
            if (this.props.location.search) {
                let cats = this.props.location.search.replace('?cat_id=', '').split(',');
                cats.map((item, id) => {
                    categorias_checked[item] = true;
                })

                let ids = Object.keys(categorias_checked);
                this.state.categorias.map((item,id)=>{
                    if(!ids.includes(item.id)){
                        item.categorias.map((item2,id2)=>{
                            if(!ids.includes(item2.id)){
                                item2.categorias.map((item3,id2)=>{
                                    if(ids.includes(item3.id)){
                                        categorias_checked[item3.id]=true;
                                        categorias_checked[item2.id]=true;
                                        categorias_checked[item.id]=true;
                                    }
                                })
                            }
                            else{
                                categorias_checked[item2.id]=true;
                                categorias_checked[item.id]=true;
                            }
                        })
                    }
                    else{
                        categorias_checked[item.id]=true;
                    }
                })
            }
            this.time_produtos = Date.now();
            this.setState({ categorias_checked: categorias_checked, marcas_checked: {}, destaques_checked: {}, page: 1, value_price: this.state.price_range });
            this.get_produtos(this.time_produtos, 1, Object.keys(categorias_checked), [], [], this.state.name, this.state.price_range.min, this.state.price_range.max);
        }
    }

    get_dados_produtos(time, categorias = [], first = false) {
        let params = '';
        let categorias_checked = {};
        let destaques_checked = {};
        let name = '';
        if (first == true) {

            if (this.props.location.search) {
                let dados = this.props.location.search.split('?')[1].split('&');
                console.log(dados);
                for (let i = 0; i < dados.length; i++) {
                    if (dados[i].includes('cat_id')) {
                        let cats = dados[i].replace('cat_id=', '').split(',');
                        cats.map((item, id) => {
                            categorias_checked[item] = true;
                            // categorias_checked[dados[i].replace('cat_id=', '')] = true;

                        })
                        this.setState({ categorias_checked });
                        categorias = Object.keys(categorias_checked);
                    }
                    else if (dados[i].includes('des_id')) {
                        destaques_checked[dados[i].replace('des_id=', '')] = true;
                        this.setState({ destaques_checked });
                    }
                    else if (dados[i].includes('name')) {
                        name = dados[i].replace('name=', '');
                        this.setState({ name });
                    }
                }

            }
        }
        if (categorias.length != 0) {
            let str_cat = '';
            for (let i = 0; i < categorias.length; i++) {
                if (i == 0) {
                    str_cat = str_cat + categorias[i]
                }
                else {
                    str_cat = str_cat + ',' + categorias[i]

                }
            }
            params = '?categoria_id=' + str_cat;
        }

        fetch(`${URL}api/get_dados_produtos${params}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                // 'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                }
                else {
                    if (time == this.time_dados) {
                        let ids = Object.keys(categorias_checked);
                        resp.categorias.map((item,id)=>{
                            if(!ids.includes(item.id)){
                                item.categorias.map((item2,id2)=>{
                                    if(!ids.includes(item2.id)){
                                        item2.categorias.map((item3,id2)=>{
                                            if(ids.includes(item3.id)){
                                                categorias_checked[item3.id]=true;
                                                categorias_checked[item2.id]=true;
                                                categorias_checked[item.id]=true;
                                            }
                                        })
                                    }
                                    else{
                                        categorias_checked[item2.id]=true;
                                        categorias_checked[item.id]=true;
                                    }
                                })
                            }
                            else{
                                categorias_checked[item.id]=true;
                            }
                        })

                        this.setState({
                            categorias: resp.categorias,
                            marcas: resp.marcas,
                            destaques: resp.destaques,
                            laoding_dados: false,
                            price_range: { min: Math.floor(parseFloat(resp.price_range.min) - 1)>0?Math.floor(parseFloat(resp.price_range.min) - 1):0, max: Math.ceil(parseFloat(resp.price_range.max)) }
                        });

                        if (first == true) {
                            this.get_produtos(this.time_produtos, this.state.page, Object.keys(categorias_checked), [], Object.keys(destaques_checked), name);
    
                        }
                    }
                    

                }




            } catch (err) {
                console.log(err);

            }

        })
            .catch((err) => {
                console.log(err);
            });
    }

    get_produtos(time, page, categorias = [], marcas = [], destaques = [], name = '', min = 0, max = 10000000000000) {
        // this.props.innerLoading();
        this.setState({ produtos: [], loading: true });
        let params = '';
        if (categorias.length != 0) {
            let str_cat = '';
            for (let i = 0; i < categorias.length; i++) {
                if (i == 0) {
                    str_cat = str_cat + categorias[i]
                }
                else {
                    str_cat = str_cat + ',' + categorias[i]

                }
            }
            params = '&categoria_id=' + str_cat;
            if (marcas.length != 0) {
                let str_mar = '';
                for (let i = 0; i < marcas.length; i++) {
                    if (i == 0) {
                        str_mar = str_mar + marcas[i]
                    }
                    else {
                        str_mar = str_mar + ',' + marcas[i]

                    }
                }
                params = params + '&marca_id=' + str_mar;
            }
        }
        if (destaques.length != 0) {
            let str_des = '';

            for (let i = 0; i < destaques.length; i++) {
                if (i == 0) {
                    str_des = str_des + destaques[i]
                }
                else {
                    str_des = str_des + ',' + destaques[i]

                }
            }
            params = params + '&destaques=' + str_des;

        }
        console.log(min, max);

        if (min != '' && max != '' && !isNaN(min) && !isNaN(max)) {
            params += '&min=' + min + '&max=' + max;
        }
        if (name != '') {
            params += '&name=' + name;
        }
        console.log(`${URL}api/get_produtos?page=${page}${params}`);
        fetch(`${URL}api/get_produtos?page=${page}${params}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i] != '')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                }
                else {
                    console.log(time == this.time_produtos);
                    if (page == this.state.page && time == this.time_produtos) {
                        console.log('updated');
                        this.setState({
                            produtos: resp.produtos,
                            loading: false,
                            current_page: resp.current_page,
                            last_page: resp.last_page,
                            total: resp.total,
                            per_page: resp.per_page
                        });
                    }
                }




            } catch (err) {
                console.log(err);
                this.setState({ loading: false });
            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading: false });

            });
    }

    render() {
        let grid = this.props.match.params.grid;
        const titles = { "list": "List", "2cols": "Grid 2 Columns", "3cols": "Grid 3 Columns", "4cols": "Grid 4 Columns" };

        if (grid !== "list" && grid !== "2cols" && grid !== "3cols" && grid !== "4cols") {
            return (
                <ErrorPage />
            )
        }

        return (
            <div className="main">
                <PageHeader title={'Lista'} subTitle="Produtos" />
                <Breadcrumb title={'Lista'} parent1={["Produtos", "shop/sidebar/list"]} adClass="mb-2" />
                {/* <Quickview /> */}

                {<div className="page-content">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-9">
                                <ProductList page={this.state.page} change_pagination={this.change_pagination.bind(this)} last_page={this.state.last_page} total={this.state.total} per_page={this.state.per_page} products={this.state.produtos} column={grid} loading={this.state.loading} />
                            </div>

                            <div className="col-lg-3 order-lg-first">
                                {this.state.laoding_dados == false && <ShopSidebar change_name={(e) => this.change_name(e)} name={this.state.name} destaques_checked={this.state.destaques_checked} change_destaques_checked={this.change_destaques_checked.bind(this)} destaques={this.state.destaques} clear_all={this.clear_all.bind(this)} change_price_range={this.change_price_range.bind(this)} categorias_checked={this.state.categorias_checked} marcas_checked={this.state.marcas_checked} change_price_range={this.change_price_range.bind(this)} change_marcas_checked={this.change_marcas_checked.bind(this)} change_categorias_checked={this.change_categorias_checked.bind(this)} price_range={this.state.price_range} categorias={this.state.categorias} marcas={this.state.marcas} adClass="sidebar sidebar-shop" />}
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        );
    }
}
export const mapStateToProps = (state) => {
    return {
    };
}
export default connect(mapStateToProps, { innerLoading })(ShopList);