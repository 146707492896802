import React, { Component } from 'react';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { Link, Redirect } from 'react-router-dom';

// import Custom Components
import { connect } from 'react-redux';
import Select from 'react-select'
import { URL } from '../../../../variables';
import { maskCep, maskPrice } from '../../../../Auxiliar/Masks';
import { logout } from '../../../../actions';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';

class EditarEndereco extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cep: '',
            rua: '',
            numero: '',
            bairro: '',
            cidade: '',
            estado: '',
            latitude: '',
            longitude: '',
            complemento: '',
            loading_save: false,
            msgErro: '',
            redirect: false
        };
    }

    buscar_endereco(cep) {
        console.log(cep);
        fetch(`https://viacep.com.br/ws/${cep}/json/`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                this.setState({
                    rua: resp.logradouro,
                    bairro: resp.bairro,
                    cidade: resp.localidade,
                    estado: resp.uf,
                    numero: ''

                }, () => {
                    this.get_lat_lng();
                });

                // await AsyncStorage.setItem('token', resp.access_token)
                // await AsyncStorage.setItem('user', JSON.stringify(resp.user))




            } catch (err) {
                console.log(err);
                this.setState({ loading: false, msgErro: 'Algo deu errado. Seu CEP está correto?' });
            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading: false, msgErro: `Erro ao pegar resposta do servidor. Você está conectado a internet?` });
            });


    }

    get_lat_lng() {
        let address = `${this.state.rua},+${this.state.numero},+${this.state.bairro},+${this.state.cidade},+${this.state.estado},+Brasil`
        fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyAyaSKLxkfqUOco6ZoPx08OxUKUnaNRjnE&components=country=Brazil|region=BR`, {
            method: 'GET',
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.results.length > 0) {
                    this.setState({ latitude: resp.results[0].geometry.location.lat, longitude: resp.results[0].geometry.location.lng })
                }



            } catch (err) {
                console.log(err);
            }

        })
            .catch((err) => {
                console.log(err);
            });
    }

    salvar() {
        this.setState({ loading_save: true, msgErro: '' });
        let form = new FormData();
        form.append('cep', this.state.cep);

        form.append('rua', this.state.rua);
        form.append('numero', this.state.numero);
        form.append('bairro', this.state.bairro);
        form.append('cidade', this.state.cidade);
        form.append('estado', this.state.estado);
        form.append('complemento', this.state.complemento);
        form.append('latitude', this.state.latitude);
        form.append('longitude', this.state.longitude);
        form.append('endereco_id', this.props.match.params.id);

        fetch(`${URL}api/editar_endereco`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
            },
            body: form
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {

                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_save: false, msgErro: erro });
                    window.scrollTo({ top: 0, behavior: 'smooth' })

                }
                else {

                    this.setState({ loading_save: false, redirect: true });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor' });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_save: false, msgErro: 'Erro ao pegar resposta do servidor. Você está conectado a internet?' });
            });
    }

    componentDidMount() {
        this.get_endereco();
    }

    get_endereco() {
        this.setState({ loading: true });

        fetch(`${URL}api/get_endereco/${this.props.match.params.id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;

                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                }
                else {

                    this.setState({
                        loading: false,
                        cep: resp.endereco.cep,
                        rua: resp.endereco.rua,
                        bairro: resp.endereco.bairro,

                        numero: resp.endereco.numero,
                        complemento: resp.endereco.complemento != null ? resp.endereco.complemento : '',
                        cidade: resp.endereco.cidade,
                        estado: resp.endereco.estado,
                        latitude: resp.endereco.latitude,
                        longitude: resp.endereco.longitude,
                    });

                }




            } catch (err) {
                console.log(err);
                this.setState({
                    loading: false
                });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({
                    loading: false
                });
            });
    }

    render() {

        return (
            <div>
                {this.state.redirect == true && <Redirect to={'/conta/enderecos'} />}

                <div style={{
                    color: '#721c24',
                    backgroundColor: '#f8d7da',
                    borderColor: '#f5c6cb'
                }} hidden={this.state.msgErro == ''} className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                    <button style={{ color: '#721c24', top: '55%', fontSize: '2rem' }} onClick={() => this.setState({ msgErro: '' })} type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>

                    <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msgErro}</p>

                </div>
                <br />

                <label>Cep *</label>
                <input value={this.state.cep} onChange={(e) => {
                    this.setState({ cep: maskCep(e.target.value) });
                    let text = e.target.value.replace('-', '');
                    if (text.length == 8) {
                        this.buscar_endereco(text);
                    }
                }} type="text" placeholder="Cep" className="form-control" required />

                <label>Rua *</label>
                <input value={this.state.rua} onChange={(e) => this.setState({ rua: e.target.value }, () => this.get_lat_lng())} type="text" placeholder="Rua" className="form-control" required />

                <label>Número *</label>
                <input value={this.state.numero} onChange={(e) => this.setState({ numero: e.target.value }, () => this.get_lat_lng())} type="text" placeholder="Número" className="form-control" required />

                <label>Complemento</label>
                <input value={this.state.complemento} onChange={(e) => this.setState({ complemento: e.target.value })} type="text" placeholder="Complemento" className="form-control" />

                <label>Bairro *</label>
                <input value={this.state.bairro} onChange={(e) => this.setState({ bairro: e.target.value }, () => this.get_lat_lng())} type="text" placeholder="Bairro" className="form-control" required />

                <label>Cidade *</label>
                <input value={this.state.cidade} onChange={(e) => this.setState({ cidade: e.target.value }, () => this.get_lat_lng())} type="text" placeholder="Cidade" className="form-control" required />

                <label>Estado (Sigla) *</label>
                <input value={this.state.estado} onChange={(e) => this.setState({ estado: e.target.value }, () => this.get_lat_lng())} type="text" placeholder="Estado (Sigla)" className="form-control" required />

                <div style={{ height: 300, overflow: 'hidden' }}>

                    <Map
                        google={window.google}
                        style={{ height: 300 }}
                        center={{ lat: this.state.latitude, lng: this.state.longitude }}
                        className="map"
                        zoom={14}>
                        {this.state.latitude != '' && <Marker
                            title={'The marker`s title will appear as a tooltip.'}
                            name={'SOMA'}
                            position={{ lat: this.state.latitude, lng: this.state.longitude }} />}

                    </Map>
                </div>

                {this.state.loading_save == false && <button onClick={() => {
                    this.salvar();
                }} style={{ marginTop: '1.3rem' }} type="button" className="btn btn-outline-primary btn-rounded">
                    <span>Salvar</span>
                    <i className="far fa-save"></i>
                </button>}
                {this.state.loading_save == true && <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', width: '100%' }}><div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div></div>}

            </div>
        )
    }
}

export const mapStateToProps = (state) => ({
    token: state.user.token

})

export default connect(mapStateToProps, { logout })(GoogleApiWrapper({ apiKey: 'AIzaSyAyaSKLxkfqUOco6ZoPx08OxUKUnaNRjnE' })(EditarEndereco));