import React, { Component } from 'react';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

// import Custom Components
import Breadcrumb from '../../common/breadcrumb';
import { URL } from '../../../variables';
import { closeModal, login } from '../../../actions';

class AlterarSenha extends Component {

    constructor(props) {
        super(props);
        this.state = {
            senha: '',
            confirmar_senha: '',
            termos: false,
            msg_erro_login: '',
            msg_erro_registrar: '',
            loading_login: false,
            loading_registrar: false,
            show_success_register: false,
            show_success_login: false, msgSucesso: ''
        }
        this.closeModal = this.closeModal.bind(this);

    }

    recuperar_senha() {
        this.setState({ loading_login: true, msg_erro_login: '' });
        fetch(`${URL}api/alterar_senha`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                password: this.state.senha,
                password_confirmation: this.state.confirmar_senha,

                token: this.props.match.params.token

            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    this.setState({ loading_login: false, msg_erro_login: erro, show_success_login: false });
                }
                else {

                    this.setState({ loading_login: false, show_success_login: true, msgSucesso: resp.msg });

                }




            } catch (err) {
                console.log(err);
                this.setState({ loading_login: false, msg_erro_login: 'Erro ao pegar resposta do servidor', show_success_login: false });

            }

        })
            .catch((err) => {
                console.log(err);
                this.setState({ loading_login: false, msg_erro_login: 'Erro ao pegar resposta do servidor. Você está conectado a internet?', show_success_login: false });
            });
    }

    closeModal() {
        if (document.getElementById("login-modal") != null) {
            document.getElementById("login-modal").classList.remove("ReactModal__Content--after-open");

            this.timer = setTimeout(() => {
                this.props.closeModal('login');
            }, 200);
        }

    }



    render() {
        return (
            <div className="main">
                <Helmet>
                    <title>Hefarma - Recuperar Senha</title>
                </Helmet>

                <h1 className="d-none">Hefarma - Recuperar Senha</h1>

                {/* <Breadcrumb title="Login" parent1={ ["pages","pages/about"] } adClass="border-0 mb-0"/> */}

                <div className="login-page bg-image pt-8 pb-8 pt-md-12 pb-md-12 pt-lg-17 pb-lg-17" style={{ backgroundImage: `url('assets/images/backgrounds/login-bg.jpg')` }}>
                    <div className="container">
                        <div className="form-box">
                            <div className="form-tab">
                                <div className="nav nav-pills nav-fill" style={{ display: 'flex', justifyContent: 'center' }}>
                                    <span className="nav-link" style={{ cursor: 'default', color: 'black', textAlign: 'center' }}>Recuperar Senha</span>

                                </div>
                                <div hidden={this.state.show_success_login == false} style={{
                                    color: '#155724',
                                    backgroundColor: '#d4edda',
                                    borderColor: '#c3e6cb'
                                }} className="alert alert-success alert-dismissible fade show" role="alert">
                                    {this.state.msgSucesso}
                                    <button onClick={() => {
                                        this.setState({ show_success_login: false });
                                    }} style={{ top: '55%', color: 'rgb(21, 87, 36)', fontSize: '2rem' }} type="button" className="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                <form onSubmit={(e) => {
                                    e.preventDefault();
                                    this.recuperar_senha();
                                }}>

                                    <div>
                                        <div style={{
                                            color: '#721c24',
                                            backgroundColor: '#f8d7da',
                                            borderColor: '#f5c6cb'
                                        }} hidden={this.state.msg_erro_login == ''} className="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                            <button style={{ color: '#721c24', top: '55%', fontSize: '2rem' }} onClick={() => this.setState({ msg_erro_login: '' })} type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>

                                            <p style={{ marginBottom: 0, whiteSpace: 'pre' }}>{this.state.msg_erro_login}</p>

                                        </div>

                                        <br />
                                        <div className="form-group">
                                            <label htmlFor="register-password-2">Senha *</label>
                                            <input value={this.state.senha} onChange={(e) => {
                                                this.setState({ senha: e.target.value });
                                            }} type="password" className="form-control" id="register-password-2" name="register-password" required />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="register-password-2">Confirmar Senha *</label>
                                            <input value={this.state.confirmar_senha} onChange={(e) => {
                                                this.setState({ confirmar_senha: e.target.value });
                                            }} type="password" className="form-control" id="register-password-2" name="register-password" required />
                                        </div>



                                        <div className="form-footer" style={{ display: 'block' }}>

                                            <div className="col-12" style={{ padding: 0, marginBottom: '1rem', marginTop: '1rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                                                {this.state.msgSucesso == '' && this.state.loading_login == false && <button type="submit" style={{ width: '100%' }} className="btn btn-outline-primary-2">
                                                    <span>Recuperar Senha</span>
                                                    <i className="icon-long-arrow-right"></i>
                                                </button>}
                                                {this.state.loading_login == true && <div className="spinner-border text-primary" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>}
                                            </div>
                                            <a href="/login" className="forgot-link">Lembrou sua senha? Faça Login</a>


                                        </div>
                                        {/* <div className="form-choice">
                                            <p className="text-center">ou entre com</p>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <a href="pages/login/#a" className="btn btn-login btn-g">
                                                        <i className="icon-google"></i>
                                                            Entrar com Google
                                                        </a>
                                                </div>
                                                <div className="col-sm-6">
                                                    <a href="pages/login/#a" className="btn btn-login btn-f">
                                                        <i className="icon-facebook-f"></i>
                                                            Entrar com Facebook
                                                        </a>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </form>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export const mapStateToProps = (state) => ({
    showModal: state.demo.showModal,
    modal: state.demo.modal
})

export default connect(mapStateToProps, { closeModal, login })(AlterarSenha);