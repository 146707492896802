import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import { matchPath, Redirect, Route, Switch } from 'react-router-dom';

import store from '../store';

// import Custom Components
// import HeaderOne from './common/header/header-1';
import HeaderSeven from './common/header/header-7';
import FooterFive from './common/footer/footer-five';

// import FooterOne from './common/footer/footer-one';
import MobileMenu from './common/header/common/mobile-menus/menu-1';
import OuterOverlay from './common/overlay/outer-overlay';

import Error from './main/pages/404';

// import Utils
import { initSettings } from '../utils/utils';

// import Actions
import { outerLoading, closeQuickViewModal } from '../actions';
import style from '../Home/index4/style.scss';
import { connect } from 'react-redux';
import MyAccount from '../components/main/shop/dashboard';
import Wishlist from '../components/main/shop/wishlist';
import Cart from '../components/main/shop/cart';
import Checkout from '../components/main/shop/checkout';
import ShopList from '../components/main/shop/sidebar';
import SingleProduct from '../components/main/product/default';
import ChatCliente from './Chat/ChatCliente';
import SelecioneEndereco from './main/shop/SelecioneEndereco';
import SelecioneEntrega from './main/shop/SelecioneEntrega';
import SelecionePagamento from './main/shop/SelecionePagamento';
import ResumoPedido from './main/shop/ResumoPedido';
import LoginOne from '../components/main/pages/login';
import EsqueciSenha from './main/pages/EsqueciSenha';
import AlterarSenha from './main/pages/AlterarSenha';

const OtherRoute = ({ component: Component, path_name, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            <Redirect to={{ pathname: path_name, state: { from: props.location } }} />
        }
    />
);

class App extends Component {
    componentDidMount() {
        initSettings();
        store.dispatch(outerLoading());
    }

    UNSAFE_componentWillMount() {
        style.use();
    }
    componentWillUnmount() {
        style.unuse();
    }

    componentDidUpdate() {
        if (store.getState() && store.getState().overlay.type === 'outer') {
            store.dispatch(outerLoading());
        }

        if (store.getState() && store.getState().data.quickView) {
            store.dispatch(closeQuickViewModal());
        }
    }

    render() {
        let flag = true, i = 0;
        
        // while( i < this.props.children.length &&  ! matchPath(window.location.pathname, { path: this.props.children[i].props.path , exact: true } ) ) {
        //     i ++;
        // }

        // if ( i < this.props.children.length ) {
        //     flag = true;
        // }

        return (
            <div>

                <OuterOverlay />
                <div className="page-wrapper">
                    <HeaderSeven />
                    {/* { flag ?
                        this.props.children :                         
                            <Error />
                    } */}
                    <Switch>
                    {this.props.token==undefined &&<Route exact path={ `${process.env.PUBLIC_URL}/login`} component={ LoginOne}/>}
                    {this.props.token==undefined &&<Route exact path={ `${process.env.PUBLIC_URL}/recuperar_senha`} component={EsqueciSenha}/>}
                    {this.props.token==undefined &&<Route exact path={ `${process.env.PUBLIC_URL}/reset/:token`} component={AlterarSenha}/>}

                    {this.props.token!=undefined && <Route path={`${process.env.PUBLIC_URL}/conta`} component={MyAccount} />}

                    <Route exact path={`${process.env.PUBLIC_URL}/product/default/:id`} component={SingleProduct} />
                    <Route exact path={`${process.env.PUBLIC_URL}/shop/sidebar/:grid`} component={ShopList} />
                    <Route exact path={`${process.env.PUBLIC_URL}/shop/cart`} component={Cart} />
                    <Route exact path={`${process.env.PUBLIC_URL}/shop/wishlist`} component={Wishlist} />
                    {this.props.token!=undefined && <Route exact path={`${process.env.PUBLIC_URL}/shop/address`} component={SelecioneEndereco} />}
                    {this.props.token!=undefined && <Route exact path={`${process.env.PUBLIC_URL}/shop/delivery`} component={SelecioneEntrega} />}
                    {this.props.token!=undefined && <Route exact path={`${process.env.PUBLIC_URL}/shop/payment`} component={SelecionePagamento} />}
                    {this.props.token!=undefined && <Route exact path={`${process.env.PUBLIC_URL}/shop/resume`} component={ResumoPedido} />}

                    {this.props.token!=undefined && <Route exact path={`${process.env.PUBLIC_URL}/shop/checkout`} component={Checkout} />}
                    <OtherRoute path="*" path_name='/' />
                    </Switch>

                    <FooterFive logo="assets/images/logo.png" />
                    <ToastContainer autoClose={3000} className="toast-container" />
                </div>

                <MobileMenu adClass="mobile-menu-light" />
                <ChatCliente />

            </div>
        );
    }
}


export const mapStateToProps = (state) => ({
    token: state.user.token,
    
})

export default connect(mapStateToProps, {})(App);