import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

// import Custom Components
import Logo from '../logo';
import MainMenu from './common/main-menus/menu-1';
import CartMenu from './common/cart-menus/menu-4';
import CategoryMenu from './common/category-menus/menu-1';
import CompareMenuTwo from './common/compare-menus/menu-2';
import LoginModal from '../../features/login-modal';

import { showModal,removeFromWishlist,login,logout } from '../../../actions';
import { stickyHeaderHandler,safeContent } from '../../../utils/utils';
import { URL } from '../../../variables';

class HeaderSeven extends Component {

    constructor(props) {
        super(props);
        this.state={
            name_produto:[],
            redirect:false,
            loading:true
        }
        this.openLoginModal = this.openLoginModal.bind(this);
    }

    componentDidMount() {
        if(this.props.token!=undefined){
            this.get_user();
        }
        else{
            this.setState({loading:false});
        }
        window.addEventListener('scroll', stickyHeaderHandler);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', stickyHeaderHandler);
    }

    openLoginModal(e) {
        this.props.showModal('login');
        e.preventDefault();
    }

    get_user() {
        this.setState({ loading: true });

        fetch(`${URL}api/auth/get_user`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                    return;
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1 && errors[i]!='')
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                }
                else {
                    this.props.login({user:resp.user,token:this.props.token});
                    this.setState({loading:false});

                }




            } catch (err) {
                console.log(err);
            }

        })
            .catch((err) => {
                console.log(err);
            });
    }

    render() {
        let wishlist = this.props.wishlist;

        return (
            <header className="header header-intro-clearance header-4">
                <div className="header-top">
                    <div className="container">
                        <div className="header-left">
                            <Link to="tel:#" style={{ marginRight: 20 }} className="d-none d-sm-flex"><i className="icon-phone"></i>(17) 3422-2131</Link>
                            <Link to="tel:#"><i className="fab fa-whatsapp"></i>(17) 99792-6125</Link>

                        </div>
                        <div className="header-right">
                            <ul className="top-menu">
                                <li>
                                    <Link to="#">Conta</Link>
                                    <ul>

                                        {/* <li>
                                            <div className="header-dropdown">
                                                <Link to="#">USD</Link>
                                                <div className="header-menu">
                                                    <ul>
                                                        <li><Link to="#">Eur</Link></li>
                                                        <li><Link to="#">Usd</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li> */}

                                        {/* <li>
                                            <div className="header-dropdown">
                                                <Link to="#">English</Link>
                                                <div className="header-menu">
                                                    <ul>
                                                        <li><Link to="#">English</Link></li>
                                                        <li><Link to="#">French</Link></li>
                                                        <li><Link to="#">Spanish</Link></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </li> */}

                                        {this.state.loading==false && this.props.token == undefined && <li><Link to="#signin-modal" data-toggle="modal" onClick={this.openLoginModal}>Entrar / Cadastre-se</Link></li>}
                                        {this.state.loading==false && this.props.token != undefined && <li><Link to="/conta"  >{this.props.user.name}</Link></li>}

                                    </ul>
                                </li>
                            </ul>
                        </div>
                       
                    </div>
                </div>

                <div className="header-middle">
                    <div className="container">
                        <div className="header-left">
                            <button className="mobile-menu-toggler">
                                <span className="sr-only">Menu Mobile</span>
                                <i className="icon-bars"></i>
                            </button>

                            <Logo logo="logo.png" />
                        </div>

                       

                                       </div>
                </div>

                <LoginModal />
               
            </header>
        )
    }
}

export const mapStateToProps = (state) => ({
    wishlist: state.wishlist.list,
    user: state.user.user,
    token: state.user.token,
})

export default connect(mapStateToProps, { showModal,removeFromWishlist,login,logout })(HeaderSeven);